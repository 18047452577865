import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bar-chart',
  imports: [CommonModule],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) data: T | null = null;
  @Output() reload = new EventEmitter<any>();

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private xAxis!: any;
  private yAxis!: any;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initMap();
      }
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose(); // Dispose previous chart to prevent memory leaks
    }
    const colorArray = [am5.color('#4763E4'), am5.color('#C0C3FF')];
    this.root = am5.Root.new('barChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
      })
    );

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'productName',
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 30,
        }),
      })
    );
    this.xAxis.get('renderer').grid.template.set('visible', false);
    this.xAxis.get('renderer').labels.template.set('visible', false);

    const validData = Array.isArray(this.data) ? this.data : [];
    this.xAxis.data.setAll(validData);

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      })
    );
    this.yAxis.get('renderer').labels.template.setAll({
      fontSize: 14,
      paddingLeft: -15,
      paddingRight: 15,
    });
    this.yAxis.get('renderer').grid.template.set('visible', false);

    const series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Scans',
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: 'scans',
        categoryXField: 'productName',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{categoryX}: {valueY} scans',
        }),
      })
    );
    series.data.setAll(validData);

    // Style the columns
    series.columns.template.setAll({
      fill: am5.color('#4763E4'),
      stroke: am5.color('#FFFFFF'),
      strokeWidth: 2,
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      tooltipText: '{categoryX}: {valueY} scans',
    });

    series.appear(1000);
    this.chart.appear(1000, 100);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
