import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { Store } from '@ngxs/store';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-right-form-base',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [DrawerModule, CommonModule, ButtonModule],
  templateUrl: './right-form-base.component.html',
  styleUrl: './right-form-base.component.scss',
})
export class RightFormBaseComponent implements OnChanges {
  @Input() isVisible = false;
  @Input() title = '';
  @Input() widthClass = 'w-50';
  @Input() processing: boolean = false;
  @Output() sidebarHidden = new EventEmitter<void>();

  constructor(private store: Store) {}
  @ViewChild('sidebarRef') sidebarRef!: Drawer;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible'].currentValue) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  closeCallback(e: any): void {
    document.body.style.overflow = 'auto';
    this.clearUploadedFiles();
    this.sidebarRef.close(e);
  }
  clearUploadedFiles() {
    this.store.dispatch(new ClearUploadedFiles());
  }
}
