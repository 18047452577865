import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
  Activities,
  HeatMap,
  PaginationParams,
} from '../general-store/general.model';
import {
  DailyProductStatsResponse,
  DeviceAnalyticsResponse,
  ExpirationAnalysisResponse,
  LocationAnalyticsResponse,
  LocationExpirationStatsResponse,
  ProductAnalyticsResponse,
  ProductLocationStatsResponse,
  QrMetricsResponse,
  RecallProductsAnalysisResponse,
  RecallProductsStatsResponse,
  TrackDashboardDataResponse,
} from './dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getActivityLogs(
    paginationParams: PaginationParams
  ): Observable<Activities> {
    return this.http
      .get<Activities>(`${this.apiUrl}/api/activities`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getChartsData(productId: number): Observable<HeatMap> {
    return this.http
      .get<any>(`${this.apiUrl}/api/qr-product-data/chart/${productId}`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getCteStatistics(productId: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/qr-product-data/cte-statistics/${productId}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }
  public getExpirationAnalysis(
    productId: number,
    filters: any
  ): Observable<ExpirationAnalysisResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<ExpirationAnalysisResponse>(
        `${this.apiUrl}/api/qr-product-data/${productId}/expiration-analysis`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getQrMetrics(
    timeFrame: string,
    productId: number | null
  ): Observable<QrMetricsResponse> {
    let params = new HttpParams().set('timeFrame', timeFrame);
    if (productId) {
      params = params.set('productId', productId.toString());
    }
    return this.http
      .get<QrMetricsResponse>(`${this.apiUrl}/api/qr-product-data/metrics`, {
        params,
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getLocationAnalytics(
    filters: any
  ): Observable<LocationAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<LocationAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/location-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getDeviceAnalytics(filters: any): Observable<DeviceAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<DeviceAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/device-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getProductAnalytics(
    filters: any
  ): Observable<ProductAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<ProductAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/product-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getDailyProductStats(
    filters: any
  ): Observable<DailyProductStatsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<DailyProductStatsResponse>(
        `${this.apiUrl}/api/qr-product-data/daily-product-stats`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getLocationExpirationStats(
    productId: number,
    filters: any
  ): Observable<LocationExpirationStatsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<LocationExpirationStatsResponse>(
        `${this.apiUrl}/api/qr-product-data/${productId}/location-expiration-stats`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getRecallProductsAnalysis(
    productId: number,
    filters: any
  ): Observable<RecallProductsAnalysisResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<RecallProductsAnalysisResponse>(
        `${this.apiUrl}/api/recall-products/${productId}/recall-analysis`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getRecallProductsStats(): Observable<RecallProductsStatsResponse> {
    return this.http
      .get<RecallProductsStatsResponse>(
        `${this.apiUrl}/api/recall-products/recall-stats`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getTrackDashboardData(
    filters: any,
    productId: number
  ): Observable<TrackDashboardDataResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<TrackDashboardDataResponse>(
        `${this.apiUrl}/api/qr-product-data/${productId}/product-kde-progress-stats`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getProductLocationStats(
    filters: any,
    productId: number
  ): Observable<ProductLocationStatsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<ProductLocationStatsResponse>(
        `${this.apiUrl}/api/qr-product-data/${productId}/product-location-stats`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }
}
