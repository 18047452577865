<div>
  <h3>Subscription Details</h3>

  @if (isLoading$() || !user$()) {
    <div class="flex items-center justify-center w-[100%]">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  } @else if (user$()?.currentPlan) {
    <div class="bg-white p-5 rounded-lg flex items-center justify-between">
      @if (!hasHighestPlan) {
        <div class="flex items-center">
          <div>
            <span
              class="pi pi-credit-card text-primary-color font-bold mr-3 text-xl"></span>
          </div>
          <div>
            You are currently subscribed to
            <span class="text-primary-color font-bold">{{
              user$()?.currentPlan?.productDetails?.name
            }}</span
            >. Upgrade to unlock more limits!
          </div>
        </div>
      } @else {
        <div class="flex items-center">
          <div>
            <span
              class="pi pi-credit-card text-primary-color font-bold mr-3 text-xl"></span>
          </div>
          <div>
            You are currently subscribed to
            <span class="text-primary-color font-bold">{{
              user$()?.currentPlan?.productDetails?.name
            }}</span
            >. You can enjoy highest level of features!
          </div>
        </div>
      }
      <div>
        <p-button [routerLink]="'/subscription/plans'">View Plans</p-button>
      </div>
    </div>
    <div class="grid grid-cols-5 gap-3 mt-3">
      @for (usage of subscriptionUsage$(); track usage) {
        <div class="bg-white rounded-lg p-5">
          <p class="text-5xl font-bold mt-5 mb-5">
            {{ usage.used | numberK }}
            <sub
              ><small class="font-normal text-sm"
                >/ {{ usage.total | numberK }}</small
              ></sub
            >
          </p>
          <p>
            <small>{{ usage.label }}</small>
          </p>
        </div>
      }
    </div>
  } @else {
    <app-no-subscription-banner
      class="block"
      title="Subscribe to Continue!"
      description="Actions on this platform are available to subscribed users only. Please
          upgrade your account to proceed."></app-no-subscription-banner>
  }
</div>
