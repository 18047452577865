import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { ActivatedRoute } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AcceptInvite } from '../../../../store/invitations-store/invitation.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    CheckboxModule,
    MessageModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    IconFieldModule,
    InputIconModule,
    CommonModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
  form: FormGroup;
  isChecked = false;
  passwordVisibility = false;
  confirmPasswordVisibility = false;
  token = '';
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: [
          { value: '', disabled: true },
          [Validators.required, Validators.email],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );

    this.form.get('password')!.valueChanges.subscribe(password => {
      this.validatePasswordStrength(password);
    });

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      const decoded = jwtDecode(this.token) as { email: string };
      this.form.get('email')?.setValue(decoded.email);
    });
  }

  validatePasswordStrength(password: string) {
    this.passwordValidations.forEach(validation => {
      validation.valid = validation.regex.test(password);
    });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      confirmPassword?.setErrors({ mismatch: true });
    } else {
      confirmPassword?.setErrors(null);
    }
  }

  passwordValidations = [
    {
      message: 'Contains at least one uppercase character',
      regex: /[A-Z]/,
      valid: false,
    },
    {
      message: 'Contains at least one lowercase character',
      regex: /[a-z]/,
      valid: false,
    },
    {
      message: 'Contains at least one special character',
      regex: /[^a-zA-Z0-9]/,
      valid: false,
    },
    { message: 'Contains at least one number', regex: /[0-9]/, valid: false },
  ];

  signup() {
    const values = this.form.value;
    this.store.dispatch(
      new AcceptInvite({
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        token: this.token,
      })
    );
  }

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
  }
}
