import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import LogRocket from 'logrocket';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { NgClass } from '@angular/common';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../store/users-store/user.selectors';
import { AUTH_ROUTES } from '../constants';
import { AppStartAction } from '../store/general-store/general.actions';
import { NoSubscriptionBannerComponent } from './components/shared-components/no-subscription-banner/no-subscription-banner.component';
import { filter } from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

LogRocket.init('d6wj4w/regenesis');

LogRocket.identify('App Started');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    SidebarComponent,
    NgClass,
    NoSubscriptionBannerComponent,
    ConfirmDialogModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'regenesis';
  isSidebarExpanded = false;
  currUser$ = this.store.selectSignal(UserStateSelectors.getUser);
  showSubscriptionBanner: boolean = true;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store.dispatch(new AppStartAction());
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd) // Only consider navigation end events
      )
      .subscribe((event: any) => {
        if (event.url.includes('subscription')) {
          this.showSubscriptionBanner = false;
        } else {
          this.showSubscriptionBanner = true;
        }
      });
  }

  toggleSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  notAnAuthRoute() {
    return !AUTH_ROUTES.includes(this.router.url.split('?')[0]);
  }
}
