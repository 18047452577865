<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
