import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NotificationService);
  const confirmationService = inject(ConfirmationService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      document.body.style.overflow = 'auto';
      let errorMessage = '';
      if (error?.error?.error?.message) {
        errorMessage = error.error.error.message;
      } else if (error?.error?.message) {
        errorMessage = error.error.message;
      } else if (error?.message) {
        errorMessage = error.message;
      } else {
        errorMessage = 'Unexpected error occurred';
      }
      if (error.status === 401) {
      }
      else if (error.status === 403) {
        confirmationService.confirm({
          header: `SESSION EXPIRED`,
          message: `Your session has been expired. Please re-login to continue!`,
          icon: 'pi pi-exclamation-circle',
          acceptLabel: 'Login',
          acceptIcon: 'pi',
          acceptButtonStyleClass: 'p-button-primary',
          rejectVisible: false,
          accept: () => {
            localStorage.clear();
            window.location.href = '/';
          },
        });
      }
      else if (error.status === 402) {
        confirmationService.confirm({
          header: `SUBSCRIPTION ERROR`,
          message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
          icon: 'pi pi-exclamation-circle',
          acceptLabel: 'View Plans',
          acceptIcon: 'pi',
          acceptButtonStyleClass: 'p-button-primary',
          rejectVisible: false,
          accept: () => {
            router.navigateByUrl('/subscription/plans');
          },
        });
      }
      else if (error.status === 403 && req.method === 'GET') {
        router.navigateByUrl('/no-access');
      }
      else {
        notificationService.openErrorToast(errorMessage);
      }
      return throwError(() => error);
    })
  );
};
