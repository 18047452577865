import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}
  openSuccessToast(message: string) {
    this.toastr.success(message);
  }

  openErrorToast(message: string) {
    this.toastr.error(message);
  }

  openValidationToast(message: Array<string>) {
    message.forEach((detail: any) => {
      this.toastr.error(detail.message);
    });
  }
}
