import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { Recall } from '../../../../store/recall-management-store/recall-management.model';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import {
  ResendOtp,
  ResetUserState,
  SendOtp,
  ValidateOtp,
} from '../../../../store/users-store/user.actions';
import { DialogModule } from 'primeng/dialog';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { NgOtpInputComponent } from 'ng-otp-input';
import {
  ResetRecall,
  UpdateRecallStatus,
} from '../../../../store/recall-management-store/recall-management.actions';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { SignaturePad, SignaturePadModule } from 'angular2-signaturepad';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { NotificationService } from '../../../services/notification.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TabsModule } from 'primeng/tabs';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';

@Component({
  selector: 'app-initiate-recall-view',
  imports: [
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    CountdownComponent,
    NgOtpInputComponent,
    CommonModule,
    SignaturePadModule,
    RightFormHeaderComponent,
    TabsModule,
  ],
  templateUrl: './initiate-recall-view.component.html',
  styleUrl: './initiate-recall-view.component.scss',
})
export class InitiateRecallViewComponent {
  @ViewChild('cd', { static: false }) countdown: CountdownComponent | undefined;
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:
    | NgOtpInputComponent
    | undefined;
  @Input() type?: string;
  @Input() title!: string;
  @Input() data?: Recall;
  @Input() id?: number;
  @Output() actionSuccess = new EventEmitter<boolean>();

  signatureImage: string | null = null;
  signaturePadOptions = {
    minWidth: 1,
    canvasWidth: 400,
    canvasHeight: 300,
  };

  bucketUrl = environment.bucketUrl;
  form: FormGroup;
  otpDialogVisible: boolean = false;
  signatureDialogVisible: boolean = false;
  otp: string | null = null;
  timeLeft = 120;
  resendDisabled = true;
  action: string = '';

  currUser$ = this.store.selectSignal(UserStateSelectors.getUser);
  isRecallOtpVerified$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isRecallOtpVerified
  );
  authProcessing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);
  uploadInProgress$ = this.store.selectSignal(
    GeneralStateSelectors.isUploadInProgress
  );

  previewFile: SafeResourceUrl | null = null;
  previewType: string | null = null;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private notificationService: NotificationService,
    public sanitizer: DomSanitizer
  ) {
    this.countdown?.stop();
    this.form = this.fb.group({
      approvedOrRejectReason: ['', [Validators.required]],
      signature: [''],
    });

    effect(() => {
      if (this.isRecallOtpVerified$() && this.id) {
        this.store
          .dispatch(
            new UpdateRecallStatus(this.id, {
              status: this.action,
              approvedOrRejectReason: this.form.value.approvedOrRejectReason,
              signature: this.form.value.signature,
            })
          )
          .subscribe(() => {
            this.otpDialogVisible = false;
            this.otp = null;
            this.ngOtpInput?.setValue('');
            this.store.dispatch(new ResetRecall());
            this.store.dispatch(new ResetUserState());
            this.store.dispatch(new ClearUploadedFiles());
            this.actionSuccess.emit(true);
          });
      }
    });

    effect(() => {
      if (
        this.uploadedFiles$()[`signature`] &&
        !this.uploadedFiles$()[`signature`][0].uploading
      ) {
        this.form
          .get('signature')
          ?.patchValue(this.uploadedFiles$()[`signature`][0].objectURL);
        this.sendOtp();
      }
    });
  }

  saveSignature() {
    if (this.signaturePad.isEmpty()) {
      this.notificationService.openErrorToast(
        'You cannot upload empty signature!'
      );
    } else {
      this.signatureImage = this.signaturePad.toDataURL();
      const file = this.base64ToFile(this.signatureImage, 'signature.png');
      this.store.dispatch(new GeneratePresignedUrl(file, 'signature'));
    }
  }

  base64ToFile(base64: string, filename: string): File {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getStatusClass(status: string | undefined) {
    switch (status) {
      case 'APPROVED':
        return 'bg-green-500';
      case 'REJECTED':
        return 'bg-red-500';
      case 'COMPLETED':
        return 'bg-primary';
      case 'PENDING':
        return 'bg-gray-500';
      default:
        return 'bg-white';
    }
  }

  onOtpDialogClose() {
    this.otp = null;
    this.ngOtpInput?.setValue('');
    this.countdown?.stop();
  }

  onSignatureDialogClose() {
    this.signatureImage = null;
    this.signaturePad.clear();
  }

  getAuthorizedUsers(users: Recall['authorizedUsers'] | undefined) {
    if (!users) return;
    return users.map(u => u.user.firstName).join(', ');
  }

  getFileName(url: string, maxLength = 50) {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + '...'
      : fileName;
  }

  getFileType(url: string): string {
    const extension = url.split('.').pop()?.toLowerCase() ?? 'unknown';
    if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
      return 'image';
    } else if (extension === 'pdf') {
      return 'pdf';
    }
    return 'other';
  }

  sendOtp() {
    this.countdown?.restart();
    this.store.dispatch(new SendOtp('RECALL')).subscribe(() => {
      this.signatureDialogVisible = false;
      this.otpDialogVisible = true;
    });
  }

  resendOtp() {
    this.resendDisabled = true;
    this.store.dispatch(new ResendOtp(this.currUser$()?.id ?? 1));
    this.countdown?.restart();
  }

  validateOtp() {
    if (this.otp && this.otp.length === 4) {
      this.store.dispatch(
        new ValidateOtp(this.currUser$()?.id ?? 0, parseInt(this.otp), 'RECALL')
      );
    }
  }

  onOtpChange(value: string) {
    if (value === this.otp) return;
    this.otp = value;
  }

  countdownEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.resendDisabled = false;
    }
  }
}
