import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'map-filter',
  standalone: true,
  imports: [FormsModule, CommonModule],
  template: `
    <div class="filter-container">
      <label for="start-date">From:</label>
      <input
        type="date"
        id="start-date"
        [(ngModel)]="startDate"
        (change)="onDateChange()"
        class="form-control" />

      <label for="end-date">To:</label>
      <input
        type="date"
        id="end-date"
        [(ngModel)]="endDate"
        (change)="onDateChange()"
        class="form-control" />

      <div *ngIf="error" class="error-message">{{ error }}</div>
    </div>
  `,
  styleUrls: ['./map-filter.component.scss'],
})
export class MapFilterComponent {
  @Output() dateRangeChange = new EventEmitter<{
    startDate: string;
    endDate: string;
  }>();
  startDate: string = '';
  endDate: string = '';
  error: string | null = null;

  onDateChange() {
    // Validate the date range
    if (
      this.startDate &&
      this.endDate &&
      new Date(this.startDate) > new Date(this.endDate)
    ) {
      this.error = 'End date must be greater than or equal to the start date.';
    } else {
      this.error = null;
      this.dateRangeChange.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
  }
}
