<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div>
      <p-button [outlined]="true" label="Export CSV" icon="pi pi-download" />
    </div>
  </div>
  <div class="grid gap-4 mt-4">
    <app-dashboard-filters
      [allowedFilters]="['PRODUCT', 'DATE_RANGE', 'CTE', 'KDE', 'KDE_VALUE']"
      (selectedFilters)="setFilters($event)"></app-dashboard-filters>

    <app-track-map [points]="productLocationStats$()"></app-track-map>
    <app-gantt-chart
      [dataset]="productId !== 0 ? trackDashboardData$() : []"
      [overlay]="overlay"></app-gantt-chart>

    <app-cluster-columns
      [dataset]="productId !== 0 ? trackDashboardData$() : []"
      [overlay]="overlay"></app-cluster-columns>
    <div class="mt-6">
      <div
        class="bg-white rounded w-full h-[15vh] p-5 overflow-hidden relative flex items-center justify-between">
        <div class="flex items-center">
          <img src="assets/reports.png" alt="Image" class="w-40 mr-4" />
          <div>
            <h3>For Trace an Item Level Product</h3>
            <p class="text-[#A7A9AC]">Click on button to move there!</p>
          </div>
        </div>
        <p-button label="Trace Dashboard" icon="pi pi-arrow-right" />
      </div>
    </div>
  </div>
</div>
