<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form" class="full-height-form">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2">
          <p>Vendor Name</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('name')?.invalid && form.get('name')?.touched
            "
            placeholder="Vendor Name"
            formControlName="name" />
          <app-error-message
            *ngIf="form.get('name')?.invalid && form.get('name')?.touched"
            [control]="form.get('name')"
            label="Vendor Name"></app-error-message>
        </div>
        <div>
          <p>Vendor Type</p>
          <p-select
            formControlName="type"
            [options]="organizationTypes$()"
            [editable]="isOther"
            (onChange)="onVendorTypeChange($event.value)"
            optionValue="value"
            optionLabel="key"
            placeholder="Select Vendor Type"
            class="w-full"
            [class.input-danger]="
              form.get('type')?.invalid && form.get('type')?.touched
            " />
          <app-error-message
            *ngIf="form.get('type')?.invalid && form.get('type')?.touched"
            [control]="form.get('type')"
            label="Vendor Type"></app-error-message>
        </div>
        <div>
          <p>Country</p>
          <p-select
            formControlName="countryCode"
            [options]="countries"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Country"
            class="w-full"
            (onChange)="getCities($event.value)"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            filter="true"
            filterBy="name"
            [class.input-danger]="
              form.get('countryCode')?.invalid &&
              form.get('countryCode')?.touched
            " />
          <app-error-message
            *ngIf="
              form.get('countryCode')?.invalid &&
              form.get('countryCode')?.touched
            "
            [control]="form.get('countryCode')"
            label="Country"></app-error-message>
        </div>
        <div>
          <p>City</p>
          <p-select
            formControlName="city"
            [options]="cities$()"
            optionLabel="name"
            optionValue="id"
            placeholder="Select City"
            class="w-full"
            filter="true"
            filterBy="name"
            [class.input-danger]="
              form.get('city')?.invalid && form.get('city')?.touched
            " />
          <app-error-message
            *ngIf="form.get('city')?.invalid && form.get('city')?.touched"
            [control]="form.get('city')"
            label="City"></app-error-message>
        </div>
        <div>
          <p>GLN (Global Location Number)</p>
          <input
            pInputText
            type="text"
            class="w-full"
            [class.input-danger]="
              form.get('gln')?.invalid && form.get('gln')?.touched
            "
            placeholder="0123456789123"
            formControlName="gln" />
          <app-error-message
            *ngIf="form.get('gln')?.invalid && form.get('gln')?.touched"
            [control]="form.get('gln')"
            label="GLN"></app-error-message>
        </div>

        <div>
          <p>Vendor Email Address</p>
          <input
            pInputText
            type="vendorEmail"
            class="w-full"
            [class.input-danger]="
              form.get('vendorEmail')?.invalid &&
              form.get('vendorEmail')?.touched
            "
            placeholder="example@example.com"
            formControlName="vendorEmail" />
          <app-error-message
            *ngIf="
              form.get('vendorEmail')?.invalid &&
              form.get('vendorEmail')?.touched
            "
            [control]="form.get('vendorEmail')"
            label="Vendor Email Address"></app-error-message>
        </div>
        <div>
          <p>Vendor Phone</p>
          <p-inputGroup>
            <p-inputGroupAddon>
              <strong>{{
                getCountryCode(form.get('countryCode')?.value)
              }}</strong>
            </p-inputGroupAddon>
            <input
              type="tel"
              formControlName="phone"
              placeholder="1233445567"
              pInputText
              class="w-full"
              [class.input-danger]="
                form.get('phone')?.invalid && form.get('phone')?.touched
              " />
          </p-inputGroup>
          <app-error-message
            *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched"
            [control]="form.get('phone')"
            label="Vendor Phone"></app-error-message>
        </div>
        <div class="col-span-2">
          <p>Logo</p>
          <app-document-upload
            [singleMode]="true"
            [supportedFormats]="'image/svg+xml, image/png, image/jpeg'"
            [fileType]="'logo'"></app-document-upload>
        </div>
        <div>
          <p>Contact Person</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('contactPerson')?.invalid &&
              form.get('contactPerson')?.touched
            "
            placeholder="Contact Person"
            formControlName="contactPerson" />
          <app-error-message
            *ngIf="
              form.get('contactPerson')?.invalid &&
              form.get('contactPerson')?.touched
            "
            [control]="form.get('contactPerson')"
            label="Contact Person"></app-error-message>
        </div>
        <div>
          <p>Contact Person Designation</p>
          <p-select
            #contactPersonDesignation
            formControlName="contactPersonDesignation"
            [options]="designationsWithOthers$()"
            [editable]="isOther"
            (onChange)="onDesginationChange($event.value)"
            optionLabel="key"
            optionValue="value"
            placeholder="Select Contact Person Designation"
            class="w-full"
            [class.input-danger]="
              form.get('contactPersonDesignation')?.invalid &&
              form.get('contactPersonDesignation')?.touched
            " />
          <app-error-message
            *ngIf="
              form.get('contactPersonDesignation')?.invalid &&
              form.get('contactPersonDesignation')?.touched
            "
            [control]="form.get('contactPersonDesignation')"
            label="Contact Person Designation"></app-error-message>
        </div>
        <div class="col-span-2">
          <p>Business Address</p>
          <textarea
            type="text"
            formControlName="address"
            placeholder="Chicago"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('address')?.invalid && form.get('address')?.touched
            "></textarea>
          <app-error-message
            *ngIf="form.get('address')?.invalid && form.get('address')?.touched"
            [control]="form.get('address')"
            label="Business Address"></app-error-message>
        </div>
      </div>
    </form>
  </div>
</div>
