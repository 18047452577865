import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';

interface City {
  title: string;
  latitude: number;
  longitude: number;
  date: string;
  count?: number;
}

@Component({
  selector: 'app-cluster-map',
  standalone: true,
  imports: [],
  templateUrl: 'cluster-map.component.html',
  styleUrls: ['./cluster-map.component.scss'],
})
export class ClusterMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() height = '400px';
  @Input() dateFilter: { startDate: string; endDate: string } | undefined;
  private root!: am5.Root;
  private chart!: am5map.MapChart;
  private bubbleSeries!: am5map.MapPointSeries;

  private cities: City[] = [
    {
      title: 'United States',
      latitude: 37.0902,
      longitude: -95.7129,
      date: '2024-01-01',
      count: 100,
    },
    {
      title: 'United Kingdom',
      latitude: 55.3781,
      longitude: -3.436,
      date: '2024-01-02',
      count: 90,
    },
    {
      title: 'China',
      latitude: 35.8617,
      longitude: 104.1954,
      date: '2024-03-01',
      count: 80,
    },
    {
      title: 'India',
      latitude: 20.5937,
      longitude: 78.9629,
      date: '2024-04-01',
      count: 100,
    },
    {
      title: 'Australia',
      latitude: -25.2744,
      longitude: 133.7751,
      date: '2024-05-01',
      count: 70,
    },
    {
      title: 'Canada',
      latitude: 56.1304,
      longitude: -106.3468,
      date: '2024-03-01',
      count: 100,
    },
    {
      title: 'Brazil',
      latitude: -14.235,
      longitude: -51.9253,
      date: '2024-04-01',
      count: 60,
    },
    {
      title: 'South Africa',
      latitude: -30.5595,
      longitude: 22.9375,
      date: '2024-05-01',
      count: 100,
    },
  ];
  ngOnInit(): void {
    this.createChart();
    setInterval(() => {
      this.updateData();
    }, 2000);
    this.updateCityMarkers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateFilter']) {
      this.updateCityMarkers();
    }
  }

  private createChart() {
    this.root = am5.Root.new('chartdiv');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        panX: 'rotateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
      })
    );

    const zoomControl = this.chart.set(
      'zoomControl',
      am5map.ZoomControl.new(this.root, {})
    );
    zoomControl.homeButton.set('visible', true);

    const polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ'],
      })
    );

    polygonSeries.mapPolygons.template.setAll({});

    this.bubbleSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: 'count',
        calculateAggregates: true,
        // polygonIdField: 'id',
        latitudeField: 'latitude',
        longitudeField: 'longitude',
      })
    );

    const circleTemplate = am5.Template.new<am5.Circle>({
      radius: 20,
    });
    this.bubbleSeries.bullets.push((root, series, dataItem) => {
      let container = am5.Container.new(root, {});

      let circle = container.children.push(
        am5.Circle.new(
          root,
          {
            fillOpacity: 0.7,
            fill: am5.color(0xff0000),
            cursorOverStyle: 'pointer',
            tooltipText: `{title}: [bold]{count}[/]`,
          },
          circleTemplate
        )
      );

      let countryLabel = container.children.push(
        am5.Label.new(root, {
          text: '{title}',
          paddingLeft: 20,
          populateText: true,
          fontWeight: 'bold',
          fontSize: 13,
          centerY: am5.p50,
        })
      );

      circle.on('radius', radius => {
        countryLabel.set('x', radius);
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });
    this.bubbleSeries.bullets.push((root, series, dataItem) => {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{count.formatNumber('#.')}",
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: 'center',
        }),
        dynamic: true,
      });
    });

    // Heat rule for bubble radius
    this.bubbleSeries.set('heatRules', [
      {
        target: circleTemplate,
        dataField: 'count',
        min: 10,
        max: 50,
        minValue: 0,
        maxValue: 100,
        key: 'radius',
      },
    ]);
  }

  private updateCityMarkers() {
    if (!this.bubbleSeries) return;

    this.bubbleSeries.data.clear();

    const filteredCities = this.cities.filter(city => {
      if (!this.dateFilter?.startDate || !this.dateFilter?.endDate) return true;

      const cityDate = new Date(city.date);
      const startDate = new Date(this.dateFilter.startDate);
      const endDate = new Date(this.dateFilter.endDate);

      return cityDate >= startDate && cityDate <= endDate;
    });

    const cityData = filteredCities.map(city => ({
      title: city.title,
      longitude: city.longitude,
      latitude: city.latitude,
      count: city.count || 1,
    }));
  }
  updateData() {
    const updatedData = this.cities.map(city => ({
      title: city.title,
      latitude: city.latitude,
      longitude: city.longitude,
      count: Math.round(Math.random() * 100),
    }));

    this.bubbleSeries.data.setAll(updatedData);
    this.bubbleSeries.appear(1000);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
