<div class="flex flex-col">
  <div class="sticky top-0 p-5 z-10 bg-white">
    <h3>Activity Feed</h3>
    <p-divider class="!m-0"></p-divider>
  </div>
  <div class="flex-1">
    <div class="px-3" (scroll)="onScroll($event)">
      @for (activity of activityLogs$().payload; track $index) {
        <div class="flex items-center justify-start gap-2 mb-3">
          <div>
            <img
              [src]="activity?.user?.photo ?? bucketUrl + '/images/avatar.png'"
              class="w-[50px] h-[50px] object-cover rounded"
              [alt]="activity?.user?.firstName" />
          </div>

          <div>
            <p class="font-bold m-0">
              {{
                '@' + activity?.user?.firstName + ' ' + activity?.user?.lastName
              }}
            </p>
            <p class="text-sm m-0">
              {{ activity.description }}
            </p>
            <p class="text-sm text-primary m-0">
              {{ activity.createdAt | timeAgo }}
            </p>
          </div>
        </div>
      }
    </div>
  </div>
</div>
