<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex">
      <p-button
        appAccess="add-report"
        icon="pi pi-plus"
        label="Generate Custom Report"
        id="add-report-button"
        (click)="sidebar.visible = true" />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      export="REPORT"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>

<app-right-form-base
  [isVisible]="sidebar.visible"
  (sidebarHidden)="sidebar.visible = false"
  [title]="sidebar.title"
  [widthClass]="sidebar.width">
  <app-generate-report-form
    [data]="sidebar.data"
    [title]="sidebar.title"
    [type]="sidebar.type"
    [id]="sidebar.id"
    (actionSuccess)="sidebar.visible = false"></app-generate-report-form>
</app-right-form-base>
