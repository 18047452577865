<div class="relative">
  <div
    class="flex flex-col w-[72px] bg-dark text-white p-4 transition-all duration-75 relative justify-between h-screen"
    [ngClass]="{ '!w-[260px]': isExpanded }">
    <div>
      <div
        (click)="isExpanded = !isExpanded"
        class="absolute top-[15%] right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer transition-transform duration-200 hover:scale-110 z-50">
        <span
          class="flex items-center justify-center cursor-pointer rounded bg-primary h-[24px] w-[24px] transition-transform duration-200 hover:scale-110"
          [ngClass]="
            isExpanded ? 'pi pi-angle-left' : 'pi pi-angle-right'
          "></span>
      </div>

      <div *ngIf="isExpanded" class="py-5">
        <a [routerLink]="'/home'">
          <img src="assets/logos/logo-light.svg" alt="regenesis" />
        </a>
      </div>

      <div *ngIf="!isExpanded" class="py-5">
        <a [routerLink]="'/home'">
          <img src="assets/logos/logo-min-light.svg" alt="regenesis" />
        </a>
      </div>
    </div>

    <div class="h-[75vh] flex flex-col justify-between">
      <div class="flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide">
        <nav class="flex flex-col">
          @for (item of items; track item) {
            @if (checkPermissions(item['slugs'])) {
              <div
                class="relative rounded"
                [ngClass]="{ 'w-[40px] text-center': !isExpanded }">
                <a
                  [routerLink]="item.routerLink"
                  [ngClass]="{
                    'active-link':
                      (isMenuActive(item) && !hasActiveChild(item)) ||
                      (isMenuActive(item) && !isExpanded),
                    'active-link-parent': hasActiveChild(item) && isExpanded,
                  }"
                  class="flex items-center text-white p-3 hover:bg-gray-800 rounded duration-100 cursor-pointer"
                  (click)="toggleMenu(item, $event)">
                  <span [class]="item.icon"></span>
                  @if (isExpanded) {
                    <span class="ml-4 flex-grow mb-[-2px]">{{
                      item.label
                    }}</span>
                    @if (item.items?.length) {
                      <span
                        class="pi"
                        [ngClass]="{
                          'pi-angle-down': item.expanded,
                          'pi-angle-right': !item.expanded,
                        }"></span>
                    }
                  }
                </a>

                @if (item.items?.length && item.expanded && isExpanded) {
                  <div
                    class="border-l border-gray-600 ml-3 transition-all duration-200 ease-in-out">
                    @for (subItem of item.items; track subItem) {
                      @if (checkPermissions(subItem['slugs'])) {
                        <a
                          [routerLink]="subItem.routerLink"
                          routerLinkActive="active-link"
                          class="flex items-center text-white p-2 m-1 hover:bg-gray-800 rounded duration-100">
                          <span class="ml-4 mb-[-2px]">{{
                            subItem.label
                          }}</span>
                        </a>
                      }
                    }
                  </div>
                }
              </div>
            }
          }
        </nav>
      </div>
      <div>
        <nav class="flex flex-col">
          <div class="relative rounded">
            @for (item of profileItems; track item) {
              @if (checkPermissions(item['slugs'])) {
                <div
                  class="relative rounded"
                  [ngClass]="{ 'w-[40px] text-center': !isExpanded }">
                  <a
                    [routerLink]="item.routerLink"
                    [ngClass]="{
                      'active-link':
                        isMenuActive(item) ||
                        (isMenuActive(item) && !isExpanded),
                    }"
                    class="flex items-center text-white p-3 hover:bg-gray-800 rounded duration-100 cursor-pointer"
                    (click)="toggleProfileMenu(item, $event)">
                    <span [class]="item.icon"></span>
                    @if (isExpanded) {
                      <span class="ml-4 flex-grow mb-[-2px]">{{
                        item.label
                      }}</span>
                    }
                  </a>
                </div>
              }
            }
          </div>
        </nav>
      </div>
      <div class="flex flex-col">
        <nav class="flex flex-col flex-1 overflow-y-auto scrollbar-hide">
          <div class="relative rounded">
            <a
              class="flex items-center text-white p-3 rounded transition-transform duration-200 cursor-pointer gap-2"
              (click)="toggleLogout()">
              <img
                [src]="user$()?.photo ?? bucketUrl + '/images/avatar.png'"
                class="rounded-full w-[25px] h-[25px] object-cover" />
              <div class="flex items-center justify-between gap-2 w-full">
                <div>
                  <div class="text-xs font-bold">
                    {{ user$()?.firstName }} {{ user$()?.lastName }}
                  </div>
                  <div class="text-xs">
                    {{ user$()?.roleName | textTransform }}
                  </div>
                  <div class="text-xs italic">
                    {{ getOrganizationName() }}
                  </div>
                </div>
                <span
                  class="pi text-lg transition-transform duration-200"
                  [ngClass]="{
                    'pi-angle-down': showLogout,
                    'pi-angle-right': !showLogout,
                  }">
                </span>
              </div>
            </a>
          </div>
          <div *ngIf="showLogout" class="p-3 transition-opacity duration-200">
            <a
              (click)="logout()"
              class="flex items-center text-white p-3 hover:bg-gray-800 rounded duration-100 cursor-pointer gap-2">
              <span class="pi pi-sign-out"></span>
              <span>Logout</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>
