import { ZoomTools } from "../../core/render/ZoomTools";
/**
 * A control that displays button for zooming [[MapChart]] in and out.
 *
 * @see {@link https://www.amcharts.com/docs/v5/charts/map-chart/map-pan-zoom/#Zoom_control} for more information
 * @important
 */
export class ZoomControl extends ZoomTools {
  _afterNew() {
    super._afterNew();
    this.addTag("zoomtools");
  }
  _prepareChildren() {
    super._prepareChildren();
    if (this.isPrivateDirty("chart")) {
      this.set("target", this.getPrivate("chart"));
    }
  }
}
Object.defineProperty(ZoomControl, "className", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: "ZoomControl"
});
Object.defineProperty(ZoomControl, "classNames", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: ZoomTools.classNames.concat([ZoomControl.className])
});
