import { MenuItem } from 'primeng/api';

export const SuperAdminMenu: MenuItem[] = [
  {
    label: 'Home',
    icon: 'pi pi-home',
    routerLink: ['/home'],
  },
  {
    label: 'Dashboards',
    icon: 'pi pi-th-large',
    routerLink: ['/dashboards'],
    items: [
      {
        label: 'Track Products',
        icon: 'pi pi-truck',
        routerLink: ['/dashboards/track-products'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
      {
        label: 'Scan Statistics',
        icon: 'pi pi-chart-bar',
        routerLink: ['/dashboards/scan-statistics'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
      {
        label: 'Product Expiration',
        icon: 'pi pi-calendar-clock',
        routerLink: ['/dashboards/product-expiration'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
    ],
  },
  {
    label: 'Organizations',
    icon: 'pi pi-building-columns',
    routerLink: ['/organizations/list'],
  },
  {
    label: 'Demo Requests',
    icon: 'pi pi-info-circle',
    routerLink: ['/organizations/demo-requests'],
  },
  {
    label: 'Admin Users',
    icon: 'pi pi-user-plus',
    routerLink: ['/users/admin'],
  },
];
export const ProfileMenu: MenuItem[] = [
  {
    label: 'Settings',
    icon: 'pi pi-cog',
    routerLink: ['/settings/profile-security'],
  },
  {
    label: 'Notifications',
    icon: 'pi pi-bell',
    routerLink: ['/settings/notifications'],
  },
];
export const OrganizationMenu: MenuItem[] = [
  {
    label: 'Home',
    icon: 'pi pi-home',
    routerLink: ['/home'],
    slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
  },
  {
    label: 'Dashboards',
    icon: 'pi pi-th-large',
    routerLink: ['/dashboards'],
    items: [
      {
        label: 'Track Products',
        icon: 'pi pi-truck',
        routerLink: ['/dashboards/track-products'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
      {
        label: 'Scan Statistics',
        icon: 'pi pi-chart-bar',
        routerLink: ['/dashboards/scan-statistics'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
      {
        label: 'Product Expiration',
        icon: 'pi pi-calendar-clock',
        routerLink: ['/dashboards/product-expiration'],
        slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
      },
    ],
  },
  {
    label: 'Users',
    icon: 'pi pi-users',
    routerLink: ['/users/list'],
    slugs: ['list-user'],
  },
  {
    label: 'Vendors',
    icon: 'pi pi-warehouse',
    routerLink: ['/vendors/list'],
    slugs: ['list-vendor'],
  },
  {
    label: 'Product Management',
    icon: 'pi pi-shopping-bag',
    slugs: ['list-product'],
    routerLink: ['/products'],
    items: [
      {
        label: 'Products',
        icon: 'pi pi-shopping-bag',
        routerLink: ['/products/list'],
        slugs: ['list-product'],
      },
      {
        label: 'Product Item Data',
        icon: 'pi pi-database',
        routerLink: ['/products/data'],
        slugs: ['list-product-data'],
      },
    ],
  },
  {
    label: 'QR Orders',
    icon: 'pi pi-qrcode',
    routerLink: ['/qr/qr-orders/list'],
    slugs: ['list-qr-order'],
  },
  {
    label: 'Recall Management',
    icon: 'pi pi-chevron-circle-left',
    slugs: ['list-vendor'],
    routerLink: ['/recall-management'],
    items: [
      {
        label: 'Recall Dashboard',
        icon: 'pi pi-directions-alt',
        routerLink: ['/recall-management/dashboard'],
        slugs: ['list-vendor'],
      },
      {
        label: 'Recall List',
        icon: 'pi pi-chevron-circle-left',
        routerLink: ['/recall-management/list'],
        slugs: ['list-vendor'],
      },
      {
        label: 'Recall Request List',
        icon: 'pi pi-directions-alt',
        routerLink: ['/recall-management/request-list'],
        slugs: ['list-vendor'],
      },
    ],
  },
  {
    label: 'Subscription',
    icon: 'pi pi-credit-card',
    routerLink: ['/subscription/plans'],
    slugs: ['create-subscription', 'view-subscription'],
  },
  {
    label: 'Custom Reports',
    icon: 'pi pi-address-book',
    routerLink: ['/reports/list'],
    slugs: ['list-cte-kde', 'list-role'],
  },
];

export const HeaderMenu: MenuItem[] = [
  {
    label: 'Profile',
    icon: 'pi pi-user',
    routerLink: ['/settings/profile-security'],
  },
  {
    separator: true,
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      localStorage.clear();
      window.location.href = '/';
    },
  },
];
