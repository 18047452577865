import { Component, effect } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetCteStatistics } from '../../../../store/dashboard-store/dashboard.actions';
import { CteMapStatistics } from '../../../../store/general-store/general.model';
import { Observable } from 'rxjs';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';
import { TrackAndTraceComponent } from '../../../components/charts/track-and-trace/track-and-trace.component';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { FilterComponent } from '../../../components/shared-components/filter/filter.component';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';

@Component({
  selector: 'app-analytical-dashboard',
  templateUrl: './analytical-dashboard.component.html',
  styleUrls: ['./analytical-dashboard.component.scss'],
  imports: [ActivityFeedComponent, TrackAndTraceComponent, FilterComponent],
  standalone: true,
})
export class AnalyticalDashboardComponent {
  pd$ = this.store.selectSignal(DashboardStateSelectors.getProductCteStats);
  products$ = this.store.selectSignal(ProductStateSelectors.getProducts);
  productData: Observable<CteMapStatistics[]> | undefined;

  selectAction = {
    action: [GetCteStatistics],
    payload: null,
  };
  constructor(private store: Store) {
    effect(() => {
      if (this.products$() && this.products$()[0]) {
        this.store.dispatch(new GetCteStatistics(this.products$()[0].id));
      }
    });
  }
}
