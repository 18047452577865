import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, catchError, switchMap, throwError, from } from 'rxjs';
import { RefreshToken } from '../../store/users-store/user.actions';

export const httpTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const store = inject(Store);
  let token = localStorage.getItem('regen_access_token');

  // Skip adding Authorization header for these requests
  if (req.url.includes('.s3.') || req.url.includes('api/auth')) {
    return next(req);
  }

  // Attach the token if available
  if (token) {
    req = req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        // Unauthorized: Dispatch NGXS action to refresh token
        const refreshToken = localStorage.getItem('regen_refresh_token') ?? '';
        return from(store.dispatch(new RefreshToken(refreshToken))).pipe(
          switchMap(() => {
            const newToken = localStorage.getItem('regen_access_token'); // Get updated token
            if (newToken) {
              // Retry the original request with the new token
              const newRequest = req.clone({
                setHeaders: { Authorization: `Bearer ${newToken}` },
              });
              return next(newRequest);
            }
            return throwError(() => error);
          }),
          catchError(refreshError => {
            return throwError(() => refreshError);
          })
        );
      }
      return throwError(() => error);
    })
  );
};
