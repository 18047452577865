import { Component, Input, OnInit, Signal, effect } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { GetProductList } from '../../../../store/products-store/products.actions';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    DropdownModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit {
  @Input({ required: true }) selectAction!: {
    action: any[];
    payload: any;
  };

  form: FormGroup;

  products$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getProducts
  );

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      product: ['', [Validators.required]],
    });
    effect(
      () => {
        if (this.products$() && this.products$().length > 0) {
          this.selectAction.action.map(action => {
            this.store.dispatch(new action(this.products$()[0].id));
          });
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.form.controls['product'].valueChanges.subscribe(value => {
      this.selectAction.action.map(action => {
        this.store.dispatch(new action(value.id));
      });
    });
    this.store.dispatch(
      new GetProductList({
        first: 0,
        rows: 100,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
        search: '',
        filters: '',
      })
    );
  }
}
