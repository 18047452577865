import { Selector } from '@ngxs/store';
import { ReportState } from './report.store';
import { ReportStateModel } from './report.model';

export class ReportStateSelectors {
  @Selector([ReportState])
  static getReports(state: ReportStateModel) {
    return state.reports;
  }

  @Selector([ReportState])
  static getViewReportData(state: ReportStateModel) {
    return state.reports;
  }

  @Selector([ReportState])
  static getReport(state: ReportStateModel) {
    return state.report;
  }

  @Selector([ReportState])
  static getVisualizationData(state: ReportStateModel) {
    return state.visualizationData;
  }

  @Selector([ReportState])
  static pagination(state: ReportStateModel) {
    return state.pagination;
  }

  @Selector([ReportState])
  static headers(state: ReportStateModel) {
    return state.headers;
  }

  @Selector([ReportState])
  static reportHeaders(state: ReportStateModel) {
    return state.reportHeaders;
  }

  @Selector([ReportState])
  static isProcessing(state: ReportStateModel) {
    return state.isProcessing;
  }
}
