import { PaginationParams } from '../general-store/general.model';
import { AddVendorParams } from './vendors.model';

const CONTEXT = '[VendorsStore]';

export class GetVendorsList {
  static readonly type = `${CONTEXT} Get Vendors List`;
  constructor(public payload: PaginationParams) {}
}

export class GetAllVendors {
  static readonly type = `${CONTEXT} Get All Vendors`;
}

export class CreateVendor {
  static readonly type = `${CONTEXT} Add Vendor`;
  constructor(public payload: AddVendorParams) {}
}

export class DeleteVendor {
  static readonly type = `${CONTEXT} Delete Vendor`;
  constructor(public id: number) {}
}

export class UpdateVendor {
  static readonly type = `${CONTEXT} Update Vendor`;
  constructor(
    public payload: AddVendorParams,
    public id: number
  ) {}
}
