import { Component, HostListener } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  ForgotPassword,
  ResetPassword,
} from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ButtonModule } from 'primeng/button';
@Component({
  selector: 'app-reset-password-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    IconFieldModule,
    InputIconModule,
    CommonModule,
    ButtonModule,
  ],
  templateUrl: './reset-password-form.component.html',
})
export class ResetPasswordFormComponent {
  form: FormGroup;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  passwordVisibility = false;
  confirmPasswordVisibility = false;
  token = '';
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
    this.form.get('password')!.valueChanges.subscribe(password => {
      this.validatePasswordStrength(password);
    });
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.resetPassword();
    }
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      confirmPassword?.setErrors({ mismatch: true });
    } else {
      confirmPassword?.setErrors(null);
    }
  }

  submitForgotPassword() {
    this.store.dispatch(new ForgotPassword(this.form.get('email')?.value));
  }

  validatePasswordStrength(password: string) {
    this.passwordValidations.forEach(validation => {
      validation.valid = validation.regex.test(password);
    });
  }

  passwordValidations = [
    {
      message: 'Contains at least one uppercase character',
      regex: /[A-Z]/,
      valid: false,
    },
    {
      message: 'Contains at least one lowercase character',
      regex: /[a-z]/,
      valid: false,
    },
    {
      message: 'Contains at least one special character',
      regex: /[^a-zA-Z0-9]/,
      valid: false,
    },
    { message: 'Contains at least one number', regex: /[0-9]/, valid: false },
  ];

  resetPassword() {
    if (this.form.invalid) return;
    this.store.dispatch(
      new ResetPassword(this.token, this.form.get('password')?.value)
    );
  }
}
