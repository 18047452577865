import { Component, OnInit, Signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { VendorStateSelectors } from '../../../../store/vendors-store/vendors.selectors';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DeleteVendor,
  GetVendorsList,
} from '../../../../store/vendors-store/vendors.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { Vendor } from '../../../../store/vendors-store/vendors.model';
import { AddVendorFormComponent } from '../../../components/form-components/add-vendor-form/add-vendor-form.component';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { User } from '../../../../store/users-store/user.model';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'app-vendor-list',
  standalone: true,
  imports: [
    ButtonModule,
    RightFormBaseComponent,
    DataTableComponent,
    BreadcrumbModule,
    AddVendorFormComponent,
    AccessDirective,
  ],
  templateUrl: './vendor-list.component.html',
  styleUrl: './vendor-list.component.scss',
})
export class VendorListComponent implements OnInit {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };

  data$: Signal<Vendor[]> = this.store.selectSignal(
    VendorStateSelectors.getVendors
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    VendorStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    VendorStateSelectors.isVendorStoreProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    VendorStateSelectors.getHeaders
  );

  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );

  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.getVendorList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
  }

  ngOnInit(): void {}

  openSidebar() {
    if (this.user$()?.subscriptionInfo.vendorsSupported) {
      this.sidebar = {
        visible: true,
        title: 'Add New Vendor',
        type: 'ADD',
        width: 'w-25',
      };
    } else {
      this.confirmationService.confirm({
        header: `SUBSCRIPTION ERROR`,
        message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'View Plans',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: false,
        accept: () => {
          this.router.navigateByUrl('/subscription/plans');
        },
      });
    }
  }

  getVendorList(refreshData = true) {
    if (refreshData) {
      this.store.dispatch(
        new GetVendorsList({
          first: 0,
          rows: this.rows,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
          search: this.search,
          filters: ObjectToUrlParams(this.selectedFilters),
        })
      );
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getVendorList();
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} VENDOR`,
        message: `Are you sure want to delete this vendor?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteVendor(action.id)).subscribe(() => {
            this.getVendorList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.sidebar = {
        visible: true,
        title: 'Edit Vendor',
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-50',
      };
    }
  }
}
