<div class="grid grid-cols-3 gap-5">
  <div>
    <div>
      <p class="font-bold m-0">Current Plan</p>
      <p class="text-sm mt-0">General Information of the subscribed plan</p>

      @if (user$()?.currentPlan) {
        <div class="bg-slate-50 p-4 rounded mb-3">
          <p class="text-sm m-0">Plan</p>
          <p class="font-bold m-0">
            {{ user$()?.currentPlan?.productDetails?.name }}
          </p>
          <small class="block mb-3"
            >${{ user$()!.currentPlan!.amount / 100 }} USD / month</small
          >
          <small class="bg-primary-color p-2 rounded-lg text-white">{{
            user$()!.subscriptionStatus.toUpperCase()
          }}</small>
        </div>
      } @else {
        <div class="bg-slate-50 p-5 rounded mb-3 text-center">
          <span class="text-white bg-black p-2 rounded text-sm"
            >Not Subscribed</span
          >
        </div>
      }

      <p-button [routerLink]="'/subscription/plans'">{{
        user$()?.currentPlan ? 'Change Plan' : 'View Plans'
      }}</p-button>
    </div>
  </div>
  <div>
    <div>
      <p class="font-bold m-0">Select Payment Method</p>
      <p class="text-sm mt-0">Select a payment method to continue</p>

      <div class="bg-slate-50 p-4 rounded mb-5">
        <p class="font-bold m-0">Credit / Debit Card</p>
        <p class="text-sm mt-0">
          We accept Master Card, Visa & American Express
        </p>

        <app-cards-list></app-cards-list>
      </div>
    </div>
  </div>
  <div>
    <div class="bg-slate-50 p-5 rounded">
      <p class="text-xl font-bold">Bill Summary</p>

      <div class="flex items-center justify-between">
        <p class="text-sm">Hamara Group</p>
        <p class="text-sm font-bold">USD 43,300</p>
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <p class="text-sm">Hamara Group SA</p>
        <p class="text-sm font-bold">USD 4,300</p>
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <p class="text-sm">Total</p>
        <p class="text-sm font-bold">USD 47,600</p>
      </div>
    </div>
  </div>
</div>
