import { Component, Input, NgZone, OnInit } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-donut',
  standalone: true,
  imports: [],
  templateUrl: './donut.component.html',
  styleUrl: './donut.component.scss',
})
export class DonutComponent implements OnInit {
  @Input() width = '100%';
  @Input() height = '200px';

  private root!: am5.Root;
  private chart!: am5map.MapChart;
  private series!: am5map.MapChart;

  constructor(private zone: NgZone) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      // Initialize chart
      this.root = am5.Root.new('donut');

      // Set themes
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      // Create chart
      const chart = this.root.container.children.push(
        am5percent.PieChart.new(this.root, {
          layout: this.root.verticalLayout,
          innerRadius: am5.percent(50),
        })
      );

      // Create series
      const series = chart.series.push(
        am5percent.PieSeries.new(this.root, {
          valueField: 'value',
          categoryField: 'category',
          alignLabels: false,
        })
      );

      series.labels.template.setAll({
        textType: 'circular',
        centerX: 0,
        centerY: 0,
      });
      series.labels.template.setAll({
        forceHidden: true,
      });
      // Set data
      series.data.setAll([
        { value: 394, category: 'Expired' },
        { value: 700, category: 'Near Expiration' },
        { value: 2500, category: 'Safe' },
      ]);

      // Create legend
      const legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.percent(0),
          x: am5.percent(0),
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10,
          layout: this.root.verticalLayout,
        })
      );

      legend.data.setAll(series.dataItems);

      // Animate series on load
      series.appear(1000, 100);
    });
  }

  ngOnDestroy(): void {
    // Dispose of the chart when the component is destroyed
    this.zone.runOutsideAngular(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
