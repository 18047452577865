import { Component, Signal, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { InviteVendorUserFormComponent } from '../../../components/form-components/invite-vendor-user-form/invite-vendor-user-form.component';
import { TabViewModule } from 'primeng/tabview';
import { SidebarData } from '../../../../store/general-store/general.model';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveUserListComponent } from './active-user-list/active-user-list.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { Store } from '@ngxs/store';
import { GetRoles } from '../../../../store/general-store/general.actions';
import { ConfirmationService } from 'primeng/api';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { User } from '../../../../store/users-store/user.model';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    ButtonModule,
    RightFormBaseComponent,
    InviteVendorUserFormComponent,
    TabViewModule,
    InvitationListComponent,
    ActiveUserListComponent,
    AccessDirective,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  @ViewChild(InvitationListComponent)
  invitationListComponent!: InvitationListComponent;

  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );

  pageTitle = '';
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-25',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.store.dispatch(new GetRoles());
  }

  openInviteUserSidebar() {
    if (this.user$()?.subscriptionInfo.canInviteUsers) {
      this.sidebar = {
        visible: true,
        title: 'Invite New User',
        type: 'INVITE',
        width: 'w-25',
      };
    } else {
      this.confirmationService.confirm({
        header: `SUBSCRIPTION ERROR`,
        message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'View Plans',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: false,
        accept: () => {
          this.router.navigateByUrl('/subscription/plans');
        },
      });
    }
  }
}
