import { Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';
import {
  UpdateUserSelf,
  UpdateUserSelfWithNotifications,
} from '../../../../store/users-store/user.actions';
@Component({
  selector: 'app-notifications',
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    ButtonModule,
    InputSwitchModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  constructor(private store: Store) {}
  categories = computed(() => this.user$()?.notifications || []);
  expandedStates: boolean[] = [];

  toggleEmailEnabled(category: any, event: Event) {
    event.stopPropagation();
    category.enabled = !category.enabled;
    const categoriesValue = this.categories();
    const updatedNotifications = categoriesValue.map((category: any) => ({
      name: category.name,
      items: category.items.map((item: any) => ({
        title: item.title,
        description: item.description,
        emailEnabled: item.emailEnabled,
      })),
    }));
    this.store.dispatch(
      new UpdateUserSelfWithNotifications({
        firstName: this.user$()?.firstName ?? '',
        lastName: this.user$()?.lastName ?? '',
        email: this.user$()?.email ?? '',
        photo: this.user$()?.photo ?? '',
        notifications: updatedNotifications,
      })
    );
  }
  toggleCategory(index: number) {
    this.expandedStates[index] = !this.expandedStates[index];
  }
}
