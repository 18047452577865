import { Selector } from '@ngxs/store';
import { UserStateModel } from './user.model';
import { UserState } from './user.state';

export class UserStateSelectors {
  @Selector([UserState])
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector([UserState])
  static isUserStoreProcessing(state: UserStateModel) {
    return state.authActionProcessing;
  }

  @Selector([UserState])
  static isLoading(state: UserStateModel) {
    return state.isLoading;
  }

  @Selector([UserState])
  static getHeaders(state: UserStateModel) {
    return state.headers;
  }

  @Selector([UserState])
  static getUsers(state: UserStateModel) {
    return state.users;
  }

  @Selector([UserState])
  static getPaginationData(state: UserStateModel) {
    return state.pagination;
  }
  @Selector([UserState])
  static getOrganizationLogo(state: UserStateModel) {
    return state.organizationLogoUrl;
  }
  @Selector([UserState])
  static getUserRole(state: UserStateModel) {
    return state.userRole;
  }
  @Selector([UserState])
  static getUserPermissions(state: UserStateModel) {
    return state.userAllowedPermissions;
  }

  @Selector([UserState])
  static isLoggedIn(state: UserStateModel) {
    return state.isLoggedIn;
  }
}
