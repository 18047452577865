import { Selector } from '@ngxs/store';
import { OrganizationStateModel } from './organizations.model';
import { OrganizationState } from './organizations.state';

export class OrganizationStateSelectors {
  @Selector([OrganizationState])
  static getOrganizations(state: OrganizationStateModel) {
    return state.organizations;
  }

  @Selector([OrganizationState])
  static getAllOrganizations(state: OrganizationStateModel) {
    return state.allOrganizations;
  }

  @Selector([OrganizationState])
  static getPaginationData(state: OrganizationStateModel) {
    return state.pagination;
  }

  @Selector([OrganizationState])
  static isOrganizationStoreProcessing(state: OrganizationStateModel) {
    return state.isProcessing;
  }

  @Selector([OrganizationState])
  static getHeaders(state: OrganizationStateModel) {
    return state.headers;
  }

  @Selector([OrganizationState])
  static getOrganizationCTEs(state: OrganizationStateModel) {
    return state.organizatonCTEs;
  }
}
