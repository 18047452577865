import { PaginationParams } from '../general-store/general.model';
import {
  AddCTEParams,
  AddOrganizationParams,
  OrganizationCTEsParams,
  OrganizationKDEsParams,
} from './organizations.model';

const CONTEXT = '[OrganizationStore]';
export class CreateOrganization {
  static readonly type = `${CONTEXT} Add Organization`;
  constructor(public payload: AddOrganizationParams) {}
}

export class GetOrganizationList {
  static readonly type = `${CONTEXT} Get Organization List`;
  constructor(public payload: PaginationParams) {}
}

export class GetAllOrganizations {
  static readonly type = `${CONTEXT} Get All Organizations`;
}

export class GetOrganizationCTEs {
  static readonly type = `${CONTEXT} Get Organization CTEs`;
}

export class DeleteOrganization {
  static readonly type = `${CONTEXT} Delete Organization`;
  constructor(public id: number) {}
}

export class DeleteOrganizationCTE {
  static readonly type = `${CONTEXT} Delete Organization CTE`;
  constructor(public id: number) {}
}

export class UpdateOrganizationCTE {
  static readonly type = `${CONTEXT} Update Organization CTE by ID`;
  constructor(
    public id: number,
    public payload: AddCTEParams
  ) {}
}

export class UpdateOrganization {
  static readonly type = `${CONTEXT} Update Organization`;
  constructor(
    public payload: AddOrganizationParams,
    public id: number
  ) {}
}

export class UpdateOrganizationCTEs {
  static readonly type = `${CONTEXT} Update Organization CTEs`;
  constructor(public payload: OrganizationCTEsParams) {}
}

export class UpdateOrganizationKDEs {
  static readonly type = `${CONTEXT} Update Organization KDEs`;
  constructor(public payload: OrganizationKDEsParams) {}
}

export class AddOrganizationCTE {
  static readonly type = `${CONTEXT} Add Organization CTE`;
  constructor(public payload: AddCTEParams) {}
}
