import { Directive, ElementRef, Input, Signal } from '@angular/core';
import { MergedPermission } from '../../store/users-store/user.model';
import { UserStateSelectors } from '../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';

@Directive({
  standalone: true,
  selector: '[appAccess]',
})
export class AccessDirective {
  userPermissions$: Signal<MergedPermission[]> = this.store.selectSignal(
    UserStateSelectors.getUserPermissions
  );

  constructor(
    private el: ElementRef,
    private store: Store
  ) {}

  @Input() set appAccess(role: string) {
    if (this.userPermissions$().some(p => p.slug === role && p.allowed)) {
      this.el.nativeElement.style.display = 'block';
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
