import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { ReportStateModel } from './report.model';
import { ReportService } from './report.services';
import {
  CreateCustomReport,
  CreateVisualization,
  DeleteReport,
  GetReport,
  GetReportList,
  GetViewReportData,
  GetVisualizationData,
  UpdateReport,
} from './report.actions';
import { Router } from '@angular/router';

@State<ReportStateModel>({
  name: 'Report',
  defaults: {
    isProcessing: false,
    reports: [],
    report: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    reportHeaders: [],
    visualizationData: null
  },
})
@Injectable({
  providedIn: 'root',
})
export class ReportState {
  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private readonly ngZone: NgZone,
    private readonly router: Router
  ) {}

  @Action(GetReportList)
  getReportList(
    { patchState }: StateContext<ReportStateModel>,
    action: GetReportList
  ) {
    patchState({ isProcessing: true });
    return this.reportService.getReports(action.payload).pipe(
      tap(async res => {
        patchState({
          reports: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetReport)
  getReport({ patchState }: StateContext<ReportStateModel>, action: GetReport) {
    patchState({ isProcessing: true });
    return this.reportService.getReport(action.id).pipe(
      tap(async res => {
        patchState({
          report: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetVisualizationData)
  getVisualizationData({ patchState }: StateContext<ReportStateModel>, action: GetVisualizationData) {
    patchState({ isProcessing: true });
    return this.reportService.getVisualizationData(action.reportId, action.visualizationId).pipe(
      tap(async res => {
        patchState({
          visualizationData: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetViewReportData)
  getViewReportData(
    { patchState }: StateContext<ReportStateModel>,
    action: GetViewReportData
  ) {
    patchState({ isProcessing: true });
    return this.reportService.getViewReportData(action.id, action.payload).pipe(
      tap(async res => {
        patchState({
          reports: res.payload,
          pagination: res.pagination,
          reportHeaders: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(CreateCustomReport)
  createCustomReport(
    { patchState }: StateContext<ReportStateModel>,
    action: CreateCustomReport
  ) {
    patchState({ isProcessing: true });
    return this.reportService.createCustomReport(action.payload).pipe(
      tap(res => {
        this.notificationService.openSuccessToast(
          'Report created successfully!'
        );
        this.ngZone.run(() => {
          this.router.navigate([`/reports/view/${res?.payload?.id}`]);
        });
      }),
      catchError(err => {
        console.error('Error creating report:', err);
        patchState({ isProcessing: false });
        return throwError(() => err);
      })
    );
  }

  @Action(CreateVisualization)
  createVisualization(
    { patchState }: StateContext<ReportStateModel>,
    action: CreateVisualization
  ) {
    patchState({ isProcessing: true });
    return this.reportService.createVisualization(action.id, action.payload).pipe(
      tap(res => {
        patchState({ isProcessing: false });
        this.notificationService.openSuccessToast(
          'Report Visualization created successfully!'
        );
      }),
      catchError(err => {
        console.error('Error creating report visualization:', err);
        patchState({ isProcessing: false });
        return throwError(() => err);
      })
    );
  }

  @Action(UpdateReport)
  updateProduct(
    { patchState }: StateContext<ReportStateModel>,
    action: UpdateReport
  ) {
    patchState({ isProcessing: true });
    return this.reportService.updateReport(action.id, action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Report updated successfully!'
        );
        this.ngZone.run(() => {
          this.router.navigate([`/reports/view/${action.id}`]);
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteReport)
  deleteReport(
    { patchState }: StateContext<ReportStateModel>,
    action: DeleteReport
  ) {
    return this.reportService.deleteReport(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Report deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }
}
