import { Component, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { DashboardFiltersComponent } from '../../../components/shared-components/dashboard-filters/dashboard-filters.component';
import { ButtonModule } from 'primeng/button';
import { TrackAndTraceComponent } from '../../../components/charts/track-and-trace/track-and-trace.component';
import {
  DailyProductStats,
  DeviceAnalytics,
  LocationAnalytics,
  ProductAnalytics,
} from '../../../../store/dashboard-store/dashboard.model';
import {
  GetDailyProductStats,
  GetDeviceAnalytics,
  GetLocationAnalytics,
  GetProductAnalytics,
} from '../../../../store/dashboard-store/dashboard.actions';
import { PieChartComponent } from '../../../components/charts/pie-chart/pie-chart.component';
import { BarChartComponent } from '../../../components/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from '../../../components/charts/line-chart/line-chart.component';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';

@Component({
  selector: 'app-track-n-trace-dashboard',
  templateUrl: './track-n-trace.component.html',
  styleUrls: ['./track-n-trace.component.scss'],
  imports: [
    DashboardFiltersComponent,
    ButtonModule,
    TrackAndTraceComponent,
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    ActivityFeedComponent,
  ],
  standalone: true,
})
export class TrackNTraceComponent {
  locationAnalytics$: Signal<LocationAnalytics[]> = this.store.selectSignal(
    DashboardStateSelectors.getLocationAnalytics
  );
  deviceAnalytics$: Signal<DeviceAnalytics[]> = this.store.selectSignal(
    DashboardStateSelectors.getDeviceAnalytics
  );
  productAnalytics$: Signal<ProductAnalytics[]> = this.store.selectSignal(
    DashboardStateSelectors.getProductAnalytics
  );
  dailyProductStats$: Signal<DailyProductStats[]> = this.store.selectSignal(
    DashboardStateSelectors.getDailyProductStats
  );
  selectedFilters: any = {};

  constructor(private readonly store: Store) {
    this.getLocationAnalytics();
    this.getDeviceAnalytics();
    this.getProductAnalytics();
    this.getDailyProductStats();
  }

  getLocationAnalytics() {
    this.store.dispatch(new GetLocationAnalytics(this.selectedFilters));
  }

  getDeviceAnalytics() {
    this.store.dispatch(new GetDeviceAnalytics(this.selectedFilters));
  }

  getProductAnalytics() {
    this.store.dispatch(new GetProductAnalytics(this.selectedFilters));
  }

  getDailyProductStats() {
    this.store.dispatch(new GetDailyProductStats(this.selectedFilters));
  }

  setFilters(event: any) {
    this.selectedFilters = event;
    this.getLocationAnalytics();
    this.getDeviceAnalytics();
    this.getProductAnalytics();
    this.getDailyProductStats();
  }
}
