<form [formGroup]="form" class="pt-6">
  <div class="grid grid-cols-6 gap-2">
    <div class="col-span-4">
      <p class="text-base font-normal">Role Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('name')?.invalid && form.get('name')?.touched
        "
        placeholder="Role Name"
        formControlName="name" />
      <app-error-message
        [control]="form.get('name')"
        label="Name"></app-error-message>
    </div>
    <div class="mt-[8px] col-span-2">
      <app-action-button
        [disabled]="!this.form.valid"
        [actionInProgress]="processing$()"
        (actionClicked)="updateRole()"
        label="Update"></app-action-button>
    </div>
  </div>
</form>

<p-tabView>
  <p-tabPanel header="Permissions">
    @if (role$()) {
      <div class="flex items-center justify-between mb-[1rem]">
        <h4>Select All</h4>
        <p-inputSwitch
          binary="true"
          [inputId]="'selectAll'"
          [(ngModel)]="selectAllPermission"
          (onChange)="toggleAllPermissions($event.checked)" />
      </div>
      @for (section of sections; track section) {
        <h4>{{ section }}</h4>
        @for (permission of groupedPermissions[section]; track permission.id) {
          <div class="flex items-center justify-between mb-[20px]">
            <div>
              <label [for]="permission.slug">{{ permission.name }}</label>
              <p class="m-[0px]">
                <small>{{ permission.slug }}</small>
              </p>
            </div>
            <p-inputSwitch
              binary="true"
              [inputId]="permission.slug"
              [(ngModel)]="permission.RolePermissions.allowed"
              (onChange)="updatePermission(permission)" />
          </div>
        }
      }
    }
  </p-tabPanel>
  <p-tabPanel header="CTEs">
    <div class="mt-[20px]">
      @if (role$()) {
        @for (cte of assignedCtes; track cte) {
          <div class="flex items-center justify-between mb-[20px]">
            <div>
              <label [for]="'cte' + cte.id">{{ cte.title }}</label>
              <p class="m-[0px]">
                <small>{{ cte.description }}</small>
              </p>
            </div>
            <p-inputSwitch
              binary="true"
              [inputId]="'cte' + cte.slug"
              [(ngModel)]="cte.RoleCte.allowed"
              (onChange)="updateCte(cte)" />
          </div>
        }
      }
    </div>
  </p-tabPanel>
  <p-tabPanel header="Products" *ngIf="showProductsTab">
    <div class="mt-[20px]">
      <div>
        <p-dropdown
          [(ngModel)]="selectedProduct"
          [options]="searchedProducts"
          optionLabel="productName"
          optionValue="id"
          optionDisabled="disabled"
          placeholder="Search & Assign Product"
          filterPlaceholder="Type here to search product..."
          emptyFilterMessage="No matching products found"
          emptyMessage="---"
          class="w-full"
          [virtualScroll]="true"
          [itemSize]="30"
          filter="true"
          filterBy="productName"
          (onFilter)="searchProducts($event.filter)"
          (onChange)="addRoleProduct($event.value)" />
      </div>
      @if (assignedProducts) {
        @for (product of assignedProducts; track product) {
          <div class="flex items-center justify-between mb-[20px] mt-[20px]">
            <div class="flex items-center">
              <div class="mr-[20px] text-[red]">
                <i
                  class="pi pi-trash cursor-pointer"
                  (click)="deleteProduct(product)"></i>
              </div>
              <div>
                <label [for]="'product' + product.gtin">{{
                  product.productName
                }}</label>
                <p class="m-[0px]">
                  <small>{{ product.gtin }}</small>
                </p>
              </div>
            </div>
            <div>
              <p-inputSwitch
                binary="true"
                [inputId]="'product' + product.gtin"
                [(ngModel)]="product.RoleProduct.allowed"
                (onChange)="updateProduct(product)" />
            </div>
          </div>
        }
      }
      @if (assignedProducts.length === 0) {
        <p class="text-center">No products assigned</p>
      }
    </div>
  </p-tabPanel>
</p-tabView>
<app-right-form-footer
  [showPrimaryActionButton]="false"
  (callCancel)="actionSuccess.emit(true)">
</app-right-form-footer>
