import { PaginationParams } from '../general-store/general.model';
import { AcceptInviteParams, AddInvitationParams } from './invitation.model';

const CONTEXT = '[InvitationStore]';

export class AddInvitation {
  static readonly type = `${CONTEXT} Add Invitation`;
  constructor(public payload: AddInvitationParams) {}
}

export class GetInvitationList {
  static readonly type = `${CONTEXT} Get Invitation List`;
  constructor(public payload: PaginationParams) {}
}

export class AcceptInvite {
  static readonly type = `${CONTEXT} Accept Invite User`;
  constructor(public payload: AcceptInviteParams) {}
}
