import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ButtonModule } from 'primeng/button';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { UpdatePasswordFormComponent } from '../../../components/form-components/update-password-form/update-password-form.component';
import { UpdateProfileFormComponent } from '../../../components/form-components/update-profile-form/update-profile-form.component';
import { SidebarData } from '../../../../store/general-store/general.model';
import { GetUser } from '../../../../store/users-store/user.actions';
import { FormatDatePipe } from '../../../pipes/format-date.pipe';

@Component({
  selector: 'app-profile-security',
  standalone: true,
  imports: [
    ButtonModule,
    RightFormBaseComponent,
    UpdatePasswordFormComponent,
    UpdateProfileFormComponent,
    FormatDatePipe,
  ],
  templateUrl: './profile-security.component.html',
  styleUrl: './profile-security.component.scss',
})
export class ProfileSecurityComponent {
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  profileSidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };
  passwordSidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };
  constructor(private store: Store) {}

  actionSuccess() {
    this.profileSidebar.visible = false;
    this.passwordSidebar.visible = false;
  }
}
