import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UpdateUserSelf } from '../../../../store/users-store/user.actions';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';

@Component({
  selector: 'app-update-profile-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ProgressSpinnerModule,
    RightFormHeaderComponent,
  ],
  templateUrl: './update-profile-form.component.html',
  styleUrl: './update-profile-form.component.scss',
})
export class UpdateProfileFormComponent {
  @Input() type?: string;
  @Input() title: string = '';
  @Output() actionSuccess = new EventEmitter<boolean>();

  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  processing$ = this.store.selectSignal(UserStateSelectors.isLoading);
  isUpload$: Signal<boolean> = this.store.selectSignal(
    GeneralStateSelectors.isUploadInProgress
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  bucketUrl = environment.bucketUrl;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      photo: [null],
    });
    this.form.get('email')?.disable();

    effect(() => {
      if (this.user$()) {
        this.form.patchValue({
          firstName: this.user$()?.firstName,
          lastName: this.user$()?.lastName,
          email: this.user$()?.email,
          photo: this.user$()?.photo,
        });
      }
      if (
        this.uploadedFiles$() &&
        this.uploadedFiles$()['photo'] &&
        !this.isUpload$()
      ) {
        this.form.patchValue({
          photo: this.uploadedFiles$()['photo'][0].objectURL,
        });
      }
    });
  }

  action() {
    this.store
      .dispatch(new UpdateUserSelf(this.form.getRawValue()))
      .subscribe(() => {
        this.form.reset();
        this.actionSuccess.emit();
      });
  }

  upload(event: any): void {
    this.store.dispatch(new ClearUploadedFiles());
    const files = event.target.files;
    this.store.dispatch(new GeneratePresignedUrl(files[0], 'photo'));
  }
}
