import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { AddProductFormComponent } from '../../../components/form-components/add-product-form/add-product-form.component';
import { CtesKdesComponent } from '../../settings/ctes-kdes/ctes-kdes.component';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { Store } from '@ngxs/store';
import {
  ClearProductData,
  GetProduct,
} from '../../../../store/products-store/products.actions';
import { RightFormHeaderComponent } from '../../../components/shared-components/right-form-header/right-form-header.component';
import { DividerModule } from 'primeng/divider';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';

@Component({
  selector: 'app-product-add-edit',
  standalone: true,
  imports: [
    CommonModule,
    AddProductFormComponent,
    CtesKdesComponent,
    RightFormHeaderComponent,
    DividerModule,
  ],
  templateUrl: './product-add-edit.component.html',
  styleUrl: './product-add-edit.component.scss',
})
export class ProductAddEditComponent {
  @ViewChild(AddProductFormComponent) productForm!: AddProductFormComponent;
  @ViewChild(CtesKdesComponent) productCtes!: CtesKdesComponent;
  @Input() data?: Product;
  @Input() id?: number;
  @Input() type?: string;
  @Input() title!: string;
  @Output() actionSuccess = new EventEmitter();
  @Output() actionCreateProduct = new EventEmitter<void>();

  product$: Signal<Product | null> = this.store.selectSignal(
    ProductStateSelectors.getProduct
  );
  processing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  formValid: boolean = false;

  steps = [
    {
      label: 'Product Passport',
      url: 'product-passport',
      icon: 'pi pi-shopping-bag',
    },
    { label: 'Define CTEs', url: 'define-ctes', icon: 'pi pi-tag' },
  ];
  activeStep = 0;

  constructor(private readonly store: Store) {}

  nextStep() {
    const productId = this.product$()?.id || 0;
    this.store.dispatch(new GetProduct(productId)).subscribe(res => {
      this.actionCreateProduct.emit();
      this.activeStep = this.activeStep + 1;
    });
  }

  previousStep() {
    this.activeStep = this.activeStep - 1;
  }

  changeStep(index: number) {
    if (this.product$()) {
      this.activeStep = index;
    }
  }

  reset() {
    this.store.dispatch(new ClearUploadedFiles());
    this.store.dispatch(new ClearProductData());
    if (this.activeStep === 0) {
      this.productForm.resetFormValues();
    }
    this.activeStep = 0;
    this.actionSuccess.emit(false);
  }
}
