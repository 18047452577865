import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  Renderer2,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { OrderListModule } from 'primeng/orderlist';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import {
  CTE,
  SidebarData,
} from '../../../../store/general-store/general.model';
import {
  UpdateOrganizationKDEs,
  UpdateOrganizationCTEs,
  DeleteOrganizationCTE,
  GetOrganizationCTEs,
} from '../../../../store/organizations-store/organizations.actions';
import {
  OrganizationKDEsParams,
  OrganizationCTEsParams,
} from '../../../../store/organizations-store/organizations.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { AddCTEFormComponent } from '../../../components/form-components/add-cte-form/add-cte-form.component';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddKDEFormComponent } from '../../../components/form-components/add-kde-form/add-kde-form.component';
import { Observable, of } from 'rxjs';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  DeleteProductKDE,
  UpdateProduct,
  DeleteProductCTE,
} from '../../../../store/products-store/products.actions';
import { Product } from '../../../../store/products-store/products.model';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { isS3Url } from '../../../services/global.service';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { DeleteKDE } from '../../../../store/general-store/general.actions';
import {
  DeleteRecallKDE,
  UpdateRecallCTEs,
  UpdateRecallKDEs,
} from '../../../../store/recall-management-store/recall-management.actions';
import {
  UpdateRecallCTEsParams,
  UpdateRecallKDEsParams,
} from '../../../../store/recall-management-store/recall-management.model';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { DividerModule } from 'primeng/divider';
import { PopoverModule } from 'primeng/popover';
import { RecallManagementStateSelectors } from '../../../../store/recall-management-store/recall-management.selectors';
@Component({
  selector: 'app-ctes-kdes',
  standalone: true,
  imports: [
    OrderListModule,
    AccordionModule,
    ButtonModule,
    RightFormBaseComponent,
    AddCTEFormComponent,
    AddKDEFormComponent,
    AccessDirective,
    DragDropModule,
    CommonModule,
    DividerModule,
    PopoverModule,
  ],
  templateUrl: './ctes-kdes.component.html',
  styleUrl: './ctes-kdes.component.scss',
})
export class CtesKdesComponent implements OnInit, OnChanges {
  @Input() isProductCtes = false;
  @Input() cteType: string = 'ORGANIZATION';
  @Input() product: Product | null = null;
  @Input() recallCtes: CTE[] = [];
  @Input() showNavigationButtons: boolean = true;
  @Input() showHeading: boolean = true;
  @Output() updatedCTEs = new EventEmitter<CTE[]>();
  @Output() actionSuccess = new EventEmitter();

  productKdesProcessing$ = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );
  organizationKdesProcessing$ = this.store.selectSignal(
    GeneralStateSelectors.isGeneralStoreProcessing
  );
  recallKdesProcessing$ = this.store.selectSignal(
    RecallManagementStateSelectors.isProcessing
  );
  ctes$: Observable<CTE[]> | undefined;
  selectedCte: CTE | null = null;
  ctes: CTE[] = [];

  currentCteId = 0;

  sidebar: SidebarData = {
    type: '',
    visible: false,
    title: '',
    data: null,
    width: 'w-50',
  };

  kdeSidebar: SidebarData = {
    visible: false,
    title: '',
    type: 'ADD',
    data: null,
    width: 'w-50',
  };

  cteActionItems: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
    },
  ];

  private overlayIndex = 100;

  isS3Url = isS3Url;
  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.store
      .select(GeneralStateSelectors.shouldHideSideMenu)
      .pipe(takeUntilDestroyed())
      .subscribe(hide => {
        if (hide) {
          this.sidebar.visible = false;
          this.kdeSidebar.visible = false;
        }
      });

    this.store.dispatch(new GetOrganizationCTEs());

    effect(() => {
      if (this.recallCtes.length > 0) {
        this.ctes$ = of(
          this.recallCtes
            .map(cte => ({
              ...cte,
              recallProductKdes: cte.recallProductKdes
                ? [...cte.recallProductKdes].sort((a, b) => a.order - b.order) // Sort child array
                : [],
            }))
            .sort((a, b) => a.order - b.order) // Sort parent array
        );
      } else {
        this.ctes$ = this.isProductCtes
          ? this.store.select(ProductStateSelectors.getSelectedProductCTEs)
          : this.store.select(OrganizationStateSelectors.getOrganizationCTEs);
      }
      this.ctes$?.subscribe(res => {
        if (res.length > 0) {
          this.ctes = res;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['recallCtes'] &&
      changes['recallCtes'].currentValue !== changes['recallCtes'].previousValue
    ) {
      this.ctes$ = of(
        this.recallCtes
          .map(cte => ({
            ...cte,
            recallProductKdes: cte.recallProductKdes
              ? [...cte.recallProductKdes].sort((a, b) => a.order - b.order) // Sort child array
              : [],
          }))
          .sort((a, b) => a.order - b.order) // Sort parent array
      );
    }
  }

  isTopmostOverlay(): boolean {
    const allOverlays = document.querySelectorAll('[data-overlay-index]');
    const currentIndex = parseInt(
      this.el.nativeElement.getAttribute('data-overlay-index') || '0',
      10
    );
    const maxIndex = Math.max(
      ...Array.from(allOverlays).map(el =>
        parseInt(el.getAttribute('data-overlay-index') || '0', 10)
      )
    );
    return currentIndex === maxIndex;
  }

  ngOnInit() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-overlay-index',
      this.overlayIndex.toString()
    );
  }

  dropCte(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.ctes, event.previousIndex, event.currentIndex);
    this.orderCTEs(this.ctes);
  }

  dropKde(event: CdkDragDrop<any[]>, cte: CTE) {
    moveItemInArray(
      cte.kdes ?? cte.productKdes ?? cte.recallProductKdes,
      event.previousIndex,
      event.currentIndex
    );
    this.listReordered(cte);
  }
  kdeProcessing$() {
    return this.cteType === 'ORGANIZATION'
      ? this.organizationKdesProcessing$
      : this.cteType === 'PRODUCT'
        ? this.productKdesProcessing$
        : this.recallKdesProcessing$;
  }
  listReordered(cte: CTE) {
    if (this.cteType === 'PRODUCT') {
      const kdes = [];
      const KDEs = cte.productKdes;
      let order = 0;
      for (const kde of KDEs) {
        kdes.push({
          ...kde,
          order,
        });
        order++;
      }
      this.ctes$?.subscribe(ctes => {
        const index = ctes.findIndex(c => c.id === cte.id);
        ctes[index] = cte;
        this.ctes = ctes;
      });
    } else if (this.cteType === 'ORGANIZATION') {
      const orderedKDERequest: OrganizationKDEsParams = {
        kdes: [],
      };
      let order = 0;
      const KDEs = cte.kdes;
      for (const kde of KDEs) {
        orderedKDERequest.kdes.push({
          id: kde.id,
          data: {
            order,
            organizationCteId: cte.id,
          },
        });
        order++;
      }
      this.store.dispatch(new UpdateOrganizationKDEs(orderedKDERequest));
    } else if (this.cteType === 'RECALL') {
      const orderedKDERequest: UpdateRecallKDEsParams = {
        kdes: [],
      };
      let order = 0;
      const KDEs = cte.recallProductKdes;
      for (const kde of KDEs) {
        orderedKDERequest.kdes.push({
          id: kde.id,
          data: {
            recallProductCteId: cte.id,
            order,
          },
        });
        order++;
      }
      this.store.dispatch(new UpdateRecallKDEs(orderedKDERequest));
    }
  }

  preventSelection(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  addKDE(cte: CTE, event: Event) {
    this.currentCteId = cte.id;
    this.selectedCte = cte;
    if (cte.kdes) {
      this.cteType = 'ORGANIZATION';
    }
    if (cte.productKdes) {
      this.cteType = 'PRODUCT';
    }
    if (cte.recallProductKdes) {
      this.cteType = 'RECALL';
    }
    this.kdeSidebar = {
      title: `Create KDE for ${this.selectedCte.title}`,
      visible: true,
      type: 'ADD',
      width: 'w-50',
    };
    event.preventDefault();
    event.stopPropagation();
  }

  updateKde(_kde: boolean) {
    this.kdeSidebar.visible = false;
  }

  orderCTEs(orgCTEs: CTE[]) {
    if (this.cteType === 'ORGANIZATION') {
      const orderedCTERequest: OrganizationCTEsParams = {
        ctes: [],
      };
      let order = 0;
      for (const cte of orgCTEs) {
        orderedCTERequest.ctes.push({
          id: cte.id,
          data: { order },
        });
        order++;
      }
      this.store.dispatch(new UpdateOrganizationCTEs(orderedCTERequest));
    } else if (this.cteType === 'RECALL') {
      const orderedCTERequest: UpdateRecallCTEsParams = {
        ctes: [],
      };
      let order = 0;
      for (const cte of orgCTEs) {
        orderedCTERequest.ctes.push({
          id: cte.id,
          data: { order },
        });
        order++;
      }
      this.store.dispatch(new UpdateRecallCTEs(orderedCTERequest));
    } else if (this.cteType === 'PRODUCT') {
      let order = 0;
      const ctes: CTE[] = [];
      for (const cte of orgCTEs) {
        ctes.push({
          ...cte,
          order,
        });
        order++;
      }
      this.ctes = ctes;
    }
  }

  updateProduct() {
    const updateProductParams = {
      productName: this.product?.productName,
      productDescription: this.product?.productDescription,
      brandName: this.product?.brandName,
      measurementUnit: this.product?.measurementUnit,
      packagingUnit: this.product?.packagingUnit,
      pricingUnit: this.product?.pricingUnit,
      gtin: this.product?.gtin,
      fdaClassified: this.product?.fdaClassified,
      logo: this.product?.logo,
      links: this.product?.links,
      productImages: this.product?.productImages.map(image => {
        return {
          id: image.id,
          name: image.name,
          url: image.url,
        };
      }),
      productCtes: this.product?.productCtes.map(pcte => {
        const localCte = this.ctes.find(c => c.id === pcte.id);
        return {
          id: pcte.id,
          title: pcte.title,
          description: pcte.description,
          isDefault: pcte.isDefault,
          organizationCteId: pcte.organizationCteId,
          type: pcte.type,
          icon: pcte.icon,
          order: localCte?.order,
          productKdes: pcte.productKdes.map(pkde => {
            const localKde = localCte?.productKdes.find(k => k.id === pkde.id);
            return {
              id: pkde.id,
              title: pkde.title,
              description: pkde.description,
              isDefault: pkde.isDefault,
              inputType: pkde.inputType,
              inputTypeOptions: pkde.inputTypeOptions,
              gs1: pkde.gs1,
              fsma204: pkde.fsma204,
              order: localKde?.order ?? pkde.order,
            };
          }),
        };
      }),
    };
    this.store
      .dispatch(new UpdateProduct(this.product!.id, updateProductParams))
      .subscribe(() => {
        this.actionSuccess.emit();
      });
  }

  actionClickedCTE(event: string, id: number, data: CTE) {
    if (event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${event} CTE`,
        message: `Are you sure want to delete this CTE?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          if (this.isProductCtes) this.store.dispatch(new DeleteProductCTE(id));
          else this.store.dispatch(new DeleteOrganizationCTE(id));
        },
      });
    } else if (event === 'EDIT') {
      this.sidebar = {
        id,
        type: 'EDIT',
        visible: true,
        title: 'Edit CTE',
        data,
        width: 'w-50',
      };
    }
  }

  actionClickedKDE(event: string, kdeID: number, data: CTE) {
    if (event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${event} KDE`,
        message: `Are you sure want to delete this KDE?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          if (data.kdes) {
            this.store.dispatch(new DeleteKDE(data, kdeID));
          } else if (data.productKdes) {
            this.store.dispatch(new DeleteProductKDE(data, kdeID));
          } else if (data.recallProductKdes) {
            this.store.dispatch(new DeleteRecallKDE(data, kdeID));
          }
        },
      });
    } else if (event === 'EDIT') {
      if (data.kdes) {
        this.cteType = 'ORGANIZATION';
      }
      if (data.productKdes) {
        this.cteType = 'PRODUCT';
      }
      if (data.recallProductKdes) {
        this.cteType = 'RECALL';
      }
      this.currentCteId = data.id;
      this.selectedCte = data;
      this.kdeSidebar = {
        id: kdeID,
        visible: true,
        title: 'Edit KDE',
        type: 'EDIT',
        data,
        width: 'w-50',
      };
    }
  }
}
