import { Component, Signal, OnInit, OnDestroy, effect } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ObjectToUrlParams } from '../../../services/global.service';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  ClearProductData,
  DeleteProduct,
  GetProduct,
  GetProductList,
  ResetHeaders,
} from '../../../../store/products-store/products.actions';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { ProductQrSettingsFormComponent } from '../../../components/form-components/product-qr-settings-form/product-qr-settings-form.component';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';
import { ProductAddEditComponent } from '../product-add-edit/product-add-edit.component';
import { ProductDesignTemplateComponent } from '../../../components/shared-components/product-design-template/product-design-template.component';
import { User } from '../../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    DataTableComponent,
    RightFormBaseComponent,
    ProductQrSettingsFormComponent,
    ProductAddEditComponent,
    AccessDirective,
    ProductDesignTemplateComponent,
    BreadcrumbModule,
  ],
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit, OnDestroy {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: 'Product Scan Details',
    type: '',
    width: 'w-80',
  };
  templateSidebar: SidebarData = {
    visible: false,
    title: 'Product Design Template',
    type: '',
    width: 'w-80',
  };
  productSidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-80',
  };
  selectedProduct: Product | null = null;

  showSidemenu = this.store.select(GeneralStateSelectors.shouldHideSideMenu);

  data$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getProducts
  );

  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    ProductStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  isListRefreshing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isListRefreshing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    ProductStateSelectors.getHeaders
  );

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmationService: ConfirmationService
  ) {
    this.getProductList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
    this.showSidemenu.pipe(takeUntilDestroyed()).subscribe(res => {
      if (res) {
        this.sidebar.visible = false;
      }
    });
    effect(() => {
      if (this.data$().length > 0) {
        this.selectedProduct = this.data$()[0];
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.store.dispatch(new ResetHeaders());
    this.store.dispatch(new ClearUploadedFiles());
  }

  getProductList() {
    this.store.dispatch(
      new GetProductList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  openAddProductSidebar() {
    if (this.user$()?.subscriptionInfo.canAddProduct) {
      this.store.dispatch(new ClearProductData()).subscribe(res => {
        this.productSidebar = {
          type: 'ADD',
          title: 'Add New Product',
          visible: true,
          width: 'w-80',
        };
      });
    } else {
      this.confirmationService.confirm({
        header: `SUBSCRIPTION ERROR`,
        message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'View Plans',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: false,
        accept: () => {
          this.router.navigateByUrl('/subscription/plans');
        },
      });
    }
  }

  onProductCreated() {
    this.getProductList();
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} PRODUCT`,
        message: `Are you sure want to delete this product?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteProduct(action.id)).subscribe(() => {
            this.getProductList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.store.dispatch(new GetProduct(action.id));
      this.productSidebar = {
        visible: true,
        title: 'Edit Product',
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-80',
      };
    } else if (action.event === 'QR') {
      if (this.user$()?.currentPlan) {
        this.store.dispatch(new GetProduct(action.id));
        this.sidebar = {
          visible: true,
          title: `QR Settings [${action.data.productName}]`,
          id: action.id,
          data: action.data,
          type: 'EDIT',
          width: 'w-80',
        };
      } else {
        this.confirmationService.confirm({
          header: `SUBSCRIPTION ERROR`,
          message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
          icon: 'pi pi-exclamation-circle',
          acceptLabel: 'View Plans',
          acceptIcon: 'pi',
          acceptButtonStyleClass: 'p-button-primary',
          rejectVisible: false,
          accept: () => {
            this.router.navigateByUrl('/subscription/plans');
          },
        });
      }
    } else if (action.event === 'SETTINGS') {
      if (this.user$()?.currentPlan) {
        this.store.dispatch(new ClearProductData()).subscribe(() => {
          this.store.dispatch(new GetProduct(action.id));
          this.templateSidebar.visible = true;
          this.templateSidebar.data = action.data;
          this.templateSidebar.id = action.id;
        });
      } else {
        this.confirmationService.confirm({
          header: `SUBSCRIPTION ERROR`,
          message: `This feature is unavailable. Please check your subscription status, limits, and plan features.`,
          icon: 'pi pi-exclamation-circle',
          acceptLabel: 'View Plans',
          acceptIcon: 'pi',
          acceptButtonStyleClass: 'p-button-primary',
          rejectVisible: false,
          accept: () => {
            this.router.navigateByUrl('/subscription/plans');
          },
        });
      }
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getProductList();
  }
}
