import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';

export const httpTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const token = localStorage.getItem('regen_access_token');
  if (token) {
    if (req.url.includes('.s3.')) {
      return next(req).pipe();
    }
    if (req.url.includes('api/auth')) {
      return next(req).pipe();
    }
    req = req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }
  return next(req).pipe();
};
