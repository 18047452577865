<div class="w-full">
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-add-role-form
      [title]="sidebar.title"
      [type]="sidebar.type"
      [id]="sidebar.id"
      (actionSuccess)="getRoleList($event)">
    </app-add-role-form>
  </app-right-form-base>
  <div class="flex justify-end">
    <p-button
      appAccess="add-role"
      id="add-role-button"
      [outlined]="true"
      label="Add Role"
      icon="pi pi-plus"
      (onClick)="
        createRole();
        sidebar = {
          visible: true,
          title: 'Add New Role',
          type: 'ADD',
          width: 'w-50',
        }
      " />
  </div>

  <div class="mt-4">
    <app-data-table
      [showTableToolbar]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [first]="first"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
