<p-accordion
  class="notifications-container w-full"
  expandIcon="none"
  collapseIcon="none">
  <p-accordionTab *ngFor="let category of categories(); let catIdx = index">
    <ng-template pTemplate="header">
      <div
        class="flex items-center justify-between w-full"
        (click)="toggleCategory(catIdx)">
        <div class="flex items-center gap-3">
          <i
            [ngClass]="
              expandedStates[catIdx] ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
            "
            class="text-black-500"></i>
          <span class="font-large txt-style">{{ category.name }}</span>
        </div>
        <label class="switch flex items-center gap-2"> </label>
      </div>
    </ng-template>
    <div
      *ngFor="let item of category.items; let itemIdx = index"
      class="notification-item flex justify-between items-center">
      <div class="notification-text">
        <strong>{{ item.title }}</strong>
        <p class="text-gray-500">{{ item.description }}</p>
      </div>
      <div class="email-toggle flex items-center gap-2">
        <input
          class="mb-[10px]"
          type="checkbox"
          [(ngModel)]="item.emailEnabled"
          (change)="toggleEmailEnabled(category, $event)" />
        EMAIL
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
