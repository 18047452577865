<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [showActionButton]="false"
      [processing]="processing$()"
      [title]="title"
      (callCancel)="actionSuccess.emit(true)"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form">
      <div class="flex items-center gap-3">
        <div class="w-100">
          <p>Role Name</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('name')?.invalid && form.get('name')?.touched
            "
            placeholder="Role Name"
            formControlName="name" />
          <app-error-message
            [control]="form.get('name')"
            label="Role Name"></app-error-message>
        </div>
        <div class="mt-2">
          <p-button
            [disabled]="this.form.invalid"
            [loading]="processing$()"
            label="Update"
            icon="pi pi-check"
            (click)="updateRole()" />
        </div>
      </div>
    </form>

    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Permissions</p-tab>
        <p-tab value="1">CTEs</p-tab>
        <p-tab *ngIf="showProductsTab" value="2">Products</p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel value="0">
          @if (role$()) {
            <div class="flex items-center justify-between mb-[1rem]">
              <h4>Select All</h4>
              <p-inputSwitch
                binary="true"
                [inputId]="'selectAll'"
                [(ngModel)]="selectAllPermission"
                (onChange)="toggleAllPermissions($event.checked)" />
            </div>
            @for (section of sections; track section) {
              <h4>{{ section }}</h4>
              @for (
                permission of groupedPermissions[section];
                track permission.id
              ) {
                <div class="flex items-center justify-between mb-[20px]">
                  <div>
                    <label [for]="permission.slug">{{ permission.name }}</label>
                    <p class="m-[0px]">
                      <small>{{ permission.slug }}</small>
                    </p>
                    <p class="mt-[5px] mr-[100px]">
                      <small>{{ permission.description }}</small>
                    </p>
                  </div>
                  <p-inputSwitch
                    binary="true"
                    [inputId]="permission.slug"
                    [(ngModel)]="permission.RolePermissions.allowed"
                    (onChange)="updatePermission(permission)" />
                </div>
              }
            }
          }
        </p-tabpanel>
        <p-tabpanel value="1">
          <div class="mt-[20px]">
            @if (role$()) {
              @for (cte of assignedCtes; track cte) {
                <div class="flex items-center justify-between mb-[20px]">
                  <div>
                    <label [for]="'cte' + cte.id">{{ cte.title }}</label>
                    <p class="m-[0px]">
                      <small>{{ cte.description }}</small>
                    </p>
                  </div>
                  <p-inputSwitch
                    binary="true"
                    [inputId]="'cte' + cte.slug"
                    [(ngModel)]="cte.RoleCte.allowed"
                    (onChange)="updateCte(cte)" />
                </div>
              }
            }
          </div>
        </p-tabpanel>
        <p-tabpanel value="2" *ngIf="showProductsTab">
          <div class="mt-[20px]">
            <div>
              <p-select
                [(ngModel)]="selectedProduct"
                [options]="searchedProducts"
                optionLabel="productName"
                optionValue="id"
                optionDisabled="disabled"
                placeholder="Search & Assign Product"
                filterPlaceholder="Type here to search product..."
                [emptyFilterMessage]="
                  productStateLoading$()
                    ? 'Loading...'
                    : 'No matching products found'
                "
                emptyMessage="---"
                class="w-full"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
                filter="true"
                filterBy="productName"
                (onFilter)="searchProducts($event.filter)"
                (onChange)="addRoleProduct($event.value)" />
            </div>
            @if (assignedProducts) {
              @for (product of assignedProducts; track product) {
                <div
                  class="flex items-center justify-between mb-[20px] mt-[20px]">
                  <div class="flex items-center">
                    <div class="mr-[20px] text-[red]">
                      <i
                        class="pi pi-trash cursor-pointer"
                        (click)="deleteProduct(product)"></i>
                    </div>
                    <div>
                      <label [for]="'product' + product.gtin">{{
                        product.productName
                      }}</label>
                      <p class="m-[0px]">
                        <small>{{ product.gtin }}</small>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p-inputSwitch
                      binary="true"
                      [inputId]="'product' + product.gtin"
                      [(ngModel)]="product.RoleProduct.allowed"
                      (onChange)="updateProduct(product)" />
                  </div>
                </div>
              }
            }
            @if (assignedProducts.length === 0) {
              <p class="text-center">No products assigned</p>
            }
          </div>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  </div>
</div>
