<p-confirmDialog></p-confirmDialog>
@if (currUser$() && notAnAuthRoute()) {
  <app-header (toggleSidebar)="toggleSidebar()"></app-header>
  <div class="p-grid flex overflow-auto bg-black">
    <div class="p-col-fixed">
      <app-sidebar [isExpanded]="isSidebarExpanded"></app-sidebar>
    </div>
    <div class="w-full overflow-auto bg-white main-layout">
      @if (currUser$()?.currentPlan === null && showSubscriptionBanner) {
        <app-no-subscription-banner
          class="block px-8 mt-8"
          title="Subscribe to Continue!"
          description="Actions on this platform are available to subscribed users only. Please
        upgrade your account to proceed."></app-no-subscription-banner>
      }
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <router-outlet></router-outlet>
}
