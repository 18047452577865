import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  AcceptInviteParams,
  AddInvitationParams,
  InvitationResponse,
} from './invitation.model';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public addInvitation = (
    payload: AddInvitationParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/invitations`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getInvitationList = (
    paginationParams: PaginationParams
  ): Observable<InvitationResponse> => {
    return this.http
      .get<InvitationResponse>(`${this.apiUrl}/api/invitations`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filter: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching invitation: ' + error.message)
          );
        })
      );
  };

  public acceptInvite = (
    payload: AcceptInviteParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/invitations/accept`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };
}
