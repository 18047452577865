import { Selector } from '@ngxs/store';
import { RolesPermissionState } from './roles-permission.state';
import { RolesPermissionStateModel } from './roles-permission.model';

export class RolesPermissionStateSelectors {
  @Selector([RolesPermissionState])
  static getRoles(state: RolesPermissionStateModel) {
    return state.roles;
  }

  @Selector([RolesPermissionState])
  static getRole(state: RolesPermissionStateModel) {
    return state.role;
  }

  @Selector([RolesPermissionState])
  static isProcessing(state: RolesPermissionStateModel) {
    return state.isProcessing;
  }

  @Selector([RolesPermissionState])
  static getPaginationData(state: RolesPermissionStateModel) {
    return state.pagination;
  }

  @Selector([RolesPermissionState])
  static getHeaders(state: RolesPermissionStateModel) {
    return state.headers;
  }
}
