import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { ProductStateModel } from './products.model';
import { ProductService } from './products.service';
import {
  AddProduct,
  AddProductKDE,
  AddProductTemplateComponent,
  AddProductTemplateModule,
  BulkUpdateProductTemplateModules,
  ClearProductData,
  DeleteProduct,
  DeleteProductTemplateComponent,
  DeleteProductTemplateModule,
  GetAllProducts,
  GetProduct,
  GetProductItemDataList,
  GetProductList,
  ResetHeaders,
  SearchProducts,
  UpdateProduct,
  UpdateProductKDEs,
  UpdateProductQrSettings,
  UpdateProductTemplate,
  UpdateProductTemplateComponent,
  DeleteProductKDE,
  BulkUpdateProductTemplateComponents,
  UpdateProductKDE,
  UpdateProductTemplateModule,
  DeleteProductItemData,
  UpdateProductCTE,
  DeleteProductCTE,
} from './products.actions';
import { Router } from '@angular/router';
import { HideSideMenu } from '../general-store/general.actions';

@State<ProductStateModel>({
  name: 'Product',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    products: [],
    productItemData: [],
    allProducts: [],
    searchedProducts: [],
    product: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    productDataheaders: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ProductState {
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  @Action(GetProductList)
  getProductList(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProductList
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProducts(action.payload).pipe(
      tap(async res => {
        patchState({
          products: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(SearchProducts)
  searchProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: SearchProducts
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProducts(action.payload).pipe(
      tap(async res => {
        patchState({
          searchedProducts: res.payload,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetProductItemDataList)
  getProductItemData(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProductItemDataList
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProductItemData(action.payload).pipe(
      tap(async res => {
        patchState({
          productItemData: res.payload,
          pagination: res.pagination,
          productDataheaders: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProduct)
  deleteProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProduct(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProductItemData)
  deleteProductItemData(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProductItemData
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProductItemData(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product Item Data deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetAllProducts)
  getAllProducts({ patchState }: StateContext<ProductStateModel>) {
    return this.productService
      .getProducts({
        first: 0,
        rows: 10000,
        sortBy: 'productName',
        sortOrder: 'ASC',
        filters: '',
        search: '',
      })
      .pipe(
        tap(async res => {
          patchState({
            allProducts: res.payload,
          });
        }),
        catchError(async error => {
          return throwError(() => error);
        })
      );
  }

  @Action(AddProduct)
  addProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.addProduct(action.payload).pipe(
      tap(async res => {
        const sortedArr = res.payload.productCtes.sort(
          (a, b) => a.order - b.order
        );
        for (const element of sortedArr) {
          element.productKdes = element.productKdes.sort(
            (a, b) => a.order - b.order
          );
        }
        patchState({
          isProcessing: false,
          product: res.payload,
        });
        this.notificationService.openSuccessToast(
          'Product created successfully'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        if (error.error.error.message === 'Validation error') {
          this.notificationService.openValidationToast(
            error.error.error.details
          );
        } else {
          this.notificationService.openErrorToast(error.error.error.message);
        }
        return throwError(() => error);
      })
    );
  }

  @Action(GetProduct)
  getProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProduct(action.payload).pipe(
      tap(async res => {
        const sortedArr = res.payload.productCtes.sort(
          (a, b) => a.order - b.order
        );
        for (const element of sortedArr) {
          element.productKdes = element.productKdes.sort(
            (a, b) => a.order - b.order
          );
        }
        patchState({
          product: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProduct)
  updateProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.updateProduct(action.id, action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        if (error.error.error.message === 'Validation error') {
          this.notificationService.openValidationToast(
            error.error.error.details
          );
        } else {
          this.notificationService.openErrorToast(error.error.error.message);
        }
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductKDEs)
  updateProductKdes(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductKDEs
  ) {
    return this.productService.updateProductKDEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Product KDEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(AddProductKDE)
  addProductKde(
    { getState, patchState, dispatch }: StateContext<ProductStateModel>,
    action: AddProductKDE
  ) {
    return this.productService.addProductKDE(action.kde).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'KDE has been created successfully!'
        );

        const state = getState();
        const product = state.product;
        dispatch(new GetProduct(product?.id || 0));
        const cteIndex = product!.productCtes.findIndex(
          cte => cte.id === action.kde.productCteId
        );

        if (cteIndex !== -1) {
          const updatedKdes = [
            ...product!.productCtes[cteIndex].productKdes,
            action.kde,
          ];

          patchState({
            product: {
              ...product!,
              productCtes: product!.productCtes.map((cte, index) =>
                index === cteIndex ? { ...cte, productKdes: updatedKdes } : cte
              ),
            },
          });
        }
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductKDE)
  updateProductKDE(
    { getState, patchState }: StateContext<ProductStateModel>,
    action: UpdateProductKDE
  ) {
    return this.productService.updateProductKDE(action.id, action.kde).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
        this.notificationService.openSuccessToast(
          'Product KDE updated successfully!'
        );

        const state = getState();
        const product = state.product;

        const cteIndex = product!.productCtes.findIndex(cte =>
          cte.productKdes.some(kde => kde.id === action.id)
        );

        if (cteIndex !== -1) {
          const updatedKdes = product!.productCtes[cteIndex].productKdes.map(
            kde => (kde.id === action.id ? { ...kde, ...action.kde } : kde)
          );

          patchState({
            product: {
              ...product!,
              productCtes: product!.productCtes.map((cte, index) =>
                index === cteIndex ? { ...cte, productKdes: updatedKdes } : cte
              ),
            },
          });
        }
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductCTE)
  updateProductCte(
    { patchState, dispatch }: StateContext<ProductStateModel>,
    action: UpdateProductCTE
  ) {
    return this.productService.updateProductCTE(action.id, action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Product CTE updated successfully!'
        );
        dispatch([new GetProduct(action.productId), new HideSideMenu()]);
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProductKDE)
  deleteProductKDE(
    { getState, patchState }: StateContext<ProductStateModel>,
    action: DeleteProductKDE
  ) {
    return this.productService.deleteProductKDE(action.kdeID).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product KDE deleted successfully!'
        );
        const state = getState();
        const product = state.product;

        const cteIndex = product!.productCtes.findIndex(
          cte => cte.id === action.cte.id
        );

        if (cteIndex !== -1) {
          const updatedKdes = product!.productCtes[cteIndex].productKdes.filter(
            kde => kde.id !== action.kdeID
          );

          product!.productCtes[cteIndex].productKdes = updatedKdes;
          patchState({
            product,
          });
        }
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductQrSettings)
  updateProductQrSettings(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductQrSettings
  ) {
    return this.productService
      .updateProductQrSettings(action.id, action.payload)
      .pipe(
        tap(async () => {
          this.notificationService.openSuccessToast(
            'Product QR Settings updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateProductTemplate)
  updateProductTemplate(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductTemplate
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .updateProductTemplate(action.id, action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(AddProductTemplateModule)
  addProductTemplateModule(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProductTemplateModule
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .addProductTemplateModule(action.productId, action.id)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateProductTemplateModule)
  updateProductTemplateModule(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductTemplateModule
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .updateProductTemplateModule(action.id, action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(BulkUpdateProductTemplateModules)
  bulkUpdateProductTemplateModules(
    { patchState }: StateContext<ProductStateModel>,
    action: BulkUpdateProductTemplateModules
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .bulkUpdateProductTemplateModules(action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(BulkUpdateProductTemplateComponents)
  bulkUpdateProductTemplateComponents(
    { patchState }: StateContext<ProductStateModel>,
    action: BulkUpdateProductTemplateComponents
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .bulkUpdateProductTemplateComponents(action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(DeleteProductTemplateModule)
  deleteProductTemplateModule(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProductTemplateModule
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProductTemplateModule(action.id).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProductTemplateComponent)
  deleteProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProductTemplateComponent(action.id).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductTemplateComponent)
  updateProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .updateProductTemplateComponent(action.id, action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(AddProductTemplateComponent)
  addProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService.addProductTemplateComponent(action.payload).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(ResetHeaders)
  resetHeaders({ patchState }: StateContext<ProductStateModel>) {
    patchState({ headers: [], productDataheaders: [] });
  }

  @Action(ClearProductData)
  clearProductData({ patchState }: StateContext<ProductStateModel>) {
    patchState({ product: null });
  }

  @Action(DeleteProductCTE)
  deleteProductCTE(
    { getState, patchState }: StateContext<ProductStateModel>,
    action: DeleteProductCTE
  ) {
    return this.productService.deleteProductCTE(action.cteId).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product CTE deleted successfully!'
        );
        const state = getState();
        const product = state.product;

        const updatedCTEs = product!.productCtes.filter(
          cte => cte.id !== action.cteId
        );

        product!.productCtes = updatedCTEs;
        patchState({
          product,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }
}
