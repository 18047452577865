import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import { QrOrder } from '../../../../store/qr-store/qr.model';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import { DownloadQrOrder } from '../../../../store/qr-store/qr.actions';
import { QrPreviewComponent } from '../../shared-components/qr-preview/qr-preview.component';
import { CornerDotType, CornerSquareType, Options } from 'ngx-qrcode-styling';
import {
  ProductQrCtaConfig,
  ProductQrSetting,
} from '../../../../store/products-store/products.model';
import { QrStateSelectors } from '../../../../store/qr-store/qr.selectors';
import { TextTransformPipe } from '../../../pipes/text-transform.pipe';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-download-qr-order-form',
  standalone: true,
  imports: [
    ButtonModule,
    QrPreviewComponent,
    TextTransformPipe,
    RightFormFooterComponent,
  ],
  templateUrl: './download-qr-order-form.component.html',
  styleUrl: './download-qr-order-form.component.scss',
})
export class DownloadQrOrderFormComponent {
  @Input() data!: QrOrder;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<void>();

  processing$: Signal<boolean> = this.store.selectSignal(
    QrStateSelectors.isProcessing
  );
  qrOrder$: Signal<QrOrder | null> = this.store.selectSignal(
    QrStateSelectors.getQrOrder
  );

  constructor(private store: Store) {}

  action() {
    this.store
      .dispatch(
        new DownloadQrOrder({
          qrGenerateOrderId: this.data.id,
        })
      )
      .subscribe(() => {
        this.actionSuccess.emit();
      });
  }

  getProductCtaConfig(productQrSetting: ProductQrSetting): ProductQrCtaConfig {
    return {
      ctaLabelPosition: productQrSetting.ctaLabelPosition,
      ctaLabelText: productQrSetting.ctaLabelText,
    };
  }

  getProductQrSettings(productQrSetting: ProductQrSetting): Options {
    return {
      data: 'https://regenesis.ag/',
      type: 'svg',
      image: productQrSetting.centerImage,
      imageOptions: {
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: productQrSetting.dotsOptionsColor,
      },
      backgroundOptions: {
        color: productQrSetting.backgroundColor,
      },
      cornersDotOptions: {
        color: productQrSetting.cornersDotColor,
        type: productQrSetting.cornersDotType.toLowerCase() as CornerDotType,
      },
      cornersSquareOptions: {
        color: productQrSetting.cornersSquareColor,
        type: productQrSetting.cornersSquareType.toLowerCase() as CornerSquareType,
      },
    };
  }
}
