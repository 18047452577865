import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  ClearDiscountDetails,
  DiscountDetails,
  GetPlans,
  Subscribe,
  SwitchSubscription,
} from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute } from '@angular/router';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { environment } from '../../../../environments/environment';
import { ConfirmationService } from 'primeng/api';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import {
  Card,
  Plan,
} from '../../../../store/subscription-store/subscription.model';
import { CheckboxModule } from 'primeng/checkbox';
import { CardsListComponent } from '../../../components/shared-components/cards-list/cards-list.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CurrencyPipe } from '../../../pipes/currency.pipe';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    CommonModule,
    StepperModule,
    ButtonModule,
    CheckboxModule,
    CardsListComponent,
    FormsModule,
    ReactiveFormsModule,
    CurrencyPipe,
  ],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss',
})
export class SubscribeComponent {
  bucketUrl: string = environment.bucketUrl;
  errorMessage = '';
  planId = '';
  selectedPlan: Plan | null = null;
  selectedCard: Card | null = null;
  totalAmount = 0;
  acceptTermsConditions = false;

  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  discountDetails$ = this.store.selectSignal(
    SubscriptionStateSelectors.getDiscountDetails
  );
  cards$ = this.store.selectSignal(SubscriptionStateSelectors.getCards);
  plans$ = this.store.selectSignal(SubscriptionStateSelectors.getPlans);
  isLoading$ = this.store.selectSignal(SubscriptionStateSelectors.isLoading);
  isLoading = false;

  form: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly confirmationService: ConfirmationService,
    private readonly fb: FormBuilder
  ) {
    this.getPlans();
    this.planId = this.activatedRoute.snapshot.params['id'];
    this.form = this.fb.group({
      coupon: ['', [Validators.required]],
    });
    effect(() => {
      if (this.plans$().length > 0) {
        this.selectedPlan =
          this.plans$().find(p => p.id === this.planId) ?? null;
        if (this.user$()) {
          if (!this.user$()!.organizations[0].hasSetupFeePaid) {
            this.totalAmount = this.selectedPlan
              ? +this.selectedPlan?.amount + +this.selectedPlan?.otc
              : 0;
          } else {
            this.totalAmount = this.selectedPlan
              ? +this.selectedPlan?.amount
              : 0;
          }
        }
      }
    });
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  applyDiscountCode() {
    this.store
      .dispatch(
        new DiscountDetails({
          coupon: this.form.get('coupon')?.value ?? '',
          planId: this.planId,
        })
      )
      .subscribe(() => {
        if (this.discountDetails$()) {
          this.form.get('coupon')?.disable();
        } else {
          this.form.get('coupon')?.patchValue('');
        }
      });
  }

  clearDiscountDetails() {
    this.store.dispatch(new ClearDiscountDetails()).subscribe(() => {
      this.form.get('coupon')?.patchValue('');
      this.form.get('coupon')?.markAsUntouched();
      this.form.get('coupon')?.enable();
    });
  }

  subscribe() {
    this.confirmationService.confirm({
      header: `CONFIRM SUBSCRIPTION`,
      message: `Are you sure you want to subscribe to ${this.selectedPlan?.productDetails.name}?`,
      icon: 'pi pi-exclamation-circle',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-primary',
      accept: () => {
        if (this.user$()?.currentPlan === null) {
          this.store.dispatch(
            new Subscribe({
              sourceId: this.selectedCard!.id,
              planId: this.planId,
              coupon: this.form.get('coupon')?.value,
            })
          );
        } else {
          this.store.dispatch(
            new SwitchSubscription(this.user$()!.subscription.id, {
              newPlanId: this.planId,
              immediateSwitch: true,
            })
          );
        }
      },
    });
  }
}
