import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-line-chart',
  imports: [CommonModule],
  templateUrl: './line-chart.component.html',
})
export class LineChartComponent<T> implements OnDestroy, OnChanges {
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) data: T | null = null;
  @Output() reload = new EventEmitter<any>();

  private root!: am5.Root;
  public noData: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initMap();
      } else {
        this.noData = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
    const validData = Array.isArray(this.data) ? this.data : [];
    this.noData = validData.length === 0;

    if (this.noData) {
      return;
    }

    this.root = am5.Root.new('lineChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        layout: this.root.verticalLayout,
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(this.root, {
        groupData: true,
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    if (validData.length > 0) {
      Object.keys(validData[0]).forEach(productName => {
        if (productName !== 'date') {
          const series = chart.series.push(
            am5xy.SmoothedXLineSeries.new(this.root, {
              name: productName,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: productName,
              valueXField: 'date',
              tooltip: am5.Tooltip.new(this.root, {
                labelText: '{name}: {valueY} scans',
                pointerOrientation: 'horizontal',
              }),
            })
          );

          series.strokes.template.set('strokeWidth', 4);

          series.bullets.push(() =>
            am5.Bullet.new(this.root, {
              sprite: am5.Circle.new(this.root, {
                radius: 5,
                fill: series.get('fill'),
              }),
            })
          );

          series.data.setAll(
            validData.map(item => ({
              date: new Date(item.date).getTime(),
              [productName]: item[productName],
            }))
          );
        }
      });
    }

    chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        behavior: 'zoomXY',
        xAxis: xAxis,
      })
    );

    yAxis.set(
      'tooltip',
      am5.Tooltip.new(this.root, {
        themeTags: ['axis'],
      })
    );

    chart.appear(1000, 100);
  }
}
