import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  DemoRequestParams,
  GenericAuthResponse,
  OtpAuthResponse,
  UserLoginParams,
  UserResponse,
  UserPaginationParams,
  AddAdminUserParams,
  UpdateUserParams,
  UpdateUserSelfParams,
  UpdateUserPasswordParams,
  RefreshTokenResponse,
} from './user.model';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import { GenericApiResponse } from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public demoRequest = (
    payload: DemoRequestParams
  ): Observable<DemoRequestParams> => {
    return this.http
      .post<DemoRequestParams>(
        `${this.apiUrl}/api/organizations/demo-requests`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public loginRequest = (
    payload: UserLoginParams
  ): Observable<GenericAuthResponse> => {
    return this.http
      .post<GenericAuthResponse>(`${this.apiUrl}/api/auth/login`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public validateOtp = (
    userId: number,
    code: number
  ): Observable<OtpAuthResponse> => {
    return this.http
      .post<OtpAuthResponse>(`${this.apiUrl}/api/auth/verify-otp`, {
        userId,
        code,
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public refreshToken = (
    refreshToken: string,
  ): Observable<RefreshTokenResponse> => {
    return this.http
      .post<RefreshTokenResponse>(`${this.apiUrl}/api/auth/refresh`, {
        refreshToken
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };


  public deleteUser(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/api/user/${id}`).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error deleting user: ' + error.message)
        );
      })
    );
  }

  public updateUser(id: number, payload: UpdateUserParams): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/api/user/${id}`, payload).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error updating user: ' + error.message)
        );
      })
    );
  }

  public updateUserSelf(payload: UpdateUserSelfParams): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/api/user/self`, payload).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error updating user: ' + error.message)
        );
      })
    );
  }

  public updateUserPassword(
    payload: UpdateUserPasswordParams
  ): Observable<void> {
    return this.http
      .patch<void>(`${this.apiUrl}/api/user/password`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating user password: ' + error.message)
          );
        })
      );
  }

  public resendOtp = (userId: number): Observable<void> => {
    return this.http
      .post<void>(`${this.apiUrl}/api/auth/resend-otp`, { userId })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public sendOtp = (type: string): Observable<void> => {
    return this.http
      .post<void>(`${this.apiUrl}/api/user/send-otp`, { type })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getUser = (): Observable<GenericAuthResponse> => {
    return this.http.get<GenericAuthResponse>(`${this.apiUrl}/api/user`).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  };

  public getUsers = (
    paginationParams: UserPaginationParams
  ): Observable<UserResponse> => {
    return this.http
      .get<UserResponse>(`${this.apiUrl}/api/user/all`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filter: paginationParams.filters,
          role: paginationParams.role,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching users: ' + error.message)
          );
        })
      );
  };

  public addAdminUser = (
    payload: AddAdminUserParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/user`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public forgotPassword = (email: string): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/auth/send-verification-link`,
        { email }
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public resetPassword = (
    token: string,
    password: string
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/auth/reset-password`, {
        token,
        password,
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };
}
