import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
  Activities,
  HeatMap,
  PaginationParams,
} from '../general-store/general.model';
import {
  DailyProductStatsResponse,
  DeviceAnalyticsResponse,
  ExpirationAPi,
  LocationAnalyticsResponse,
  ProductAnalyticsResponse,
  QrMetricsResponse,
} from './dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getActivityLogs(
    paginationParams: PaginationParams
  ): Observable<Activities> {
    return this.http
      .get<Activities>(`${this.apiUrl}/api/activities`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getChartsData(productId: number): Observable<HeatMap> {
    return this.http
      .get<any>(`${this.apiUrl}/api/qr-product-data/chart/${productId}`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getCteStatistics(productId: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/qr-product-data/cte-statistics/${productId}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }
  public getExpirations(productId: number): Observable<ExpirationAPi> {
    return this.http
      .get<ExpirationAPi>(
        `${this.apiUrl}/api/qr-product-data/expiration-analysis/${productId}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }

  public getQrMetrics(
    timeFrame: string,
    productId: number | null
  ): Observable<QrMetricsResponse> {
    let params = new HttpParams().set('timeFrame', timeFrame);
    if (productId) {
      params = params.set('productId', productId.toString());
    }
    return this.http
      .get<QrMetricsResponse>(`${this.apiUrl}/api/qr-product-data/metrics`, {
        params,
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getLocationAnalytics(
    filters: any
  ): Observable<LocationAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<LocationAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/location-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getDeviceAnalytics(filters: any): Observable<DeviceAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<DeviceAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/device-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getProductAnalytics(
    filters: any
  ): Observable<ProductAnalyticsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<ProductAnalyticsResponse>(
        `${this.apiUrl}/api/qr-product-data/product-analytics`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getDailyProductStats(
    filters: any
  ): Observable<DailyProductStatsResponse> {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined) {
        params = params.append(key, filters[key]);
      }
    });
    return this.http
      .get<DailyProductStatsResponse>(
        `${this.apiUrl}/api/qr-product-data/daily-product-stats`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }
}
