import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {
  bucketUrl = environment.bucketUrl;
  constructor(private router: Router) {
    if (localStorage.getItem('regen_access_token')) {
      this.router.navigate(['/dashboards/analytical']);
    }
  }
}
