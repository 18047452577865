import { Selector } from '@ngxs/store';
import { QrState } from './qr.state';
import { QrStateModel } from './qr.model';

export class QrStateSelectors {
  @Selector([QrState])
  static getQrOrders(state: QrStateModel) {
    return state.qrOrders;
  }

  @Selector([QrState])
  static getQrOrder(state: QrStateModel) {
    return state.qrOrder;
  }

  @Selector([QrState])
  static getPaginationData(state: QrStateModel) {
    return state.pagination;
  }

  @Selector([QrState])
  static isProcessing(state: QrStateModel) {
    return state.isProcessing;
  }

  @Selector([QrState])
  static getHeaders(state: QrStateModel) {
    return state.headers;
  }
}
