import { Component, effect } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetCteStatistics } from '../../../../store/dashboard-store/dashboard.actions';
import { CteMapStatistics } from '../../../../store/general-store/general.model';
import { Observable } from 'rxjs';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';
import { ClusterMapComponent } from '../../../components/charts/cluster-map/cluster-map.component';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { MapFilterComponent } from '../../../components/map-filter-components/map-filter.component';
@Component({
  selector: 'app-scan-statistics',
  templateUrl: './scan-statistics.component.html',
  styleUrls: ['./scan-statistics.component.scss'],
  imports: [ActivityFeedComponent, ClusterMapComponent, MapFilterComponent],
  standalone: true,
})
export class ScanStatistics {
  pd$ = this.store.selectSignal(DashboardStateSelectors.getProductCteStats);
  products$ = this.store.selectSignal(ProductStateSelectors.getProducts);
  productData: Observable<CteMapStatistics[]> | undefined;

  currentDateFilter: { startDate: string; endDate: string } | undefined;

  onDateRangeChange(filter: { startDate: string; endDate: string }) {
    this.currentDateFilter = filter;
  }
  zoomConfig = { latitude: 48.2092, longitude: 16.3728, zoomLevel: 2 };

  selectAction = {
    action: [GetCteStatistics],
    payload: null,
  };
  constructor(private store: Store) {
    effect(() => {
      if (this.products$() && this.products$()[0]) {
        this.store.dispatch(new GetCteStatistics(this.products$()[0].id));
      }
    });
  }
}
