import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
  SimpleChanges,
  OnChanges,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TextareaModule } from 'primeng/textarea';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Store } from '@ngxs/store';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { CheckboxModule } from 'primeng/checkbox';
import {
  AddKDE,
  UpdateKDE,
} from '../../../../store/general-store/general.actions';
import {
  Data,
  KDE,
  CTE,
  AddKDEParams,
} from '../../../../store/general-store/general.model';
import {
  UpdateProductKDE,
  AddProductKDE,
} from '../../../../store/products-store/products.actions';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ValidationComponent } from '../../shared-components/kdes-types-validation/validation.component';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import {
  AddRecallKDE,
  UpdateRecallKDE,
} from '../../../../store/recall-management-store/recall-management.actions';
import { SelectModule } from 'primeng/select';
import { TabsModule } from 'primeng/tabs';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
@Component({
  selector: 'app-add-kdeform',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    TextareaModule,
    CheckboxModule,
    TabsModule,
    SelectModule,
    InputSwitchModule,
    ValidationComponent,
    RightFormHeaderComponent,
    CommonModule,
    DropdownModule,
  ],
  templateUrl: './add-kde-form.component.html',
  styleUrl: './add-kde-form.component.scss',
})
export class AddKDEFormComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  @Input() data?: CTE;
  @Input() id?: number;
  @Input() type?: string;
  @Input() cteID = 0;
  @Input() cteType = 'ORGANIZATION';
  @Input() title = '';
  @Output() actionSuccess = new EventEmitter<boolean>();
  inputOptions = ['TEXT', 'NUMBER', 'DECIMAL'];
  dateInputOptions = ['DATE', 'TIME', 'DATETIME'];
  disabling = true;
  [key: string]: any;
  radioOptions: Data[] = [];
  dropdownOptions: Data[] = [];
  activeTabIndex = 0;
  isLoading = false;
  charCount = 0;
  private readonly overlayIndex = 300;
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {
    let defaultValidations: any;
    this.store
      .select(GeneralStateSelectors.getValidations)
      .subscribe(validations => {
        defaultValidations = validations;
      });
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      gs1: [false],
      fsma204: [false],
      inputType: ['', [Validators.required]],
      inputTypeOptions: [[]],
      radioKey: [''],
      radioValue: [''],
      dropdownKey: [''],
      dropdownValue: [''],
      validations: this.fb.group({
        type: [''],
        mandatory: this.fb.group({
          enabled: [false],
          shouldDisplay: [false],
        }),
        format: this.fb.group({
          enabled: [false],
          shouldDisplay: [false],
          details: this.fb.group({
            type: ['range'],
            validationName: [''],
            errorMessage: [''],
            key: [''],
            value: [''],
            start: [null],
            end: [null],
            format: [''],
            min: [0],
            max: [],
          }),
        }),
        linkQRSettings: this.fb.group({
          enabled: [false],
          shouldDisplay: [false],
          details: {},
        }),
        uniqueness: this.fb.group({
          enabled: [false],
          shouldDisplay: [false],
          prefix: [''],
          suffix: [''],
        }),
        length: this.fb.group({
          enabled: [false],
          shouldDisplay: [false],
          min: [0],
          max: [],
        }),
      }),
    });
    this.form.get('inputType')?.valueChanges.subscribe(type => {
      console.log('********', type);
      if (defaultValidations[type]) {
        const validationKeys = Object.keys(defaultValidations[type]);

        validationKeys.forEach(key => {
          const validation = defaultValidations[type][key];

          if (typeof validation === 'object' && validation !== null) {
            Object.keys(validation).forEach(subKey => {
              this.form
                .get(['validations', key, subKey].join('.'))
                ?.setValue(validation[subKey]);
            });
          } else {
            this.form.get(['validations', key].join('.'))?.setValue(validation);
          }
        });
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'Enter' &&
      this.isTopmostOverlay() &&
      !this.processing$()
    ) {
      this.action();
    }
  }

  isTopmostOverlay(): boolean {
    const allOverlays = document.querySelectorAll('[data-overlay-index]');
    const currentIndex = parseInt(
      this.el.nativeElement.getAttribute('data-overlay-index') || '0',
      10
    );
    const maxIndex = Math.max(
      ...Array.from(allOverlays).map(el =>
        parseInt(el.getAttribute('data-overlay-index') || '0', 10)
      )
    );
    return currentIndex === maxIndex;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']?.currentValue === 'ADD') {
      this.resetForm();
    }
    if (this.data && this.id) {
      let kdeItem: KDE | undefined;
      if (this.cteType === 'PRODUCT') {
        kdeItem = this.data.productKdes.find(item => item.id === this.id);
      } else if (this.cteType === 'ORGANIZATION') {
        kdeItem = this.data?.kdes?.find(item => item.id === this.id);
      } else if (this.cteType === 'RECALL') {
        kdeItem = this.data?.recallProductKdes?.find(
          item => item.id === this.id
        );
      }
      if (kdeItem) {
        this.form.patchValue({
          title: kdeItem.title || '',
          description: kdeItem.description || '',
          gs1: kdeItem.gs1 ?? false,
          fsma204: kdeItem.fsma204 ?? false,
          inputType: kdeItem.inputType || '',
          inputTypeOptions: kdeItem.inputTypeOptions || [],
          validations: kdeItem.validations,
        });
        if (kdeItem.inputType === 'TEXT') {
          this.activeTabIndex = 0;
        } else if (
          kdeItem.inputType === 'DATE' ||
          kdeItem.inputType === 'TIME' ||
          kdeItem.inputType === 'DATETIME'
        ) {
          this.activeTabIndex = 1;
        } else if (kdeItem.inputType === 'RADIO') {
          this.activeTabIndex = 2;
          this.radioOptions = kdeItem.inputTypeOptions || [];
        } else if (kdeItem.inputType === 'SELECT') {
          this.activeTabIndex = 3;
          this.dropdownOptions = kdeItem.inputTypeOptions || [];
        } else if (kdeItem.inputType === 'LOCATION') {
          this.activeTabIndex = 4;
          this.dropdownOptions = kdeItem.inputTypeOptions || [];
        } else if (kdeItem.inputType === 'FILE') {
          this.activeTabIndex = 5;
          this.dropdownOptions = kdeItem.inputTypeOptions || [];
        } else if (kdeItem.inputType === 'LINK_QR') {
          this.activeTabIndex = 6;
          // this.dropdownOptions = kdeItem.inputTypeOptions || [];
        }
      }
    }
  }

  ngOnInit(): void {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-overlay-index',
      this.overlayIndex.toString()
    );
    this.form.get('inputType')?.valueChanges.subscribe(value => {
      if (value === 'RADIO' || value === 'SELECT') {
        this.form.get('inputTypeOptions')?.setValidators([Validators.required]);
      } else {
        this.form.get('inputTypeOptions')?.clearValidators();
      }
      this.form.get('inputTypeOptions')?.updateValueAndValidity();
    });
  }
  addRadioOption() {
    if (this.form.value.radioKey !== '' && this.form.value.radioValue !== '') {
      this.radioOptions.push({
        key: this.form.value.radioKey,
        value: this.form.value.radioValue,
      });
      this.form.get('inputTypeOptions')?.patchValue(this.radioOptions);
      this.form.get('radioKey')?.patchValue('');
      this.form.get('radioValue')?.patchValue('');
    }
  }

  addDropdownOption() {
    if (
      this.form.value.dropdownKey !== '' &&
      this.form.value.dropdownValue !== ''
    ) {
      this.dropdownOptions.push({
        key: this.form.value.dropdownKey,
        value: this.form.value.dropdownValue,
      });
      this.form.get('inputTypeOptions')?.patchValue(this.dropdownOptions);
      this.form.get('dropdownKey')?.patchValue('');
      this.form.get('dropdownValue')?.patchValue('');
    }
  }

  deleteRadioOption(index: number) {
    this.radioOptions.splice(index, 1);
    this.form.get('inputTypeOptions')?.patchValue(this.radioOptions);
  }

  deleteDropdownOption(index: number) {
    this.dropdownOptions.splice(index, 1);
    this.form.get('inputTypeOptions')?.patchValue(this.dropdownOptions);
  }

  onTabChange(e: any) {
    this.form.get('inputType')?.patchValue('');
    this.form.get('inputTypeOptions')?.patchValue([]);
    if (e === 2) {
      this.form.get('inputType')?.patchValue('RADIO');
    }
    if (e === 3) {
      this.form.get('inputType')?.patchValue('SELECT');
    }
    if (e === 4) {
      this.form.get('inputType')?.patchValue('LOCATION');
    }
    if (e === 5) {
      this.form.get('inputType')?.patchValue('FILE');
    }
    if (e === 6) {
      this.form.get('inputType')?.patchValue('LINK_QR');
    }
  }
  getOptionsForInputType(inputType: string | null): any[] {
    return [
      {
        label: this.form
          .get('validations')
          ?.get('format')
          ?.get('details')
          ?.get('validationName')?.value,
        subLabel: this.form
          .get('validations')
          ?.get('format')
          ?.get('details')
          ?.get('format')?.value,
      },
    ];
  }
  filterTopLevelProps(obj: any) {
    if (!obj) return {};

    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) =>
          value === null ||
          typeof value !== 'object' ||
          (Array.isArray(value) &&
            value.every(item => typeof item !== 'object'))
      )
    );
  }
  filterKdeTopLevel(kdeArray: any[]): any[] {
    if (!Array.isArray(kdeArray)) return [];

    return kdeArray.map(kde =>
      Object.fromEntries(
        Object.entries(kde).filter(
          ([_, value]) => value === null || typeof value !== 'object'
        )
      )
    );
  }

  action() {
    if (this.form.invalid) return;
    if (this.cteType === 'ORGANIZATION') {
      const selectedFilters: any = {};

      const detailsControl = this.form
        .get('validations')
        ?.get('linkQRSettings')
        ?.get('details');

      if (detailsControl?.value && detailsControl?.value?.product) {
        selectedFilters.product = this.filterTopLevelProps(
          detailsControl?.value.product
        );
      }
      if (detailsControl?.value && detailsControl?.value?.cte) {
        selectedFilters.cte = this.filterTopLevelProps(
          detailsControl?.value.cte
        );
      }
      if (detailsControl?.value && detailsControl?.value?.kde) {
        selectedFilters.kde = this.filterKdeTopLevel(detailsControl?.value.kde);
      }

      detailsControl?.setValue(selectedFilters);
      const newKde: AddKDEParams = {
        title: this.form.value.title,
        description: this.form.value.description,
        gs1: this.form.value.gs1 ?? false,
        fsma204: this.form.value.fsma204 ?? false,
        inputType: this.form.value.inputType,
        inputTypeOptions: this.form.value.inputTypeOptions,
        isDefault: false,
        organizationCteId: this.cteID,
        validations: this.form.value.validations,
      };
      this.disabling = false;
      if (this.type === 'EDIT') {
        this.store.dispatch(new UpdateKDE(this.id, newKde)).subscribe(() => {
          this.disabling = true;
          this.resetForm();
        });
        return;
      }
      if (this.type === 'ADD') {
        this.store.dispatch(new AddKDE(newKde)).subscribe(() => {
          this.disabling = true;
          this.resetForm();
        });
        return;
      }
    }
    if (this.cteType === 'PRODUCT') {
      const selectedFilters: any = {};

      const detailsControl = this.form
        .get('validations')
        ?.get('linkQRSettings')
        ?.get('details');

      if (detailsControl?.value && detailsControl?.value?.product) {
        selectedFilters.product = this.filterTopLevelProps(
          detailsControl?.value.product
        );
      }
      if (detailsControl?.value && detailsControl?.value?.cte) {
        selectedFilters.cte = this.filterTopLevelProps(
          detailsControl?.value.cte
        );
      }
      if (detailsControl?.value && detailsControl?.value?.kde) {
        selectedFilters.kde = this.filterKdeTopLevel(detailsControl?.value.kde);
      }

      detailsControl?.setValue(selectedFilters);
      const newKde: KDE = {
        title: this.form.value.title,
        description: this.form.value.description,
        gs1: this.form.value.gs1 ?? false,
        fsma204: this.form.value.fsma204 ?? false,
        inputType: this.form.value.inputType,
        inputTypeOptions: this.form.value.inputTypeOptions,
        isDefault: false,
        productCteId: this.cteID,
        order: 0,
        id: 0,
        validations: this.form.value.validations,
      };
      this.disabling = false;
      if (this.type === 'ADD') {
        this.store.dispatch(new AddProductKDE(newKde)).subscribe(() => {
          this.disabling = true;
          this.resetForm();
          this.actionSuccess.emit(true);
        });
      }
      if (this.type === 'EDIT') {
        this.store
          .dispatch(new UpdateProductKDE(this.id, newKde))
          .subscribe(() => {
            this.disabling = true;
            this.resetForm();
            this.actionSuccess.emit(true);
          });
        this.isLoading = false;
        this.activeTabIndex = 0;
      }
    }

    if (this.cteType === 'RECALL') {
      const newKde: KDE = {
        title: this.form.value.title,
        description: this.form.value.description,
        gs1: this.form.value.gs1 ?? false,
        fsma204: this.form.value.fsma204 ?? false,
        inputType: this.form.value.inputType,
        inputTypeOptions: this.form.value.inputTypeOptions,
        isDefault: false,
        recallProductCteId: this.cteID,
        order: 0,
        id: 0,
        validations: this.form.value.validations,
      };
      this.disabling = false;
      if (this.type === 'ADD') {
        this.store.dispatch(new AddRecallKDE(newKde)).subscribe(() => {
          this.disabling = true;
          this.resetForm();
          this.actionSuccess.emit(true);
        });
      }
      if (this.type === 'EDIT') {
        this.store
          .dispatch(new UpdateRecallKDE(this.id, newKde))
          .subscribe(() => {
            this.disabling = true;
            this.resetForm();
            this.actionSuccess.emit(true);
          });
        this.isLoading = false;
        this.activeTabIndex = 0;
      }
    }
  }

  resetForm() {
    this.form.reset({});
    this.form.get('inputTypeOptions')?.patchValue([]);
    this.radioOptions = [];
    this.dropdownOptions = [];
    this.activeTabIndex = 0;
    this.isLoading = false;
  }
}
