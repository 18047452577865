import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import { CteMapStatistics } from '../../../../store/general-store/general.model';

@Component({
  selector: 'app-track-and-trace',
  standalone: true,
  imports: [],
  templateUrl: './track-and-trace.component.html',
  styleUrl: './track-and-trace.component.scss',
})
export class TrackAndTraceComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) cteData: CteMapStatistics[] = [];
  @Input() height = '400px';
  private root!: am5.Root;
  private chart!: am5map.MapChart;
  private backgroundSeries!: am5map.MapPolygonSeries;
  private polygonSeries!: am5map.MapPolygonSeries;
  private lineSeries!: am5map.MapLineSeries;
  private pointSeries!: am5map.MapPointSeries;
  private planeSeries!: am5map.MapPointSeries;
  private planeDataItem!: any;

  ngOnInit(): void {
    // Create root element
    this.root = am5.Root.new('trackAndTrace');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create the map chart
    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        panX: 'rotateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
      })
    );

    this.createControls();
    this.createBackgroundSeries();
    this.createPolygonSeries();
    this.createLineSeries();
    this.createPointSeries();
    this.createPlaneSeries();

    this.chart.appear(1000, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cteData'] && !changes['cteData'].firstChange) {
      if (this.planeDataItem) {
        this.planeDataItem = null;
      }
      if (this.pointSeries) {
        this.pointSeries.data.clear();
      }
      if (this.lineSeries) {
        this.lineSeries.data.clear();
      }
      if (this.planeSeries) {
        this.planeSeries.data.clear();
      }
      if (this.cteData.length > 0) {
        this.createLineSeries();
        this.createPointSeries();
        this.createPlaneSeries();
      }
    }
  }
  private createControls() {
    const cont = this.chart.children.push(
      am5.Container.new(this.root, {
        layout: this.root.horizontalLayout,
        x: 20,
        y: 40,
      })
    );

    cont.children.push(
      am5.Label.new(this.root, {
        centerY: am5.p50,
        text: 'Map',
      })
    );

    const switchButton = cont.children.push(
      am5.Button.new(this.root, {
        themeTags: ['switch'],
        centerY: am5.p50,
        icon: am5.Circle.new(this.root, {
          themeTags: ['icon'],
        }),
      })
    );

    switchButton.on('active', () => {
      if (!switchButton.get('active')) {
        this.chart.set('projection', am5map.geoMercator());
        this.chart.set('panY', 'translateY');
        this.chart.set('rotationY', 0);
        this.backgroundSeries.mapPolygons.template.set('fillOpacity', 0);
      } else {
        this.chart.set('projection', am5map.geoOrthographic());
        this.chart.set('panY', 'rotateY');
        this.backgroundSeries.mapPolygons.template.set('fillOpacity', 0.1);
      }
    });

    cont.children.push(
      am5.Label.new(this.root, {
        centerY: am5.p50,
        text: 'Globe',
      })
    );
  }

  private createBackgroundSeries() {
    this.backgroundSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {})
    );
    this.backgroundSeries.mapPolygons.template.setAll({
      fill: this.root.interfaceColors.get('alternativeBackground'),
      fillOpacity: 0,
      strokeOpacity: 0,
    });

    this.backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });
  }

  private createPolygonSeries() {
    this.polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ'],
      })
    );
  }

  private createLineSeries() {
    this.lineSeries = this.chart.series.push(
      am5map.MapLineSeries.new(this.root, {})
    );
    this.lineSeries.mapLines.template.setAll({
      stroke: this.root.interfaceColors.get('alternativeBackground'),
      strokeOpacity: 0.3,
    });
  }

  private createPointSeries() {
    this.pointSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: 'totalCount',
        calculateAggregates: true,
        latitudeField: 'latitude',
        longitudeField: 'longitude',
      })
    );

    this.pointSeries.bullets.push((root, _, _dataItem) => {
      const circle = am5.Circle.new(root, {
        radius: 7,
        cursorOverStyle: 'pointer',
        tooltipY: 0,
        fill: am5.color(0xffba00),
        stroke: root.interfaceColors.get('background'),
        strokeWidth: 2,
        draggable: false,
        tooltipText: '{cteName}: [bold]{totalCount}[/] Items',
      });
      // custom point
      // const picture = am5.Picture.new(root, {
      //   width: 32,
      //   height: 32,
      //   x: am5.percent(30),
      //   y: am5.percent(50),
      //   centerX: am5.percent(50),
      //   centerY: am5.percent(50),
      //   src: '../../../../assets/bacteria-svgrepo-com.svg',
      // });

      return am5.Bullet.new(root, {
        sprite: circle,
        dynamic: true,
      });
    });
  }

  private createPlaneSeries() {
    this.planeSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {})
    );

    const plane = am5.Graphics.new(this.root, {
      svgPath:
        'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47',
      scale: 0.06,
      centerY: am5.p50,
      centerX: am5.p50,
      fill: am5.color(0x000000),
    });

    this.planeSeries.bullets.push(() => {
      const container = am5.Container.new(this.root, {});
      container.children.push(plane);
      return am5.Bullet.new(this.root, { sprite: container });
    });

    const lineDataItem = this.lineSeries.pushDataItem({
      pointsToConnect: this.cteData.map((cte: any) => this.addCity(cte)),
    });

    this.planeDataItem = this.planeSeries.pushDataItem({
      lineDataItem: lineDataItem,
      positionOnLine: 0,
      autoRotate: true,
    });

    this.planeDataItem.dataContext = {};

    this.planeDataItem.animate({
      key: 'positionOnLine',
      to: 1,
      duration: 30000, // Increase the duration to make the plane move slower
      loops: Infinity,
      easing: am5.ease.yoyo(am5.ease.linear),
    });
    this.planeDataItem.on('positionOnLine', (value: number) => {
      if (this.planeDataItem.dataContext.prevPosition < value) {
        plane.set('rotation', 0);
      }

      if (this.planeDataItem.dataContext.prevPosition > value) {
        plane.set('rotation', -180);
      }
      this.planeDataItem.dataContext.prevPosition = value;
    });
  }

  private addCity(payload: any) {
    return this.pointSeries.pushDataItem(payload);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
