<div class="w-full p-8">
  @if (sidebar.visible && (sidebar.type === 'ADD' || sidebar.type === 'EDIT')) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      (sidebarHidden)="sidebar.visible = false"
      [title]="sidebar.title">
      <app-add-organization-form
        [data]="sidebar.data"
        [title]="sidebar.title"
        [type]="sidebar.type"
        [id]="sidebar.id"
        (actionSuccess)="getOrganizationList($event); sidebar.visible = false">
      </app-add-organization-form>
    </app-right-form-base>
  }
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex">
      <p-button
        icon="pi pi-plus"
        label="Add Organization"
        (onClick)="
          sidebar = {
            visible: true,
            title: 'Add New Organization',
            type: 'ADD',
            width: 'w-50',
          }
        " />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      export="ORGANIZATION"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
