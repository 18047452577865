<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h1 class="font-normal">Forgot <strong>Password</strong></h1>
    <p class="text-gray">
      Enter your email address to reset your reGenesis account password.
    </p>
  </div>
  <div class="flex-1 px-4">
    <form [formGroup]="form">
      <div>
        <p>Email</p>
        <p-iconfield>
          <p-inputicon class="pi pi-envelope" />
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('email')?.invalid && form.get('email')?.touched
            "
            placeholder="example@example.com"
            formControlName="email" />
        </p-iconfield>
        <app-error-message
          *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
          [control]="form.get('email')"
          label="Email"></app-error-message>
      </div>

      <div>
        <p-button
          label="Reset Password"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="w-100"
          (click)="submitForgotPassword()"
          [disabled]="this.form.invalid"
          [loading]="processing$()" />
      </div>

      <div class="mt-8">
        <p class="text-center text-gray">
          Remember your password?
          <a class="text-dark font-bold" [routerLink]="'/'">SIGN IN</a>
        </p>
      </div>
    </form>
  </div>
</div>
