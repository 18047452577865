<div class="relative w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div>
      <p-button [outlined]="true" label="Export CSV" icon="pi pi-download" />
    </div>
  </div>
  @if (isLoading$()) {
    <div
      class="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
  <div class="grid gap-4 mt-4">
    <div>
      <app-dashboard-filters
        (selectedFilters)="setFilters($event)"
        [allowedFilters]="[
          'PRODUCT',
          'DATE_RANGE',
          'CTE',
          'KDE',
          'KDE_VALUE',
        ]"></app-dashboard-filters>

      <div class="relative">
        <div>
          <div class="grid grid-cols-4 gap-3">
            <div class="col-span-1">
              <div>
                <div class="flex items-center justify-between mt-2 mb-2">
                  <h3>Statistics</h3>
                </div>
                <div class="h-[500px] flex flex-col gap-5">
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        Expired
                        <span
                          class="pi pi-circle-fill ml-5 text-red-600 ring-4 ring-red-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ expirationAnalysis$()?.expired }}
                      </h2>
                    </div>
                  </div>
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        Near Expiration
                        <span
                          class="pi pi-circle-fill ml-5 text-yellow-300 ring-4 ring-yellow-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ expirationAnalysis$()?.expiring }}
                      </h2>
                    </div>
                  </div>
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        Safe
                        <span
                          class="pi pi-circle-fill ml-5 text-green-600 ring-4 ring-green-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ expirationAnalysis$()?.safe }}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              <app-heat-map
                [overlay]="overlay"
                [title]="'Expiration Heat Map'"
                [data]="locationExpirationStats$()"></app-heat-map>
            </div>
          </div>
          <div class="grid grid-cols-4 gap-3">
            <div class="col-span-2">
              <app-donut-chart
                [overlay]="overlay"
                [title]="'Expiration Counters'"
                [data]="expirationAnalysis$()"></app-donut-chart>
            </div>
            <div class="col-span-2">
              <app-expiration-bar-chart
                [overlay]="overlay"
                [title]="'Expiration Details'"
                [data]="locationExpirationStats$()"></app-expiration-bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
