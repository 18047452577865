import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-report-line-chart',
  imports: [
    ButtonModule
  ],
  templateUrl: './report-line-chart.component.html',
})
export class ReportLineChartComponent<T> implements OnChanges, OnDestroy {
  @Input({ required: true }) data: T | null = null;
  @Output() reload = new EventEmitter<any>();

  private root!: am5.Root;
  public noData: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initMap();
      } else {
        this.noData = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
  
    const validData = Array.isArray(this.data) ? this.data : [];
    this.noData = validData.length === 0;
    if (this.noData) return;
  
    this.root = am5.Root.new('lineChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);
  
    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        layout: this.root.verticalLayout,
      })
    );
  
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(this.root, {
        groupData: true,
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );
  
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );
  
    // Create a single series for "Quantity Received"
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(this.root, {
        name: 'Quantity Received',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'time',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{name}: {valueY}',
        }),
      })
    );
  
    series.strokes.template.set('strokeWidth', 3);
  
    // Add bullets (data points)
    series.bullets.push(() =>
      am5.Bullet.new(this.root, {
        sprite: am5.Circle.new(this.root, {
          radius: 5,
          fill: series.get('fill'),
        }),
      })
    );
  
    // Convert date string to timestamp
    series.data.setAll(
      validData.map(item => ({
        time: new Date(item.time).getTime(),
        value: item.value,
      }))
    );
  
    chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        behavior: 'zoomXY',
        xAxis: xAxis,
      })
    );
  
    yAxis.set(
      'tooltip',
      am5.Tooltip.new(this.root, {
        themeTags: ['axis'],
      })
    );
  
    chart.appear(1000, 100);
  }
  
}
