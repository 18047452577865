import {
  Component,
  effect,
  Signal,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { DropdownModule } from 'primeng/dropdown';
import { ImageUploadComponent } from '../../shared-components/image-upload/image-upload.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Store } from '@ngxs/store';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  AddProduct,
  UpdateProduct,
} from '../../../../store/products-store/products.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { Product } from '../../../../store/products-store/products.model';
import {
  AddUploadedFile,
  ClearUploadedFiles,
} from '../../../../store/general-store/general.actions';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-add-product-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    ImageUploadComponent,
    CheckboxModule,
    InputNumberModule,
    RightFormFooterComponent,
  ],
  templateUrl: './add-product-form.component.html',
  styleUrl: './add-product-form.component.scss',
})
export class AddProductFormComponent implements OnDestroy, OnChanges {
  @Input() data?: Product;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter();
  @Output() actionCreateProduct = new EventEmitter();

  form: FormGroup;
  gtin = '';
  fdaClassified = false;

  processing$ = this.store.selectSignal(ProductStateSelectors.isProcessing);
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);
  product$: Signal<Product | null> = this.store.selectSignal(
    ProductStateSelectors.getProduct
  );

  measurementUnits = ['MILLIGRAM', 'KILOGRAM', 'GRAM', 'MILLILITRE', 'LITRE'];
  pricingUnits = ['USD', 'PKR', 'GBP', 'EURO', 'CAD', 'AUD'];
  packagingUnits = [
    'CARTON',
    'BOX',
    'CRATE',
    'CONTAINER',
    'PACK',
    'LOAF',
    'BAG',
    'BOTTLE',
    'JAR',
    'PACKET',
    'BAGS',
    'BALES',
  ];

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      productName: ['', [Validators.required]],
      brandName: [''],
      productDescription: ['', [Validators.required]],
      measurementUnit: ['', [Validators.required]],
      pricingUnit: ['', [Validators.required]],
      packagingUnit: ['', [Validators.required]],
      gtin: [this.gtin, [this.gtinValidator]],
      logo: ['', [Validators.required]],
      fdaClassified: [false, [Validators.required]],
      facebookUrl: [''],
      xUrl: [''],
      youtubeUrl: [''],
      webUrl: [''],
      instagramUrl: [''],
      videoContentUrl: [''],
      greenDuration: [0, [Validators.required, Validators.min(0)]],
      yellowDuration: [0, [Validators.required, Validators.min(0)]],
      redDuration: [0, [Validators.required, Validators.min(0)]],
    });

    effect(
      () => {
        if (this.product$() || this.type === 'ADD') {
          if (this.type === 'ADD') {
            this.store.dispatch(new ClearUploadedFiles());
            this.resetFormValues();
          } else {
            this.setFormValues(this.product$());
            if (!this.uploadedFiles$()['logo']) {
              this.populateUploadedFiles(this.product$());
            }
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.type === 'ADD') {
      this.store.dispatch(new ClearUploadedFiles());
      this.resetFormValues();
    } else if (
      this.type === 'EDIT' &&
      changes['data']?.currentValue !== changes['data']?.previousValue
    ) {
      this.setFormValues(this.data);
      if (!this.uploadedFiles$()['logo']) {
        this.populateUploadedFiles(this.data);
      }

      this.form.get('logo')?.clearValidators();
      this.form.get('logo')?.updateValueAndValidity();
    }
  }

  private gtinValidator(control: AbstractControl): ValidationErrors | null {
    const gtin = control.value;
    const isValidGtin = /^\d{8,14}$/.test(gtin);
    return isValidGtin ? null : { invalidGtin: true };
  }

  onLogoSelected(_event: Event) {
    this.form.get('logo')?.clearValidators();
    this.form.get('logo')?.updateValueAndValidity();
  }

  action() {
    if (!this.fdaClassified) return;
    if (!this.form.valid) return;
    const values = this.form.value;
    const logoFiles = this.uploadedFiles$()['logo'];
    const logo =
      logoFiles && logoFiles.length > 0 ? logoFiles[0]?.objectURL : '';
    const productImages =
      this.uploadedFiles$()['productImages']?.map(i => ({
        name: i.name,
        url: i.objectURL,
      })) ?? [];

    // Collect the links dynamically
    const links: Record<string, string> = {};
    [
      'facebookUrl',
      'xUrl',
      'youtubeUrl',
      'webUrl',
      'instagramUrl',
      'videoContentUrl',
    ].forEach(key => {
      if (values[key]) {
        links[key] = values[key];
      }
    });

    const payload = {
      productName: values.productName,
      productDescription: values.productDescription,
      brandName: values.brandName,
      measurementUnit: values.measurementUnit,
      packagingUnit: values.packagingUnit,
      pricingUnit: values.pricingUnit,
      gtin: values.gtin,
      links, // Use dynamic links
      fdaClassified: values.fdaClassified,
      logo: logo ?? '',
      productImages,
      greenDuration: values.greenDuration,
      yellowDuration: values.yellowDuration,
      redDuration: values.redDuration,
    };

    const action$ =
      this.type === 'ADD'
        ? this.store.dispatch(new AddProduct(payload))
        : this.store.dispatch(new UpdateProduct(this.id!, payload));

    action$.subscribe(() => {
      this.resetFormValues();
      this.store.dispatch(new ClearUploadedFiles());
      this.actionCreateProduct.emit();
    });
  }

  private setFormValues(data?: Product | null): void {
    this.form.patchValue({
      productName: data?.productName || '',
      productDescription: data?.productDescription || '',
      brandName: data?.brandName || '',
      measurementUnit: data?.measurementUnit || '',
      packagingUnit: data?.packagingUnit || '',
      pricingUnit: data?.pricingUnit || '',
      gtin: data?.gtin || '',
      fdaClassified: data?.fdaClassified || false,
      greenDuration: data?.greenDuration || 0,
      yellowDuration: data?.yellowDuration || 0,
      redDuration: data?.redDuration || 0,
    });

    // Dynamically set the links
    if (data?.links) {
      Object.keys(data.links).forEach(key => {
        this.form.patchValue({ [key]: data.links[key] || '' });
      });
    }
  }

  private resetFormValues(): void {
    this.form.reset({
      productName: '',
      brandName: '',
      productDescription: '',
      measurementUnit: '',
      pricingUnit: '',
      packagingUnit: '',
      gtin: '',
      logo: '',
      fdaClassified: false,
      facebookUrl: '',
      xUrl: '',
      youtubeUrl: '',
      webUrl: '',
      instagramUrl: '',
      videoContentUrl: '',
      greenDuration: 0,
      yellowDuration: 0,
      redDuration: 0,
    });
  }

  private populateUploadedFiles(product?: Product | null): void {
    if (product) {
      const logo: UploadedFile = {
        objectURL: product.logo,
      };
      this.store.dispatch(new AddUploadedFile(logo, 'logo'));

      product.productImages.forEach(image => {
        const productImage: UploadedFile = {
          name: image.name,
          objectURL: image.url,
        };
        this.store.dispatch(new AddUploadedFile(productImage, 'productImages'));
      });
    }
  }

  generateSKU(productName: string, brandName: string): string {
    const productCode = productName
      .substring(0, 3)
      .toUpperCase()
      .padEnd(3, 'X');
    const brandCode = brandName.substring(0, 3).toUpperCase().padEnd(3, 'X');
    const randomSuffix = this.generateRandomAlphanumeric(4);
    return `${productCode}-${brandCode}-${randomSuffix}`;
  }

  generateRandomAlphanumeric(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearUploadedFiles());
  }
}
