import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  GetRecallProductsAnalysis,
  GetRecallProductsStats,
  ResetDashboardState,
} from '../../../../store/dashboard-store/dashboard.actions';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { ButtonModule } from 'primeng/button';
import { DashboardFiltersComponent } from '../../../components/shared-components/dashboard-filters/dashboard-filters.component';
import {
  RecallProductsAnalysis,
  RecallProductsStats,
} from '../../../../store/dashboard-store/dashboard.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RecallGanttChartComponent } from '../../../components/charts/recall-gantt-chart/recall-gantt-chart.component';
import { RecallClusterColumnsComponent } from '../../../components/charts/recall-cluster-columns/recall-cluster-columns.component';
import { ScanMapComponent } from '../../../components/charts/scan-map/scan-map.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-recall-dashboard',
  standalone: true,
  imports: [
    ScanMapComponent,
    ButtonModule,
    DashboardFiltersComponent,
    ProgressSpinnerModule,
    RecallGanttChartComponent,
    RecallClusterColumnsComponent,
    BreadcrumbModule,
  ],
  templateUrl: './recall-dashboard.component.html',
  styleUrl: './recall-dashboard.component.scss',
})
export class RecallDashboardComponent implements OnInit, OnDestroy {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  recallId: number | null = null;
  overlay: boolean = false;
  isLoading$: Signal<boolean> = this.store.selectSignal(
    DashboardStateSelectors.isLoading
  );

  recallProductsAnalysis$: Signal<RecallProductsAnalysis | null> =
    this.store.selectSignal(DashboardStateSelectors.getRecallProductsAnalysis);

  recallProductsStats$: Signal<RecallProductsStats | null> =
    this.store.selectSignal(DashboardStateSelectors.getRecallProductsStats);

  selectedFilters: any = {
    recallId: null,
  };

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
    this.getRecallProductsStats();
    this.store.dispatch(
      new GetRecallProductsAnalysis(15, this.selectedFilters)
    );
  }

  ngOnInit(): void {}

  setFilters(event: any) {
    if (event.recall) {
      this.overlay = true;
      this.recallId = event.recall.id;
      delete event.recall;
      this.selectedFilters = event;
      this.getRecallProductsAnalysis();
    } else {
      this.overlay = false;
      this.store.dispatch(new ResetDashboardState());
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetDashboardState());
  }

  getRecallProductsAnalysis() {
    this.store.dispatch(
      new GetRecallProductsAnalysis(this.recallId!, this.selectedFilters)
    );
  }

  getRecallProductsStats() {
    this.store.dispatch(new GetRecallProductsStats());
  }
}
