import { Component, Input, Signal, ViewEncapsulation } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { SuperAdminMenu, OrganizationMenu } from '../../data/menu.data';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../store/users-store/user.selectors';
import {
  MergedPermission,
  UserRoles,
} from '../../../store/users-store/user.model';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarModule, PanelMenuModule, ButtonModule, CommonModule],
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() isExpanded = false;
  items: MenuItem[] = OrganizationMenu;
  userRole$: Signal<UserRoles> = this.store.selectSignal(
    UserStateSelectors.getUserRole
  );
  userPermissions$: Signal<MergedPermission[]> = this.store.selectSignal(
    UserStateSelectors.getUserPermissions
  );

  constructor(private store: Store) {
    if (this.userRole$() === UserRoles.SUPER_ADMIN) {
      this.items = SuperAdminMenu;
    }
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  checkPermissions(slugs: string[]): boolean {
    // If user is SUPER_ADMIN, allow access to all slugs
    if (this.userRole$() === UserRoles.SUPER_ADMIN) {
      return true;
    }

    // If no slugs are provided, allow access by default
    if (slugs.length === 0) return true;

    // Check permissions for non-SUPER_ADMIN users
    return this.userPermissions$().some(
      p => slugs.includes(p.slug) && p.allowed
    );
  }
}
