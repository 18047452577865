@if (user$()?.currentPlan?.productDetails?.name === 'Basic Plan') {
  <app-no-subscription-banner
    class="mb-5 block"
    title="Feature Unavailable"
    description="This is feature is not available in your subscribed plan. Please upgrade your subscription to access this feature!"></app-no-subscription-banner>
}
<div
  class="grid grid-cols-3 gap-8 full-height-form"
  [ngClass]="{
    'disabled-section':
      user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
  }">
  <div class="qr-template-form col-span-2">
    <div class="flex flex-col">
      <div class="sticky top-0 z-50 pt-5 bg-light">
        <app-right-form-header
          [title]="title"
          (callCancel)="actionSuccess.emit()"
          (callAction)="updateProductQrSettings()"
          [processing]="processing$()"
          [disabled]="form.invalid"></app-right-form-header>
      </div>
      <div class="flex-1 h-full">
        <form [formGroup]="form">
          <p-accordion value="0">
            <p-accordion-panel value="0">
              <p-accordion-header>QR Printing Settings</p-accordion-header>
              <p-accordion-content>
                <div>
                  <p>Print Width</p>
                  <input
                    type="number"
                    pInputText
                    class="w-full bg-light"
                    [class.input-danger]="
                      form.get('width')?.invalid && form.get('width')?.touched
                    "
                    placeholder="Print Width"
                    formControlName="width" />
                  <app-error-message
                    [control]="form.get('width')"
                    label="Print Width"></app-error-message>
                </div>

                <div>
                  <p>Print Height</p>
                  <input
                    type="number"
                    pInputText
                    class="w-full bg-light"
                    [class.input-danger]="
                      form.get('height')?.invalid && form.get('height')?.touched
                    "
                    placeholder="Print Height"
                    formControlName="height" />
                  <app-error-message
                    [control]="form.get('height')"
                    label="Print Height"></app-error-message>
                </div>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="1">
              <p-accordion-header>QR Styles</p-accordion-header>
              <p-accordion-content>
                <p class="text-primary">QR Colors</p>

                <div
                  class="flex circle-container bg-light !mb-3 p-3 rounded items-center gap-3">
                  <ngx-colors
                    ngx-colors-trigger
                    formControlName="dotsColor"></ngx-colors>
                  <p class="!m-0 !mb-[-5px]">QR Color</p>
                </div>

                <div
                  class="flex circle-container !mb-3 bg-light p-3 rounded items-center gap-3">
                  <ngx-colors
                    ngx-colors-trigger
                    formControlName="backgroundColor"></ngx-colors>
                  <p class="!m-0 !mb-[-5px]">Background Color</p>
                </div>

                <div
                  class="flex circle-container !mb-3 bg-light p-3 rounded items-center gap-3">
                  <ngx-colors
                    ngx-colors-trigger
                    formControlName="cornersDotColor"></ngx-colors>
                  <p class="!m-0 !mb-[-5px]">Corner Dot Color</p>
                </div>

                <div
                  class="flex circle-container !mb-3 bg-light p-3 rounded items-center gap-3">
                  <ngx-colors
                    ngx-colors-trigger
                    formControlName="cornersSquareColor"></ngx-colors>
                  <p class="!m-0 !mb-[-5px]">Corner Square Color</p>
                </div>

                <p class="text-primary">QR Shapes</p>

                <div
                  class="flex circle-container !mb-3 bg-light p-3 rounded items-center gap-3">
                  <span
                    (click)="cornersDotType = 'dot'; setQrConfig()"
                    class="material-icons font-bold text-2xl cursor-pointer mr-[5px]"
                    [ngClass]="{
                      'active-shape': cornersDotType.toLowerCase() === 'dot',
                    }"
                    pTooltip="Dot"
                    tooltipPosition="top">
                    radio_button_unchecked
                  </span>
                  <span
                    (click)="cornersDotType = 'square'; setQrConfig()"
                    class="material-icons font-bold text-2xl cursor-pointer mr-[5px]"
                    [ngClass]="{
                      'active-shape': cornersDotType.toLowerCase() === 'square',
                    }"
                    pTooltip="Square"
                    tooltipPosition="top">
                    check_box_outline_blank
                  </span>

                  <p class="!m-0 !mb-[-5px]">Corner Dot Shape</p>
                </div>

                <div
                  class="flex circle-container !mb-3 bg-light p-3 rounded items-center gap-3">
                  <span
                    (click)="cornersSquareType = 'dot'; setQrConfig()"
                    class="material-icons font-bold text-2xl cursor-pointer mr-[5px]"
                    [ngClass]="{
                      'active-shape': cornersSquareType.toLowerCase() === 'dot',
                    }"
                    pTooltip="Dot"
                    tooltipPosition="top">
                    radio_button_unchecked
                  </span>
                  <span
                    (click)="cornersSquareType = 'square'; setQrConfig()"
                    class="material-icons font-bold text-2xl cursor-pointer mr-[5px]"
                    [ngClass]="{
                      'active-shape':
                        cornersSquareType.toLowerCase() === 'square',
                    }"
                    pTooltip="Square"
                    tooltipPosition="top">
                    check_box_outline_blank
                  </span>
                  <p class="!m-0 !mb-[-5px]">Corner Square Shape</p>
                </div>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="2">
              <p-accordion-header>QR Images</p-accordion-header>
              <p-accordion-content>
                <div class="col-span-2 !mb-[15px]">
                  <p>Center Image</p>
                  <app-document-upload
                    [title]="'Select Center Image'"
                    [fileType]="'qrCenterImage'"
                    [supportedFormats]="'image/png'"
                    [singleMode]="true"></app-document-upload>
                </div>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="3">
              <p-accordion-header
                >Call to Action (CTA) Settings</p-accordion-header
              >
              <p-accordion-content>
                <div>
                  <p>Call to Action (CTA) Position</p>
                  <p-select
                    formControlName="ctaLabelPosition"
                    [options]="ctaPositions"
                    placeholder="Select CTA Position"
                    class="w-full !bg-light"
                    [class.input-danger]="
                      form.get('ctaLabelPosition')?.invalid &&
                      form.get('ctaLabelPosition')?.touched
                    " />
                  <app-error-message
                    [control]="form.get('ctaLabelPosition')"
                    label="CTA Position"></app-error-message>
                </div>

                <div>
                  <p>Call to Action (CTA) Text</p>
                  <input
                    pInputText
                    class="w-full !bg-light"
                    [class.input-danger]="
                      form.get('ctaLabelText')?.invalid &&
                      form.get('ctaLabelText')?.touched
                    "
                    placeholder="Call to Action (CTA) Text"
                    formControlName="ctaLabelText" />
                  <app-error-message
                    [control]="form.get('ctaLabelText')"
                    label="CTA Text"></app-error-message>
                </div>
              </p-accordion-content>
            </p-accordion-panel>
          </p-accordion>
        </form>
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="sticky top-0 z-50 pt-5 bg-light">
      <div class="flex items-center justify-between">
        <h2>QR Preview</h2>
        <p-button
          (click)="resetQr()"
          [disabled]="defaultQRValues()"
          [outlined]="true"
          label="Reset QR Design" />
      </div>
      <p-divider></p-divider>
    </div>
    @if (data && data.productQrSettings) {
      <app-qr-preview
        [product]="data"
        [qrConfig]="qrConfig"
        [ctaConfig]="ctaConfig"></app-qr-preview>
    }
  </div>
  <div></div>
</div>
