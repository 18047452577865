@if (user$()?.currentPlan?.productDetails?.name === 'Basic Plan') {
  <app-no-subscription-banner
    class="mb-5 block"
    title="Feature Unavailable"
    description="This is feature is not available in your subscribed plan. Please upgrade your subscription to access this feature!"></app-no-subscription-banner>
}
<div
  class="grid grid-cols-3 gap-8 full-height-form"
  [ngClass]="{
    'disabled-section':
      user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
  }">
  <div class="qr-template-form col-span-2">
    <form [formGroup]="form">
      <p-accordion [activeIndex]="0">
        <p-accordionTab header="QR Printing Settings" class="mb-[10px]">
          <div>
            <p class="text-base font-normal mb-[3px]">Print Width</p>
            <input
              type="number"
              pInputText
              class="w-full"
              [class.input-danger]="
                form.get('width')?.invalid && form.get('width')?.touched
              "
              placeholder="Print Width"
              formControlName="width" />
            <app-error-message
              [control]="form.get('width')"
              label="Print Width"></app-error-message>
          </div>

          <div>
            <p class="text-base font-normal mb-[3px]">Print Height</p>
            <input
              type="number"
              pInputText
              class="w-full"
              [class.input-danger]="
                form.get('height')?.invalid && form.get('height')?.touched
              "
              placeholder="Print Height"
              formControlName="height" />
            <app-error-message
              [control]="form.get('height')"
              label="Print Height"></app-error-message>
          </div>
        </p-accordionTab>

        <p-accordionTab header="QR Styles">
          <div class="!mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">QR Color</p>
            <div class="flex circle-container">
              @for (color of colors; track color) {
                <button
                  pButton
                  type="button"
                  (click)="dotsColor = color; setQrConfig()"
                  [style]="{ backgroundColor: color }"
                  class="circle-button"
                  [ngClass]="{ active: color === dotsColor }"></button>
              }
              <p-colorPicker
                (onChange)="
                  dotsColor = $event.value.toString(); setQrConfig()
                " />
            </div>
          </div>

          <div class="!mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">Background Color</p>
            <div class="flex circle-container">
              @for (color of colors; track color) {
                <button
                  pButton
                  type="button"
                  (click)="backgroundColor = color; setQrConfig()"
                  [style]="{ backgroundColor: color }"
                  class="circle-button"
                  [ngClass]="{ active: color === backgroundColor }"></button>
              }
              <p-colorPicker
                (onChange)="
                  backgroundColor = $event.value.toString(); setQrConfig()
                " />
            </div>
          </div>

          <div class="!mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">Corner Dot Color</p>
            <div class="flex circle-container">
              @for (color of colors; track color) {
                <button
                  pButton
                  type="button"
                  (click)="cornersDotColor = color; setQrConfig()"
                  [style]="{ backgroundColor: color }"
                  class="circle-button"
                  [ngClass]="{ active: color === cornersDotColor }"></button>
              }
              <p-colorPicker
                (onChange)="
                  cornersDotColor = $event.value.toString(); setQrConfig()
                " />
            </div>
          </div>

          <div class="!mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">Corner Dot Shape</p>
            <div class="flex circle-container">
              <span
                (click)="cornersDotType = 'dot'; setQrConfig()"
                class="material-icons text-4xl cursor-pointer mr-[5px]"
                [ngClass]="{
                  'active-shape': cornersDotType.toLowerCase() === 'dot',
                }"
                pTooltip="Dot"
                tooltipPosition="top">
                radio_button_unchecked
              </span>
              <span
                (click)="cornersDotType = 'square'; setQrConfig()"
                class="material-icons text-4xl cursor-pointer mr-[5px]"
                [ngClass]="{
                  'active-shape': cornersDotType.toLowerCase() === 'square',
                }"
                pTooltip="Square"
                tooltipPosition="top">
                check_box_outline_blank
              </span>
            </div>
          </div>

          <div class="!mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">Corner Square Color</p>
            <div class="flex circle-container">
              @for (color of colors; track color) {
                <button
                  pButton
                  type="button"
                  (click)="cornersSquareColor = color; setQrConfig()"
                  [style]="{ backgroundColor: color }"
                  class="circle-button"
                  [ngClass]="{ active: color === cornersSquareColor }"></button>
              }
              <p-colorPicker
                (onChange)="
                  cornersSquareColor = $event.value.toString(); setQrConfig()
                " />
            </div>
          </div>

          <div>
            <p class="text-base font-normal !mb-[5px]">Corner Square Shape</p>
            <div class="flex circle-container">
              <span
                (click)="cornersSquareType = 'dot'; setQrConfig()"
                class="material-icons text-4xl cursor-pointer mr-[5px]"
                [ngClass]="{
                  'active-shape': cornersSquareType.toLowerCase() === 'dot',
                }"
                pTooltip="Dot"
                tooltipPosition="top">
                radio_button_unchecked
              </span>
              <span
                (click)="cornersSquareType = 'square'; setQrConfig()"
                class="material-icons text-4xl cursor-pointer mr-[5px]"
                [ngClass]="{
                  'active-shape': cornersSquareType.toLowerCase() === 'square',
                }"
                pTooltip="Square"
                tooltipPosition="top">
                check_box_outline_blank
              </span>
            </div>
          </div>
        </p-accordionTab>

        <p-accordionTab header="QR Images">
          <div class="col-span-2 !mb-[15px]">
            <p class="text-base font-normal !mb-[5px]">Center Image</p>
            <app-image-upload
              [singleMode]="true"
              [fileType]="'qrCenterImage'"></app-image-upload>
          </div>
        </p-accordionTab>

        <p-accordionTab header="Call to Action (CTA) Settings">
          <div>
            <p class="text-base font-normal mb-[3px]">
              Call to Action (CTA) Text
            </p>
            <input
              pInputText
              class="w-full"
              [class.input-danger]="
                form.get('ctaLabelText')?.invalid &&
                form.get('ctaLabelText')?.touched
              "
              placeholder="Call to Action (CTA) Text"
              formControlName="ctaLabelText" />
            <app-error-message
              [control]="form.get('ctaLabelText')"
              label="CTA Text"></app-error-message>
          </div>

          <div>
            <p class="text-base font-normal">Call to Action (CTA) Position</p>
            <p-dropdown
              formControlName="ctaLabelPosition"
              [options]="ctaPositions"
              placeholder="Select CTA Position"
              class="w-full"
              [class.input-danger]="
                form.get('ctaLabelPosition')?.invalid &&
                form.get('ctaLabelPosition')?.touched
              " />
            <app-error-message
              [control]="form.get('ctaLabelPosition')"
              label="CTA Position"></app-error-message>
          </div>
        </p-accordionTab>
      </p-accordion>
    </form>
  </div>
  <div>
    <div class="flex items-center justify-between">
      <h2>QR Preview</h2>
      <p-button
        (click)="resetQr()"
        [disabled]="defaultQRValues()"
        class="inline-flex text-button"
        [text]="true"
        >Reset QR</p-button
      >
    </div>
    @if (data && data.productQrSettings) {
      <app-qr-preview
        [product]="data"
        [qrConfig]="qrConfig"
        [ctaConfig]="ctaConfig"></app-qr-preview>
    }
  </div>
</div>
<app-right-form-footer
  [formValid]="this.form.valid"
  (callAction)="updateProductQrSettings()"
  label="Save"
  (callCancel)="actionSuccess.emit()">
</app-right-form-footer>
