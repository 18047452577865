import { Component, effect, EventEmitter, Output, Signal } from '@angular/core';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Store } from '@ngxs/store';
import { Card } from '../../../../store/subscription-store/subscription.model';
import {
  AddCard,
  DeleteCard,
  GetCards,
  SetCardAsDefault,
} from '../../../../store/subscription-store/subscription.actions';
import { ConfirmationService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { StripeService } from '../../../services/stripe.service';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'app-cards-list',
  standalone: true,
  imports: [
    RadioButtonModule,
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
  ],
  templateUrl: './cards-list.component.html',
  styleUrl: './cards-list.component.scss',
})
export class CardsListComponent {
  @Output() outputSelectedCard = new EventEmitter<Card>();
  cards$: Signal<Card[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getCards
  );
  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  isLoading: boolean = false;
  selectedCard: Card | null = null;
  showCardDetailsForm: boolean = false;
  bucketUrl: string = environment.bucketUrl;
  stripe: Stripe | null = null;
  card: StripeCardElement | undefined;

  cardDetailsForm: FormGroup;

  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.getCards();
    this.cardDetailsForm = this.fb.group({
      nameOnCard: [
        `${this.user$()?.firstName} ${this.user$()?.lastName}`,
        [Validators.required],
      ],
    });
    effect(() => {
      if (this.cards$().length > 0) {
        this.selectedCard = this.cards$().find(c => c.isDefault) ?? null;
        if (this.selectedCard) {
          this.outputSelectedCard.emit(this.selectedCard);
        }
      }
    });
  }

  getCards() {
    this.store.dispatch(new GetCards());
  }

  createPaymentMethod() {
    if (this.card) {
      this.isLoading = true;
      this.stripe
        ?.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.cardDetailsForm.get('nameOnCard')?.value,
          },
        })
        .then(pm => {
          this.store
            .dispatch(
              new AddCard({
                cardToken: pm!.paymentMethod!.id,
              })
            )
            .subscribe(() => {
              this.getCards();
              this.notificationService.openSuccessToast(
                'New card added successfully!'
              );
              this.isLoading = false;
              this.showCardDetailsForm = false;
            });
        });
    }
  }

  initializeStripe() {
    setTimeout(() => {
      this.stripeService.initialize().then(stripe => {
        this.stripe = stripe;
        this.card = stripe?.elements().create('card');
        this.card?.mount('#card-element');
      });
    }, 1000);
  }

  toggleCardDetailsForm() {
    if (this.showCardDetailsForm) {
      this.createPaymentMethod();
    } else {
      this.showCardDetailsForm = true;
      this.initializeStripe();
    }
  }

  deleteCard(card: Card) {
    this.confirmationService.confirm({
      header: `DELETE`,
      message: `Are you sure you want to delete this card?`,
      icon: 'pi pi-trash',
      acceptLabel: 'Yes, delete',
      acceptIcon: 'pi',
      acceptButtonStyleClass: 'p-button-danger',
      rejectVisible: false,
      accept: () => {
        this.store.dispatch(new DeleteCard(card.id)).subscribe(() => {
          this.getCards();
        });
      },
    });
  }

  setCardAsDefault(card: Card) {
    this.confirmationService.confirm({
      header: `SET CARD AS DEFAULT`,
      message: `Are you sure you want to set this card as default for your upcoming payments!`,
      icon: 'pi pi-exclamation-circle',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      acceptButtonStyleClass: 'p-button-primary',
      rejectVisible: false,
      accept: () => {
        this.store.dispatch(new SetCardAsDefault(card.id)).subscribe(() => {
          this.getCards();
        });
      },
    });
  }
}
