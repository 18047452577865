<div class="flex wrapper">
  <form [formGroup]="form">
    <p class="text-base font-normal mb-[3px]">Choose Product</p>
    <p-dropdown
      formControlName="product"
      [options]="products$()"
      optionLabel="productName"
      placeholder="Select Product"
      class="w-full"
      [class.input-danger]="
        form.get('product')?.invalid && form.get('product')?.touched
      " />
    <app-error-message
      [control]="form.get('product')"
      label="Product"></app-error-message>
  </form>
</div>
