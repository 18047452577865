import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  GetAllProducts,
  GetProductKdeValues,
} from '../../../../store/products-store/products.actions';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { ProductKdeValues } from '../../../../store/products-store/products.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import {
  RecallFilters,
  SimpleFilters,
} from '../../../../store/recall-management-store/recall-management.model';
import { CTE, KDE } from '../../../../store/general-store/general.model';
import { SelectModule } from 'primeng/select';

@Component({
  selector: 'app-product-filters',
  imports: [SelectModule, CommonModule, FormsModule, MultiSelectModule],
  templateUrl: './product-filters.component.html',
  styleUrl: './product-filters.component.scss',
})
export class ProductFiltersComponent implements OnChanges {
  @Output() selectedFilters = new EventEmitter();
  @Input() filters: RecallFilters | SimpleFilters = {
    product: null,
    cte: null,
    kde: null,
    kdeValues: [],
  };
  @Input() isFullWidth: boolean = true;
  @Input() StandardCTES: boolean = true;
  @Input() isOrganizationFilters: boolean = false;
  products$ = this.store.selectSignal(ProductStateSelectors.getAllProducts);
  productKdeValues$: Signal<ProductKdeValues | null> = this.store.selectSignal(
    ProductStateSelectors.getProductKdeValues
  );

  constructor(private store: Store) {
    this.getAllProducts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['filters'] &&
      changes['filters'].currentValue !== changes['filters'].previousValue
    ) {
      if (this.filters && this.filters.kde) {
        if (Array.isArray(this.filters.kde)) {
          // this.getProductKdeValues(this.filters.kde[0].id);
        } else {
          this.getProductKdeValues(this.filters.kde.id);
        }
      }
    }
  }
  kdeTitle(): any {
    if (Array.isArray(this.filters.kde)) {
      return this.filters.kde.length > 0 ? this.filters.kde[0].title || '' : '';
    }
    return this.filters.kde?.title || '';
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  getProductKdeValues(kdeId: number) {
    this.store.dispatch(new GetProductKdeValues(kdeId));
  }

  filterCtes(ctes: CTE[]) {
    if (!ctes) return [];
    if (this.StandardCTES === true) {
      return ctes.filter(cte => cte.type === 'STANDARD');
    } else {
      return ctes;
    }
  }
  applyFilter() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    const selectedFiltersProcessed: any = {};
    if (selectedFilters['kde']) {
      selectedFiltersProcessed['kde'] = selectedFilters['kde'];
    }
    if (selectedFilters['cte']) {
      selectedFiltersProcessed['cte'] = selectedFilters['cte'];
    }
    if (selectedFilters['product']) {
      selectedFiltersProcessed['product'] = selectedFilters['product'];
    }
    if (selectedFilters['kdeValues']) {
      selectedFiltersProcessed['kdeValues'] = selectedFilters['kdeValues'];
    }
    this.selectedFilters.emit(selectedFiltersProcessed);
  }

  isKDEObject(kde: any): kde is KDE {
    return (
      kde && typeof kde === 'object' && !Array.isArray(kde) && 'title' in kde
    );
  }
}
