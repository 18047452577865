import { Selector } from '@ngxs/store';
import { VendorState } from './vendors.state';
import { VendorStateModel } from './vendors.model';

export class VendorStateSelectors {
  @Selector([VendorState])
  static getVendors(state: VendorStateModel) {
    return state.vendors;
  }

  @Selector([VendorState])
  static getAllVendors(state: VendorStateModel) {
    return state.allVendors;
  }

  @Selector([VendorState])
  static getPaginationData(state: VendorStateModel) {
    return state.pagination;
  }

  @Selector([VendorState])
  static isVendorStoreProcessing(state: VendorStateModel) {
    return state.isProcessing;
  }

  @Selector([VendorState])
  static getHeaders(state: VendorStateModel) {
    return state.headers;
  }
}
