import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
  Activities,
  HeatMap,
  PaginationParams,
} from '../general-store/general.model';
import { ExpirationAPi } from './dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getActivityLogs(
    paginationParams: PaginationParams
  ): Observable<Activities> {
    return this.http
      .get<Activities>(`${this.apiUrl}/api/activities`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getChartsData(productId: number): Observable<HeatMap> {
    return this.http
      .get<any>(`${this.apiUrl}/api/qr-product-data/chart/${productId}`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error exporting data: ' + error.message)
          );
        })
      );
  }
  public getCteStatistics(productId: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/qr-product-data/cte-statistics/${productId}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }
  public getExpirations(productId: number): Observable<ExpirationAPi> {
    return this.http
      .get<ExpirationAPi>(
        `${this.apiUrl}/api/qr-product-data/expiration-analysis/${productId}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }
}
