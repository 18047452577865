import { Component, effect, Signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import {
  Plan,
  SubscriptionUsage,
} from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import {
  GetPlans,
  GetSubscriptionUsage,
} from '../../../../store/subscription-store/subscription.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { NumberKPipe } from '../../../pipes/number-k.pipe';
import { NoSubscriptionBannerComponent } from '../no-subscription-banner/no-subscription-banner.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { isHighestPlan } from '../../../services/global.service';

@Component({
  selector: 'app-subscription-details',
  imports: [
    ButtonModule,
    RouterModule,
    NumberKPipe,
    NoSubscriptionBannerComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './subscription-details.component.html',
  styleUrl: './subscription-details.component.scss',
})
export class SubscriptionDetailsComponent {
  subscriptionUsage$: Signal<SubscriptionUsage[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getSubscriptionUsage
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  plans$: Signal<Plan[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getPlans
  );
  hasHighestPlan: boolean = false;

  constructor(private store: Store) {
    this.getSubscriptionUsage();
    this.getPlans();

    effect(() => {
      if (this.plans$() && this.user$()) {
        this.hasHighestPlan = isHighestPlan(
          this.user$()?.currentPlan.plan,
          this.plans$()
        );
      }
    });
  }

  getSubscriptionUsage() {
    this.store.dispatch(new GetSubscriptionUsage());
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }
}
