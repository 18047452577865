<div class="p-5 rounded border-dashed border-2 bg-white cursor-pointer">
  <div class="text-center" (click)="fileInput.click()">
    <div>
      <span class="pi pi-upload text-2xl text-primary"></span>
    </div>
    <div class="font-bold text-md">{{ title }}</div>
    <div>
      <small>Supported Formats: {{ supportedFormats }}</small>
    </div>
    <div>
      <small>Max File Size: {{ maxFileSizeMb }} MB</small>
    </div>
  </div>
  <input
    type="file"
    (change)="onFileSelected($event)"
    [accept]="supportedFormats"
    [attr.multiple]="singleMode ? null : ''"
    hidden
    #fileInput />
  <div class="mt-4">
    @if (uploadedFiles$()[fileType] && uploadedFiles$()[fileType].length > 0) {
      <div class="flex items-center space-x-4 flex-wrap">
        <div
          *ngFor="let file of uploadedFiles$()[fileType]; index as i"
          class="relative group w-[150px] h-[150px]">
          @if (!file.uploading) {
            <div class="relative p-5 border-2 border-solid rounded">
              @if (getFileTypeFromUrl(file.objectURL) === 'csv') {
                <div
                  class="w-[100px] h-[105px] flex items-center justify-center">
                  <span class="pi pi-file-excel !text-[50px]"></span>
                </div>
              } @else if (getFileTypeFromUrl(file.objectURL) === 'pdf') {
                <div
                  class="w-[100px] h-[105px] flex items-center justify-center">
                  <span class="pi pi-file-pdf !text-[50px]"></span>
                </div>
              } @else {
                <img
                  [alt]="file.name"
                  [src]="file.objectURL"
                  class="object-contain w-[100px] h-[100px]" />
              }
              <div
                class="absolute top-[10px] !p-[5px] h-[15px] w-[15px] bg-primary rounded-full right-[10px] flex items-center justify-center text-white">
                <i
                  class="pi pi-times !text-[8px]"
                  (click)="removeDocument($event, file)">
                </i>
              </div>
            </div>
          }
          @if (file.uploading) {
            <div
              class="flex items-center justify-center h-[100%] w-100 border-2 border-solid rounded">
              <p-progressSpinner class="loader"></p-progressSpinner>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
