import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';
import { AddVendorParams, VendorsResponse } from './vendors.model';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getVendors = (
    paginationParams: PaginationParams
  ): Observable<VendorsResponse> => {
    return this.http
      .get<VendorsResponse>(`${this.apiUrl}/api/vendors`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching vendors: ' + error.message)
          );
        })
      );
  };

  public createVendor = (
    payload: AddVendorParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/vendors`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public deleteVendor(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/api/vendors/${id}`).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error deleting vendor: ' + error.message)
        );
      })
    );
  }

  public updateVendor(
    payload: AddVendorParams,
    id: number
  ): Observable<VendorsResponse> {
    return this.http
      .put<VendorsResponse>(`${this.apiUrl}/api/vendors/${id}`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating vendor: ' + error.message)
          );
        })
      );
  }
}
