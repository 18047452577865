export const OrganizationTypes = [
  'FARMER',
  'TRADER',
  'FOOD_PROCESSOR',
  'MANUFACTURER',
  'SUPER_MARKET',
  'DISTRIBUTOR',
  'STORAGE_UNIT',
  'RETAILER',
  'OTHER',
];

export const POCTypes = [
  'CHIEF_EXECUTIVE_OFFICER',
  'PROPRIETOR',
  'BUSINESS_MANAGER',
  'CHIEF_FINANCIAL_OFFICER',
  'CHIEF_INFORMATION_OFFICER',
  'MANAGER',
  'OPERATIONS_MANAGER',
  'PRODUCTION_MANAGER',
  'INVENTORY_MANAGER',
  'SALES_AND_DISTRIBUTION_MANAGER',
  'EXECUTIVE',
  'ASSISTANT',
  'EXPORT_MANAGER',
  'ACCCOUNTS_MANAGER',
  'INVENTORY_ASSISTANT',
];

export const AUTH_ROUTES = [
  '/',
  '/otp',
  '/demo',
  '/accept-invitation',
  '/forgot-password',
  '/reset-password',
];
