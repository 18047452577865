import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  AddCTEParams,
  AddOrganizationParams,
  OrganizationCTEsParams,
  OrganizationCTEsResponse,
  OrganizationKDEsParams,
  OrganizationsResponse,
} from './organizations.model';
import {
  CTE,
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public createOrganization = (
    payload: AddOrganizationParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/organizations`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getOrganizations = (
    paginationParams: PaginationParams
  ): Observable<OrganizationsResponse> => {
    return this.http
      .get<OrganizationsResponse>(`${this.apiUrl}/api/organizations`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching organizations: ' + error.message)
          );
        })
      );
  };

  public getOrganizationCTEs = (): Observable<CTE[]> => {
    return this.http
      .get<OrganizationCTEsResponse>(`${this.apiUrl}/api/organizations/ctes`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching organizations: ' + error.message)
          );
        })
      );
  };

  public deleteOrganization(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/organizations/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting organization: ' + error.message)
          );
        })
      );
  }

  public deleteOrganizationCTE(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/organizations/ctes/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting organization CTE: ' + error.message)
          );
        })
      );
  }

  public updateOrganizationCTEs(
    payload: OrganizationCTEsParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/organizations/ctes/bulk`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating organization CTEs');
          });
        })
      );
  }

  public updateOrganizationCTE(
    id: number,
    payload: AddCTEParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/organizations/ctes/${id}`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating organization CTE');
          });
        })
      );
  }

  public updateOrganizationKDEs(
    payload: OrganizationKDEsParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/organizations/kdes/bulk`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating organization KDEs');
          });
        })
      );
  }

  public updateOrganization(
    payload: AddOrganizationParams,
    id: number
  ): Observable<OrganizationsResponse> {
    return this.http
      .put<OrganizationsResponse>(
        `${this.apiUrl}/api/organizations/${id}`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }

  public addOrganizationCTE(
    payload: AddCTEParams
  ): Observable<GenericApiResponse> {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/organizations/ctes`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization: ' + error.message)
          );
        })
      );
  }
}
