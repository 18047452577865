<div id="wrapper">
  <div id="div1">
    <div class="filter">
      <app-filter [selectAction]="selectAction" />
    </div>
  </div>

  <div id="div5">
    <div class="parameters">
      @for (parameter of productExpiration$(); track $index) {
        <p-card
          header="{{ parameter.category }}"
          subheader="{{ parameter.category }}"
          styleClass="{{ 'parameter.color' }}"
          class="parameter">
          <strong class="count">{{ parameter.value }}</strong>
        </p-card>
      }
    </div>
  </div>

  <div id="div6">
    <div class="w-full chart">
      <app-heat-map
        [zoomCords]="chartData$()?.zoomCords"
        [chartData]="chartData$()?.chartData ?? []" />
    </div>
  </div>

  <div id="div4">
    <div class="flex">
      <div class="w-1/2 bg-blue-200 p-4">
        <p-card>
          <app-donut height="320px" width="100%" />
        </p-card>
      </div>
      <div class="w-1/2 bg-blue-200 p-4">
        <p-card>
          <app-bar-chat height="320px" width="100%" />
        </p-card>
      </div>
    </div>
  </div>
  <div id="div3"><app-activity-feed /></div>
</div>
