@if (actionType === 'APPROVE') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-check"
    label="Approve"
    [text]="true"
    (click)="actionClicked.emit('APPROVE')" />
} @else if (actionType === 'REJECT') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-times"
    label="Reject"
    [text]="true"
    (click)="actionClicked.emit('REJECT')" />
} @else if (actionType === 'RESEND') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-refresh"
    label="Resend"
    [text]="true"
    (click)="actionClicked.emit('RESEND')" />
} @else if (actionType === 'DELETE') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-trash"
    label="Delete"
    [text]="true"
    (click)="actionClicked.emit('DELETE')" />
} @else if (actionType === 'EDIT') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-pencil"
    label="Edit"
    [text]="true"
    (click)="actionClicked.emit('EDIT')" />
} @else if (actionType === 'QR') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-qrcode"
    label="QR Settings"
    [text]="true"
    (click)="actionClicked.emit('QR')" />
} @else if (actionType === 'SETTINGS') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-cog"
    label="Product Settings"
    [text]="true"
    (click)="actionClicked.emit('SETTINGS')" />
} @else if (actionType === 'DOWNLOAD') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-download"
    label="Download"
    [text]="true"
    (click)="actionClicked.emit('DOWNLOAD')" />
} @else if (actionType === 'BULK') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-upload"
    label="Bulk Upload"
    [text]="true"
    (click)="actionClicked.emit('BULK')" />
} @else if (actionType === 'VIEW') {
  <p-button
    [disabled]="disabled"
    class="dark"
    icon="pi pi-eye"
    label="View"
    [text]="true"
    (click)="actionClicked.emit('VIEW')" />
}
