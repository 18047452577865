import { Component, EventEmitter, Output, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { format } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { City, Country } from '../../../../store/general-store/general.model';
import { GetAllProducts } from '../../../../store/products-store/products.actions';
import {
  GetCities,
  GetCountries,
} from '../../../../store/general-store/general.actions';
import { Store } from '@ngxs/store';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'primeng/select';
import { DatePickerModule } from 'primeng/datepicker';
@Component({
  selector: 'app-dashboard-filters-2',
  imports: [
    SelectModule,
    ButtonModule,
    DatePickerModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    CommonModule,
  ],
  templateUrl: './dashboard-filters-2.component.html',
  styleUrl: './dashboard-filters-2.component.scss',
})
export class DashboardFilters2Component {
  @Output() selectedFilters = new EventEmitter();

  products$ = this.store.selectSignal(ProductStateSelectors.getAllProducts);
  countries$: Signal<Country[]> = this.store.selectSignal(
    GeneralStateSelectors.getCountries
  );
  cities$: Signal<City[]> = this.store.selectSignal(
    GeneralStateSelectors.getCities
  );

  public deviceTypes = [
    { key: 'Android', value: 'android' },
    { key: 'IOS', value: 'ios' },
    { key: 'Web', value: 'web' },
  ];
  public timeFrames = [
    { key: 'Weekly', value: 'weekly' },
    { key: 'Monthly', value: 'monthly' },
    { key: 'Yearly', value: 'yearly' },
  ];
  public filters: any = {
    productId: null,
    dateRange: [],
    startDate: null,
    endDate: null,
    country: null,
    city: null,
    timeFrame: null,
    deviceType: null,
    qrCode: null,
  };

  constructor(private store: Store) {
    this.getCountries();
    this.getAllProducts();
  }

  getCities(country: Country) {
    this.store.dispatch(new GetCities(country.id));
  }

  getCountries() {
    this.store.dispatch(new GetCountries());
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  applyFilter() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    Object.keys(selectedFilters).forEach(key => {
      if (key === 'country' || key === 'city') {
        selectedFilters[key] = selectedFilters[key]?.name;
      }
      if (key === 'dateRange') {
        if (selectedFilters[key].length > 0) {
          selectedFilters['startDate'] = format(
            selectedFilters[key][0],
            'yyyy-MM-dd'
          );
          selectedFilters['endDate'] = format(
            selectedFilters[key][1],
            'yyyy-MM-dd'
          );
          delete selectedFilters[key];
        } else {
          delete selectedFilters[key];
        }
      }
    });
    this.selectedFilters.emit(selectedFilters);
  }
  clearFilter(filter: string) {
    this.filters[filter] = null;
  }
  refreshData() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    Object.keys(selectedFilters).forEach(key => {
      if (key === 'country' || key === 'city') {
        selectedFilters[key] = selectedFilters[key]?.name;
      }
      if (key === 'dateRange') {
        if (selectedFilters[key].length > 0) {
          selectedFilters['startDate'] = format(
            selectedFilters[key][0],
            'yyyy-MM-dd'
          );
          selectedFilters['endDate'] = format(
            selectedFilters[key][1],
            'yyyy-MM-dd'
          );
          delete selectedFilters[key];
        } else {
          delete selectedFilters[key];
        }
      }
    });
    this.selectedFilters.emit(selectedFilters);
  }
}
