<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      (callAction)="
        $event ? (action = 'APPROVED') : (action = 'REJECTED');
        signatureDialogVisible = true
      "
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      [processing]="authProcessing$()"
      [disabled]="form.invalid"
      [showRejectButton]="true"
      [actionLabel]="'Approve'"
      [title]="title"></app-right-form-header>
  </div>
  <div class="flex-1">
    <div class="min-h-[90vh] p-4 custom-gradient rounded">
      <p-tabs value="0">
        <p-tablist>
          <p-tab value="0">Information</p-tab>
          <p-tab value="1">Attachments</p-tab>
        </p-tablist>
        <p-tabpanels>
          <p-tabpanel value="0">
            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Recall Initiated By</div>
              </div>
              <div class="col-span-2">
                <div class="flex items-center gap-4">
                  <div>
                    <img
                      [src]="
                        data?.createdBy?.photo ??
                        bucketUrl + '/images/avatar.png'
                      "
                      class="w-[50px] h-[50px] object-cover rounded-full" />
                  </div>
                  <div>
                    <div class="mb-0 font-bold">
                      {{ data?.createdBy?.firstName }}
                    </div>
                    <div>
                      <small>{{ data?.createdBy?.email }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Recall Name</div>
              </div>
              <div class="col-span-2">
                <div>{{ data?.recallName }}</div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Recall Status</div>
              </div>
              <div class="col-span-2">
                <div
                  class="rounded-full py-2 px-3 text-xs w-fit text-white"
                  [ngClass]="getStatusClass(data?.status)">
                  {{ data?.status }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Severity Level</div>
              </div>
              <div class="col-span-2">
                <div>{{ data?.severityLevel }}</div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Authorized Users</div>
              </div>
              <div class="col-span-2">
                <div>{{ getAuthorizedUsers(data?.authorizedUsers) }}</div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Reason of Recall</div>
              </div>
              <div class="col-span-2">
                <div>
                  {{ data?.recallReason }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Description for Email & SMS</div>
              </div>
              <div class="col-span-2">
                <div>
                  {{ data?.descriptionForEmailAndSms }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
              <div>
                <div class="font-bold">Product Scan Message</div>
              </div>
              <div class="col-span-2">
                <div>
                  {{ data?.productScanMessage }}
                </div>
              </div>
            </div>

            @if (data?.status === 'PENDING') {
              <div>
                <p class="text-base block font-bold !mb-2">Reason / Comments</p>
                <form [formGroup]="form">
                  <textarea
                    pInputTextarea
                    rows="5"
                    class="w-full"
                    [class.input-danger]="
                      form.get('approvedOrRejectReason')?.invalid &&
                      form.get('approvedOrRejectReason')?.touched
                    "
                    placeholder="Description for Email & SMS"
                    formControlName="approvedOrRejectReason"></textarea>
                  <app-error-message
                    [control]="form.get('approvedOrRejectReason')"
                    label="Reason / Comments"></app-error-message>
                </form>
              </div>
            } @else {
              <div
                class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
                <div>
                  <div class="font-bold">Reason / Comments</div>
                </div>
                <div class="col-span-2">
                  <div>
                    {{ data?.approvedOrRejectReason }}
                  </div>
                </div>
              </div>
            }

            @if (data?.status === 'APPROVED') {
              <div
                class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
                <div>
                  <div class="font-bold">Recall Approved By</div>
                </div>
                <div class="col-span-2">
                  <div class="flex items-center gap-4">
                    <div>
                      <img
                        [src]="
                          data?.approvedBy?.photo ??
                          bucketUrl + '/images/avatar.png'
                        "
                        class="w-[50px] h-[50px] object-cover rounded-full" />
                    </div>
                    <div>
                      <div class="mb-0 font-bold">
                        {{ data?.approvedBy?.firstName }}
                      </div>
                      <div>
                        <small>{{ data?.approvedBy?.email }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="data?.signature"
                class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
                <div>
                  <div class="font-bold">Signature</div>
                </div>
                <div class="col-span-2">
                  <img
                    *ngIf="data?.signature"
                    [src]="data?.signature"
                    class="w-[100px] object-cover" />
                </div>
              </div>
            }
          </p-tabpanel>
          <p-tabpanel value="1">
            <div class="grid grid-cols-2 gap-4">
              <div>
                @for (att of data?.attachments; track att) {
                  <div
                    class="border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC] cursor-pointer hover:text-primary flex items-center justify-between"
                    (click)="
                      previewFile = sanitizer.bypassSecurityTrustResourceUrl(
                        att.url
                      );
                      previewType = getFileType(att.url)
                    ">
                    <div>{{ getFileName(att.url) }}</div>
                    <div><span class="pi pi-arrow-right"></span></div>
                  </div>
                }
              </div>
              <div>
                @if (previewFile) {
                  @if (previewType === 'image') {
                    <img
                      [src]="previewFile"
                      class="w-100 h-[70vh] object-contain" />
                  } @else if (previewType === 'pdf') {
                    <iframe [src]="previewFile" class="w-100 h-[70vh]"></iframe>
                  }
                }
              </div>
            </div>
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    </div>
  </div>
</div>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  [modal]="false"
  (onHide)="onSignatureDialogClose()"
  [(visible)]="signatureDialogVisible"
  class="w-[25%]">
  <div class="text-center">
    <div class="mb-2">
      <span class="pi pi-file-check text-primary !text-[50px]"></span>
    </div>
    <h3>Digital Signature</h3>
    <p>Please add your Digital Signature below to proceed with the action!</p>
    <div class="mb-4 border-solid rounded border-primary">
      <signature-pad
        [options]="signaturePadOptions"
        #signaturePad></signature-pad>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <p-button
          label="Clear Signature"
          [outlined]="true"
          icon="pi pi-eraser"
          (click)="onSignatureDialogClose()" />
        <p-button
          icon="pi pi-check"
          [disabled]="uploadInProgress$()"
          (click)="saveSignature()"
          label="Continue" />
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  (onHide)="onOtpDialogClose()"
  [modal]="false"
  [(visible)]="otpDialogVisible"
  class="w-[25%]">
  <div class="text-center p-4">
    <div class="mb-4">
      <span class="pi pi-exclamation-circle text-primary !text-[50px]"></span>
    </div>
    <h3>Confirm your recall with Two-Factor Authentication</h3>
    <p>Please enter the 4-digit code sent to your registered email address</p>
    <div class="mb-4 text-center">
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length: 4,
          inputClass: 'otp-box',
          allowNumbersOnly: true,
        }"></ng-otp-input>
    </div>
    <div class="flex w-full justify-between items-center mb-4">
      <div>
        <countdown
          #cd
          [config]="{ leftTime: timeLeft, format: 'm:ss' }"
          (event)="countdownEvent($event)" />
      </div>
      <div>
        <p-button
          [text]="true"
          label="Resend OTP"
          (click)="resendOtp()"
          [disabled]="resendDisabled" />
      </div>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <p-button
          (click)="otpDialogVisible = false"
          label="Cancel"
          icon="pi pi-times"
          [outlined]="true" />
        <p-button
          icon="pi pi-check"
          [disabled]="!otp || otp.length < 4 || authProcessing$()"
          label="Continue"
          (click)="validateOtp()" />
      </div>
    </div>
  </div>
</p-dialog>
