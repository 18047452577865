import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { UpdateUserPassword } from '../../../../store/users-store/user.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-update-password-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    RightFormFooterComponent,
    InputIconModule,
    IconFieldModule,
    CommonModule,
  ],
  templateUrl: './update-password-form.component.html',
  styleUrl: './update-password-form.component.scss',
})
export class UpdatePasswordFormComponent {
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<boolean>();

  processing$ = this.store.selectSignal(UserStateSelectors.isLoading);

  form: FormGroup;
  passwordVisibility: boolean = false;

  passwordValidations = [
    {
      message: 'Contains at least one uppercase character',
      regex: /[A-Z]/,
      valid: false,
    },
    {
      message: 'Contains at least one lowercase character',
      regex: /[a-z]/,
      valid: false,
    },
    {
      message: 'Contains at least one special character',
      regex: /[^a-zA-Z0-9]/,
      valid: false,
    },
    { message: 'Contains at least one number', regex: /[0-9]/, valid: false },
  ];

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group(
      {
        currentPassword: [null, [Validators.required]],
        newPassword: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/
            ),
          ],
        ],
        retypePassword: [null, [Validators.required]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );

    this.form.get('newPassword')!.valueChanges.subscribe(password => {
      this.validatePasswordStrength(password);
    });
  }

  action() {
    this.store
      .dispatch(
        new UpdateUserPassword({
          currentPassword: this.form.get('currentPassword')?.value,
          newPassword: this.form.get('newPassword')?.value,
        })
      )
      .subscribe(() => {
        this.form.reset();
        this.actionSuccess.emit();
      });
  }

  validatePasswordStrength(password: string) {
    this.passwordValidations.forEach(validation => {
      validation.valid = validation.regex.test(password);
    });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const currentPassword = formGroup.get('currentPassword');
    const newPassword = formGroup.get('newPassword');
    const retypePassword = formGroup.get('retypePassword');

    if (currentPassword?.value === newPassword?.value) {
      newPassword?.setErrors({ match: true });
    }
    if (newPassword?.value !== retypePassword?.value) {
      retypePassword?.setErrors({ mismatch: true });
    } else {
      retypePassword?.setErrors(null);
    }
  }
}
