<form [formGroup]="form" class="pt-6">
  <div class="grid grid-cols-1 gap-4">
    <div>
      <p class="text-base font-normal">Email</p>
      <input
        type="email"
        formControlName="email"
        placeholder="example@example.com"
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('email')?.invalid && form.get('email')?.touched
        " />
      <app-error-message
        [control]="form.get('email')"
        label="Email"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Select Vendor</p>
      <p-dropdown
        formControlName="vendorId"
        [options]="vendors$()"
        optionLabel="name"
        optionValue="id"
        placeholder="Select Vendor"
        class="w-full"
        [virtualScroll]="true"
        [itemSize]="30"
        [class.input-danger]="
          form.get('vendorId')?.invalid && form.get('vendorId')?.touched
        " />
      <app-error-message
        [control]="form.get('vendorId')"
        label="Vendor"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Select Role</p>
      <p-multiSelect
        [options]="roles$()"
        formControlName="roleId"
        optionLabel="name"
        placeholder="Select User Role"
        class="!w-full"
        [class.input-danger]="
          form.get('roleId')?.invalid && form.get('roleId')?.touched
        ">
        <ng-template let-role pTemplate="item">
          <span>{{ role.name | textTransform }}</span>
        </ng-template></p-multiSelect
      >
      <app-error-message
        [control]="form.get('roleId')"
        label="Role"></app-error-message>
    </div>
  </div>
</form>
<app-right-form-footer
  [type]="type"
  [formValid]="this.form.valid"
  (callAction)="action()"
  (callCancel)="actionSuccess.emit()">
</app-right-form-footer>
