@if (user$()?.currentPlan?.productDetails?.name === 'Basic Plan') {
  <app-no-subscription-banner
    class="mb-5 block"
    title="Feature Unavailable!"
    description="Customizing the product design template is restricted on your subscribed plan. Please upgrade your subscription to access full customization."></app-no-subscription-banner>
}
@if (product$()) {
  <div class="grid grid-cols-4">
    <div
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @for (module of pageModules; track module) {
        <p
          class="cursor-pointer mb-[15px]"
          [ngClass]="{
            'text-primary border-solid border-l-[3px] border-t-[0px] border-b-[0px] border-r-[0px]  border-primary pl-3 font-bold':
              selectedPageModule?.id === module.id,
          }"
          (click)="
            selectedPageModule = module;
            selectedPageModuleTemplate = null;
            selectPageModuleTemplate()
          ">
          {{ module.title }}
        </p>
      }
    </div>
    <div class="col-span-2 max-h-[85vh] overflow-y-auto pl-[30px] pr-[30px]">
      <div
        class="mb-[20px]"
        [ngClass]="{
          'disabled-section':
            user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
        }">
        <p class="mb-[15px]">Select a template:</p>
        @for (
          template of selectedPageModule?.pageModuleTemplates;
          track template;
          let i = $index
        ) {
          <div
            class="h-[130px] w-[130px] bg-primary-color rounded-[20px] text-white text-center flex justify-center items-center p-[20px] cursor-pointer text-[15px] p-overlay-badge"
            pTooltip="Click to Insert"
            tooltipPosition="top"
            (click)="addModule(template)">
            <p>{{ template.title }} {{ i + 1 }}</p>
            <span class="p-badge p-badge-dark">{{
              getTemplateUsedCount(template)
            }}</span>
          </div>
        }
      </div>
      @if (selectedPageModuleTemplate) {
        <p-tabView>
          <p-tabPanel
            header="Components"
            [ngClass]="{
              'disabled-section':
                user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
            }">
            <p-orderList
              class="w-full sidebar-orderlist"
              [value]="selectedPageModuleTemplateComponents"
              [dragdrop]="selectedPageModule?.rules?.canAddNewComponents"
              (onReorder)="reorderComponent()">
              <ng-template let-component pTemplate="item">
                <div>
                  @switch (component.type) {
                    @case ('text') {
                      <div
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-pen-to-square mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                    @case ('image') {
                      <div
                        (click)="
                          $event.preventDefault(); $event.stopPropagation()
                        "
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-image mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                    @case ('video') {
                      <div
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-youtube mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                    @case ('file') {
                      <div
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-image mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                    @case ('boolean') {
                      <div
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-calendar mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                    @case ('link') {
                      <div
                        class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                        <div>
                          <span class="pi pi-link mr-[10px]"></span>
                          <span>{{ component.label }}</span>
                        </div>
                        <div class="!z-[9]">
                          <span
                            *ngIf="
                              selectedPageModule?.rules?.canAddNewComponents
                            "
                            class="pi pi-trash text-danger cursor-pointer"
                            (click)="deleteComponent(component.id)"></span>
                        </div>
                      </div>
                    }
                  }
                </div>
              </ng-template>
            </p-orderList>
            <div class="mt-[15px]">
              @if (selectedPageModule?.rules?.canAddNewComponents) {
                <div
                  class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-center component-item text-primary-color font-bold text-center cursor-pointer border border-primary-color"
                  (click)="
                    showComponentItemOptions = !showComponentItemOptions
                  ">
                  <span class="pi pi-plus mr-[10px]"></span>
                  Add
                </div>
                @if (showComponentItemOptions) {
                  <div
                    class="bg-[#f9f9fb] rounded-[10px] transition-colors duration-300 ease-in-out mt-[10px]">
                    @for (option of componentItemOptions; track option) {
                      <div
                        class="p-[15px] cursor-pointer hover:!text-primary-color"
                        (click)="addComponent(option)">
                        <span [class]="option.icon + ' mr-[10px]'"></span>
                        {{ option.label }}
                      </div>
                    }
                  </div>
                }
              }
            </div>
          </p-tabPanel>
          <p-tabPanel header="Values & Styles">
            @for (
              component of sortArray(
                selectedPageModuleTemplate!.pageModuleTemplateDataContents
              );
              track component
            ) {
              <form>
                @switch (component.type) {
                  @case ('text') {
                    <div class="mb-[15px]">
                      <label for="">{{ component.label }}</label>
                      <input
                        pInput
                        class="w-[100%]"
                        type="text"
                        [(ngModel)]="component.value.text"
                        (change)="updateComponent(component)"
                        [name]="component.label" />
                    </div>
                  }
                  @case ('link') {
                    <div class="mb-[15px]">
                      <label for="">{{ component.label }}</label>
                      <div class="flex gap-4 items-center">
                        <input
                          [(ngModel)]="component.value.label"
                          [name]="component.label + '_label'"
                          pInput
                          class="w-[100%]"
                          type="text"
                          placeholder="Button / Link Label"
                          (change)="updateComponent(component)" />
                        <input
                          [(ngModel)]="component.value.url"
                          [name]="component.label + '_value'"
                          pInput
                          class="w-[100%]"
                          type="text"
                          placeholder="Button / Link Value"
                          (change)="updateComponent(component)" />
                      </div>
                    </div>
                  }
                  @case ('video') {
                    <div class="mb-[15px]">
                      <label for="">{{ component.label }}</label>
                      <input
                        pInput
                        class="w-[100%]"
                        type="text"
                        [name]="component.label"
                        (change)="updateComponent(component)"
                        [(ngModel)]="component.value.url" />
                    </div>
                  }
                  @case ('image') {
                    <div class="flex">
                      <div
                        class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded-lg transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary-color">
                        <input
                          type="file"
                          [id]="'attachment_' + component.id"
                          class="hidden"
                          accept="image/jpeg, image/png, image/svg+xml"
                          (change)="upload($event, component)" />
                        <label
                          [for]="'attachment_' + component.id"
                          class="flex flex-col items-center justify-center cursor-pointer text-center">
                          <i class="pi pi-upload mb-[5px]"></i>
                          <p class="text-primary-color font-bold m-0">
                            {{ component.label }}
                          </p>
                        </label>
                      </div>
                      <img
                        [src]="component.value.url"
                        [alt]="component.value.altText"
                        class="w-[150px] h-[150px] ml-[20px] object-cover" />
                    </div>
                  }
                  @case ('file') {
                    <div class="flex">
                      <div
                        class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded-lg transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary-color">
                        <input
                          type="file"
                          [id]="'attachment_' + component.id"
                          class="hidden"
                          (change)="upload($event, component)" />
                        <label
                          [for]="'attachment_' + component.id"
                          class="flex flex-col items-center justify-center cursor-pointer text-center">
                          <i class="pi pi-upload mb-[5px]"></i>
                          <p class="text-primary-color font-bold m-0">
                            {{ component.label }}
                          </p>
                        </label>
                      </div>
                      <img
                        [src]="component.value.url"
                        [alt]="component.value.altText"
                        class="w-[150px] h-[150px] ml-[20px] object-cover" />
                    </div>
                  }
                  @case ('boolean') {
                    <div class="mb-[15px]">
                      <label for="">{{ component.label }}</label>
                      <div class="mt-[5px]">
                        <p-inputSwitch
                          [name]="component.label"
                          (onChange)="updateComponent(component)"
                          [(ngModel)]="component.value.isActive" />
                      </div>
                    </div>
                  }
                }
              </form>
            }
            <div>
              <div class="mb-[10px]">
                <label class="block" for="">Font Color</label>
                <p-colorPicker
                  (onHide)="updateModule()"
                  [(ngModel)]="selectedPageModuleTemplate.styles.color" />
              </div>
              <div class="mb-[10px]">
                <label class="block" for="">Background Color</label>
                <p-colorPicker
                  (onHide)="updateModule()"
                  [(ngModel)]="
                    selectedPageModuleTemplate.styles.backgroundColor
                  " />
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      }
    </div>
    <div class="max-h-[85vh] overflow-y-auto border-l border-gray-300">
      @for (content of pageContent; track content; let i = $index) {
        @if (content.pageModuleTemplateDataContents.length === 0) {
          <div
            class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[300px] text-center hover:!border-2 hover:!border-solid hover:!border-primary-color"
            [id]="content.slug"
            [ngStyle]="{ backgroundColor: content.styles.backgroundColor }"
            [ngClass]="{
              '!border-2 !border-solid !border-primary-color':
                content?.id === selectedPageModuleTemplate?.id,
            }">
            <div
              [ngClass]="{
                'disabled-section':
                  user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
              }"
              class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
              <button
                class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                (click)="editModule(content)">
                <i class="pi pi-pencil text-[10px]"></i>
              </button>
              <button
                *ngIf="pageContent.length > 1"
                class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                (click)="deleteModule(content.id)">
                <i class="pi pi-trash text-[10px]"></i>
              </button>
              <button
                class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                *ngIf="i !== 0"
                (click)="reorderModule(content, -1)">
                <i class="pi pi-arrow-up text-[10px]"></i>
              </button>
              <button
                class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                *ngIf="i !== pageContent.length - 1"
                (click)="reorderModule(content, 1)">
                <i class="pi pi-arrow-down text-[10px]"></i>
              </button>
            </div>
            <p>No Components</p>
          </div>
        } @else {
          @if (
            content.slug.startsWith('header-data') ||
            content.slug.startsWith('header-template')
          ) {
            <div
              class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[300px] hover:!border-2 hover:!border-solid hover:!border-primary-color"
              [id]="content.slug"
              [ngStyle]="{ backgroundColor: content.styles.backgroundColor }"
              [ngClass]="{
                '!border-2 !border-solid !border-primary-color':
                  content?.id === selectedPageModuleTemplate?.id,
              }">
              <div
                [ngClass]="{
                  'disabled-section':
                    user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
                }"
                class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  (click)="editModule(content)">
                  <i class="pi pi-pencil text-[10px]"></i>
                </button>
                <button
                  *ngIf="pageContent.length > 1"
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  (click)="deleteModule(content.id)">
                  <i class="pi pi-trash text-[10px]"></i>
                </button>
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  *ngIf="i !== 0"
                  (click)="reorderModule(content, -1)">
                  <i class="pi pi-arrow-up text-[10px]"></i>
                </button>
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  *ngIf="i !== pageContent.length - 1"
                  (click)="reorderModule(content, 1)">
                  <i class="pi pi-arrow-down text-[10px]"></i>
                </button>
              </div>
              <div
                class="w-full h-[400px] bg-cover bg-center relative rounded-bl-[30px] rounded-br-[30px] mb-[35px]"
                [ngStyle]="{
                  'background-image':
                    'url(' +
                    content.pageModuleTemplateDataContents[1].value.url +
                    ')',
                }">
                <img
                  [src]="content.pageModuleTemplateDataContents[0].value.url"
                  alt="content.pageModuleTemplateDataContents[0].value.altText"
                  class="w-[100px] h-auto absolute top-[10px] left-1/2 transform -translate-x-1/2" />
                <div
                  class="flex p-2 bg-green-500 rounded-lg absolute top-[78%] left-1/2 transform -translate-x-1/2 translate-y-full">
                  <img
                    [src]="bucketUrl + '/images/verified-badge.svg'"
                    class="w-10 h-10" />
                </div>
              </div>
              <div class="text-center">
                <p
                  class="text-2xl font-semibold"
                  [ngStyle]="{ color: content.styles.color }">
                  {{ content.pageModuleTemplateDataContents[2].value.text }}
                </p>
              </div>
              <div class="text-justify">
                <p [ngStyle]="{ color: content.styles.color }">
                  {{ content.pageModuleTemplateDataContents[4].value.text }}
                </p>
              </div>
              @if (content.pageModuleTemplateDataContents[3].value.isActive) {
                <div
                  class="flex justify-between bg-gray-300 items-center border-2 border-solid p-2.5 rounded-md"
                  [ngStyle]="{
                    borderColor:
                      content.pageModuleTemplateDataContents[3].value.color,
                  }">
                  <div class="flex items-center">
                    <span>
                      <img
                        class="w-[25px] h-[25px] object-cover"
                        [src]="bucketUrl + '/images/clock-regular.svg'" />
                    </span>
                    <span
                      class="mr-[10px] text-[12px]"
                      [ngStyle]="{
                        color:
                          content.pageModuleTemplateDataContents[3].value.color,
                      }"></span>
                    <div>
                      <small>{{
                        content.pageModuleTemplateDataContents[3].label
                      }}</small>
                      <p class="m-[0px] font-bold">
                        {{
                          content.pageModuleTemplateDataContents[3].value.date
                        }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span
                      class="text-[12px] p-[5px] text-white rounded-[5px]"
                      [ngStyle]="{
                        backgroundColor:
                          content.pageModuleTemplateDataContents[3].value.color,
                      }"
                      >{{
                        content.pageModuleTemplateDataContents[3].value.text
                      }}</span
                    >
                  </div>
                </div>
              }
              <div
                class="text-center"
                [ngStyle]="{ color: content.styles.color }">
                <p class="text-2xl font-semibold">
                  {{ content.pageModuleTemplateDataContents[5].value.text }}
                </p>
              </div>
              <div
                class="text-justify"
                [ngStyle]="{ color: content.styles.color }">
                <p class="">
                  {{ content.pageModuleTemplateDataContents[6].value.text }}
                </p>
              </div>
            </div>
          }
          @if (
            content.slug.startsWith('text-image-video-data') ||
            content.slug.startsWith('text-image-video-template')
          ) {
            <div
              class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[300px] hover:!border-2 hover:!border-solid hover:!border-primary-color"
              [id]="content.slug"
              [ngStyle]="{ backgroundColor: content.styles.backgroundColor }"
              [ngClass]="{
                '!border-2 !border-solid !border-primary-color':
                  content?.id === selectedPageModuleTemplate?.id,
              }">
              <div
                [ngClass]="{
                  'disabled-section':
                    user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
                }"
                class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  (click)="editModule(content)">
                  <i class="pi pi-pencil text-[10px]"></i>
                </button>
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  *ngIf="pageContent.length > 1"
                  (click)="deleteModule(content.id)">
                  <i class="pi pi-trash text-[10px]"></i>
                </button>
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  *ngIf="i !== 0"
                  (click)="reorderModule(content, -1)">
                  <i class="pi pi-arrow-up text-[10px]"></i>
                </button>
                <button
                  class="bg-white text-black border-2 border-solid border-primary-color w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                  *ngIf="i !== pageContent.length - 1"
                  (click)="reorderModule(content, 1)">
                  <i class="pi pi-arrow-down text-[10px]"></i>
                </button>
              </div>
              @for (
                data of content.pageModuleTemplateDataContents;
                track data
              ) {
                @if (data.type === 'link') {
                  <div
                    class="text-center p-[15px] border-2 border-solid border-primary-color mt-[5px] mb-[5px] rounded-[5px] bg-transparent">
                    <a
                      [href]="data.value.url"
                      target="_blank"
                      class="text-primary-color no-underline"
                      >{{ data.value.label }}</a
                    >
                  </div>
                }
                @if (data.type === 'text') {
                  <div>
                    <p [ngStyle]="{ color: content.styles.color }">
                      {{ data.value.text }}
                    </p>
                  </div>
                }
                @if (data.type === 'image') {
                  <div>
                    <img
                      [src]="data.value.url"
                      [alt]="data.value.altText"
                      class="w-[100%]" />
                  </div>
                }
                @if (data.type === 'video') {
                  <div class="text-center mt-3">
                    <iframe
                      width="100%"
                      height="300"
                      [src]="sanitizeUrl(data.value.url ?? '')"
                      [title]="data.value.caption"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen></iframe>
                  </div>
                }
              }
            </div>
          }
        }
      }
    </div>
  </div>
} @else {
  <div class="flex items-center justify-center w-[100%] h-[100%]">
    <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
  </div>
}
