import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { TrackDashboardData } from '../../../../store/dashboard-store/dashboard.model';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-cluster-columns',
  imports: [CommonModule],
  templateUrl: './cluster-columns.component.html',
  styleUrls: ['./cluster-columns.component.scss'],
})
export class ClusterColumnsComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) dataset: TrackDashboardData[] = [];
  @Input() overlay: boolean = false;
  private root!: am5.Root;
  private colors = ['#FFDE07', '#AC7080', '#0F9008', '#007DF2', '#E1E1E1'];

  ngOnInit(): void {
    this.initChart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataset'] &&
      changes['dataset'].currentValue !== changes['dataset'].previousValue
    ) {
      this.initChart();
    }
  }
  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initChart(): void {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('clusterColumns');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: 'none',
        wheelY: 'none',
        layout: this.root.verticalLayout,
      })
    );

    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Transform dataset for chart
    const chartData = this.dataset.map(item => {
      const transformedItem: any = { name: item.name };
      item.kdes.forEach((kde, index) => {
        transformedItem[`KDE ${index + 1}`] = kde.progress;
      });
      return transformedItem;
    });

    // const seriesNames = this.dataset[0].kdes.map((_, index) => `KDE ${index + 1}`);
    const seriesNames =
      this.dataset[0]?.kdes && Array.isArray(this.dataset[0].kdes)
        ? this.dataset[0].kdes.map((_, index) => `KDE ${index + 1}`)
        : [];
    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xRenderer.grid.template.setAll({ location: 1 });
    xAxis.data.setAll(chartData);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 0.1,
        }),
        min: 0,
        max: 100,
      })
    );

    // Hide the labels of the Y-axis
    yAxis.get('renderer').labels.template.set('visible', false);
    const yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.set('forceHidden', true);
    yRenderer.labels.template.set('forceHidden', true);

    const makeSeries = (
      name: string,
      fieldName: string,
      colorIndex: number
    ) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: 'name',
        })
      );

      series.columns.template.setAll({
        tooltipText: '{name}, {categoryX}:{valueY}%',
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        fill: am5.color(this.colors[colorIndex % this.colors.length]),
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      series.data.setAll(chartData);

      series.appear();

      series.bullets.push(() =>
        am5.Bullet.new(this.root, {
          locationY: 0,
          sprite: am5.Label.new(this.root, {
            text: '{valueY}%',
            fill: this.root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        })
      );

      legend.data.push(series);
    };

    seriesNames.forEach((seriesName, index) => {
      makeSeries(seriesName, seriesName, index);
    });

    // Animate chart on load
    chart.appear(1000, 100);
  }
}
