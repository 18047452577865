<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="activeStep === 0 ? !formValid : false"
      [title]="title"
      [actionLabel]="activeStep === 0 ? 'Save & Next' : 'Save & Close'"
      (callCancel)="reset()"
      (callAction)="
        activeStep === 0 ? productForm.action() : productCtes.updateProduct()
      "></app-right-form-header>
  </div>
  <div class="flex-1">
    <div class="flex justify-start gap-4">
      <div class="w-[25%]">
        @for (step of steps; track step; let index = $index) {
          <div
            class="flex items-center gap-3 cursor-pointer"
            (click)="changeStep(index)"
            [ngClass]="{ 'text-primary font-bold': activeStep === index }">
            <div>
              <span [ngClass]="step.icon"></span>
            </div>
            <div>
              <p>{{ step.label }}</p>
            </div>
          </div>
        }
      </div>
      <p-divider layout="vertical"></p-divider>
      <div class="w-100">
        @if (activeStep === 0) {
          <app-add-product-form
            [data]="data"
            [type]="type"
            [id]="id"
            (formValid)="formValid = $event"
            (actionCreateProduct)="nextStep()"></app-add-product-form>
        }
        @if (activeStep === 1) {
          <app-ctes-kdes
            [cteType]="'PRODUCT'"
            [isProductCtes]="true"
            [product]="product$()"
            (actionSuccess)="reset()"></app-ctes-kdes>
        }
      </div>
    </div>
  </div>
</div>
