<p class="text-4xl font-semibold">Email verification</p>
<p class="text-base font-normal !mt-10 mb-1">OTP</p>
<ng-otp-input
  (onInputChange)="onOtpChange($event)"
  [config]="{ length: 4, inputClass: 'otp-box' }"></ng-otp-input>
<div class="flex w-full justify-between mt-1 items-center">
  <div>
    <countdown
      #cd
      [config]="{ leftTime: timeLeft, format: 'm:ss' }"
      (event)="countdownEvent($event)" />
  </div>
  <div>
    <p-button
      class="!bg-none"
      label="Resend OTP"
      [text]="true"
      (click)="resendOtp()"
      [disabled]="resendDisabled" />
  </div>
</div>
<p class="text-sm font-normal">
  We send an OTP on <strong>{{ user?.email }}</strong
  >. Please check your inbox. Do check your spam as well before resending OTP.
</p>
