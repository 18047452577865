import { Component, OnInit } from '@angular/core';
import { HeatMapComponent } from '../../../components/charts/heat-map/heat-map.component';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';
import { CardModule } from 'primeng/card';
import { DonutComponent } from '../../../components/charts/donut/donut.component';
import { BarComponent } from '../../../components/charts/bar/bar.component';
import { Store } from '@ngxs/store';
import {
  GetChartsData,
  GetProductExpiration,
} from '../../../../store/dashboard-store/dashboard.actions';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { AsyncPipe } from '@angular/common';
import { FilterComponent } from '../../../components/shared-components/filter/filter.component';

@Component({
  selector: 'app-digital-expiration',
  standalone: true,
  imports: [
    HeatMapComponent,
    ActivityFeedComponent,
    CardModule,
    DonutComponent,
    BarComponent,
    AsyncPipe,
    FilterComponent,
  ],
  templateUrl: './digital-expiration.component.html',
  styleUrl: './digital-expiration.component.scss',
})
export class DigitalExpirationComponent implements OnInit {
  chartData$ = this.store.selectSignal(
    DashboardStateSelectors.getProductChartData
  );
  productExpiration$ = this.store.selectSignal(
    DashboardStateSelectors.getProductExpiration
  );
  selectAction = {
    action: [GetChartsData, GetProductExpiration],
    payload: null,
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new GetChartsData(1));
    this.store.dispatch(new GetProductExpiration(1));
  }
}
