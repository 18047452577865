import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-auth',
  standalone: true,
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
  imports: [CommonModule, CarouselModule, ProgressSpinnerModule],
})
export class AuthComponent implements OnInit {
  @Input() title: string = '';
  bucketUrl = environment.bucketUrl;
  backgroundImages: any[] = [];
  isLoading: boolean = true;
  loadedImagesCount: number = 0;

  constructor(private readonly router: Router) {
    if (localStorage.getItem('regen_access_token')) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {
    this.backgroundImages = [
      {
        url: `${environment.bucketUrl}/sliders/slide_1.webp`,
        title: 'agriculture',
      },
      { url: `${environment.bucketUrl}/sliders/slide_2.webp`, title: 'pharma' },
      { url: `${environment.bucketUrl}/sliders/slide_3.webp`, title: 'retail' },
      {
        url: `${environment.bucketUrl}/sliders/slide_4.webp`,
        title: 'logistics',
      },
      {
        url: `${environment.bucketUrl}/sliders/slide_5.webp`,
        title: 'sea food',
      },
    ];
  }

  onImageLoad(): void {
    this.loadedImagesCount++;
    if (this.loadedImagesCount === this.backgroundImages.length) {
      this.isLoading = false;
    }
  }
}
