import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { DeviceAnalytics } from '../../../../store/dashboard-store/dashboard.model';

@Component({
  selector: 'app-pie-chart',
  imports: [],
  templateUrl: './pie-chart.component.html',
  styleUrl: './pie-chart.component.scss',
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input({ required: true }) data: DeviceAnalytics[] = [];
  @Input({ required: true }) title: string = '';
  private root!: am5.Root;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.initMap();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('piChart');

    this.root.setThemes([am5themes_Animated.new(this.root)]);

    const chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: 'scans',
        categoryField: 'device',
      })
    );

    series.data.setAll(this.data);

    const colorArray = [am5.color('#4763E4'), am5.color('#C0C3FF')];

    series.slices.template.setAll({
      tooltipText: "{category}: {value} scans ({percent.formatNumber('#.0')}%)",
    });

    series.labels.template.setAll({
      text: "[fontSize:24px fontWeight:bold]{percent.formatNumber('#.0')}%\n[fontSize:12px]{category}[/]",
      textAlign: 'center',
    });

    series.slices.each((slice, i) => {
      slice.set('fill', colorArray[i % colorArray.length]);
      slice.set('stroke', am5.color('#FFFFFF'));
      slice.set('strokeWidth', 10);
      slice.set('strokeOpacity', 0.8);
    });

    chart.appear(1000, 100);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
