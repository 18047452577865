<div class="w-full p-8">
  <div class="text-center">
    <h2 class="mb-0">Billing & Payment Details</h2>
    <p>
      Provide your billing and payment details so that we can subscribe you to
      your desired plan!
    </p>
  </div>
  <div class="flex items-center justify-center">
    <div class="w-[50%]">
      <p-stepper [value]="1">
        <p-step-list>
          <p-step [value]="1">Payment Method</p-step>
          <p-step [value]="2">Confirmation</p-step>
        </p-step-list>

        <p-step-panels>
          <p-step-panel [value]="1">
            <ng-template
              pTemplate="content"
              let-activateCallback="activateCallback">
              <div class="bg-white pb-5 pt-10 px-5 rounded">
                <app-cards-list
                  (outputSelectedCard)="selectedCard = $event"></app-cards-list>

                <div class="mt-5">
                  <p-button
                    [disabled]="
                      isLoading || isLoading$() || cards$().length === 0
                    "
                    label="Next"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    class="w-100"
                    (onClick)="activateCallback(2)" />
                </div>

                <div class="text-center mt-5">
                  <p class="text-xs mb-0">Powered by</p>
                  <img
                    class="w-[50px]"
                    [src]="bucketUrl + '/images/stripe-logo.png'"
                    alt="" />
                </div>
              </div>
            </ng-template>
          </p-step-panel>

          <p-step-panel [value]="2">
            <ng-template
              pTemplate="content"
              let-nextCallback="nextCallback"
              let-index="index">
              @if (selectedPlan) {
                <div class="bg-white rounded p-5 mb-3">
                  <p class="font-bold m-0 mb-3">Order Review</p>
                  <div class="flex items-center justify-between">
                    <p class="m-0">{{ selectedPlan.productDetails.name }}</p>
                    <p class="m-0">{{ selectedPlan.amount | currency }}</p>
                  </div>
                </div>

                <div class="bg-white rounded p-5 mb-3">
                  <p class="font-bold m-0 mb-3">Payment Method</p>
                  <div class="block">
                    <p class="m-0 mb-1">
                      {{ selectedCard!.billing_details.name }} ({{
                        selectedCard!.card.brand.toUpperCase()
                      }})
                    </p>
                    <p class="m-0">
                      **** **** **** {{ selectedCard!.card.last4 }}
                    </p>
                  </div>
                </div>

                @if (!user$()?.currentPlan) {
                  <div class="bg-white rounded p-5 mb-3">
                    <p class="font-bold m-0 mb-3">Discount Code</p>
                    <div class="block">
                      <form [formGroup]="form">
                        <div class="flex items-center gap-4">
                          <input
                            type="text"
                            formControlName="coupon"
                            placeholder="Enter Discount Code"
                            pInputText
                            class="w-full bg-light"
                            [class.input-danger]="
                              form.get('coupon')?.invalid &&
                              form.get('coupon')?.touched
                            " />
                          <p-button
                            *ngIf="!discountDetails$()"
                            [disabled]="isLoading$() || form.invalid"
                            (click)="applyDiscountCode()"
                            >Apply</p-button
                          >
                          <p-button
                            *ngIf="discountDetails$()"
                            (click)="clearDiscountDetails()"
                            >Clear</p-button
                          >
                        </div>
                      </form>
                    </div>
                  </div>
                }

                <div class="bg-white rounded p-5 mb-3">
                  <p class="font-bold m-0 mb-3">Checkout Summary</p>
                  <div class="flex items-center justify-between">
                    <p class="m-0">Subtotal</p>
                    <p class="m-0">{{ selectedPlan.amount | currency }}</p>
                  </div>
                  @if (!user$()!.organizations[0]!.hasSetupFeePaid) {
                    <div class="flex items-center justify-between">
                      <p class="m-0">One Time Charge (OTC)</p>
                      <p class="m-0">{{ +selectedPlan.otc | currency }}</p>
                    </div>
                  }
                  <hr />
                  <div class="flex items-center justify-between">
                    <p class="m-0">Total</p>
                    <p class="m-0">{{ totalAmount | currency }}</p>
                  </div>
                  @if (discountDetails$()) {
                    <div class="flex items-center justify-between">
                      <p class="m-0">
                        Discount ({{
                          discountDetails$()?.couponDetails?.percentOff
                        }}% Off)
                      </p>
                      <p class="m-0">
                        - {{ discountDetails$()!.discount | currency }}
                      </p>
                    </div>
                    <hr />
                    <div class="flex items-center justify-between">
                      <p class="m-0">Discounted Total</p>
                      <p class="m-0">
                        {{ discountDetails$()!.estimatedTotal | currency }}
                      </p>
                    </div>
                  }
                </div>

                <div class="mt-5">
                  <p-checkbox
                    class="mr-3"
                    [binary]="true"
                    inputId="termsConditions"
                    (onChange)="
                      acceptTermsConditions = !acceptTermsConditions
                    " />
                  <label for="termsConditions">
                    I agree to all the
                    <a
                      class="text-primary font-bold no-underline"
                      href="https://www.regenesis.ag/terms-and-conditions/"
                      target="_blank"
                      >Terms & Conditions</a
                    >
                    and
                    <a
                      class="text-primary no-underline font-bold"
                      href="https://www.regenesis.ag/privacy-policy/"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </label>
                </div>

                <div class="mt-5">
                  <p-button
                    [disabled]="
                      isLoading$() ||
                      !acceptTermsConditions ||
                      (!discountDetails$() && form.get('coupon')?.value !== '')
                    "
                    class="w-100"
                    (click)="subscribe()"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    [label]="
                      'Pay ' +
                      ((discountDetails$()
                        ? discountDetails$()!.estimatedTotal
                        : totalAmount
                      ) | currency)
                    " />
                </div>

                <div class="text-center mt-5">
                  <small class="block text-xs mb-0">Powered by</small>
                  <img
                    class="w-[50px]"
                    [src]="bucketUrl + '/images/stripe-logo.png'"
                    alt="" />
                </div>
              }
            </ng-template>
          </p-step-panel>
        </p-step-panels>
      </p-stepper>
    </div>
  </div>
</div>
