<app-qr-preview
  [product]="data.product"
  [qrConfig]="getProductQrSettings(data.productQrSetting)"
  [ctaConfig]="getProductCtaConfig(data.productQrSetting)"></app-qr-preview>

<div class="full-height-form">
  @if (qrOrder$()) {
    @for (download of qrOrder$()?.qrOrderDownloads; track download) {
      <div class="list">
        <p class="m-[0px]">{{ download.status }}</p>
        <p class="m-[0px]">
          <small>{{ download.updatedAt | textTransform }}</small>
        </p>
        @if (download.status === 'COMPLETED') {
          <a [href]="download.downloadUrl" target="_blank">Download</a>
        }
      </div>
    }
  }
</div>
<app-right-form-footer
  [type]="type"
  [formValid]="true"
  (callAction)="action()"
  [label]="'Initiate Download'"
  (callCancel)="actionSuccess.emit()">
</app-right-form-footer>
