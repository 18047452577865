<div class="w-full p-8">
    <div class="flex justify-between items-center">
      <div>
        <h2>{{ pageTitle }}</h2>
        <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
      </div>
      <div class="d-flex">
        <p-button
          appAccess="add-report"
          icon="pi pi-plus"
          label="Add New"
          id="add-report-button"
          (click)="sidebar.visible = true" />
      </div>
    </div>
  
    <div class="mt-5">
      <app-dashboard-filters
        [allowedFilters]="['PRODUCT', 'DATE_RANGE', 'CTE', 'KDES']"
        (selectedFilters)="setFilters($event)"></app-dashboard-filters>
    </div>
  
    <div class="mt-4">
      <app-data-table
        [first]="first"
        export="PRODUCT"
        [rows]="rows"
        [sortBy]="sortBy"
        [sortOrder]="sortOrder"
        [selectedFilters]="selectedFilters"
        [data$]="this.data$"
        [headers$]="this.headers$"
        [isProcessing$]="this.isProcessing$"
        [pagination$]="this.pagination$"
        [showTableToolbar]="false"
        [showActions]="false"
        (actionClickedEvent)="actionClicked($event)"
        (refreshEvent)="refresh($event)" />
    </div>

    <div class="flex justify-between items-center mt-4">
        <div>
          <h3>Data Visualization for Reports</h3>
        </div>
    </div>

    <app-graph-list (graphSelected)="onGraphSelected($event)"></app-graph-list>

    @if (visualizations.length > 0) {
        @for (v of visualizations; track v;) {
            <div class="flex items-center justify-between mt-4 mb-4">
                <div>
                <p class="font-bold m-0">{{ v.title }}</p>
                <small>{{ v.description }}</small>
                </div>
                <p-button
                    [text]="true"
                    icon="pi pi-pencil"
                    label="Configure"
                    (click)="showChartSidebar()"
                />
            </div>
            @if (visualizationData$()) {
                @if (v.visualizationType === 'LINE_CHART') {
                    <app-report-line-chart [data]="visualizationData$()!.chartData"></app-report-line-chart>
                }
                @if (v.visualizationType === 'BAR_CHART') {
                    <app-report-bar-chart [data]="visualizationData$()!.chartData"></app-report-bar-chart>
                }
            }
        }
    }
    @else {
        <div class="flex items-center justify-end mt-4 mb-4">
            <p-button
                [text]="true"
                icon="pi pi-pencil"
                label="Configure"
                (click)="showChartSidebar()"
            />
        </div>

        <div class="relative w-full h-[500px] mt-4">
            <img
                src="assets/chart-bg.svg"
                alt="Chart Background"
                class="w-full h-full object-cover blur-[8px]" />
            <div
                class="absolute inset-0 flex flex-col items-center justify-center text-white">
                <p>
                Please configure the chart setting to see accurate results.
                </p>
            </div>
        </div>
    }
</div>

@if (selectedGraph) {
    <app-right-form-base
    [isVisible]="chartSidebar.visible"
    (sidebarHidden)="chartSidebar.visible = false"
    [title]="chartSidebar.title"
    [widthClass]="chartSidebar.width">
        <app-add-line-chart-form
            *ngIf="selectedGraph.type === 'LINE_CHART'"
            [data]="chartSidebar.data"
            [title]="chartSidebar.title"
            [type]="chartSidebar.type"
            [id]="chartSidebar.id"
            [report]="report"
            (actionSuccess)="chartSidebar.visible = false; getViewReportData();">
        </app-add-line-chart-form>

        <app-add-heat-map-form
            *ngIf="selectedGraph.type === 'HEAT_MAP'"
            [data]="chartSidebar.data"
            [title]="chartSidebar.title"
            [type]="chartSidebar.type"
            [id]="chartSidebar.id"
            [report]="report"
            (actionSuccess)="chartSidebar.visible = false; getViewReportData();">
        </app-add-heat-map-form>

        <app-add-bar-chart-form *ngIf="selectedGraph.type === 'BAR_CHART'"
            [data]="chartSidebar.data"
            [title]="chartSidebar.title"
            [type]="chartSidebar.type"
            [id]="chartSidebar.id"
            [report]="report"
            (actionSuccess)="chartSidebar.visible = false; getViewReportData();">></app-add-bar-chart-form>
    </app-right-form-base>
}
  
<app-right-form-base
[isVisible]="sidebar.visible"
(sidebarHidden)="sidebar.visible = false"
[title]="sidebar.title"
[widthClass]="sidebar.width">
    <app-generate-report-form
    [data]="sidebar.data"
    [title]="sidebar.title"
    [type]="sidebar.type"
    [id]="sidebar.id"
    (actionSuccess)="sidebar.visible = false"></app-generate-report-form>
</app-right-form-base>
  