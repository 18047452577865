<div class="w-full">
  <app-right-form-base
    [isVisible]="profileSidebar.visible"
    (sidebarHidden)="profileSidebar.visible = false"
    [title]="profileSidebar.title">
    <app-update-profile-form
      [type]="profileSidebar.type"
      (actionSuccess)="actionSuccess()">
    </app-update-profile-form>
  </app-right-form-base>

  <app-right-form-base
    [isVisible]="passwordSidebar.visible"
    (sidebarHidden)="passwordSidebar.visible = false"
    [title]="passwordSidebar.title">
    <app-update-password-form
      [type]="passwordSidebar.type"
      (actionSuccess)="actionSuccess()">
    </app-update-password-form>
  </app-right-form-base>

  <div class="mb-10 block">
    <h3 class="text-primary-color">Profile</h3>
    <div class="grid grid-cols-3 gap-4">
      <div>
        <div class="flex justify-start mb-3">
          <div class="mr-5">
            <span class="pi pi-user"></span>
          </div>
          <div>
            <p class="m-0 mb-1 font-bold">Your Name</p>
            <p class="m-0">{{ user$()?.firstName }} {{ user$()?.lastName }}</p>
          </div>
        </div>
        <div class="flex justify-start mb-3">
          <div class="mr-5">
            <span class="pi pi-envelope"></span>
          </div>
          <div>
            <p class="m-0 mb-1 font-bold">Email</p>
            <p class="m-0">{{ user$()?.email }}</p>
          </div>
        </div>
      </div>
      <div>
        <p-button
          class="inline-block"
          [outlined]="true"
          (click)="
            profileSidebar = {
              visible: true,
              title: 'Edit Profile',
              type: 'EDIT',
              width: 'w-50',
            }
          "
          >Edit Profile</p-button
        >
      </div>
    </div>
  </div>
  <div class="mb-10 block">
    <h3 class="text-primary-color">Login Details</h3>

    <div class="grid grid-cols-3 gap-4">
      <div>
        <div class="flex justify-start mb-3">
          <div class="mr-5">
            <span class="pi pi-id-card"></span>
          </div>
          <div>
            <p class="m-0 mb-1 font-bold">Login ID</p>
            <p class="m-0">{{ user$()?.email }}</p>
          </div>
        </div>
        <div class="flex justify-start mb-3">
          <div class="mr-5">
            <span class="pi pi-key"></span>
          </div>
          <div>
            <p class="m-0 mb-1 font-bold">Password</p>
            @if (user$()?.passwordResetAt) {
              <p class="m-0">
                Last updated at
                {{ user$()?.passwordResetAt | formatDate: 'MMM dd, yyyy' }}
              </p>
            } @else {
              <p class="m-0">Never updated</p>
            }
          </div>
        </div>
      </div>
      <div>
        <p-button
          class="inline-block"
          (click)="
            passwordSidebar = {
              visible: true,
              title: 'Update Password',
              type: 'EDIT',
              width: 'w-50',
            }
          "
          [outlined]="true"
          >Update Password</p-button
        >
      </div>
    </div>
  </div>
</div>
