<h3>Product Passport</h3>
<form [formGroup]="form" class="full-height-form mt-5 mb-5">
  <div class="grid grid-cols-2 gap-x-4 gap-y-4">
    <div>
      <p>Product Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('productName')?.invalid && form.get('productName')?.touched
        "
        placeholder="Product Name"
        formControlName="productName" />
      <app-error-message
        *ngIf="
          form.get('productName')?.invalid && form.get('productName')?.touched
        "
        [control]="form.get('productName')"
        label="Product Name"></app-error-message>
    </div>

    <div>
      <p>Manufacturer / Brand Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('brandName')?.invalid && form.get('brandName')?.touched
        "
        placeholder="Brand Name"
        formControlName="brandName" />
      <app-error-message
        *ngIf="form.get('brandName')?.invalid && form.get('brandName')?.touched"
        [control]="form.get('brandName')"
        label="Brand Name"></app-error-message>
    </div>

    <div class="col-span-2">
      <p>Product Description</p>
      <textarea
        type="text"
        rows="4"
        formControlName="productDescription"
        placeholder="Product Description"
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('productDescription')?.invalid &&
          form.get('productDescription')?.touched
        "></textarea>
      <app-error-message
        *ngIf="
          form.get('productDescription')?.invalid &&
          form.get('productDescription')?.touched
        "
        [control]="form.get('productDescription')"
        label="Product Description"></app-error-message>
    </div>

    <div>
      <p>Unit of Measurement</p>
      <p-select
        #measurementUnitDropdown
        formControlName="measurementUnit"
        [options]="measurementUnits"
        [editable]="isOtherMeasurementUnit"
        (onChange)="onMeasurementUnitChange($event.value)"
        placeholder="Select Measurement Unit"
        class="w-full"
        [class.input-danger]="
          form.get('measurementUnit')?.invalid &&
          form.get('measurementUnit')?.touched
        " />
      <app-error-message
        *ngIf="
          form.get('measurementUnit')?.invalid &&
          form.get('measurementUnit')?.touched
        "
        [control]="form.get('measurementUnit')"
        label="Measurement Unit"></app-error-message>
    </div>

    <div>
      <p>Unit of Pricing</p>
      <p-select
        #pricingUnitDropdown
        formControlName="pricingUnit"
        [options]="pricingUnits"
        [editable]="isOtherPricingUnit"
        (onChange)="onPricingUnitChange($event.value)"
        placeholder="Select Pricing Unit"
        class="w-full"
        [class.input-danger]="
          form.get('pricingUnit')?.invalid && form.get('pricingUnit')?.touched
        " />
      <app-error-message
        *ngIf="
          form.get('pricingUnit')?.invalid && form.get('pricingUnit')?.touched
        "
        [control]="form.get('pricingUnit')"
        label="Pricing Unit"></app-error-message>
    </div>

    <div>
      <p>Unit of Packaging</p>
      <p-select
        #packagingUnitDropdown
        formControlName="packagingUnit"
        [options]="packagingUnits"
        [editable]="isOtherPackagingUnit"
        (onChange)="onPackagingUnitChange($event.value)"
        placeholder="Select Packaging Unit"
        class="w-full"
        [class.input-danger]="
          form.get('packagingUnit')?.invalid &&
          form.get('packagingUnit')?.touched
        " />
      <app-error-message
        *ngIf="
          form.get('packagingUnit')?.invalid &&
          form.get('packagingUnit')?.touched
        "
        [control]="form.get('packagingUnit')"
        label="Packaging Unit"></app-error-message>
    </div>

    <div>
      <p>GTIN (Global Trade Item Number)</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('gtin')?.invalid && form.get('gtin')?.touched
        "
        placeholder="GTIN"
        formControlName="gtin" />
      <app-error-message
        *ngIf="
          form.get('gtin')?.hasError('required') && form.get('gtin')?.touched
        "
        [control]="form.get('gtin')"
        label="GTIN"></app-error-message>
      <app-error-message
        *ngIf="
          form.get('gtin')?.hasError('pattern') && form.get('gtin')?.touched
        "
        [control]="form.get('gtin')"
        label="GTIN"></app-error-message>
    </div>

    <div>
      <p>Logo</p>
      <app-document-upload
        [fileType]="'logo'"
        [supportedFormats]="'image/svg+xml, image/png, image/jpeg'"
        title="Upload Logo"
        [singleMode]="true"
        (onUpload)="onLogoSelected($event)"></app-document-upload>
    </div>

    <div>
      <p>Product Images</p>
      <app-document-upload
        [supportedFormats]="'image/svg+xml, image/png, image/jpeg'"
        title="Upload Product Images"
        [fileType]="'productImages'"></app-document-upload>
    </div>

    <div class="col-span-2">
      <p>Product Links</p>
      <div class="grid grid-cols-2 gap-2">
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('facebookUrl')?.invalid &&
              form.get('facebookUrl')?.touched
            "
            placeholder="Facebook URL"
            formControlName="facebookUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('xUrl')?.invalid && form.get('xUrl')?.touched
            "
            placeholder="X URL"
            formControlName="xUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('youtubeUrl')?.invalid && form.get('youtubeUrl')?.touched
            "
            placeholder="Youtube URL"
            formControlName="youtubeUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('webUrl')?.invalid && form.get('webUrl')?.touched
            "
            placeholder="Web URL"
            formControlName="webUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('instagramUrl')?.invalid &&
              form.get('instagramUrl')?.touched
            "
            placeholder="Instagram URL"
            formControlName="instagramUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('videoContentUrl')?.invalid &&
              form.get('videoContentUrl')?.touched
            "
            placeholder="Video Content URL"
            formControlName="videoContentUrl" />
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <p class="text-md !mb-0">Product Degradation Counter</p>
      <p class="!font-thin">
        Tracks the duration a product remains in optimal, warning, or critical
        condition before degradation.
      </p>
      <div class="grid grid-cols-3 gap-2">
        <div>
          <p>Green Duration (Stays Green for Next)</p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('greenDuration')?.invalid &&
              form.get('greenDuration')?.touched
            "
            placeholder="30 days"
            formControlName="greenDuration" />
          <app-error-message
            *ngIf="
              form.get('greenDuration')?.invalid &&
              form.get('greenDuration')?.touched
            "
            [control]="form.get('greenDuration')"
            label="Green Duration"></app-error-message>
        </div>
        <div>
          <p>Yellow Duration (Stays Yellow for Next)</p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('yellowDuration')?.invalid &&
              form.get('yellowDuration')?.touched
            "
            placeholder="30 days"
            formControlName="yellowDuration" />
          <app-error-message
            *ngIf="
              form.get('yellowDuration')?.invalid &&
              form.get('yellowDuration')?.touched
            "
            [control]="form.get('yellowDuration')"
            label="Yellow Duration"></app-error-message>
        </div>
        <div>
          <p>Red Duration (Stays Red for Next)</p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('redDuration')?.invalid &&
              form.get('redDuration')?.touched
            "
            placeholder="30 days"
            formControlName="redDuration" />
          <app-error-message
            *ngIf="
              form.get('redDuration')?.invalid &&
              form.get('redDuration')?.touched
            "
            [control]="form.get('redDuration')"
            label="Red Duration"></app-error-message>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="flex items-center gap-2">
        <p-checkbox
          formControlName="fdaClassified"
          class="mr-[10px]"
          [binary]="true"
          inputId="fdaClassified" />
        <label for="fdaClassified">
          <p class="!m-0">This product is classified in the FDA tracing list</p>
          <small>
            Learn more about FDA food tracing list
            <a class="font-bold text-primary no-underline" href="#">here</a>
          </small>
        </label>
      </div>
    </div>
  </div>
</form>
