<div class="activity-feed-container">
  <p-card
    header=""
    (scroll)="onScroll($event)"
    class="activity-card"
    [style]="{ height: 'calc(100vh - 100px)', overflow: 'auto' }">
    <div (scroll)="onScroll($event)" class="activity-list">
      @for (activity of activityLogs$().payload; track $index) {
        <div class="activity-item">
          <div class="activity-avatar">
            <img
              [src]="activity?.user?.photo ?? bucketUrl + '/images/avatar.png'"
              class="w-[50px] h-[50px] object-cover rounded-full"
              [alt]="activity?.user?.firstName" />
          </div>

          <div class="activity-content">
            <div class="activity-header">
              <span class="username">{{
                '@' + activity?.user?.firstName + activity?.user?.lastName
              }}</span>
              <span class="timestamp">{{ activity.createdAt | timeAgo }}</span>
            </div>

            <div class="activity-text">
              {{ activity.description }}
            </div>
          </div>
        </div>
      }
    </div>
  </p-card>
</div>
