<div class="w-full">
  <div class="flex items-center justify-between bg-orange-100 p-5 rounded-lg">
    <div>
      <p class="font-bold m-0 mb-2">{{ title }}</p>
      <p class="m-0">
        {{ description }}
      </p>
    </div>
    <div>
      <p-button [routerLink]="'/subscription/plans'">View Plans</p-button>
    </div>
  </div>
</div>
