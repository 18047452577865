import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { TrackDashboardData } from '../../../../store/dashboard-store/dashboard.model';
import { CommonModule } from '@angular/common';
interface GanttDataContext {
  name: string;
  startMonth: number;
  endMonth: number;
  progress: number;
  value?: number;
}
@Component({
  selector: 'app-gantt-chart',
  imports: [CommonModule],
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss'],
})
export class GanttChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) dataset: TrackDashboardData[] = [];
  @Input() overlay: boolean = false;
  private root!: am5.Root;
  private colors = ['#FFDE07', '#AC7080', '#0F9008', '#007DF2', '#E1E1E1'];
  ngOnInit(): void {
    this.initChart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataset'] &&
      changes['dataset'].currentValue !== changes['dataset'].previousValue
    ) {
      this.initChart();
    }
  }
  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initChart(): void {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('trackMap');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        paddingLeft: 50,
        paddingRight: 20,
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'name',
        renderer: am5xy.AxisRendererY.new(this.root, {
          inversed: true,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 20,
        }),
      })
    );

    yAxis.get('renderer').labels.template.setAll({
      paddingRight: 40,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {
          opposite: true,
        }),
        min: 1,
        max: 12,
        strictMinMax: true,
        extraMin: 0.1,
        extraMax: 0.1,
        numberFormat: '#',
      })
    );
    xAxis.get('renderer').grid.template.setAll({
      forceHidden: false,
      visible: true,
    });

    xAxis.get('renderer').labels.template.setAll({
      populateText: true,
    });

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    xAxis
      .get('renderer')
      .labels.template.adapters.add('text', (text, target) => {
        const value = parseInt(text || '', 10);

        if (!isNaN(value) && value >= 1 && value <= 12) {
          return monthNames[value - 1];
        }
        return text;
      });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: 'startMonth',
        valueXField: 'endMonth',
        categoryYField: 'name',
        sequencedInterpolation: true,
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      tooltipText: '{name}: {progress}%',
      tooltipX: am5.p50,
      cornerRadiusBR: 15,
      cornerRadiusTR: 15,
      cornerRadiusBL: 15,
      cornerRadiusTL: 15,
      fillOpacity: 0.8,
    });

    series.bullets.push(() =>
      am5.Bullet.new(this.root, {
        locationX: 0.5,
        sprite: am5.Label.new(this.root, {
          text: '{progress}%',
          centerX: am5.p50,
          centerY: am5.p50,
          populateText: true,
        }),
      })
    );
    series.data.setAll(
      this.dataset.map(data => {
        if (data.startMonth === data.endMonth) {
          return {
            ...data,
            endMonth: data.endMonth - 1, // Adjust endMonth slightly
          };
        }
        return data;
      })
    );

    yAxis.data.setAll(this.dataset);

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.dataContext) {
        const context = dataItem.dataContext as GanttDataContext;
        if (context.progress === 100) {
          return am5.color(this.colors[2]);
        } else if (context.progress === 0) {
          return am5.color(this.colors[4]);
        } else if (context.progress >= 70 && context.progress <= 90) {
          return am5.color(this.colors[0]);
        } else if (context.progress >= 50 && context.progress < 70) {
          return am5.color(this.colors[3]);
        } else if (context.progress >= 1 && context.progress < 50) {
          return am5.color(this.colors[1]);
        }
      }
      return fill;
    });

    series.appear();
    chart.appear(1000, 100);
  }
}
