import { Selector } from '@ngxs/store';
import { DemoRequestsStateModal } from './demo-requests.model';
import { DemoRequestsState } from './demo-requests.state';

export class DemoRequestStateSelectors {
  @Selector([DemoRequestsState])
  static getDemoRequests(state: DemoRequestsStateModal) {
    return state.demoRequests;
  }

  @Selector([DemoRequestsState])
  static getPaginationData(state: DemoRequestsStateModal) {
    return state.pagination;
  }

  @Selector([DemoRequestsState])
  static isDemoStoreProcessing(state: DemoRequestsStateModal) {
    return state.isLoading;
  }

  @Selector([DemoRequestsState])
  static getHeaders(state: DemoRequestsStateModal) {
    return state.headers;
  }
}
