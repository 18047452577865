import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import { AddProductFormComponent } from '../../../components/form-components/add-product-form/add-product-form.component';
import { CtesKdesComponent } from '../../settings/ctes-kdes/ctes-kdes.component';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { Store } from '@ngxs/store';
import { GetProduct } from '../../../../store/products-store/products.actions';

@Component({
  selector: 'app-product-add-edit',
  standalone: true,
  imports: [CommonModule, AddProductFormComponent, CtesKdesComponent],
  templateUrl: './product-add-edit.component.html',
  styleUrl: './product-add-edit.component.scss',
})
export class ProductAddEditComponent {
  @Input() data?: Product;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter();
  @Output() actionCreateProduct = new EventEmitter<void>();

  product$: Signal<Product | null> = this.store.selectSignal(
    ProductStateSelectors.getProduct
  );

  steps = [
    { label: 'Product Passport', url: 'product-passport' },
    { label: 'Define CTEs', url: 'define-ctes' },
  ];
  activeStep = 0;

  constructor(private readonly store: Store) {}

  nextStep() {
    const productId = this.product$()?.id || 0;
    this.store.dispatch(new GetProduct(productId)).subscribe(res => {
      this.actionCreateProduct.emit();
      this.activeStep = this.activeStep + 1;
    });
  }

  previousStep() {
    this.activeStep = this.activeStep - 1;
  }

  success() {
    this.activeStep = 0;
    this.actionSuccess.emit();
  }
}
