import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { DemoRequestSuccessComponent } from '../../components/form-components/demo-request-success/demo-request-success.component';

@Component({
  selector: 'app-request-demo-success',
  imports: [AuthComponent, DemoRequestSuccessComponent],
  templateUrl: './request-demo-success.component.html',
  styleUrl: './request-demo-success.component.scss',
})
export class RequestDemoSuccessComponent {
  title: string = 'Experience the Power of Product Traceability';
}
