import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  HostListener,
} from '@angular/core';
import { QrOrder } from '../../../../store/qr-store/qr.model';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { QrStateSelectors } from '../../../../store/qr-store/qr.selectors';
import { Store } from '@ngxs/store';
import { GetAllProducts } from '../../../../store/products-store/products.actions';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  Product,
  ProductQrCtaConfig,
} from '../../../../store/products-store/products.model';
import { QrPreviewComponent } from '../../shared-components/qr-preview/qr-preview.component';
import { CreateQrOrder } from '../../../../store/qr-store/qr.actions';
import { CornerDotType, CornerSquareType, Options } from 'ngx-qrcode-styling';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-create-qr-order-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    InputGroupModule,
    InputGroupAddonModule,
    RadioButtonModule,
    QrPreviewComponent,
    RightFormFooterComponent,
  ],
  templateUrl: './create-qr-order-form.component.html',
  styleUrl: './create-qr-order-form.component.scss',
})
export class CreateQrOrderFormComponent implements OnInit {
  @Input() data!: QrOrder;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<boolean>();

  processing$: Signal<boolean> = this.store.selectSignal(
    QrStateSelectors.isProcessing
  );
  products$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getAllProducts
  );

  form: FormGroup;
  packagingUnits = ['CARTON', 'BOX', 'CRATE', 'CONTAINER', 'PACK'];
  numberOfQrs = 0;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      packagingUnit: ['', [Validators.required]],
      quantity: ['1', [Validators.required, Validators.min(1)]],
      batchSize: ['1', [Validators.required, Validators.min(1)]],
      product: ['', [Validators.required]],
      batchSizeDependence: ['PACKAGING', [Validators.required]],
    });
    this.getAllProducts();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnInit(): void {
    this.form.get('product')?.valueChanges.subscribe(data => {
      this.form.get('packagingUnit')?.setValue(data.packagingUnit);
    });
    this.form.valueChanges.subscribe(res => {
      if (res.batchSizeDependence === 'PACKAGING') {
        this.numberOfQrs = res.quantity;
      } else if (res.batchSizeDependence === 'UNIT') {
        this.numberOfQrs = res.batchSize;
      }
    });
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  getProductCtaConfig(product: Product): ProductQrCtaConfig | null {
    if (!product) {
      return null;
    }
    if (product.productQrSettings.length === 0) {
      return null;
    }
    return {
      ctaLabelPosition: product.productQrSettings[0].ctaLabelPosition,
      ctaLabelText: product.productQrSettings[0].ctaLabelText,
    };
  }

  getProductQrSettings(product: Product): Options {
    if (!product) {
      return {};
    }
    if (product.productQrSettings.length === 0) {
      return {};
    }
    return {
      data: 'https://regenesis.ag/',
      type: 'svg',
      image: product.productQrSettings[0].centerImage,
      imageOptions: {
        crossOrigin: 'anoynymous',
      },
      dotsOptions: {
        color: product.productQrSettings[0].dotsOptionsColor,
      },
      backgroundOptions: {
        color: product.productQrSettings[0].backgroundColor,
      },
      cornersDotOptions: {
        color: product.productQrSettings[0].cornersDotColor,
        type: product.productQrSettings[0].cornersDotType.toLowerCase() as CornerDotType,
      },
      cornersSquareOptions: {
        color: product.productQrSettings[0].cornersSquareColor,
        type: product.productQrSettings[0].cornersSquareType.toLowerCase() as CornerSquareType,
      },
    };
  }

  action() {
    if (this.form.invalid) return;
    const values = this.form.value;
    if (this.type === 'ADD') {
      this.store
        .dispatch(
          new CreateQrOrder({
            quantity: values.quantity,
            packagingUnit: values.packagingUnit,
            batchSize: values.batchSize,
            productId: values.product.id,
            productQrSettingId: values.product.productQrSettings[0].id,
          })
        )
        .subscribe(() => {
          this.form.reset({
            batchSizeDependence: 'PACKAGING',
            quantity: 1,
            batchSize: 1,
          });
          this.actionSuccess.emit(true);
        });
    }
  }
}
