<div class="w-full p-8">
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-add-organization-admin-form
      [title]="sidebar.title"
      (actionSuccess)="getUserList($event); sidebar.visible = false">
    </app-add-organization-admin-form>
  </app-right-form-base>
  <app-right-form-base
    [isVisible]="updateSidebar.visible"
    (sidebarHidden)="updateSidebar.visible = false"
    [title]="updateSidebar.title">
    <app-add-user-form
      [title]="updateSidebar.title"
      [type]="updateSidebar.type"
      [data]="updateSidebar.data"
      [id]="updateSidebar.id"
      (actionSuccess)="updateSidebar.visible = false; getUserList()">
    </app-add-user-form>
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex">
      <p-button
        appAccess="add-user"
        icon="pi pi-plus"
        label="Add Admin User"
        (onClick)="
          sidebar = {
            visible: true,
            title: 'Add New Admin User',
            type: 'ADD',
            width: 'w-50',
          }
        " />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      export="USER"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
