import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  GetPlans,
  Subscribe,
  SwitchSubscription,
} from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute } from '@angular/router';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { environment } from '../../../../environments/environment';
import { ErrorMessageComponent } from '../../../components/shared-components/error-message/error-message.component';
import { ConfirmationService } from 'primeng/api';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import {
  Card,
  Plan,
} from '../../../../store/subscription-store/subscription.model';
import { CheckboxModule } from 'primeng/checkbox';
import { CardsListComponent } from '../../../components/shared-components/cards-list/cards-list.component';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    CommonModule,
    StepperModule,
    ButtonModule,
    ErrorMessageComponent,
    CheckboxModule,
    CardsListComponent,
  ],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss',
})
export class SubscribeComponent {
  bucketUrl: string = environment.bucketUrl;
  errorMessage: string = '';
  planId: string = '';
  selectedPlan: Plan | null = null;
  selectedCard: Card | null = null;
  acceptTermsConditions: boolean = false;

  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  cards$ = this.store.selectSignal(SubscriptionStateSelectors.getCards);
  plans$ = this.store.selectSignal(SubscriptionStateSelectors.getPlans);
  isLoading$ = this.store.selectSignal(SubscriptionStateSelectors.isLoading);
  isLoading: boolean = false;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {
    this.getPlans();
    this.planId = this.activatedRoute.snapshot.params['id'];
    effect(() => {
      if (this.plans$().length > 0) {
        this.selectedPlan =
          this.plans$().find(p => p.id === this.planId) ?? null;
      }
    });
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  subscribe() {
    this.confirmationService.confirm({
      header: `CONFIRM SUBSCRIPTION`,
      message: `Are you sure you want to subscribe to ${this.selectedPlan?.productDetails.name}?`,
      icon: 'pi pi-exclamation-circle',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-primary',
      accept: () => {
        if (this.user$()?.currentPlan === null) {
          this.store
            .dispatch(
              new Subscribe({
                sourceId: this.selectedCard!.id,
                planId: this.planId,
              })
            )
            .subscribe(() => {
              window.location.href = '/subscription/success';
            });
        } else {
          this.store
            .dispatch(
              new SwitchSubscription(this.user$()!.subscriptionId, {
                newPlanId: this.planId,
                immediateSwitch: true,
              })
            )
            .subscribe(() => {
              window.location.href = '/subscription/success';
            });
        }
      },
    });
  }
}
