import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { SignInComponent } from '../../components/form-components/sign-in/sign-in.component';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [AuthComponent, SignInComponent],
  templateUrl: './login.component.html',
})
export class LoginPageComponent {}
