import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

@Component({
  selector: 'app-report-bar-chart',
  imports: [],
  templateUrl: './report-bar-chart.component.html',
})
export class ReportBarChartComponent<T> implements OnChanges {
  @Input({ required: true }) data: T | null = null;
  @Output() reload = new EventEmitter<any>();

  private root!: am5.Root;
  public noData: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initMap();
      } else {
        this.noData = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('barChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'category',
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 30,
        }),
      })
    );

    // Hide the x-axis labels
    xAxis.get('renderer').labels.template.setAll({
      visible: false, // This will hide the labels below the bars
    });

    if (Array.isArray(this.data)) {
      xAxis.data.setAll(this.data);
    } else {
      console.error("Data is not an array:", this.data);
    }

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Value',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'category',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{categoryX}: {valueY} units',
        }),
      })
    );

    if (Array.isArray(this.data)) {
      series.data.setAll(this.data);
    } else {
      console.error("Data is not an array:", this.data);
    }

    series.columns.template.setAll({
      fill: am5.color('#4763E4'),
      stroke: am5.color('#FFFFFF'),
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
    });

    series.columns.template.set('tooltipText', '{categoryX}: {valueY} units');
    series.columns.template.adapters.add('tooltipY', (value, target) => {
      return target.y() + target.height() / 2;
    });

    series.columns.template.setAll({
      cursorOverStyle: 'pointer',
      tooltip: am5.Tooltip.new(this.root, {
        labelText: '{categoryX}: {valueY} units',
      }),
    });

    series.appear(1000);
    chart.appear(1000, 100);
  }
}
