import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { SearchProducts } from '../../../../store/products-store/products.actions';
import { CalendarModule } from 'primeng/calendar';
import {
  GetCities,
  GetCountries,
} from '../../../../store/general-store/general.actions';
import { City, Country } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ButtonModule } from 'primeng/button';
import { format } from 'date-fns';

@Component({
  selector: 'app-dashboard-filters',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    CalendarModule,
    ButtonModule,
  ],
  templateUrl: './dashboard-filters.component.html',
  styleUrl: './dashboard-filters.component.scss',
})
export class DashboardFiltersComponent implements OnInit {
  @Output() selectedFilters = new EventEmitter();
  searchedProducts$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.searchProducts
  );
  productStateLoading$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );
  countries$: Signal<Country[]> = this.store.selectSignal(
    GeneralStateSelectors.getCountries
  );
  cities$: Signal<City[]> = this.store.selectSignal(
    GeneralStateSelectors.getCities
  );

  public deviceTypes = [
    {
      key: 'Android',
      value: 'android',
    },
    {
      key: 'IOS',
      value: 'ios',
    },
  ];
  public timeFrames = [
    {
      key: 'Weekly',
      value: 'weekly',
    },
    {
      key: 'Monthly',
      value: 'monthly',
    },
    {
      key: 'Yearly',
      value: 'yearly',
    },
  ];

  public filters = {
    productId: null,
    qrCode: null,
    dateRange: null,
    country: null,
    city: null,
    deviceType: null,
    timeFrame: null,
  };

  constructor(private store: Store) {
    this.getCountries();
  }

  ngOnInit(): void {}

  loadData(dropdown: any = null) {
    if (dropdown) {
      dropdown.filterValue = '';
    }
  }

  getCities(country: Country) {
    this.store.dispatch(new GetCities(country.id));
  }

  getCountries() {
    this.store.dispatch(new GetCountries());
  }

  searchProducts(value: string) {
    this.filters.productId = null;
    this.store.dispatch(
      new SearchProducts({
        first: 0,
        rows: 10,
        sortBy: 'productName',
        sortOrder: 'ASC',
        filters: '',
        search: value,
      })
    );
  }

  applyFilter() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    Object.keys(selectedFilters).forEach(key => {
      if (key === 'country' || key === 'city') {
        selectedFilters[key] = selectedFilters[key]?.name;
      }
      if (key === 'dateRange') {
        selectedFilters['startDate'] = format(
          selectedFilters[key][0],
          'yyyy-MM-dd'
        );
        selectedFilters['endDate'] = format(
          selectedFilters[key][1],
          'yyyy-MM-dd'
        );
        delete selectedFilters[key];
      }
    });
    this.selectedFilters.emit(selectedFilters);
  }
}
