<div class="flex items-center justify-between mt-2 mb-2">
  <h3>Scan Map</h3>
</div>
<div class="flex w-full gap-5">
  <div class="w-100">
    <google-map height="500px" width="100%" [options]="mapOptions">
      @for (marker of markers; track marker) {
        <map-marker
          [position]="marker.position"
          (mapClick)="openInfoWindow(marker, mapMarker)"
          #mapMarker="mapMarker">
        </map-marker>
        <map-info-window>
          <div class="flex gap-4 items-center w-100">
            <div>
              <div class="font-bold">{{ selectedMarker?.label?.title }}</div>
              <div>{{ selectedMarker?.label?.text }}</div>
            </div>
            <button
              class="bg-white border-none cursor-pointer"
              (click)="infoWindow.close()">
              <span class="pi pi-times text-sm"></span>
            </button>
          </div>
        </map-info-window>
      }
    </google-map>
  </div>
</div>
