import { Selector } from '@ngxs/store';
import { InvitationStateModel } from './invitation.model';
import { InvitationState } from './invitation.state';

export class InvitationStateSelectors {
  @Selector([InvitationState])
  static isLoading(state: InvitationStateModel) {
    return state.isLoading;
  }

  @Selector([InvitationState])
  static getHeaders(state: InvitationStateModel) {
    return state.headers;
  }

  @Selector([InvitationState])
  static getInvitations(state: InvitationStateModel) {
    return state.invitations;
  }

  @Selector([InvitationState])
  static getPaginationData(state: InvitationStateModel) {
    return state.pagination;
  }
}
