import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Graph } from '../../../../store/report-store/report.model';

@Component({
  selector: 'app-graph-list',
  imports: [CommonModule],
  templateUrl: './graph-list.component.html',
})
export class GraphListComponent implements OnInit {
  @Output() graphSelected = new EventEmitter<Graph>();

  graphs: Graph[] = [
    {
      label: 'Line Chart',
      type: 'LINE_CHART',
      icon: 'pi pi-chart-line',
      description: 'Track how KDE changes over time.',
    },
    {
      label: 'Heat Map',
      type: 'HEAT_MAP',
      icon: 'pi pi-chart-scatter',
      description: 'Analyze trends and correlations.',
    },
    {
      label: 'Bar Chart',
      type: 'BAR_CHART',
      icon: 'pi pi-chart-bar',
      description: 'Compare values across different categories.',
    },
    {
      label: 'Time Series',
      type: 'TIME_SERIES',
      icon: 'pi pi-clock',
      description: 'Show proportions of different groups.',
    },
    {
      label: 'Pie Chart',
      type: 'PIE_CHART',
      icon: 'pi pi-chart-pie',
      description: 'Show proportions of different groups.',
    },
  ];

  selectedGraphIndex = 2;
  selectedGraph = this.graphs[this.selectedGraphIndex];

  ngOnInit(): void {
    this.graphSelected.emit(this.selectedGraph)
  }

  selectGraph(index: number) {
    this.selectedGraphIndex = index;
    this.selectedGraph = this.graphs[this.selectedGraphIndex];
    this.graphSelected.emit(this.selectedGraph);
  }
}
