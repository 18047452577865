<app-right-form-base
  [isVisible]="sidebar.visible"
  (sidebarHidden)="sidebar.visible = false"
  [title]="sidebar.title">
  <app-add-user-form
    [type]="sidebar.type"
    [data]="sidebar.data"
    [id]="sidebar.id"
    (actionSuccess)="sidebar.visible = false; getUserList()">
  </app-add-user-form>
</app-right-form-base>
<app-data-table
  [first]="first"
  export="USER"
  [rows]="rows"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  [selectedFilters]="selectedFilters"
  [data$]="this.data$"
  [headers$]="this.headers$"
  [isProcessing$]="this.isProcessing$"
  [pagination$]="this.pagination$"
  (actionClickedEvent)="actionClicked($event)"
  (refreshEvent)="refresh($event)" />
