import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  AddCardParams,
  Card,
  CardResponse,
  Plan,
  PlanResponse,
  SubscribeParams,
  SwitchSubscriptionParams,
} from './subscription.model';
import { GenericApiResponse } from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getPlans(): Observable<Plan[]> {
    return this.http.get<PlanResponse>(`${this.apiUrl}/api/plans`).pipe(
      map(response => response.payload),
      catchError(error => {
        return throwError(
          () => new Error('Error fetching plans: ' + error.message)
        );
      })
    );
  }

  public getCards(): Observable<Card[]> {
    return this.http.get<CardResponse>(`${this.apiUrl}/api/cards`).pipe(
      map(response => response.payload.data),
      catchError(error => {
        return throwError(
          () => new Error('Error fetching cards: ' + error.message)
        );
      })
    );
  }

  public addCard(payload: AddCardParams): Observable<GenericApiResponse> {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/cards`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error adding card: ' + error.message)
          );
        })
      );
  }

  public setCardAsDefault(cardId: string): Observable<GenericApiResponse> {
    return this.http
      .patch<GenericApiResponse>(
        `${this.apiUrl}/api/cards/${cardId}/default`,
        {}
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error setting card as default: ' + error.message)
          );
        })
      );
  }

  public deleteCard(cardId: string): Observable<GenericApiResponse> {
    return this.http
      .delete<GenericApiResponse>(`${this.apiUrl}/api/cards/${cardId}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting card: ' + error.message)
          );
        })
      );
  }

  public subscribe(payload: SubscribeParams): Observable<GenericApiResponse> {
    return this.http
      .post<GenericApiResponse>(`${this.apiUrl}/api/subscriptions`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error subscribing: ' + error.message)
          );
        })
      );
  }

  public switchSubscription(
    id: number,
    payload: SwitchSubscriptionParams
  ): Observable<GenericApiResponse> {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/subscriptions/${id}/switch`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error switching subscription: ' + error.message)
          );
        })
      );
  }

  public cancelSubscription(id: string): Observable<GenericApiResponse> {
    return this.http
      .delete<GenericApiResponse>(`${this.apiUrl}/api/subscriptions/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error canceling subscription: ' + error.message)
          );
        })
      );
  }
}
