<div class="flex justify-between items-center">
  <h2>Product Passport</h2>
</div>
<form [formGroup]="form" class="full-height-form">
  <div class="grid grid-cols-2 gap-x-4 gap-y-0">
    <div>
      <p class="text-base font-normal mb-[3px]">Product Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('productName')?.invalid && form.get('productName')?.touched
        "
        placeholder="Product Name"
        formControlName="productName" />
      <app-error-message
        [control]="form.get('productName')"
        label="Product Name"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Manufacturer / Brand Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('brandName')?.invalid && form.get('brandName')?.touched
        "
        placeholder="Brand Name"
        formControlName="brandName" />
      <app-error-message
        [control]="form.get('brandName')"
        label="Brand Name"></app-error-message>
    </div>

    <div class="col-span-2">
      <p class="text-base font-normal mb-[3px]">Product Description</p>
      <textarea
        type="text"
        rows="4"
        formControlName="productDescription"
        placeholder="Product Description"
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('productDescription')?.invalid &&
          form.get('productDescription')?.touched
        "></textarea>
      <app-error-message
        [control]="form.get('productDescription')"
        label="Product Description"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Unit of Measurement</p>
      <p-dropdown
        formControlName="measurementUnit"
        [options]="measurementUnits"
        placeholder="Select Measurement Unit"
        class="w-full"
        [class.input-danger]="
          form.get('measurementUnit')?.invalid &&
          form.get('measurementUnit')?.touched
        " />
      <app-error-message
        [control]="form.get('measurementUnit')"
        label="Measurement Unit"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Unit of Pricing</p>
      <p-dropdown
        formControlName="pricingUnit"
        [options]="pricingUnits"
        placeholder="Select Pricing Unit"
        class="w-full"
        [class.input-danger]="
          form.get('pricingUnit')?.invalid && form.get('pricingUnit')?.touched
        " />
      <app-error-message
        [control]="form.get('pricingUnit')"
        label="Pricing Unit"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Unit of Packaging</p>
      <p-dropdown
        formControlName="packagingUnit"
        [options]="packagingUnits"
        placeholder="Select Packaging Unit"
        class="w-full"
        [class.input-danger]="
          form.get('packagingUnit')?.invalid &&
          form.get('packagingUnit')?.touched
        " />
      <app-error-message
        [control]="form.get('packagingUnit')"
        label="Packaging Unit"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">
        GTIN (Global Trade Item Number)
      </p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('gtin')?.invalid && form.get('gtin')?.touched
        "
        placeholder="GTIN"
        formControlName="gtin" />
      <app-error-message
        [control]="form.get('gtin')"
        label="GTIN is invalid. Please enter a valid GTIN (8 or 13 digits)."></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Logo</p>
      <app-image-upload
        [singleMode]="true"
        [fileType]="'logo'"
        (fileChange)="onLogoSelected($event)"></app-image-upload>
    </div>

    <div>
      <p class="text-base font-normal mb-[3px]">Product Images</p>
      <app-image-upload
        [singleMode]="false"
        [fileType]="'productImages'"></app-image-upload>
    </div>

    <div class="col-span-2 mt-[10px]">
      <p class="text-base font-normal mb-[3px]">Product Links</p>
      <div class="grid grid-cols-2 gap-2">
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('facebookUrl')?.invalid &&
              form.get('facebookUrl')?.touched
            "
            placeholder="Facebook URL"
            formControlName="facebookUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('xUrl')?.invalid && form.get('xUrl')?.touched
            "
            placeholder="X URL"
            formControlName="xUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('youtubeUrl')?.invalid && form.get('youtubeUrl')?.touched
            "
            placeholder="Youtube URL"
            formControlName="youtubeUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('webUrl')?.invalid && form.get('webUrl')?.touched
            "
            placeholder="Web URL"
            formControlName="webUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('instagramUrl')?.invalid &&
              form.get('instagramUrl')?.touched
            "
            placeholder="Instagram URL"
            formControlName="instagramUrl" />
        </div>
        <div>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('videoContentUrl')?.invalid &&
              form.get('videoContentUrl')?.touched
            "
            placeholder="Video Content URL"
            formControlName="videoContentUrl" />
        </div>
      </div>
    </div>
    <div class="col-span-2 mt-[10px]">
      <p class="text-base font-normal mb-[3px]">Product degradation counter</p>
      <div class="grid grid-cols-3 gap-2">
        <div>
          <p class="text-base font-normal mb-[3px] mt-[1px]">Stays green for</p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('greenDuration')?.invalid &&
              form.get('greenDuration')?.touched
            "
            placeholder="30 days"
            formControlName="greenDuration" />
          <app-error-message
            [control]="form.get('greenDuration')"
            label="Green Duration"></app-error-message>
        </div>
        <div>
          <p class="text-base font-normal mb-[3px] mt-[1px]">
            Stays yellow for next
          </p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('yellowDuration')?.invalid &&
              form.get('yellowDuration')?.touched
            "
            placeholder="30 days"
            formControlName="yellowDuration" />
          <app-error-message
            [control]="form.get('yellowDuration')"
            label="Yellow Duration"></app-error-message>
        </div>
        <div>
          <p class="text-base font-normal mb-[3px] mt-[1px]">
            Stays red for next
          </p>
          <p-inputNumber
            class="w-full"
            [class.input-danger]="
              form.get('redDuration')?.invalid &&
              form.get('redDuration')?.touched
            "
            placeholder="30 days"
            formControlName="redDuration" />
          <app-error-message
            [control]="form.get('redDuration')"
            label="Red Duration"></app-error-message>
        </div>
      </div>
    </div>
    <div class="flex items-center mt-[20px]">
      <p-checkbox
        formControlName="fdaClassified"
        class="mr-[10px]"
        [binary]="true"
        inputId="fdaClassified"
        [(ngModel)]="fdaClassified" />
      <label for="fdaClassified">
        <p class="mb-[3px]">
          This product is classified in the FDA tracing list
        </p>
        <small>
          Learn more about FDA food tracing list
          <a class="text-blue-600 underline" href="#">here</a>
        </small>
      </label>
    </div>
  </div>
</form>

<app-right-form-footer
  [type]="type"
  [formValid]="fdaClassified && form.valid && !processing$()"
  (callAction)="action()"
  [label]="type === 'EDIT' ? 'Update & Next' : 'Submit & Next'"
  (callCancel)="actionSuccess.emit(false)">
</app-right-form-footer>
