import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  GetDemoRequestsList,
  UpdateDemoRequest,
} from './demo-requests.actions';
import { DemoRequestsStateModal } from './demo-requests.model';
import { DemoRequestsService } from './demo-requests.service';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
@State<DemoRequestsStateModal>({
  name: 'DemoRequests',
  defaults: {
    isLoading: false,
    demoRequests: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class DemoRequestsState {
  constructor(
    private demoRequestsService: DemoRequestsService,
    private notificationService: NotificationService
  ) {}

  @Action(GetDemoRequestsList)
  getDemoRequestsList(
    { patchState }: StateContext<DemoRequestsStateModal>,
    action: GetDemoRequestsList
  ) {
    patchState({ isLoading: true });
    return this.demoRequestsService.getDemoRequests(action.payload).pipe(
      tap(async res => {
        patchState({
          demoRequests: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(UpdateDemoRequest)
  updateDemoRequest(
    { patchState }: StateContext<DemoRequestsStateModal>,
    action: UpdateDemoRequest
  ) {
    patchState({ isLoading: true });
    return this.demoRequestsService
      .updateDemoRequest(action.payload, action.id)
      .pipe(
        tap(async _res => {
          patchState({
            isLoading: false,
          });
          this.notificationService.openSuccessToast(
            'Demo request updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isLoading: false });
        })
      );
  }
}
