import { Component } from '@angular/core';
import { NewDemoRequestComponent } from '../../components/form-components/new-demo-request/new-demo-request.component';
import { AuthComponent } from '../../layouts/auth/auth.component';

@Component({
  selector: 'app-request-demo',
  standalone: true,
  imports: [NewDemoRequestComponent, AuthComponent],
  templateUrl: './request-demo.component.html',
})
export class RequestDemoPageComponent {}
