import { Component, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SubscriptionDetailsComponent } from '../../components/shared-components/subscription-details/subscription-details.component';
import { StackBarChartComponent } from '../../components/charts/stacked-bar-chart/stack-bar-chart.component';
import { DashboardStateSelectors } from '../../../store/dashboard-store/dashboard.selectors';
import { IntroService } from '../../services/intro.service';
import { QrMetrics } from '../../../store/dashboard-store/dashboard.model';
import { GetQrMetrics } from '../../../store/dashboard-store/dashboard.actions';

@Component({
  selector: 'app-landing',
  imports: [
    SubscriptionDetailsComponent,
    StackBarChartComponent,
    BreadcrumbModule,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  qrMetrics$: Signal<QrMetrics | null> = this.store.selectSignal(
    DashboardStateSelectors.getQrMetrics
  );
  constructor(
    private readonly introService: IntroService,
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('regen_tour_completed') !== 'true')
      this.introService.startTour();
  }

  getQrMetrics(filters: any) {
    this.store.dispatch(
      new GetQrMetrics(filters?.timeFrame, filters?.productId)
    );
  }
}
