<app-data-table
  [first]="first"
  export="USER"
  [rows]="rows"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  [selectedFilters]="selectedFilters"
  [data$]="this.data$"
  [headers$]="this.headers$"
  [isProcessing$]="this.isProcessing$"
  [pagination$]="this.pagination$"
  (actionClickedEvent)="actionClicked($event)"
  (refreshEvent)="refresh($event)" />
