import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  transform(
    value: string | number | Date | undefined | null,
    dateFormat: string = 'MMM dd, yyyy'
  ): string {
    if (!value) return '';

    let date: Date;

    if (typeof value === 'number') {
      // Convert numeric timestamp to Date
      date = new Date(value * 1000); // Multiply by 1000 to convert seconds to milliseconds
    } else if (typeof value === 'string') {
      // Parse ISO string
      date = parseISO(value);
    } else {
      // Value is already a Date
      date = value;
    }

    return format(date, dateFormat);
  }
}
