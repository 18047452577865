import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import { CreateReportResponse, CreateVisualizationPayload, Report, ReportResponse, VisualizationDataResponse, VisualizationResponse } from './report.model';
import { PaginationParams } from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getReports = (
    paginationParams: PaginationParams
  ): Observable<ReportResponse> => {
    return this.http
      .get<ReportResponse>(`${this.apiUrl}/api/product-reports`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching reports: ' + error.message)
          );
        })
      );
  };

  public getReport = (id: number): Observable<CreateReportResponse> => {
    return this.http
      .get<CreateReportResponse>(`${this.apiUrl}/api/product-reports/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching report: ' + error.message)
          );
        })
      );
  };

  public getVisualizationData = (reportId: number, visualizationId: number): Observable<VisualizationDataResponse> => {
    return this.http
      .get<VisualizationDataResponse>(`${this.apiUrl}/api/product-reports/${reportId}/visualizations/${visualizationId}/data`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching visualization data: ' + error.message)
          );
        })
      );
  };

  public getViewReportData = (
    id: number,
    paginationParams: PaginationParams
  ): Observable<ReportResponse> => {
    return this.http
      .get<ReportResponse>(`${this.apiUrl}/api/product-reports/${id}/data`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching view report data: ' + error.message)
          );
        })
      );
  };

  public createCustomReport(payload: any): Observable<CreateReportResponse> {
    return this.http
      .post<CreateReportResponse>(
        `${this.apiUrl}/api/product-reports/`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public createVisualization(id: number, payload: CreateVisualizationPayload): Observable<VisualizationResponse> {
    return this.http
      .post<VisualizationResponse>(
        `${this.apiUrl}/api/product-reports/${id}/visualizations`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateReport = (
    id: number,
    payload: any
  ): Observable<CreateReportResponse> => {
    return this.http
      .put<CreateReportResponse>(
        `${this.apiUrl}/api/product-reports/${id}`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public deleteReport(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/product-reports/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error deleting report');
          });
        })
      );
  }
}
