import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-table-action-button',
  standalone: true,
  imports: [ProgressSpinnerModule, ButtonModule, TooltipModule],
  templateUrl: './table-action-button.component.html',
  styleUrl: './table-action-button.component.scss',
})
export class TableActionButtonComponent {
  @Input() actionType: string | null = null;
  @Input() disabled = false;
  @Output() actionClicked = new EventEmitter<string>();
}
