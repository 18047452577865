import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { RecallDashboardData } from '../../../../store/dashboard-store/dashboard.model';
import { CommonModule } from '@angular/common';
import { getMonth } from 'date-fns';

interface GanttDataContext {
  title: string;
  startMonth: number;
  endMonth: number;
  progress: number;
  value?: number;
}

@Component({
  selector: 'app-recall-gantt-chart',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './recall-gantt-chart.component.html',
  styleUrls: ['./recall-gantt-chart.component.scss'],
})
export class RecallGanttChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) dataset: RecallDashboardData[] = [];
  @Input() overlay: boolean = false;
  private root!: am5.Root;
  private colors = ['#FFDE07', '#AC7080', '#0F9008', '#007DF2', '#E1E1E1'];
  ngOnInit(): void {
    this.initChart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataset'] &&
      changes['dataset'].currentValue !== changes['dataset'].previousValue
    ) {
      this.initChart();
    }
  }
  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initChart(): void {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('trackMap');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        paddingLeft: 50,
        paddingRight: 20,
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'title',
        renderer: am5xy.AxisRendererY.new(this.root, {
          inversed: true,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 20,
        }),
      })
    );

    yAxis.get('renderer').labels.template.setAll({
      paddingRight: 40,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {
          opposite: true,
        }),
        min: 1,
        max: 12,
        strictMinMax: true,
        extraMin: 0.1,
        extraMax: 0.1,
        numberFormat: '#',
      })
    );

    xAxis.get('renderer').grid.template.setAll({
      forceHidden: false,
      visible: true,
    });

    xAxis.get('renderer').labels.template.setAll({
      populateText: true,
    });

    // Month names array
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    xAxis
      .get('renderer')
      .labels.template.adapters.add('text', (text, target) => {
        const value = parseInt(text || '', 10);
        if (!isNaN(value) && value >= 1 && value <= 12) {
          return monthNames[value - 1]; // Show all months (Jan-Dec)
        }
        return text;
      });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: 'startMonth',
        valueXField: 'endMonth',
        categoryYField: 'title',
        sequencedInterpolation: true,
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      tooltipText: '{title}: {progress}%',
      tooltipX: am5.p50,
      cornerRadiusBR: 15,
      cornerRadiusTR: 15,
      cornerRadiusBL: 15,
      cornerRadiusTL: 15,
      fillOpacity: 0.8,
    });

    series.bullets.push(() =>
      am5.Bullet.new(this.root, {
        locationX: 0.5,
        sprite: am5.Label.new(this.root, {
          text: '{progress}%',
          centerX: am5.p50,
          centerY: am5.p50,
          populateText: true,
        }),
      })
    );

    // Define a dictionary for the color mapping based on progress value
    const progressColorMap: { [key: number]: am5.Color } = {
      100: am5.color(this.colors[2]), // Finished tasks (100%)
      0: am5.color(this.colors[4]), // No progress (0%)
      70: am5.color(this.colors[0]), // 70% to 90%
      50: am5.color(this.colors[3]), // 50% to 70%
      1: am5.color(this.colors[1]), // 1% to 50%
    };

    // Optional: Customize the tooltip or appearance for tasks with 0% progress
    series.columns.template.adapters.add('tooltipText', (text, target) => {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.dataContext) {
        const context = dataItem.dataContext as GanttDataContext;
        if (context.progress === 0) {
          return `${context.title}: No progress (0%)`; // Custom text for 0% progress tasks
        }
      }
      return text;
    });

    // Optional: Add a border for tasks with 0% progress for better visibility
    series.columns.template.adapters.add('stroke', (stroke, target) => {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.dataContext) {
        const context = dataItem.dataContext as GanttDataContext;
        if (context.progress === 0) {
          return am5.color(0xff0000); // Red border for 0% progress tasks
        }
      }
      return stroke;
    });

    // ✅ Transform dataset for Gantt chart
    const ganttData = this.dataset.flatMap(item => {
      const startDate = item.startDate ? new Date(item.startDate) : null;
      const endDate = item.endDate ? new Date(item.endDate) : null;
      return {
        title: item.title,
        progress: item.progress,
        startMonth: startDate ? getMonth(startDate) + 1 : 0, // Months are 0-indexed in date-fns
        endMonth: endDate ? getMonth(endDate) + 1 : 1,
      };
    });

    if (ganttData.length === 0) {
      console.warn('⚠️ No valid data for Gantt chart');
    }

    series.data.setAll(ganttData);
    yAxis.data.setAll(ganttData);

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.dataContext) {
        const context = dataItem.dataContext as GanttDataContext;
        const progress = context.progress;

        // Use the progress color map to determine the appropriate fill color
        if (progress in progressColorMap) {
          return progressColorMap[progress]; // Return the mapped color
        }

        // Default case for other progress values (could be customized further)
        return fill;
      }

      return fill;
    });

    series.appear();
    chart.appear(1000, 100);
  }
}
