import { Component, Signal, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import {
  Header,
  Pagination,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../../store/general-store/general.model';
import { Invitation } from '../../../../../store/invitations-store/invitation.model';
import { InvitationStateSelectors } from '../../../../../store/invitations-store/invitation.selectors';
import { Store } from '@ngxs/store';
import {
  DeleteInvitation,
  GetInvitationList,
  ResendInvitation,
} from '../../../../../store/invitations-store/invitation.actions';
import { ObjectToUrlParams } from '../../../../services/global.service';
import { DataTableComponent } from '../../../../components/shared-components/data-table/data-table.component';

@Component({
  selector: 'app-invitation-list',
  standalone: true,
  imports: [ButtonModule, DataTableComponent],
  templateUrl: './invitation-list.component.html',
  styleUrl: './invitation-list.component.scss',
})
export class InvitationListComponent implements OnInit {
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};

  data$: Signal<Invitation[]> = this.store.selectSignal(
    InvitationStateSelectors.getInvitations
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    InvitationStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    InvitationStateSelectors.isLoading
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    InvitationStateSelectors.getHeaders
  );

  visibleHeaders: Header[] = [];
  skeleton: string[] = [];

  constructor(
    private store: Store,
    private confirmationService: ConfirmationService
  ) {
    this.getInvitationList();
  }

  ngOnInit(): void {}

  getInvitationList() {
    this.store.dispatch(
      new GetInvitationList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} USER`,
        message: `Are you sure want to delete this invitation?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteInvitation(action.id)).subscribe(() => {
            this.getInvitationList();
          });
        },
      });
    } else if (action.event === 'RESEND') {
      this.store.dispatch(new ResendInvitation(action.id));
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getInvitationList();
  }
}
