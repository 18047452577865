import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import { LocationAnalytics } from '../../../../store/dashboard-store/dashboard.model';

@Component({
  selector: 'app-track-and-trace',
  standalone: true,
  imports: [],
  templateUrl: './track-and-trace.component.html',
  styleUrl: './track-and-trace.component.scss',
})
export class TrackAndTraceComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) data: LocationAnalytics[] = [];

  private root!: am5.Root;
  private chart!: am5map.MapChart;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.initMap();
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initMap(): void {
    if (this.root) {
      this.root.dispose(); // Dispose previous chart to prevent memory leaks
    }
    this.root = am5.Root.new('mapChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        projection: am5map.geoMercator(),
        panX: 'translateX',
        panY: 'translateY',
      })
    );

    const polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ'],
      })
    );
    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}',
      fill: am5.color('#C0C3FF'),
    });

    const pointSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: 'value',
        latitudeField: 'latitude',
        longitudeField: 'longitude',
      })
    );

    pointSeries.bullets.push((root, series, dataItem: any) => {
      const circle = am5.Circle.new(this.root, {
        radius: this.calculateRadius(dataItem.dataContext.scans),
        fill: am5.color('#4763E4'),
        tooltipText: '{productName} ({cteName}): [bold]{scans}[/] scans',
      });
      circle.animate({
        key: 'scale',
        from: 1,
        to: 1.5,
        duration: 2000,
        loops: Infinity,
        easing: am5.ease.inOut(am5.ease.quad),
      });
      return am5.Bullet.new(this.root, {
        sprite: circle,
      });
    });

    pointSeries.data.setAll(this.data);
    this.chart.appear(1000, 100);
  }

  private calculateRadius(scans: number): number {
    const minRadius = 5;
    const maxRadius = 30;
    const scaledRadius = Math.log10(scans + 1);
    const normalizedRadius =
      (scaledRadius / Math.log10(1_000_000 + 1)) * (maxRadius - minRadius) +
      minRadius;
    return Math.max(minRadius, Math.min(maxRadius, normalizedRadius));
  }
}
