import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import * as _ from 'lodash';
import { NotificationService } from '../../app/services/notification.service';
import { SubscriptionService } from './subscription.service';
import {
  AddCard,
  CancelSubscription,
  ClearDiscountDetails,
  DeleteCard,
  DiscountDetails,
  GetBillings,
  GetCards,
  GetPlans,
  GetSubscriptionUsage,
  SetCardAsDefault,
  Subscribe,
  SwitchSubscription,
} from './subscription.actions';
import { SubscriptionStateModel } from './subscription.model';
import { Router } from '@angular/router';
import { GetUser } from '../users-store/user.actions';

@State<SubscriptionStateModel>({
  name: 'Subscription',
  defaults: {
    isLoading: false,
    plans: [],
    cards: [],
    billings: null,
    discountDetails: null,
    subscriptionUsage: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class SubscriptionState {
  constructor(
    private subscriptionService: SubscriptionService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  @Action(ClearDiscountDetails)
  clearDiscountDetails({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ discountDetails: null });
  }

  @Action(GetPlans)
  getPlans({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getPlans().pipe(
      tap(async res => {
        patchState({
          plans: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(GetSubscriptionUsage)
  getSubscriptionUsage({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getSubscriptionUsage().pipe(
      tap(async res => {
        patchState({
          subscriptionUsage: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(GetBillings)
  getBillings({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getBillings().pipe(
      tap(async res => {
        patchState({
          billings: Object.keys(res).length === 0 ? null : res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(GetCards)
  getCards({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getCards().pipe(
      tap(async res => {
        patchState({
          cards: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(AddCard)
  addCard(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: AddCard
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.addCard(action.payload).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(DiscountDetails)
  discountDetails(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: DiscountDetails
  ) {
    patchState({ isLoading: true, discountDetails: null });
    return this.subscriptionService.discountDetails(action.payload).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
          discountDetails: res.payload,
        });
        this.notificationService.openSuccessToast(
          'Hurray! Discount code applied successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isLoading: false, discountDetails: null });
      })
    );
  }

  @Action(SetCardAsDefault)
  setCardAsDefault(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: SetCardAsDefault
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.setCardAsDefault(action.cardId).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(DeleteCard)
  deleteCard(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: DeleteCard
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.deleteCard(action.cardId).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(Subscribe)
  subscribe(
    { patchState, dispatch }: StateContext<SubscriptionStateModel>,
    action: Subscribe
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.subscribe(action.payload).pipe(
      tap(async res => {
        this.notificationService.openSuccessToast(
          'Your have subscribed to the plan successfully!'
        );
        dispatch([new GetUser()]).subscribe(() => {
          this.router.navigateByUrl('/settings/payment-methods');
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(CancelSubscription)
  cancelSubscription(
    { patchState, dispatch }: StateContext<SubscriptionStateModel>,
    action: CancelSubscription
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.cancelSubscription(action.id).pipe(
      tap(async res => {
        this.notificationService.openSuccessToast(
          'Your subscription has been canceled successfully!'
        );
        dispatch([new GetUser()]).subscribe(() => {
          this.router.navigateByUrl('/settings/payment-methods');
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(SwitchSubscription)
  switchSubscription(
    { patchState, dispatch }: StateContext<SubscriptionStateModel>,
    action: SwitchSubscription
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService
      .switchSubscription(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isLoading: false,
          });
          this.notificationService.openSuccessToast(
            'Your subscription has been switched to new plan successfully!'
          );
          dispatch([new GetUser()]).subscribe(() => {
            this.router.navigateByUrl('/settings/payment-methods');
          });
        }),
        catchError(async error => {
          patchState({ isLoading: false });
        })
      );
  }
}
