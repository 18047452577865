<div
  class="flex flex-col w-16 bg-black text-white p-4 transition-all duration-75 height-100"
  [ngClass]="{ 'w-64': isExpanded }">
  <nav class="flex flex-col mt-10" [ngClass]="{ 'items-center': !isExpanded }">
    @for (item of items; track item) {
      @if (checkPermissions(item['slugs'])) {
        <a
          [routerLink]="item.routerLink"
          routerLinkActive="active-link"
          class="flex text-white p-3 hover:bg-gray-800 rounded-md duration-100">
          <span [class]="item.icon"></span>
          @if (isExpanded) {
            <span class="ml-4">{{ item.label }}</span>
          }
        </a>
      }
    }
  </nav>
</div>
