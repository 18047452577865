import { Component, OnInit, OnDestroy, Signal } from '@angular/core';
import { TimeAgoPipe } from '../../../pipes/time-ago.pipe';
import { Store } from '@ngxs/store';
import { GetActivityLogs } from '../../../../store/dashboard-store/dashboard.actions';
import { Activities } from '../../../../store/general-store/general.model';
import { CardModule } from 'primeng/card';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-activity-feed',
  standalone: true,
  imports: [TimeAgoPipe, CardModule],
  templateUrl: './activity-feed.component.html',
  styleUrl: './activity-feed.component.scss',
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  loading = false;
  hasNextPage = true;
  bucketUrl = environment.bucketUrl;

  activityLogs$: Signal<Activities> = this.store.selectSignal(
    DashboardStateSelectors.getActivityLogs
  );
  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(
      new GetActivityLogs({
        rows: 100,
        first: 0,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
        search: '',
        filters: '',
      })
    );
  }

  ngOnDestroy() {
    console.log('destroyed');
    // this.subscription?.unsubscribe();
  }

  onScroll(event: any) {
    const element = event.target;
    const reachedBottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 100;

    if (reachedBottom) {
      console.log('reached bottom');
    }
  }
}
