<div>
  <div class="flex items-center justify-between mt-5 mb-5">
    <h3>{{ title }}</h3>
    <div class="flex gap-3 items-center" *ngIf="showFilters">
      <p-select
        #dropdown
        [(ngModel)]="filters.productId"
        [options]="products$()"
        optionLabel="productName"
        optionValue="id"
        optionDisabled="disabled"
        placeholder="Search Products"
        (onFilter)="searchProducts($event.filter)"
        [style.width]="'300px'"
        (onChange)="this.reload.emit(filters)" />
      <p-select
        [options]="timeFrames"
        [(ngModel)]="filters.timeFrame"
        optionValue="value"
        optionLabel="key"
        (onChange)="this.reload.emit(filters)" />
    </div>
  </div>
  <div class="bg-white rounded w-100 h-[75vh]" id="stackedBarChart"></div>
</div>
