import { Component, OnInit, Signal, ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { Store } from '@ngxs/store';
import {
  CreateRole,
  DeleteRole,
  GetRoleList,
} from '../../../../store/roles-permission-store/roles-permission.actions';
import { AddRoleFormComponent } from '../../../components/form-components/add-role-form/add-role-form.component';
import { Role } from '../../../../store/roles-permission-store/roles-permission.model';
import { RolesPermissionStateSelectors } from '../../../../store/roles-permission-store/roles-permission.selectors';
import { ConfirmationService } from 'primeng/api';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    RightFormBaseComponent,
    ButtonModule,
    DataTableComponent,
    AddRoleFormComponent,
    AccessDirective,
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RolesComponent implements OnInit {
  data$: Signal<Role[]> = this.store.selectSignal(
    RolesPermissionStateSelectors.getRoles
  );
  pagination$: Signal<Pagination> = this.store.selectSignal(
    RolesPermissionStateSelectors.getPaginationData
  );
  headers$: Signal<Header[]> = this.store.selectSignal(
    RolesPermissionStateSelectors.getHeaders
  );
  isProcessing$: Signal<boolean> = this.store.selectSignal(
    RolesPermissionStateSelectors.isProcessing
  );

  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };

  constructor(
    private readonly store: Store,
    private readonly confirmationService: ConfirmationService
  ) {
    this.getRoleList();
  }

  ngOnInit(): void {}

  createRole() {
    this.store.dispatch(new CreateRole()).subscribe(() => {
      this.getRoleList();
    });
  }

  getRoleList(hideSidebar = false) {
    if (hideSidebar) {
      this.sidebar.visible = false;
    }
    this.store.dispatch(
      new GetRoleList({
        first: 0,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: '',
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} ROLE`,
        message: `Are you sure want to delete this role?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteRole(action.id)).subscribe(() => {
            this.getRoleList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.sidebar = {
        visible: true,
        title: 'Edit Role',
        type: 'edit',
        width: 'w-50',
        id: action.id,
      };
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getRoleList();
  }
}
