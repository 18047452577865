import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
import { RequestDemoPageComponent } from './pages/request-demo/request-demo.component';
import { OTPPageComponent } from './pages/otppage/otppage.component';
import { SignUpPageComponent } from './pages/sign-up-page/sign-up-page.component';
import { OrganizationListComponent } from './pages/organizations/organization-list/organization-list.component';
import { OrganizationDemoRequestsComponent } from './pages/organizations/organization-demo-requests/organization-demo-requests.component';
import { ScanStatistics } from './pages/dashboards/scan-statistics/scan-statistics.component';
import { AdminUserListComponent } from './pages/users/admin-user-list/admin-user-list.component';
import { VendorListComponent } from './pages/vendors/vendor-list/vendor-list.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RolesComponent } from './pages/settings/roles/roles.component';
import { CtesKdesComponent } from './pages/settings/ctes-kdes/ctes-kdes.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { QrOrderListComponent } from './pages/qr/qr-order-list/qr-order-list.component';
import { ProductItemDataListComponent } from './pages/products/product-item-data-list/product-item-data-list.component';
import { canActivateTeam } from './guards/role.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NoAccessComponent } from './components/shared-components/no-access/no-access.component';
import { ProductExpirationComponent } from './pages/dashboards/product-expiration/product-expiration.component';
import { PaymentMethodsComponent } from './pages/settings/payment-methods/payment-methods.component';
import { NotificationsComponent } from './pages/settings/notifications/notifications.component';
import { PlansComponent } from './pages/subscription/plans/plans.component';
import { SubscribeComponent } from './pages/subscription/subscribe/subscribe.component';
import { ProfileSecurityComponent } from './pages/settings/profile-security/profile-security.component';
import { TrackDashboardComponent } from './pages/dashboards/track-products/track-products.component';
import { RecallListComponent } from './pages/recall-management/recall-list/recall-list.component';
import { RecallRequestListComponent } from './pages/recall-management/recall-request-list/recall-request-list.component';
import { RecallDashboardComponent } from './pages/recall-management/recall-dashboard/recall-dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { RequestDemoSuccessComponent } from './pages/request-demo-success/request-demo-success.component';
import { ListComponent } from './pages/custom-reports/list/list.component';
import { AllProductsResolver } from '../store/products-store/products.resolver';
import { ViewComponent } from './pages/custom-reports/view/view.component';
import { ReportResolver } from '../store/report-store/report.resolver';

export const routes: Routes = [
  {
    path: 'organizations',
    children: [
      {
        path: 'list',
        component: OrganizationListComponent,
        data: {
          title: 'Organization List',
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Organizations', routerLink: '/organizations/list' },
          ],
        },
      },
      {
        path: 'demo-requests',
        component: OrganizationDemoRequestsComponent,
        data: {
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            {
              label: 'Demo Requests',
              routerLink: '/organizations/demo-requests',
            },
          ],
          title: 'Organization Demo Requests',
        },
      },
    ],
  },
  {
    path: 'vendors',
    children: [
      {
        path: 'list',
        component: VendorListComponent,
        data: {
          title: 'Vendor List',
          requiredPermissions: ['list-vendor'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Vendors', routerLink: '/vendors/list' },
          ],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },
  {
    path: 'recall-management',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: RecallDashboardComponent,
        data: {
          title: 'Recall Dashboard',
          requiredPermissions: ['list-vendor'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Recall Management', routerLink: '/recall-management' },
            {
              label: 'Recall Dashboard',
              routerLink: '/recall-management/dashboard',
            },
          ],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'list',
        component: RecallListComponent,
        data: {
          title: 'Recall Product List',
          requiredPermissions: ['list-vendor'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Recall Management', routerLink: '/recall-management' },
            { label: 'Recall Product', routerLink: '/recall-management/list' },
          ],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'request-list',
        component: RecallRequestListComponent,
        data: {
          title: 'Recall Product Request List',
          requiredPermissions: ['list-vendor'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Recall Management', routerLink: '/recall-management' },
            {
              label: 'Recall Product Requests',
              routerLink: '/recall-management/request-list',
            },
          ],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: 'list',
        component: UserListComponent,
        data: {
          title: 'User List',
          requiredPermissions: ['list-user'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Users', routerLink: '/users/list' },
          ],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'admin',
        component: AdminUserListComponent,
        data: {
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Admin Users', routerLink: '/users/admin' },
          ],
          title: 'Admin User List',
        },
      },
    ],
  },

  {
    path: 'subscription',
    children: [
      {
        path: 'plans',
        children: [
          {
            path: '',
            component: PlansComponent,
            data: {
              title: 'Subscription Plans',
              requiredPermissions: ['view-subscription'],
            },
            canActivate: [canActivateTeam],
          },
          {
            path: 'subscribe/:id',
            component: SubscribeComponent,
            data: {
              title: 'Subscribe',
              requiredPermissions: ['create-subscription'],
            },
            canActivate: [canActivateTeam],
          },
        ],
      },
    ],
  },

  {
    path: 'products',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: 'list',
        component: ProductListComponent,
        data: {
          title: 'Product List',
          requiredPermissions: ['list-product'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Product Management', routerLink: '/product-management' },
            { label: 'Products', routerLink: '/products/list' },
          ],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'data',
        component: ProductItemDataListComponent,
        data: {
          title: 'Product Item Data List',
          requiredPermissions: ['list-product-data'],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Product Management', routerLink: '/product-management' },
            { label: 'Product Item Data', routerLink: '/products/data' },
          ],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },

  {
    path: 'reports',
    children: [
      {
        path: 'list',
        component: ListComponent,
        data: {
          title: 'Custom Reports List',
          requiredPermissions: [
            'list-cte-kde',
            'list-role',
            'view-subscription',
          ],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Reports', routerLink: '/reports/list' },
          ],
        },
        resolve: {
          products: AllProductsResolver,
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'view/:id',
        component: ViewComponent,
        data: {
          requiredPermissions: [
            'list-cte-kde',
            'list-role',
            'view-subscription',
          ],
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Reports', routerLink: '/reports/list' },
            {
              label: 'View',
              routerLink: '/reports/view/:id',
            },
          ],
        },
        resolve: {
          report: ReportResolver,
        },
        canActivate: [canActivateTeam],
      },
    ],
  },

  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      title: 'Settings',
      breadcrumbs: [
        { label: 'Home', routerLink: '/home' },
        { label: 'Settings', routerLink: '/settings' },
      ],
      requiredPermissions: ['list-cte-kde', 'list-role', 'view-subscription'],
    },
    canActivate: [canActivateTeam],
    children: [
      { path: '', redirectTo: 'profile-security', pathMatch: 'full' },
      {
        path: 'payment-methods',
        component: PaymentMethodsComponent,
      },
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'profile-security',
        component: ProfileSecurityComponent,
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'ctes-kdes',
        component: CtesKdesComponent,
      },
    ],
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          title: 'Home',
          breadcrumbs: [{ label: 'Home', routerLink: '/' }],
        },
      },
    ],
  },
  {
    path: 'dashboards',
    children: [
      { path: '', redirectTo: 'track-products', pathMatch: 'full' },
      {
        path: 'product-expiration',
        component: ProductExpirationComponent,
        data: {
          title: 'Product Expiration',
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Dashboards', routerLink: '/dashboards' },
            {
              label: 'Product Expiration',
              routerLink: '/dashboards/product-expiration',
            },
          ],
        },
      },
      {
        path: 'scan-statistics',
        component: ScanStatistics,
        data: {
          title: 'Scan Statistics',
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Dashboards', routerLink: '/dashboards' },
            {
              label: 'Scan Statistics',
              routerLink: '/dashboards/scan-statistics',
            },
          ],
        },
      },
      {
        path: 'track-products',
        component: TrackDashboardComponent,
        data: {
          title: 'Track Products',
          breadcrumbs: [
            { label: 'Home', routerLink: '/home' },
            { label: 'Dashboards', routerLink: '/dashboards' },
            {
              label: 'Track Products',
              routerLink: '/dashboards/track-products',
            },
          ],
        },
      },
    ],
  },
  {
    path: 'qr',
    children: [
      {
        path: 'qr-orders',
        children: [
          {
            path: 'list',
            component: QrOrderListComponent,
            data: {
              title: 'QR Order List',
              requiredPermissions: ['list-qr-order'],
              breadcrumbs: [
                { label: 'Dashboard', routerLink: '/' },
                { label: 'QR Orders', routerLink: '/qr-orders/list' },
              ],
            },
            canActivate: [canActivateTeam],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'otp',
    component: OTPPageComponent,
  },
  {
    path: 'demo',
    component: RequestDemoPageComponent,
  },
  {
    path: 'demo-success',
    component: RequestDemoSuccessComponent,
  },
  {
    path: 'accept-invitation',
    component: SignUpPageComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];
