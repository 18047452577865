<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <div>
      <span
        class="pi pi-check p-5 !text-lg rounded-full bg-primaryLight text-primary"></span>
    </div>
    <h2>Thank you for your request!</h2>
    <p>
      A member of our team will reach out shortly to schedule your personalized
      demo.
    </p>
    <p-button
      (click)="redirect()"
      label="Explore More Features"
      class="w-100" />
  </div>
</div>
