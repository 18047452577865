import { Selector } from '@ngxs/store';
import { DashboardState } from './dashboard.state';
import { DashboardStateModel } from './dashboard.model';

export class DashboardStateSelectors {
  @Selector([DashboardState])
  static getProductCteStats(state: DashboardStateModel) {
    return state.productStatistics;
  }
  @Selector([DashboardState])
  static getActivityLogs(state: DashboardStateModel) {
    return state.activities;
  }
  @Selector([DashboardState])
  static getProductChartData(state: DashboardStateModel) {
    return state.productChartData;
  }
  @Selector([DashboardState])
  static getProductExpiration(state: DashboardStateModel) {
    return state.productExpiration;
  }
  @Selector([DashboardState])
  static getQrMetrics(state: DashboardStateModel) {
    return state.qrMetrics;
  }
  @Selector([DashboardState])
  static getLocationAnalytics(state: DashboardStateModel) {
    return state.locationAnalytics;
  }
  @Selector([DashboardState])
  static getDeviceAnalytics(state: DashboardStateModel) {
    return state.deviceAnalytics;
  }
  @Selector([DashboardState])
  static getProductAnalytics(state: DashboardStateModel) {
    return state.productAnalytics;
  }
  @Selector([DashboardState])
  static getDailyProductStats(state: DashboardStateModel) {
    return state.dailyProductStats;
  }
}
