<div class="w-full p-8">
  @if (sidebar.visible && (sidebar.type === 'ADD' || sidebar.type === 'EDIT')) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      (sidebarHidden)="sidebar.visible = false; resetRecall()"
      [title]="sidebar.title"
      [widthClass]="sidebar.width">
      <app-initiate-recall-form
        [title]="sidebar.title"
        [type]="sidebar.type"
        (refresh)="getRecallList()"
        (close)="sidebar.visible = false"
        (actionSuccess)="
          sidebar.visible = false; resetRecall(); getRecallList()
        "></app-initiate-recall-form>
    </app-right-form-base>
  }
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex">
      <p-button
        id="initiate-recall-button"
        appAccess="initial-recall"
        icon="pi pi-plus"
        label="Initiate New Recall"
        (onClick)="dialogVisible = true" />
    </div>
  </div>

  <div class="mt-4">
    <app-data-table
      [allowedActions]="['DELETE', 'EDIT']"
      [first]="first"
      export="RECALL"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>

<p-dialog
  [modal]="true"
  class="bg-light"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="dialogVisible">
  <div class="text-center p-5">
    <div class="mb-4">
      <span class="pi pi-exclamation-circle text-primary !text-xl"></span>
    </div>
    <h3>
      Please ensure you follow the steps below to successfully complete the
      product recall
    </h3>
    <div class="text-left rounded p-3 bg-primary-light mb-4">
      <p>
        <span class="text-primary pi pi-check mr-2 font-bold"></span
        ><span>Related CTEs and KDEs for it</span>
      </p>
      <p>
        <span class="text-primary pi pi-check mr-2 font-bold"></span
        ><span>Add Users and assign them these CTEs</span>
      </p>
      <p>
        <span class="text-primary pi pi-check mr-2 font-bold"></span
        ><span
          >Recall Approving Authority is added within the Organization or
          not</span
        >
      </p>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <p-button
          [outlined]="true"
          icon="pi pi-times"
          label="Cancel"
          (click)="dialogVisible = false" />
        <p-button
          icon="pi pi-check"
          label="Continue"
          (click)="dialogVisible = false; openSidebar()" />
      </div>
    </div>
  </div>
</p-dialog>
