import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-video-thumbnail',
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './video-thumbnail.component.html',
  styleUrl: './video-thumbnail.component.scss',
})
export class VideoThumbnailComponent implements OnChanges, OnInit {
  @Input() videoUrl!: string | undefined;
  thumbnailUrl: string | null = null;
  isInvalidUrl: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['videoUrl'] &&
      changes['videoUrl'].currentValue !== changes['videoUrl'].previousValue &&
      this.videoUrl
    ) {
      if (this.videoUrl) {
        this.setThumbnail(this.videoUrl);
      }
    }
  }

  ngOnInit(): void {
    if (this.videoUrl) {
      this.setThumbnail(this.videoUrl);
    }
  }

  async setThumbnail(videoUrl: string): Promise<void> {
    this.thumbnailUrl = '';
    this.isInvalidUrl = false;
    this.thumbnailUrl = await this.getVideoThumbnail(videoUrl);
    this.cdRef.detectChanges();
  }

  async getVideoThumbnail(videoUrl: string): Promise<string | null> {
    try {
      this.isInvalidUrl = false;
      const url = new URL(videoUrl);
      const youtubeDomains = ['www.youtube.com', 'youtube.com', 'youtu.be'];
      const vimeoDomains = ['www.vimeo.com', 'vimeo.com'];

      if (youtubeDomains.includes(url.hostname)) {
        let videoId = null;

        // Handle YouTube Shorts
        if (url.pathname.startsWith('/shorts/')) {
          videoId = url.pathname.split('/shorts/')[1];
        }
        // Handle youtu.be short links
        else if (url.hostname === 'youtu.be') {
          videoId = url.pathname.substring(1);
        }
        // Handle regular YouTube URLs
        else if (url.hostname.includes('youtube.com')) {
          videoId = url.searchParams.get('v');
        }

        if (!videoId) {
          this.isInvalidUrl = true;
          throw new Error('YouTube Video ID not found in the URL');
        }

        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      } else if (vimeoDomains.includes(url.hostname)) {
        const vimeoIdMatch = url.pathname.match(/\/(\d+)/);
        const videoId = vimeoIdMatch ? vimeoIdMatch[1] : null;
        if (!videoId) {
          this.isInvalidUrl = true;
          throw new Error('Vimeo Video ID not found in the URL');
        }
        const vimeoApiUrl = `https://vimeo.com/api/v2/video/${videoId}.json`;
        const response = await fetch(vimeoApiUrl);
        if (!response.ok) {
          this.isInvalidUrl = true;
          throw new Error('Failed to fetch Vimeo thumbnail');
        }
        const data = await response.json();
        return data[0]?.thumbnail_large || null;
      } else {
        this.isInvalidUrl = true;
        throw new Error('Unsupported video URL');
      }
    } catch (error: any) {
      this.isInvalidUrl = true;
      console.error('Error extracting video thumbnail:', error.message);
      return null;
    }
  }
}
