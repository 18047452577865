import { Selector } from '@ngxs/store';
import { SubscriptionState } from './subscription.state';
import { SubscriptionStateModel } from './subscription.model';

export class SubscriptionStateSelectors {
  @Selector([SubscriptionState])
  static getPlans(state: SubscriptionStateModel) {
    return state.plans;
  }

  @Selector([SubscriptionState])
  static getCards(state: SubscriptionStateModel) {
    return state.cards;
  }

  @Selector([SubscriptionState])
  static isLoading(state: SubscriptionStateModel) {
    return state.isLoading;
  }
}
