<div class="flex items-center justify-center min-h-screen p-8">
  <div class="w-full max-w-2xl bg-slate-50 rounded-lg p-10 text-center">
    <div class="mt-5">
      <span class="pi pi-verified font-bold text-9xl text-green-600"></span>
    </div>
    <p class="text-2xl font-bold text-center">Plan Subscribed Successfully!</p>
    <p>
      In the meantile, with your PRO membership, you now have access to advanced
      and exciting features.
    </p>
    <p>
      Have any questions or thoughts? Feel free to shoot us an email. We are
      here to help!
    </p>

    <p-button [routerLink]="'/dashboards/analytical'">Explore More</p-button>
  </div>
</div>
