import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { ResetPasswordFormComponent } from '../../components/form-components/reset-password-form/reset-password-form.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [AuthComponent, ResetPasswordFormComponent],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {}
