import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  AddRoleProductParams,
  AddRoleResponse,
  GetRoleResponse,
  RolesResponse,
  UpdateRoleCteParams,
  UpdateRoleParams,
  UpdateRolePermissionParams,
  UpdateRoleProductParams,
} from './roles-permission.model';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class RolesPermissionService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public createRole = (): Observable<AddRoleResponse> => {
    return this.http
      .post<AddRoleResponse>(`${this.apiUrl}/api/roles-permissions/roles`, {})
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateRole = (
    id: number,
    payload: UpdateRoleParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .put<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/roles/${id}`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateRolePermission = (
    id: number,
    payload: UpdateRolePermissionParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .patch<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/permissions/${id}`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateRolePermissions = (
    id: number,
    payload: UpdateRolePermissionParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .put<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/roles/${id}/permissions`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateRoleCte = (
    id: number,
    payload: UpdateRoleCteParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .patch<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/role-cte/${id}`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateRoleProduct = (
    id: number,
    payload: UpdateRoleProductParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .patch<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/role-product/${id}`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public deleteRole = (id: number): Observable<GenericApiResponse> => {
    return this.http
      .delete<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/roles/${id}`
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public deleteRoleProduct = (id: number): Observable<GenericApiResponse> => {
    return this.http
      .delete<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/role-product/${id}`
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public addRoleProduct = (
    payload: AddRoleProductParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/roles-permissions/role-product`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getRoles = (
    paginationParams: PaginationParams
  ): Observable<RolesResponse> => {
    return this.http
      .get<RolesResponse>(`${this.apiUrl}/api/roles-permissions/roles`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getRole = (id: number): Observable<GetRoleResponse> => {
    return this.http
      .get<GetRoleResponse>(`${this.apiUrl}/api/roles-permissions/roles/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };
}
