<div class="w-full p-8">
  <div class="text-center">
    <h2 class="mb-0">Get a Plan Tailored for your Business</h2>
    <p>
      Tailored solutions for businesses of all sizes, with flexible
      subscriptions and industry-leading features.
    </p>
  </div>
  @if (isLoading$()) {
    <div class="flex justify-center items-center h-[400px]">
      <p-progressSpinner />
    </div>
  } @else {
    <div class="flex justify-evenly bg-white pt-[40px] pb-[5px] rounded">
      <ng-container *ngTemplateOutlet="featuresList" />
      @for (plan of plans$(); track plan.id) {
        <ng-container
          *ngTemplateOutlet="planColumn; context: { $implicit: plan }" />
      }
      <ng-container *ngTemplateOutlet="enterpriseColumn" />
    </div>
    <ng-container *ngTemplateOutlet="billingInfo" />
  }
</div>

<!-- Features List Template -->
<ng-template #featuresList>
  <div
    class="flex flex-col justify-between relative w-[50%] pt-[50px] right-border mt-[11rem]">
    <div *ngFor="let feature of features">
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        {{ feature }}<span *ngIf="feature === 'Traceability'"> (Products)</span>
      </p>
    </div>
    <div class="">
      <p
        class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary">
        TOP-UPS
      </p>
      <div *ngFor="let topUp of topUps">
        <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">{{ topUp }}</p>
      </div>
    </div>
  </div>
</ng-template>

<!-- Plan Column Template -->
<ng-template #planColumn let-plan>
  <div
    [class]="
      plan.hasPurchasedSubscription && plan.subscriptionStatus !== 'canceled'
        ? 'active-plan'
        : ''
    "
    class="flex flex-col justify-between relative w-[50%] pt-[40px] left-border right-border">
    <div class="pr-[40px] pl-[40px] pb-[20px]">
      <div class="flex flex-row justify-between">
        <p class="text-[13px] m-0 text-primary capitalize">
          {{ plan.productDetails.name }}
        </p>
      </div>
      <p class="text-[24px] font-bold m-0 pt-2">
        {{ plan.amount / 100 | currency }}
        <small class="font-normal text-sm"
          >/ {{ plan.interval_count > 1 ? plan.interval_count : '' }}
          {{ plan.interval }}</small
        >
      </p>
      <p class="m-0 pt-1 pb-4">
        <small class="car-p-tag-color text-[12px] font-normal">{{
          plan.metadata['line1'] || '-'
        }}</small>
      </p>
      <p-button
        class="w-100"
        [outlined]="
          plan.hasPurchasedSubscription &&
          plan.subscriptionStatus !== 'canceled'
            ? false
            : true
        "
        [label]="getLabel(plan)"
        [disabled]="getLabel(plan) === 'Canceled'"
        (click)="handleSubscriptionAction(plan)" />
    </div>

    <div *ngFor="let item of unifiedList; let idx = index">
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        @if (!item.isFeature) {
          {{ plan.metadata[item.key] || '-' }}
        } @else {
          @if (plan.metadata[item.key] === 'yes') {
            <span class="pi pi-check text-black text-[14px]"></span>
          } @else {
            -
          }
        }
      </p>
    </div>
    <div class="mt-5">
      <p
        class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary"></p>
      @for (i of [0, 1, 2]; track i) {
        <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
          @if ((plan.metadata['addOn_' + i] || '').trim() !== 'no') {
            {{ plan.metadata['addOn_' + i] }}
          } @else {
            -
          }
        </p>
      }
    </div>
  </div>
</ng-template>

<!-- Enterprise Column Template -->
<ng-template #enterpriseColumn>
  <div
    class="flex flex-col justify-between relative w-[50%] pt-[40px] pb-[40px] right-border left-border">
    <div class="pr-[40px] pl-[40px] pb-[20px]">
      <div class="flex flex-row justify-between">
        <p class="text-[13px] m-0 text-primary capitalize">Enterprise Plan</p>
      </div>
      <p class="text-[24px] font-bold m-0 pt-2">
        <small class="font-normal text-sm">Please contact Sales Team</small>
      </p>
      <p class="m-0 pt-1 pb-4">
        <small class="car-p-tag-color text-[12px] font-normal">
          Everything that have advanced QR-marketing and strategy needs
        </small>
      </p>

      <div>
        <a
          href="https://www.regenesis.ag/contact/"
          target="_blank"
          class="no-underline"
          rel="noopener noreferrer">
          <p-button
            class="w-100"
            [outlined]="true"
            label="Talk to Sales Team" />
        </a>
      </div>
    </div>
  </div>
</ng-template>

<!-- Billing Info Template -->
<ng-template #billingInfo>
  <div class="flex justify-between items-center rounded p-5 bg-white mt-5">
    <div>
      <h3>Billing Cycle process?</h3>
      <p>Bi-Annual subscription, payments deducted monthly.</p>
    </div>
    <div>
      <a
        href="https://www.regenesis.ag/contact/"
        target="_blank"
        class="no-underline"
        rel="noopener noreferrer">
        <p-button [outlined]="true" label="Know more" />
      </a>
    </div>
  </div>
</ng-template>
