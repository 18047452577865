<div class="relative h-full flex flex-col min-h-0">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h1 class="font-normal">
      Welcome to <strong>{{ orgName }}</strong>
    </h1>
    <p class="text-gray">
      Thank you for accepting invitation, fill the form following to proceed.
    </p>
  </div>
  <div class="flex-1 px-4 overflow-auto min-h-0 max-h-[40vh]">
    <form [formGroup]="form" autocomplete="off">
      <div>
        <p>First Name</p>
        <input
          type="text"
          pInputText
          formControlName="firstName"
          placeholder="James"
          class="w-full"
          [class.input-danger]="
            form.get('firstName')?.invalid && form.get('firstName')?.touched
          " />
        <app-error-message
          *ngIf="
            form.get('firstName')?.invalid && form.get('firstName')?.touched
          "
          [control]="form.get('firstName')"
          label="First Name"></app-error-message>
      </div>

      <div>
        <p>Last Name</p>
        <input
          type="text"
          pInputText
          formControlName="lastName"
          placeholder="McKenzie"
          class="w-full"
          [class.input-danger]="
            form.get('lastName')?.invalid && form.get('lastName')?.touched
          " />
        <app-error-message
          *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched"
          [control]="form.get('lastName')"
          label="Last Name"></app-error-message>
      </div>

      <div>
        <p>Email</p>
        <p-iconfield>
          <p-inputicon class="pi pi-envelope text-gray-500" />
          <input
            type="email"
            formControlName="email"
            placeholder="Ex. pat@example.com"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('email')?.invalid && form.get('email')?.touched
            " />
        </p-iconfield>
        <app-error-message
          *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
          [control]="form.get('email')"
          label="Email"></app-error-message>
      </div>

      <div>
        <p>Password</p>
        <p-iconField iconPosition="right">
          <p-inputicon class="pi pi-lock text-gray-500" />
          <input
            [type]="passwordVisibility ? 'text' : 'password'"
            formControlName="password"
            placeholder="Enter password"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('password')?.invalid && form.get('password')?.touched
            " />
          <i
            class="pi cursor-pointer absolute right-2 top-2 text-gray-500"
            [ngClass]="passwordVisibility ? 'pi-eye' : 'pi-eye-slash'"
            (click)="passwordVisibility = !passwordVisibility">
          </i>
        </p-iconField>
        <app-error-message
          *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          [control]="form.get('password')"
          label="Password"></app-error-message>
      </div>

      <div>
        <p>Confirm Password</p>
        <p-iconField iconPosition="right">
          <p-inputicon class="pi pi-lock text-gray-500" />
          <input
            [type]="confirmPasswordVisibility ? 'text' : 'password'"
            formControlName="confirmPassword"
            placeholder="Confirm your password"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('confirmPassword')?.invalid &&
              form.get('confirmPassword')?.touched
            " />
          <i
            class="pi cursor-pointer absolute right-2 top-2 text-gray-500"
            [ngClass]="confirmPasswordVisibility ? 'pi-eye' : 'pi-eye-slash'"
            (click)="confirmPasswordVisibility = !confirmPasswordVisibility">
          </i>
        </p-iconField>
        <app-error-message
          *ngIf="
            form.get('confirmPassword')?.invalid &&
            form.get('confirmPassword')?.touched
          "
          [control]="form.get('confirmPassword')"
          label="Confirm Password"></app-error-message>
      </div>

      <div class="text-xs font-normal">
        @for (validation of passwordValidations; track $index) {
          <p class="password-text flex items-center !mb-1">
            <span
              class="password-icon mr-2"
              [class.text-teal-700]="validation.valid"
              [class.text-red-600]="!validation.valid">
              @if (!validation.valid) {
                <i class="pi pi-times-circle"></i>
              } @else {
                <i class="pi pi-verified"></i>
              }
            </span>
            <span>
              {{ validation.message }}
            </span>
          </p>
        }
        <p class="password-text flex items-center !mb-1">
          <span
            class="password-icon mr-2"
            [class.text-teal-700]="
              !form.get('confirmPassword')?.hasError('mismatch')
            "
            [class.text-red-600]="
              form.get('confirmPassword')?.hasError('mismatch')
            ">
            <i
              class="pi"
              [ngClass]="{
                'pi-times-circle': form
                  .get('confirmPassword')
                  ?.hasError('mismatch'),
                'pi-verified': !form
                  .get('confirmPassword')
                  ?.hasError('mismatch'),
              }"></i>
          </span>
          <span *ngIf="form.get('confirmPassword')?.hasError('mismatch')"
            >Passwords do not match</span
          >
          <span *ngIf="!form.get('confirmPassword')?.hasError('mismatch')"
            >Passwords are matching</span
          >
        </p>
      </div>

      <div class="flex items-center gap-3">
        <p-checkbox
          [binary]="true"
          inputId="termsConditions"
          (onChange)="toggleCheckbox()" />
        <label for="termsConditions"
          >I agree to all the
          <a
            class="text-primary no-underline"
            href="https://www.regenesis.ag/terms-and-conditions/"
            target="_blank"
            >Terms & Conditions</a
          >
          and
          <a
            class="text-primary no-underline"
            href="https://www.regenesis.ag/privacy-policy/"
            target="_blank"
            >Privacy Policy</a
          ></label
        >
      </div>
    </form>
  </div>
  <div class="sticky bottom-0 z-10 p-4 bg-light">
    <p-button
      label="Sign Up"
      icon="pi pi-arrow-right"
      iconPos="right"
      class="w-100"
      (click)="signup()"
      [disabled]="!this.form.valid || !isChecked"
      [loading]="processing$()" />
  </div>
</div>
