<form [formGroup]="form" class="pt-6">
  <div class="grid grid-cols-2 gap-2">
    <div class="col-span-2">
      <div class="flex">
        <div
          class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded-lg transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary-color">
          <input
            type="file"
            class="hidden"
            id="profileImage"
            accept="image/jpeg, image/png, image/svg+xml"
            (change)="upload($event)" />
          <label
            for="profileImage"
            class="flex flex-col items-center justify-center cursor-pointer text-center">
            <i class="pi pi-upload mb-[5px]"></i>
            <p class="text-primary-color font-bold m-0">Upload Photo</p>
          </label>
        </div>
        @if (!isUpload$() && !processing$()) {
          <img
            [src]="form.get('photo')?.value ?? bucketUrl + '/images/avatar.png'"
            [alt]="user$()?.firstName"
            class="w-[150px] h-[150px] ml-[20px] object-cover" />
        } @else {
          <div
            class="flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
            <p-progressSpinner />
          </div>
        }
      </div>
    </div>
    <div>
      <p class="text-base font-normal">First Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('firstName')?.invalid && form.get('firstName')?.touched
        "
        placeholder="First Name"
        formControlName="firstName" />
      <app-error-message
        [control]="form.get('firstName')"
        label="First Name"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal">Last Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('lastName')?.invalid && form.get('lastName')?.touched
        "
        placeholder="Last Name"
        formControlName="lastName" />
      <app-error-message
        [control]="form.get('lastName')"
        label="Last Name"></app-error-message>
    </div>
    <div class="col-span-2">
      <p class="text-base font-normal">Email</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('email')?.invalid && form.get('email')?.touched
        "
        placeholder="Email"
        formControlName="email" />
      <app-error-message
        [control]="form.get('email')"
        label="Email"></app-error-message>
    </div>
  </div>
</form>

<app-right-form-footer
  [type]="type"
  [formValid]="this.form.valid && !isUpload$() && !processing$()"
  (callAction)="action()"
  [processing]="processing$()"
  (callCancel)="actionSuccess.emit(false)">
</app-right-form-footer>
