import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { Store } from '@ngxs/store';
import { SliderModule } from 'primeng/slider';
import { ProductFiltersComponent } from '../../shared-components/product-filters/product-filters.component';
import { SetFilters } from '../../../../store/recall-management-store/recall-management.actions';
import { SelectModule } from 'primeng/select';
import { DatePickerModule } from 'primeng/datepicker';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { TooltipModule } from 'primeng/tooltip';
@Component({
  selector: 'app-validation',
  standalone: true,
  imports: [
    InputSwitchModule,
    SelectModule,
    CheckboxModule,
    InputTextModule,
    ReactiveFormsModule,
    DatePickerModule,
    SliderModule,
    ProductFiltersComponent,
    ToggleSwitchModule,
    TooltipModule,
  ],
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent {
  @Input() form!: FormGroup;
  @Input() type!: string;
  @Input() formType!: string | undefined;
  @Input() options!: any[];
  @Input() charCount = 0;
  @Input() filters = {
    product: null,
    cte: null,
    kde: [],
    kdeValues: [],
  };
  @Output() remove = new EventEmitter<void>();
  fileSize = 0;
  selectedOption: any;
  constructor(private readonly store: Store) {}

  get formatDetails(): FormGroup {
    return this.form.get('format')?.get('details') as FormGroup;
  }
  onSliderChange(event: any): void {
    this.fileSize = event.value;
  }
  setFilters(event: any) {
    this.store.dispatch(new SetFilters(event));
    const detailsControl = this.form
      .get('validations')
      ?.get('linkQRSettings')
      ?.get('details');
    if (detailsControl) {
      detailsControl.setValue(event);
    }
  }
  updateCharCount(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.charCount = input.value.length;
  }
  onDropdownChange(event: any): void {
    const selectedValue = event?.value || event?.option?.value;
    this.selectedOption = this.options.find(
      option =>
        option.label === selectedValue.label &&
        option.subLabel === selectedValue.subLabel
    );
    console.log('Selected Option:', this.selectedOption);
  }
}
