<p-drawer
  #sidebarRef
  [styleClass]="widthClass"
  [(visible)]="isVisible"
  position="right"
  dismissible="false"
  [closeOnEscape]="false"
  showCloseIcon="false"
  (onHide)="sidebarHidden.emit(); clearUploadedFiles()">
  <ng-content></ng-content>
</p-drawer>
