<div class="grid grid-cols-6 gap-3">
  <div class="col-span-2 relative">
    <p-select
      [(ngModel)]="filters.productId"
      [options]="products$()"
      optionLabel="productName"
      optionValue="id"
      optionDisabled="disabled"
      placeholder="Product Passport"
      class="w-full"
      filter="true"
      filterBy="productName" />
    @if (filters.productId) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('productId')"></span>
    }
  </div>

  <div class="col-span-2 relative">
    <input
      [(ngModel)]="filters.qrCode"
      pInputText
      class="w-full"
      placeholder="QR Code" />
    @if (filters.qrCode) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('qrCode')"></span>
    }
  </div>

  <div class="col-span-1 relative">
    <p-select
      [(ngModel)]="filters.country"
      [options]="countries$()"
      optionLabel="name"
      placeholder="Country"
      class="w-full"
      (onChange)="getCities($event.value)"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      filter="true"
      filterBy="name" />
    @if (filters.country) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('country')"></span>
    }
  </div>

  <div class="col-span-1 relative">
    <p-select
      [(ngModel)]="filters.city"
      [options]="cities$()"
      optionLabel="name"
      placeholder="City"
      class="w-full"
      filter="true"
      filterBy="name" />
    @if (filters.city) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('city')"></span>
    }
  </div>

  <div class="col-span-2 relative">
    <p-select
      placeholder="Device Type"
      [(ngModel)]="filters.deviceType"
      [options]="deviceTypes"
      optionValue="value"
      optionLabel="key" />
    @if (filters.deviceType) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('deviceType')"></span>
    }
  </div>

  <div class="col-span-2 relative">
    <p-datePicker
      [(ngModel)]="filters.dateRange"
      class="w-100"
      placeholder="Date Range"
      selectionMode="range" />
    @if (filters.dateRange?.length) {
      <span
        class="pi pi-times absolute top-[8px] right-[10px] bg-white text-sm text-primary cursor-pointer"
        (click)="clearFilter('dateRange')"></span>
    }
  </div>

  <!-- Buttons -->
  <div class="flex gap-2 items-center col-span-2">
    <p-button
      class="w-100"
      icon="pi pi-refresh"
      label="Reload Data"
      [outlined]="true"
      (click)="refreshData()" />
    <p-button
      class="w-100"
      (click)="applyFilter()"
      label="Apply Filter(s)"
      icon="pi pi-check" />
  </div>
</div>
