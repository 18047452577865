import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { Product } from '../../../../store/products-store/products.model';
import {
  GetAllProducts,
  SearchProducts,
} from '../../../../store/products-store/products.actions';
import { SelectModule } from 'primeng/select';

@Component({
  selector: 'app-stack-bar-chart',
  imports: [SelectModule, CommonModule, FormsModule],
  templateUrl: './stack-bar-chart.component.html',
  styleUrl: './stack-bar-chart.component.scss',
})
export class StackBarChartComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) showFilters: boolean = true;
  @Input({ required: true }) data: T | null = null;

  public filters = {
    timeFrame: 'monthly',
    productId: null,
  };

  @Output() reload = new EventEmitter<any>();

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private legend!: am5.Legend;
  private xRenderer!: am5xy.AxisRendererX;
  private xAxis!: any;
  private yAxis!: any;

  public timeFrames = [
    {
      key: 'Weekly',
      value: 'weekly',
    },
    {
      key: 'Monthly',
      value: 'monthly',
    },
    {
      key: 'Yearly',
      value: 'yearly',
    },
  ];

  products$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getAllProducts
  );
  productStateLoading$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  constructor(private store: Store) {
    this.getAllProducts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initMap();
      }
    }
  }

  searchProducts(value: string) {
    this.filters.productId = null;
    this.store.dispatch(
      new SearchProducts({
        first: 0,
        rows: 10,
        sortBy: 'productName',
        sortOrder: 'ASC',
        filters: '',
        search: value,
      })
    );
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  initMap() {
    if (this.root) {
      this.root.dispose(); // Dispose previous chart to prevent memory leaks
    }
    this.root = am5.Root.new('stackedBarChart');
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        paddingTop: 100,
        paddingRight: 30,
        paddingBottom: 10,
        paddingLeft: 30,
        layout: this.root.verticalLayout,
      })
    );

    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p0,
        centerY: am5.p0,
        x: am5.p0,
        y: am5.percent(-12),
      })
    );
    this.xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });
    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'period',
        renderer: this.xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );
    this.xRenderer.grid.template.setAll({
      location: 1,
    });
    this.xAxis.data.setAll(this.data);
    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    this.makeSeries('QR Codes Generated', 'qrGenerated', false, '#4763E4');
    this.makeSeries('QR Codes Scanned', 'qrScanned', true, '#C0C3FF');

    this.chart.appear(1000, 100);
  }

  ngOnInit(): void {
    this.reload.emit(this.filters);
  }

  makeSeries(name: string, fieldName: string, stacked: any, color: string) {
    let series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        stacked: stacked,
        name: name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: fieldName,
        categoryXField: 'period',
        fill: am5.color(color),
        stroke: am5.color(color),
      })
    );

    series.columns.template.setAll({
      tooltipText: '{name}, {categoryX}: {valueY}',
      width: am5.percent(90),
      tooltipY: am5.percent(10),
      cornerRadiusTL: 20,
      cornerRadiusTR: 20,
      cornerRadiusBL: 20,
      cornerRadiusBR: 20,
    });

    series.columns.template.adapters.add('dy', (dy, target) => {
      if (stacked) {
        return -10; // Adds 5px spacing between stacked bars
      }
      return dy;
    });

    const validData = Array.isArray(this.data) ? this.data : [];
    series.data.setAll(validData);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();

    this.legend.data.push(series);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
