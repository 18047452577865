import {
  Component,
  effect,
  HostListener,
  Input,
  OnChanges,
  Signal,
  SimpleChanges,
} from '@angular/core';
import {
  Product,
  ProductDesignTemplatePageContent,
  ProductDesignTemplatePageModule,
  ProductDesignTemplatePageModuleDataContent,
} from '../../../../store/products-store/products.model';
import { TabViewModule } from 'primeng/tabview';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  AddProductTemplateComponent,
  AddProductTemplateModule,
  BulkUpdateProductTemplateComponents,
  BulkUpdateProductTemplateModules,
  DeleteProductTemplateComponent,
  DeleteProductTemplateModule,
  GetProduct,
  UpdateProductTemplateComponent,
  UpdateProductTemplateModule,
} from '../../../../store/products-store/products.actions';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OrderListModule } from 'primeng/orderlist';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmationService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '../../../../environments/environment';
import { User } from '../../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { NoSubscriptionBannerComponent } from '../no-subscription-banner/no-subscription-banner.component';

@Component({
  selector: 'app-product-design-template',
  standalone: true,
  imports: [
    TabViewModule,
    CommonModule,
    FormsModule,
    InputSwitchModule,
    OrderListModule,
    ColorPickerModule,
    BadgeModule,
    TooltipModule,
    ProgressSpinnerModule,
    NoSubscriptionBannerComponent,
  ],
  templateUrl: './product-design-template.component.html',
  styleUrl: './product-design-template.component.scss',
})
export class ProductDesignTemplateComponent implements OnChanges {
  @Input() id?: number;
  bucketUrl = environment.bucketUrl;
  product$: Signal<Product | null> = this.store.selectSignal(
    ProductStateSelectors.getProduct
  );
  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );
  showComponentItemOptions = false;
  componentItemOptions: any[] = [];
  pageModules: ProductDesignTemplatePageModule[] = [];
  pageContent: ProductDesignTemplatePageContent[] = [];
  selectedPageModule: ProductDesignTemplatePageModule | null = null;
  selectedComponent: ProductDesignTemplatePageModuleDataContent | null = null;
  selectedPageModuleTemplate: ProductDesignTemplatePageContent | null = null;
  selectedPageModuleTemplateComponents: any[] = [];

  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  constructor(
    private store: Store,
    private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService
  ) {
    effect(() => {
      if (this.product$()) {
        if (this.pageModules.length === 0) {
          this.pageModules =
            this.product$()!.productDesignTemplates[0].pageModules.sort(
              (a, b) => a.id - b.id
            );
          this.selectedPageModule = this.pageModules[0];
        }

        this.pageContent = [];
        this.product$()!
          .productDesignTemplates[0].pageContent.sort(
            (a, b) => a.order - b.order
          )
          .forEach(content => {
            this.pageContent.push(content);
          });
        this.selectPageModuleTemplate();
        this.componentItemOptions = [
          {
            type: 'text',
            label: 'Text',
            icon: 'pi pi-pen-to-square',
            value: { text: this.product$()?.productName },
          },
          {
            type: 'image',
            label: 'Image',
            icon: 'pi pi-image',
            value: { url: this.product$()?.logo },
          },
          {
            type: 'video',
            label: 'Video',
            icon: 'pi pi-youtube',
            value: {
              url: null,
            },
          },
          {
            type: 'link',
            label: 'Button / Link',
            icon: 'pi pi-link',
            value: { url: null, label: null },
          },
        ];
      } else {
        this.componentItemOptions = [];
        this.pageModules = [];
        this.pageContent = [];
        this.selectedPageModule = null;
        this.selectedComponent = null;
        this.selectedPageModuleTemplate = null;
        this.selectedPageModuleTemplateComponents = [];
      }
    });

    effect(() => {
      if (
        this.uploadedFiles$()[
          `productTemplateComponentImage_${this.selectedComponent?.id}`
        ] &&
        !this.uploadedFiles$()[
          `productTemplateComponentImage_${this.selectedComponent?.id}`
        ][0].uploading
      ) {
        this.updateComponent({
          ...this.selectedComponent,
          value: {
            ...this.selectedComponent?.value,
            url: this.uploadedFiles$()[
              `productTemplateComponentImage_${this.selectedComponent?.id}`
            ][0].objectURL,
          },
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['id'] &&
      this.id &&
      changes['id'].currentValue !== changes['id'].previousValue
    ) {
      this.getProduct();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.classList.contains('component-item')) {
      this.showComponentItemOptions = false;
    }
  }

  getProduct() {
    this.store.dispatch(new GetProduct(this.id!));
  }

  sortArray(array: any[] | undefined) {
    if (!array) return [];
    return array.sort((a, b) => a.order - b.order);
  }

  selectPageModuleTemplate() {
    const content = this.pageContent.filter(
      content => content.pageModuleId === this.selectedPageModule?.id
    );
    if (content.length > 0) {
      this.selectedPageModuleTemplate = content[content.length - 1];
      this.selectedPageModuleTemplateComponents = [
        ...this.selectedPageModuleTemplate?.pageModuleTemplateDataContents,
      ];
      setTimeout(() => {
        this.scrollToModule(this.selectedPageModuleTemplate!.slug);
      }, 500);
    } else {
      this.selectedPageModuleTemplate = null;
      this.selectedPageModuleTemplateComponents = [];
    }
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  editModule(contentModule: ProductDesignTemplatePageContent) {
    this.selectedPageModule =
      this.pageModules.find(m => m.id === contentModule.pageModuleId) ?? null;
    this.selectedPageModuleTemplate = contentModule;
    this.selectedPageModuleTemplateComponents = [
      ...this.selectedPageModuleTemplate?.pageModuleTemplateDataContents,
    ];
    this.scrollToModule(this.selectedPageModuleTemplate.slug);
  }

  updateModule() {
    this.store
      .dispatch(
        new UpdateProductTemplateModule(this.selectedPageModuleTemplate!.id, {
          title: this.selectedPageModuleTemplate!.title,
          order: this.selectedPageModuleTemplate!.order,
          pageModuleId: this.selectedPageModuleTemplate!.pageModuleId,
          pageModuleTemplateId:
            this.selectedPageModuleTemplate!.pageModuleTemplateId,
          styles: this.selectedPageModuleTemplate!.styles,
        })
      )
      .subscribe(() => {
        this.getProduct();
      });
  }

  scrollToModule(moduleId: string) {
    const element = document.getElementById(moduleId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  reorderModule(contentModule: any, direction: number) {
    const index = this.pageContent.findIndex(
      content => content.id === contentModule.id
    );
    if (direction > 0) {
      this.moveDownModule(index);
    }
    if (direction < 0) {
      this.moveUpModule(index);
    }
  }

  reorderComponent() {
    let order = 1;
    for (const component of this.selectedPageModuleTemplateComponents) {
      component.order = order;
      order++;
    }
    this.store
      .dispatch(
        new BulkUpdateProductTemplateComponents(
          this.selectedPageModuleTemplateComponents.map(c => {
            return {
              id: c.id,
              order: c.order,
            };
          })
        )
      )
      .subscribe(() => {
        this.getProduct();
      });
  }

  moveUpModule(index: number): void {
    if (index < this.pageContent.length) {
      const currentModule = this.pageContent[index];
      const previousModule = this.pageContent[index - 1];
      [currentModule.order, previousModule.order] = [
        previousModule.order,
        currentModule.order,
      ];
      this.store
        .dispatch(
          new BulkUpdateProductTemplateModules({
            orderUpdates: this.pageContent.map(content => {
              return {
                id: content.id,
                order: content.order,
              };
            }),
          })
        )
        .subscribe(() => {
          this.getProduct();
        });
    }
  }

  moveDownModule(index: number): void {
    if (index < this.pageContent.length - 1) {
      const currentModule = this.pageContent[index];
      const nextModule = this.pageContent[index + 1];
      [currentModule.order, nextModule.order] = [
        nextModule.order,
        currentModule.order,
      ];
      this.store
        .dispatch(
          new BulkUpdateProductTemplateModules({
            orderUpdates: this.pageContent.map(content => {
              return {
                id: content.id,
                order: content.order,
              };
            }),
          })
        )
        .subscribe(() => {
          this.getProduct();
        });
    }
  }

  deleteModule(id: number) {
    this.confirmationService.confirm({
      header: `DELETE MODULE`,
      message: `Are you sure want to delete this module?`,
      icon: 'pi pi-trash',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.pageContent = this.pageContent.filter(
          content => content.id !== id
        );
        this.store
          .dispatch(new DeleteProductTemplateModule(id))
          .subscribe(() => {
            this.getProduct();
          });
      },
    });
  }

  addModule(template: any) {
    this.store
      .dispatch(new AddProductTemplateModule(this.product$()!.id, template.id))
      .subscribe(() => {
        this.getProduct();
      });
  }

  getTemplateUsedCount(template: any) {
    return this.pageContent.filter(p => p.pageModuleTemplateId === template.id)
      .length;
  }

  updateComponent(component: any) {
    this.store
      .dispatch(
        new UpdateProductTemplateComponent(component.id, {
          label: component.label,
          order: component.order,
          type: component.type,
          value: component.value,
        })
      )
      .subscribe(() => {
        this.getProduct();
      });
  }

  addComponent(option: any) {
    this.store
      .dispatch(
        new AddProductTemplateComponent({
          label: option.label,
          pageModuleTemplateDataId: this.selectedPageModuleTemplate!.id,
          order:
            this.selectedPageModuleTemplate!.pageModuleTemplateDataContents
              .length + 1,
          type: option.type,
          value: option.value,
        })
      )
      .subscribe(() => {
        this.getProduct();
      });
  }

  deleteComponent(id: number) {
    this.confirmationService.confirm({
      header: `DELETE COMPONENT`,
      message: `Are you sure want to delete this component?`,
      icon: 'pi pi-trash',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.store
          .dispatch(new DeleteProductTemplateComponent(id))
          .subscribe(() => {
            this.getProduct();
          });
      },
    });
  }

  upload(event: any, component: any): void {
    this.selectedComponent = component;
    this.store.dispatch(new ClearUploadedFiles());
    const files = event.target.files;
    const key = `productTemplateComponentImage_${component.id}`;
    Array.from(files).forEach(async (file: any) => {
      this.store.dispatch(new GeneratePresignedUrl(file, key));
    });
  }
}
