import { PaginationParams } from '../general-store/general.model';

const CONTEXT = '[Dashboard Store]';

export class GetActivityLogs {
  static readonly type = `${CONTEXT} Get Activity Logs`;
  constructor(public payload: PaginationParams) {}
}
export class GetChartsData {
  static readonly type = `${CONTEXT} Get Dashboard Chart Data `;
  constructor(public productId: number) {}
}
export class GetCteStatistics {
  static readonly type = `${CONTEXT} Get CTE Statistics`;
  constructor(public productId: number) {}
}
export class GetProductExpiration {
  static readonly type = `${CONTEXT} Get CTE Expiration analysis data`;
  constructor(public productId: number) {}
}
