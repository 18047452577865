<div class="relative w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div>
      <p-button [outlined]="true" label="Export CSV" icon="pi pi-download" />
    </div>
  </div>
  @if (isLoading$()) {
    <div
      class="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
  <div class="grid gap-4 mt-4">
    <app-dashboard-filters-2
      (selectedFilters)="setFilters($event)"></app-dashboard-filters-2>

    <div class="grid grid-cols-4 gap-4">
      <div class="p-0 col-span-1">
        <div>
          <div class="flex items-center justify-between mt-2 mb-2">
            <h3>Statistics</h3>
          </div>
          <div class="h-[500px] flex flex-col gap-5">
            <div class="bg-white p-5 rounded flex-1 flex items-center">
              <div>
                <p class="m-0 mb-4">Total Scan Count</p>
                <h2>
                  {{ locationAnalytics$()?.totalScans }}
                </h2>
              </div>
            </div>
            <div class="bg-white p-5 rounded flex-1 flex items-center">
              <div>
                <p class="m-0 mb-4">Unique QR Codes Scanned</p>
                <h2>
                  {{ locationAnalytics$()?.totalUniqueQrCodes }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        @if (locationAnalytics$()) {
          <app-scan-map
            [data]="locationAnalytics$()!.locationStats"></app-scan-map>
        }
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <app-pie-chart
        [title]="'Device Analytics'"
        [data]="deviceAnalytics$()"></app-pie-chart>
      <app-bar-chart
        [title]="'Product Analytics'"
        [data]="productAnalytics$()"></app-bar-chart>
    </div>

    <app-line-chart
      [title]="'Daily Scan Analytics'"
      [data]="dailyProductStats$()"></app-line-chart>
  </div>
</div>
