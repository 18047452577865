import { AddKDEParams, CTE, UploadedFile } from './general.model';

const CONTEXT = '[General Store]';
export class GetCountries {
  static readonly type = `${CONTEXT} Get Countries`;
}

export class GetOrganizationTypes {
  static readonly type = `${CONTEXT} Get Organization Types`;
}

export class GetDesignations {
  static readonly type = `${CONTEXT} Get Designations`;
}

export class GetRoles {
  static readonly type = `${CONTEXT} Get Roles`;
}

export class GetOrganizationRoles {
  static readonly type = `${CONTEXT} Get Organization Roles`;
  constructor(public organizationId: number) {}
}

export class GetCities {
  static readonly type = `${CONTEXT} Get Cities`;
  constructor(public countryId: number) {}
}

export class GeneratePresignedUrl {
  static readonly type = `${CONTEXT} Get Presigned Url`;
  constructor(
    public file: File,
    public fileType: string
  ) {}
}

export class RemoveUploadedFile {
  static readonly type = '[General] Remove Uploaded File';
  constructor(public file: UploadedFile) {}
}

export class AddUploadedFile {
  static readonly type = '[General] Add Uploaded File';
  constructor(
    public file: UploadedFile,
    public fileType: string
  ) {}
}

export class ClearUploadedFilesByTypes {
  static readonly type = '[General] Clear Uploaded Files By Types';
  constructor(
    public fileType: string,
    public file: UploadedFile
  ) {}
}

export class ClearAllUploadedFilesByTypes {
  static readonly type = '[General] Clear All Uploaded Files By Types';
  constructor(public fileType: string) {}
}

export class ClearUploadedFiles {
  static readonly type = '[General] Clear Uploaded Files';
}

export class HideSideMenu {
  static readonly type = '[General] Hide Side Menu';
}

export class AppStartAction {
  static readonly type = '[General] App Start Action';
}

export class KDEInputTypes {
  static readonly type = '[General] KDE Input Types';
}

export class GetIconsList {
  static readonly type = '[General] Get Icons List';
}

export class AddKDE {
  static readonly type = `${CONTEXT} Add Organization KDE`;
  constructor(public payload: AddKDEParams) {}
}
export class UpdateKDE {
  static readonly type = `${CONTEXT} Update Organization KDE`;
  constructor(
    public id: number | undefined,
    public payload: AddKDEParams
  ) {}
}

export class DeleteKDE {
  static readonly type = `${CONTEXT} Delete Organization KDE`;
  constructor(
    public cte: CTE,
    public kdeID: number
  ) {}
}
export class GetCteStatistics {
  static readonly type = `${CONTEXT} Get CTE Statistics`;
  constructor(public productId: number) {}
}
export class ExportData {
  static readonly type = `${CONTEXT} Export Data`;
  constructor(public payload: { model: string }) {}
}

export class GetValidations {
  static readonly type = `${CONTEXT} Get KDE Validations`;
}
