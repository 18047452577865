import { Action, dispatch, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { Router } from '@angular/router';
import { QrStateModel } from './qr.model';
import { QrService } from './qr.service';
import {
  CreateQrOrder,
  DownloadQrOrder,
  GetQrOrder,
  GetQrOrderList,
} from './qr.actions';

@State<QrStateModel>({
  name: 'Qr',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    qrOrders: [],
    qrOrder: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class QrState {
  constructor(
    private qrService: QrService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  @Action(GetQrOrderList)
  getQrOrderList(
    { patchState }: StateContext<QrStateModel>,
    action: GetQrOrderList
  ) {
    patchState({ isProcessing: true });
    return this.qrService.getQrOrders(action.payload).pipe(
      tap(async res => {
        patchState({
          qrOrders: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetQrOrder)
  getQrOrder({ patchState }: StateContext<QrStateModel>, action: GetQrOrder) {
    return this.qrService.getQrOrder(action.id).pipe(
      tap(async res => {
        patchState({
          qrOrder: res.payload,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }

  @Action(CreateQrOrder)
  createQrOrder(
    { patchState }: StateContext<QrStateModel>,
    action: CreateQrOrder
  ) {
    patchState({ isProcessing: true });
    return this.qrService.createQrOder(action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'QR order created successfully'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DownloadQrOrder)
  downloadQrOrder(
    { patchState }: StateContext<QrStateModel>,
    action: DownloadQrOrder
  ) {
    patchState({ isProcessing: true });
    return this.qrService.downloadQrOder(action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'QR order download process has been initiated. Please check after a few minutes!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }
}
