import identity from "../identity.js";
import stream from "../stream.js";
import pathArea from "./area.js";
import pathBounds from "./bounds.js";
import pathCentroid from "./centroid.js";
import PathContext from "./context.js";
import pathMeasure from "./measure.js";
import PathString from "./string.js";
export default function (projection, context) {
  let digits = 3,
    pointRadius = 4.5,
    projectionStream,
    contextStream;
  function path(object) {
    if (object) {
      if (typeof pointRadius === "function") contextStream.pointRadius(+pointRadius.apply(this, arguments));
      stream(object, projectionStream(contextStream));
    }
    return contextStream.result();
  }
  path.area = function (object) {
    stream(object, projectionStream(pathArea));
    return pathArea.result();
  };
  path.measure = function (object) {
    stream(object, projectionStream(pathMeasure));
    return pathMeasure.result();
  };
  path.bounds = function (object) {
    stream(object, projectionStream(pathBounds));
    return pathBounds.result();
  };
  path.centroid = function (object) {
    stream(object, projectionStream(pathCentroid));
    return pathCentroid.result();
  };
  path.projection = function (_) {
    if (!arguments.length) return projection;
    projectionStream = _ == null ? (projection = null, identity) : (projection = _).stream;
    return path;
  };
  path.context = function (_) {
    if (!arguments.length) return context;
    contextStream = _ == null ? (context = null, new PathString(digits)) : new PathContext(context = _);
    if (typeof pointRadius !== "function") contextStream.pointRadius(pointRadius);
    return path;
  };
  path.pointRadius = function (_) {
    if (!arguments.length) return pointRadius;
    pointRadius = typeof _ === "function" ? _ : (contextStream.pointRadius(+_), +_);
    return path;
  };
  path.digits = function (_) {
    if (!arguments.length) return digits;
    if (_ == null) digits = null;else {
      const d = Math.floor(_);
      if (!(d >= 0)) throw new RangeError(`invalid digits: ${_}`);
      digits = d;
    }
    if (context === null) contextStream = new PathString(digits);
    return path;
  };
  return path.projection(projection).digits(digits).context(context);
}