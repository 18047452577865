<div class="w-full p-8">
  <div class="grid grid-cols-4 gap-4 mt-[20px]">
    <div class="stepper-bar">
      @for (step of steps; track step; let index = $index) {
        <div
          class="flex items-center w-100 stepper-bar-item cursor-pointer"
          [ngClass]="{ active: activeStep === index }">
          <i class="pi pi-circle mr-[15px]"></i>
          <p>{{ step.label }}</p>
        </div>
      }
    </div>
    <div class="col-span-3 pl-[20px]">
      @if (activeStep === 0) {
        <app-add-product-form
          [data]="data"
          [type]="type"
          [id]="id"
          (actionCreateProduct)="nextStep()"
          (actionSuccess)="success()"></app-add-product-form>
      }
      @if (activeStep === 1) {
        <app-ctes-kdes
          [isProductCtes]="true"
          [product]="product$()"
          (actionSuccess)="success()"></app-ctes-kdes>
      }
    </div>
  </div>
</div>
