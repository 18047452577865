import { Injectable } from '@angular/core';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  async initialize() {
    return await loadStripe(environment.stripeKey);
  }

  async createToken(stripe: Stripe, card: StripeCardElement) {
    return await stripe.createToken(card);
  }
}
