<div class="relative w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div>
      <p-button [outlined]="true" label="Export CSV" icon="pi pi-download" />
    </div>
  </div>
  @if (isLoading$()) {
    <div
      class="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
  <div class="grid gap-4 mt-4">
    <div>
      <div class="flex items-center justify-between mb-2">
        <h3>Recall Summary</h3>
      </div>

      <div class="grid grid-cols-3 gap-5">
        <div class="bg-white p-5 rounded flex items-center">
          <div>
            <p class="m-0 mb-4">
              Recall Initiated
              <span
                class="pi pi-circle-fill ml-5 text-yellow-300 ring-4 ring-yellow-300 ring-opacity-50 rounded-full"></span>
            </p>
            <h2>
              {{ recallProductsStats$()?.initiated }}
            </h2>
          </div>
        </div>
        <div class="bg-white p-5 rounded flex items-center">
          <div>
            <p class="m-0 mb-4">
              Completed
              <span
                class="pi pi-circle-fill ml-5 text-green-600 ring-4 ring-green-300 ring-opacity-50 rounded-full"></span>
            </p>
            <h2>
              {{ recallProductsStats$()?.completed }}
            </h2>
          </div>
        </div>
        <div class="bg-white p-5 rounded flex items-center">
          <div>
            <p class="m-0 mb-4">
              Rejected
              <span
                class="pi pi-circle-fill ml-5 text-red-600 ring-4 ring-red-300 ring-opacity-50 rounded-full"></span>
            </p>
            <h2>
              {{ recallProductsStats$()?.rejected }}
            </h2>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <app-dashboard-filters
          [allowedFilters]="['RECALL']"
          (selectedFilters)="setFilters($event)"></app-dashboard-filters>
      </div>

      <div class="relative">
        <div>
          <div class="grid grid-cols-4 gap-3">
            <div class="col-span-1">
              <div>
                <div class="flex items-center justify-between mt-2 mb-2">
                  <h3>Recall Items</h3>
                </div>
                <div class="h-[500px] flex flex-col gap-5">
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        Active
                        <span
                          class="pi pi-circle-fill ml-5 text-yellow-300 ring-4 ring-yellow-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ recallProductsAnalysis$()?.items?.active }}
                      </h2>
                    </div>
                  </div>
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        In Transit
                        <span
                          class="pi pi-circle-fill ml-5 text-green-600 ring-4 ring-green-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ recallProductsAnalysis$()?.items?.inTransit }}
                      </h2>
                    </div>
                  </div>
                  <div class="bg-white p-5 rounded flex-1 flex items-center">
                    <div>
                      <p class="m-0 mb-4">
                        Cleared
                        <span
                          class="pi pi-circle-fill ml-5 text-red-600 ring-4 ring-red-300 ring-opacity-50 rounded-full"></span>
                      </p>
                      <h2>
                        {{ recallProductsAnalysis$()?.items?.cleared }}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              @if (recallProductsAnalysis$()) {
                <app-scan-map
                  [data]="
                    recallProductsAnalysis$()!.recallScans
                  "></app-scan-map>
              }
            </div>
          </div>

          <div class="grid grid-cols-2 gap-3">
            <div class="col-span-2">
              @if (
                recallProductsAnalysis$() && recallProductsAnalysis$()?.data
              ) {
                <app-recall-gantt-chart
                  [dataset]="
                    recallId !== 0 ? recallProductsAnalysis$()!.data : []
                  "
                  [overlay]="true"></app-recall-gantt-chart>
              }
            </div>
            <div class="col-span-2">
              @if (
                recallProductsAnalysis$() && recallProductsAnalysis$()?.data
              ) {
                <app-recall-cluster-columns
                  [dataset]="
                    recallId !== 0 ? recallProductsAnalysis$()!.data : []
                  "
                  [overlay]="true"></app-recall-cluster-columns>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
