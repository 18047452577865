<div>
  <div class="flex items-center justify-between mt-2 mb-2">
    <h3>{{ title }}</h3>
  </div>
  <div
    class="bg-white rounded w-full h-[50vh] p-5 overflow-hidden relative flex items-center justify-center">
    @if (noData) {
      <div class="absolute inset-0 flex items-center justify-center">
        <div
          class="bg-primary text-white text-sm font-bold px-4 py-2 rounded shadow-lg">
          No data to display
        </div>
      </div>
    }
    <div id="lineChart" class="w-full h-full"></div>
  </div>
</div>
