<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form" class="pt-6 full-height-form">
      <div class="grid grid-cols-2 gap-4">
        <div>
          <p class="text-base font-normal">First Name</p>
          <input
            type="text"
            pInputText
            formControlName="firstName"
            placeholder="James"
            class="w-full"
            [class.input-danger]="
              form.get('firstName')?.invalid && form.get('firstName')?.touched
            " />
          <app-error-message
            [control]="form.get('firstName')"
            label="First Name"></app-error-message>
        </div>

        <div>
          <p class="text-base font-normal">Last Name</p>
          <input
            type="text"
            pInputText
            formControlName="lastName"
            placeholder="McKenzie"
            class="w-full"
            [class.input-danger]="
              form.get('lastName')?.invalid && form.get('lastName')?.touched
            " />
          <app-error-message
            [control]="form.get('lastName')"
            label="Last Name"></app-error-message>
        </div>

        <div class="col-span-2">
          <p class="text-base font-normal">Email</p>
          <input
            type="email"
            formControlName="email"
            placeholder="example@example.com"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('email')?.invalid && form.get('email')?.touched
            " />
          <app-error-message
            [control]="form.get('email')"
            label="Email"></app-error-message>
        </div>

        <div class="col-span-2">
          <p class="text-base font-normal">Select Role</p>
          <p-multiSelect
            [options]="roles$()"
            formControlName="roleIds"
            optionLabel="name"
            optionValue="id"
            placeholder="Select User Role"
            class="!w-full"
            [class.input-danger]="
              form.get('roleIds')?.invalid && form.get('roleIds')?.touched
            ">
            <ng-template let-role pTemplate="item">
              <span>{{ role.name | textTransform }}</span>
            </ng-template></p-multiSelect
          >
          <app-error-message
            [control]="form.get('roleId')"
            label="Role"></app-error-message>
        </div>
      </div>
    </form>
  </div>
</div>
