<div class="flex justify-start sticky top-0">
  <div class="card qr-container">
    @if (!product) {
      <p class="text-center">Select a product</p>
    } @else {
      <div class="flex items-center">
        <div class="mr-[10px]">
          <img class="product-logo" [src]="product.logo" alt="Product Logo" />
        </div>
        <div>
          <p class="m-[0px]">{{ product.productName }}</p>
          <p class="m-[0px]">
            <small>{{ product.productDescription }}</small>
          </p>
        </div>
      </div>
      <div class="text-center mt-[30px]">
        @if (ctaConfig?.ctaLabelPosition === 'TOP') {
          <p>{{ ctaConfig?.ctaLabelText }}</p>
        }
        <ngx-qrcode-styling [config]="qrConfig"></ngx-qrcode-styling>
        @if (ctaConfig?.ctaLabelPosition === 'BOTTOM') {
          <p>{{ ctaConfig?.ctaLabelText }}</p>
        }
      </div>
      <div class="flex items-center mt-[30px]">
        <p class="!mr-[10px] font-bold">Powered by reGenesis</p>
        <img
          class="w-[30px]"
          [src]="bucketUrl + '/images/qrlogo.png'"
          alt="Regensis Logo" />
      </div>
    }
  </div>
</div>
