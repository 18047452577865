<div class="sticky top-0 z-50 pt-5 bg-light">
  <app-right-form-header
    [processing]="processing$()"
    [disabled]="lineChartForm.invalid"
    [title]="title"
    [actionLabel]="'Generate'"
    (callCancel)="actionSuccess.emit(false)"
    (callAction)="action()"></app-right-form-header>
</div>

<app-graph-list></app-graph-list>

<form [formGroup]="lineChartForm" class="full-height-form mt-5 mb-5">
  <div class="grid grid-cols-2 gap-x-4 gap-y-4">
    <div class="col-span-2">
      <p>Chart Title</p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g. Stock Quantity by Product"
        formControlName="title"
        [class.input-danger]="
          lineChartForm.get('title')?.invalid &&
          lineChartForm.get('title')?.touched
        " />
      <app-error-message
        *ngIf="
          lineChartForm.get('title')?.invalid &&
          lineChartForm.get('title')?.touched
        "
        [control]="lineChartForm.get('title')"
        label="Chart Title"></app-error-message>
    </div>

    <div class="col-span-2">
      <p>Chart Description</p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g. This chart displays ..."
        formControlName="description"
        [class.input-danger]="
          lineChartForm.get('description')?.invalid &&
          lineChartForm.get('description')?.touched
        " />
      <app-error-message
        *ngIf="
          lineChartForm.get('description')?.invalid &&
          lineChartForm.get('description')?.touched
        "
        [control]="lineChartForm.get('description')"
        label="Chart Description"></app-error-message>
    </div>

    <div>
      <p>X-Axis (Time-Based)</p>
      <p-select
        formControlName="xAxis"
        [options]="xAxisOptions"
        optionLabel="title"
        placeholder="e.g., Processing Date YYYY-MM-DD"
        class="w-full"
        [class.input-danger]="
          lineChartForm.get('xAxis')?.invalid &&
          lineChartForm.get('xAxis')?.touched
        " />
      <app-error-message
        *ngIf="
          lineChartForm.get('xAxis')?.invalid &&
          lineChartForm.get('xAxis')?.touched
        "
        [control]="lineChartForm.get('xAxis')"
        label="X-Axis"></app-error-message>
    </div>

    <div>
      <p>Y-Axis (Numeric)</p>
      <p-select
        formControlName="yAxis"
        [options]="yAxisOptions"
        optionLabel="title"
        placeholder="e.g., Temperature (°C) Range: 0-100°C"
        class="w-full"
        [class.input-danger]="
          lineChartForm.get('yAxis')?.invalid &&
          lineChartForm.get('yAxis')?.touched
        " />
      <app-error-message
        *ngIf="
          lineChartForm.get('yAxis')?.invalid &&
          lineChartForm.get('yAxis')?.touched
        "
        [control]="lineChartForm.get('yAxis')"
        label="Y-Axis"></app-error-message>
    </div>

    <div class="col-span-2">
      <p class="font-bold text-md">Additional Features</p>
    </div>

    <div>
      <p>Line Customization</p>
      <p-select
        formControlName="lineStyle"
        [options]="lineStyleOptions"
        placeholder="e.g., Solid, Dashed, Dotted"
        class="w-full" />
    </div>

    <div>
      <p>Line Color</p>
      <input type="color" formControlName="lineColor" class="w-full" />
    </div>

    <div>
      <p>Show Legends</p>
      <p-select
        formControlName="showLegend"
        [options]="showLegend"
        placeholder="e.g. true, false"
        class="w-full" />
    </div>
  </div>
</form>
