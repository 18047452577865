import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { SignUpComponent } from '../../components/form-components/sign-up/sign-up.component';

@Component({
  selector: 'app-sign-up-page',
  standalone: true,
  imports: [AuthComponent, SignUpComponent],
  templateUrl: './sign-up-page.component.html',
})
export class SignUpPageComponent {}
