<div class="w-full p-8 bg-[#FAFAFA]">
  <div class="grid grid-cols-4">
    <div class="col-span-3">
      <div class="flex items-center justify-between mb-5">
        <h3>Track & Trace Dashboard</h3>
        <p-button [outlined]="true"
          ><span class="pi pi-download mr-3 pl-4"></span
          ><span class="pr-4">Export CSV</span></p-button
        >
      </div>

      <app-dashboard-filters
        (selectedFilters)="setFilters($event)"></app-dashboard-filters>
      <app-track-and-trace [data]="locationAnalytics$()"></app-track-and-trace>

      <div class="grid grid-cols-2 gap-4">
        <app-pie-chart
          [title]="'Device Analytics'"
          [data]="deviceAnalytics$()"></app-pie-chart>
        <app-bar-chart
          [title]="'Product Analytics'"
          [data]="productAnalytics$()"></app-bar-chart>
      </div>

      <app-line-chart
        [title]="'Daily Scan Analytics'"
        [data]="dailyProductStats$()"></app-line-chart>
    </div>
    <div>
      <!-- Activity Logs -->
      <app-activity-feed></app-activity-feed>
    </div>
  </div>
</div>
