import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { ProductStateSelectors } from './products.selectors';
import { GetAllProducts } from './products.actions';

export const AllProductsResolver: ResolveFn<any> = async () => {
  const store = inject(Store);
  let data = await firstValueFrom(
    store.select(ProductStateSelectors.getAllProducts)
  );
  if (!data || data.length === 0) {
    await firstValueFrom(store.dispatch(new GetAllProducts()));
    data = await firstValueFrom(
      store.select(ProductStateSelectors.getAllProducts)
    );
  }

  return data;
};
