import { Component, Signal } from '@angular/core';
import { Plan } from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Store } from '@ngxs/store';
import {
  CancelSubscription,
  GetPlans,
} from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [ButtonModule, RouterModule, ProgressSpinnerModule],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.scss',
})
export class PlansComponent {
  pageTitle = '';
  plans$: Signal<Plan[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getPlans
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.getPlans();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  isSubscribedPlan(plan: Plan): boolean {
    if (this.user$()?.currentPlan && plan.id === this.user$()?.currentPlan.id) {
      return true;
    } else {
      return false;
    }
  }

  cancelSubscription() {
    if (this.user$()?.subscriptionStatus === 'active') {
      this.confirmationService.confirm({
        header: `CANCEL SUBSCRIPTION`,
        message: `Are you sure want to cancel the subscription?`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Yes, proceed',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store
            .dispatch(
              new CancelSubscription(this.user$()!.subscriptionId.toString())
            )
            .subscribe(() => {
              window.location.href = '/subscription/plans';
            });
        },
      });
    }
  }

  switchSubscription(plan: Plan) {
    if (this.user$()?.currentPlan) {
      this.confirmationService.confirm({
        header: `SWITCH SUBSCRIPTION`,
        message: `Are you sure want to switch to the new plan?`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Yes, proceed',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-primary',
        accept: () => {
          this.router.navigateByUrl('/subscription/plans/subscribe/' + plan.id);
        },
      });
    } else {
      this.router.navigateByUrl('/subscription/plans/subscribe/' + plan.id);
    }
  }

  getLabel(plan: Plan) {
    if (this.user$()?.currentPlan === null) {
      return 'Subscribe';
    } else if (
      this.user$()?.subscriptionStatus === 'canceled' &&
      this.user$()?.currentPlan.id === plan.id
    ) {
      return 'Canceled';
    } else {
      if (plan.amount > this.user$()!.currentPlan.amount) {
        return 'Upgrade Subscription';
      } else if (plan.amount < this.user$()!.currentPlan.amount) {
        return 'Downgrade Subscription';
      } else {
        return 'Cancel Subscription';
      }
    }
  }
}
