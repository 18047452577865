<div>
  <div class="flex items-center justify-between mt-5 mb-5">
    <h3>{{ title }}</h3>
    <div class="flex gap-3 items-center" *ngIf="showFilters">
      <p-dropdown
        #dropdown
        [(ngModel)]="filters.productId"
        [options]="searchedProducts$()"
        optionLabel="productName"
        optionValue="id"
        optionDisabled="disabled"
        placeholder="Search Products"
        filterPlaceholder="Type here to search product"
        [emptyFilterMessage]="
          productStateLoading$() ? 'Loading...' : 'No matching products found'
        "
        emptyMessage="---"
        class="w-full"
        [virtualScroll]="true"
        [itemSize]="30"
        filter="true"
        filterBy="productName"
        (onFilter)="searchProducts($event.filter)"
        [style.width]="'300px'"
        (onChange)="this.reload.emit(filters)" />
      <p-dropdown
        [options]="timeFrames"
        [(ngModel)]="filters.timeFrame"
        optionValue="value"
        optionLabel="key"
        (onChange)="this.reload.emit(filters)" />
    </div>
  </div>
  <div class="bg-white rounded-xl w-100 h-[75vh]" id="stackedBarChart"></div>
</div>
