import {
  Component,
  effect,
  EventEmitter,
  Output,
  Signal,
  OnDestroy,
} from '@angular/core';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Store } from '@ngxs/store';
import { Card } from '../../../../store/subscription-store/subscription.model';
import {
  AddCard,
  DeleteCard,
  GetCards,
  SetCardAsDefault,
} from '../../../../store/subscription-store/subscription.actions';
import { ConfirmationService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { StripeService } from '../../../services/stripe.service';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { NotificationService } from '../../../services/notification.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-cards-list',
  standalone: true,
  imports: [
    RadioButtonModule,
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './cards-list.component.html',
  styleUrl: './cards-list.component.scss',
})
export class CardsListComponent implements OnDestroy {
  @Output() outputSelectedCard = new EventEmitter<Card>();
  cards$: Signal<Card[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getCards
  );
  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  // Initialize with defaults to prevent undefined issues
  isValidCard: boolean = true;
  isLoading: boolean = false;
  selectedCard: Card | null = null;
  showCardDetailsForm: boolean = false;
  bucketUrl: string = environment.bucketUrl;
  stripe: Stripe | null = null;
  card: StripeCardElement | undefined;

  // Initialize an empty form
  cardDetailsForm: FormGroup = this.fb.group({
    nameOnCard: [''],
  });

  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.getCards();

    effect(() => {
      if (this.cards$().length > 0) {
        this.selectedCard = this.cards$().find(c => c.isDefault) ?? null;
        if (this.selectedCard) {
          this.outputSelectedCard.emit(this.selectedCard);
        }
      }
    });
  }

  ngOnDestroy() {
    this.cleanupStripeElements();
  }

  getCards() {
    this.store.dispatch(new GetCards());
  }

  initForm() {
    // Get user's name
    const firstName = this.user$()?.firstName || '';
    const lastName = this.user$()?.lastName || '';
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

    // Create the form
    this.cardDetailsForm = this.fb.group({
      nameOnCard: [fullName, [Validators.required, validNameValidator(3)]],
    });
  }

  createPaymentMethod() {
    if (this.card && this.cardDetailsForm.valid) {
      this.isLoading = true;
      this.stripe
        ?.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.cardDetailsForm.get('nameOnCard')?.value,
          },
        })
        .then(pm => {
          if (pm && pm.paymentMethod) {
            this.store
              .dispatch(
                new AddCard({
                  cardToken: pm.paymentMethod.id,
                })
              )
              .subscribe({
                next: () => {
                  this.getCards();
                  this.notificationService.openSuccessToast(
                    'New card added successfully!'
                  );
                  this.isLoading = false;
                  this.showCardDetailsForm = false;
                  this.cleanupStripeElements();
                },
                error: err => {
                  this.isLoading = false;
                  console.error('Error adding card:', err);
                },
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error('Error creating payment method:', error);
        });
    }
  }

  initializeStripe() {
    this.isValidCard = false;
    setTimeout(() => {
      this.stripeService.initialize().then(stripe => {
        this.stripe = stripe;
        // Cleanup any existing card element before creating a new one
        this.cleanupStripeElements();

        // Create and mount the card element
        this.card = stripe?.elements().create('card');
        const cardElement = document.getElementById('card-element');
        if (this.card && cardElement) {
          this.card.mount('#card-element');
          this.card.on('change', event => {
            this.isValidCard = event.complete;
          });
        }
      });
    }, 500);
  }

  cleanupStripeElements() {
    if (this.card) {
      try {
        this.card.unmount();
      } catch (e) {
        // Silently handle unmounting errors
      }
      this.card = undefined;
    }
  }

  toggleCardDetailsForm() {
    if (this.showCardDetailsForm) {
      // If form is showing, try to save the card
      if (this.cardDetailsForm.valid && this.isValidCard) {
        this.createPaymentMethod();
      }
    } else {
      // If form is hidden, show it and initialize everything
      this.showCardDetailsForm = true;
      this.initForm();
      this.initializeStripe();
    }
  }

  // This function determines if the Save Card button should be disabled
  isSaveCardDisabled(): boolean {
    return (
      this.isLoading$() ||
      this.isLoading ||
      !this.isValidCard ||
      (this.showCardDetailsForm &&
        this.cardDetailsForm.get('nameOnCard')?.invalid === true)
    );
  }

  deleteCard(card: Card) {
    this.confirmationService.confirm({
      header: `DELETE`,
      message: `Are you sure you want to delete this card?`,
      icon: 'pi pi-trash',
      acceptLabel: 'Yes, delete',
      acceptIcon: 'pi',
      acceptButtonStyleClass: 'p-button-danger',
      rejectVisible: false,
      accept: () => {
        this.store.dispatch(new DeleteCard(card.id)).subscribe(() => {
          this.getCards();
        });
      },
    });
  }

  setCardAsDefault(card: Card) {
    this.confirmationService.confirm({
      header: `SET CARD AS DEFAULT`,
      message: `Are you sure you want to set this card as default for your upcoming payments!`,
      icon: 'pi pi-exclamation-circle',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      acceptButtonStyleClass: 'p-button-primary',
      rejectVisible: false,
      accept: () => {
        this.store.dispatch(new SetCardAsDefault(card.id)).subscribe(() => {
          this.getCards();
        });
      },
      reject: () => {
        this.selectedCard = this.cards$().find(c => c.isDefault) ?? null;
        if (this.selectedCard) {
          this.outputSelectedCard.emit(this.selectedCard);
        }
      },
    });
  }
}

export function validNameValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const trimmedValue = value.replace(/\s+/g, '');
    const hasSpecialCharacters = /[^a-zA-Z]/.test(trimmedValue);

    if (hasSpecialCharacters) {
      return { invalidCharacters: true };
    }

    if (trimmedValue.length < minLength) {
      return {
        minLengthExcludingSpaces: {
          requiredLength: minLength,
          actualLength: trimmedValue.length,
        },
      };
    }

    return null;
  };
}
