/**
 * From https://github.com/pixijs/pixi.js/blob/3dd0ff9a935f0bc13a09aefff9eb2872f02c51b9/packages/canvas/canvas-renderer/src/utils/mapCanvasBlendModesToPixi.ts#L13
 */
export var BlendMode;
(function (BlendMode) {
  BlendMode["ADD"] = "lighter";
  BlendMode["COLOR"] = "color";
  BlendMode["COLOR_BURN"] = "color-burn";
  BlendMode["COLOR_DODGE"] = "color-dodge";
  BlendMode["DARKEN"] = "darken";
  BlendMode["DIFFERENCE"] = "difference";
  BlendMode["DST_OVER"] = "destination-over";
  BlendMode["EXCLUSION"] = "exclusion";
  BlendMode["HARD_LIGHT"] = "hard-light";
  BlendMode["HUE"] = "hue";
  BlendMode["LIGHTEN"] = "lighten";
  BlendMode["LUMINOSITY"] = "luminosity";
  BlendMode["MULTIPLY"] = "multiply";
  BlendMode["NORMAL"] = "source-over";
  BlendMode["OVERLAY"] = "overlay";
  BlendMode["SATURATION"] = "saturation";
  BlendMode["SCREEN"] = "screen";
  BlendMode["SOFT_LIGHT"] = "soft-light";
  BlendMode["SRC_ATOP"] = "source-atop";
  BlendMode["XOR"] = "xor";
})(BlendMode || (BlendMode = {}));
