import { Selector } from '@ngxs/store';
import { RecallManagementState } from './recall-management.store';
import { RecallManagementStateModel } from './recall-management.model';

export class RecallManagementStateSelectors {
  @Selector([RecallManagementState])
  static getAllRecalls(state: RecallManagementStateModel) {
    return state.allRecalls;
  }

  @Selector([RecallManagementState])
  static getRecalls(state: RecallManagementStateModel) {
    return state.recalls;
  }

  @Selector([RecallManagementState])
  static getRecallUsers(state: RecallManagementStateModel) {
    return state.recallUsers;
  }

  @Selector([RecallManagementState])
  static getRecallRequests(state: RecallManagementStateModel) {
    return state.recallRequests;
  }

  @Selector([RecallManagementState])
  static getRecall(state: RecallManagementStateModel) {
    return state.recall;
  }

  @Selector([RecallManagementState])
  static getPaginationData(state: RecallManagementStateModel) {
    return state.pagination;
  }

  @Selector([RecallManagementState])
  static getRecallUsersPaginationData(state: RecallManagementStateModel) {
    return state.recallUsersPagination;
  }

  @Selector([RecallManagementState])
  static isProcessing(state: RecallManagementStateModel) {
    return state.isProcessing;
  }

  @Selector([RecallManagementState])
  static getHeaders(state: RecallManagementStateModel) {
    return state.headers;
  }

  @Selector([RecallManagementState])
  static getRecallUsersHeaders(state: RecallManagementStateModel) {
    return state.recallUsersHeaders;
  }

  @Selector([RecallManagementState])
  static getFilters(state: RecallManagementStateModel) {
    return state.recallFilters;
  }

  @Selector([RecallManagementState])
  static countRecallItems(state: RecallManagementStateModel) {
    return state.countRecallItems;
  }
}
