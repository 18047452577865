import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {
  Header,
  Pagination,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { ProductItemData } from '../../../../store/products-store/products.model';
import { ButtonModule } from 'primeng/button';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import {
  DeleteProductItemData,
  GetProductItemDataList,
  ResetHeaders,
} from '../../../../store/products-store/products.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'app-product-item-data-list',
  standalone: true,
  imports: [ButtonModule, DataTableComponent, BreadcrumbModule],
  templateUrl: './product-item-data-list.component.html',
  styleUrl: './product-item-data-list.component.scss',
})
export class ProductItemDataListComponent implements OnInit, OnDestroy {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type
  selectedFilters: Record<string, any> = {};

  data$: Signal<ProductItemData[]> = this.store.selectSignal(
    ProductStateSelectors.getProductItemData
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    ProductStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    ProductStateSelectors.getProductDataHeaders
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
    this.getProductItemDataList();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.store.dispatch(new ResetHeaders());
  }

  getProductItemDataList() {
    this.store.dispatch(
      new GetProductItemDataList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getProductItemDataList();
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} PRODUCT ITEM DATA`,
        message: `Are you sure want to delete this product item data?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store
            .dispatch(new DeleteProductItemData(action.id))
            .subscribe(() => {
              this.getProductItemDataList();
            });
        },
      });
    }
  }
}
