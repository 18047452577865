<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
  </div>

  @if (!isLoading$()) {
    <div class="flex mt-10 gap-5 items-stretch justify-evenly">
      @for (plan of plans$(); track plan) {
        @if (!isSubscribedPlan(plan)) {
          <div
            class="flex flex-col justify-between relative w-[50%] p-9 bg-slate-50 rounded-lg">
            <div>
              <p class="text-2xl font-bold m-0">
                {{ plan.productDetails.name }}
              </p>
              <p>{{ plan.productDetails.description }}</p>
              <p class="text-5xl font-bold">
                ${{ plan.amount / 100
                }}<sub><small class="font-normal text-sm">/month</small></sub>
              </p>

              <ul class="mb-9">
                @for (
                  feature of plan.productDetails.marketing_features;
                  track feature
                ) {
                  <li>{{ feature.name }}</li>
                }
              </ul>
            </div>
            <div>
              <button
                pButton
                [label]="getLabel(plan)"
                (click)="switchSubscription(plan)"
                class="p-button-primary rounded-md w-[100%]"></button>
            </div>
          </div>
        } @else {
          <div
            class="flex flex-col justify-between shadow-primary-color-200 relative bottom-5 w-[50%] p-9 bg-primary-color rounded-lg text-white">
            <div>
              <p class="text-2xl font-bold m-0">
                {{ plan.productDetails.name }}
              </p>
              <p>{{ plan.productDetails.description }}</p>
              <p class="text-5xl font-bold">
                ${{ plan.amount / 100
                }}<sub><small class="font-normal text-sm">/month</small></sub>
              </p>

              <ul class="mb-9">
                @for (
                  feature of plan.productDetails.marketing_features;
                  track feature
                ) {
                  <li>{{ feature.name }}</li>
                }
              </ul>
            </div>
            <div>
              <button
                pButton
                [label]="getLabel(plan)"
                (click)="cancelSubscription()"
                class="p-button-outlined bg-white text-primary-color rounded-md w-[100%]"></button>
            </div>
          </div>
        }
      }
    </div>
  } @else {
    <div class="flex items-center justify-center w-[100%] h-[50vh]">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
</div>
