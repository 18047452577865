import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { ReportStateSelectors } from './report.selectors';
import { GetReport, GetReportList } from './report.actions';
import { PaginationParams } from '../general-store/general.model';
import { ObjectToUrlParams } from '../../app/services/global.service';
import { Report } from './report.model';

export const ReportListResolver: ResolveFn<any> = async () => {
  const store = inject(Store);

  let data = await firstValueFrom(
    store.select(ReportStateSelectors.getReports)
  );

  if (!data || data.length === 0) {
    const pagination: PaginationParams = {
      first: 0,
      rows: 5,
      sortBy: 'createdAt',
      sortOrder: 'ASC',
      search: '',
      filters: ObjectToUrlParams({}),
    };
    await firstValueFrom(store.dispatch(new GetReportList(pagination)));
    data = await firstValueFrom(store.select(ReportStateSelectors.getReports));
  }

  return data;
};

export const ReportResolver: ResolveFn<any> = async (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const id = Number(route.paramMap.get('id')); // Get the ID from the route parameter

  if (!id) {
    throw new Error('Report ID is required');
  }

  let data = await firstValueFrom(
    store.select(ReportStateSelectors.getReport) // Assuming you have a selector to get a report by ID
  );

  if (!data) {
    await firstValueFrom(store.dispatch(new GetReport(id))); // Dispatch action with the ID
    data = await firstValueFrom(store.select(ReportStateSelectors.getReport));
  }

  return data;
};
