import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { OtpComponent } from '../../components/form-components/otp/otp.component';

@Component({
  selector: 'app-otppage',
  standalone: true,
  imports: [AuthComponent, OtpComponent],
  templateUrl: './otppage.component.html',
})
export class OTPPageComponent {}
