import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-demo-request-success',
  imports: [ButtonModule],
  templateUrl: './demo-request-success.component.html',
  styleUrl: './demo-request-success.component.scss',
})
export class DemoRequestSuccessComponent {
  mainSite = environment.mainSite;

  redirect() {
    window.location.href = this.mainSite;
  }
}
