<div class="w-100 bg-white rounded">
  <div class="flex items-center justify-start gap-2">
    <div
      class="m-3 border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-dark">
      <p class="font-bold mr-3">Filters</p>
    </div>

    @if (allowedFilters.includes('RECALL')) {
      @if (!filters.recall) {
        <div
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-select
            [options]="recalls$()"
            [(ngModel)]="filters.recall"
            optionLabel="recallName"
            [filter]="true"
            filterBy="recallName"
            placeholder="Select Recall"
            class="w-full md:w-56"
            (ngModelChange)="applyFilter()">
            <ng-template let-recall #item>
              <div class="flex items-center gap-2">
                <div>{{ recall?.recallName }}</div>
              </div>
            </ng-template>
          </p-select>
        </div>
      } @else {
        <div
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">Recall</div>
              <div class="mr-3 font-bold">
                <small>{{ filters.recall.recallName }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="filters.recall = null; applyFilter()"></span>
            </div>
          </div>
        </div>
      }
    }

    @if (allowedFilters.includes('PRODUCT')) {
      @if (!filters.product) {
        <div
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-select
            [options]="products$()"
            [(ngModel)]="filters.product"
            optionLabel="productName"
            [filter]="true"
            filterBy="productName"
            placeholder="Product Passport"
            class="w-full md:w-56"
            (ngModelChange)="applyFilter()">
            <ng-template let-product #item>
              <div class="flex items-center gap-2">
                <div>{{ product?.productName }}</div>
              </div>
            </ng-template>
          </p-select>
        </div>
      } @else {
        <div
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">Product Passport</div>
              <div class="mr-3 font-bold">
                <small>{{ filters.product.productName }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="
                  filters.product = null;
                  filters.dateRange = [];
                  filters.cte = null;
                  filters.kde = null;
                  filters.startDate = null;
                  filters.endDate = null;
                  applyFilter()
                "></span>
            </div>
          </div>
        </div>
      }
    }

    @if (allowedFilters.includes('DATE_RANGE')) {
      @if (!filters.startDate || !filters.endDate) {
        <div
          *ngIf="filters.product"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-datePicker
            [(ngModel)]="filters.dateRange"
            [showIcon]="false"
            placeholder="Date Range"
            selectionMode="range"
            (ngModelChange)="setDateFilter($event)"
            [readonlyInput]="true"
            (ngModelChange)="applyFilter()" />
        </div>
      } @else {
        <div
          *ngIf="filters.product"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">Date Range</div>
              <div class="mr-3 font-bold">
                <small>{{ filters.startDate }} - {{ filters.endDate }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="
                  filters.dateRange = [];
                  filters.startDate = null;
                  filters.endDate = null;
                  filters.cte = null;
                  filters.kde = null;
                  applyFilter()
                "></span>
            </div>
          </div>
        </div>
      }
    }

    @if (allowedFilters.includes('CTE')) {
      @if (!filters.cte) {
        <div
          *ngIf="filters.product && filters.startDate && filters.endDate"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-select
            [options]="filters!.product!.productCtes"
            [(ngModel)]="filters.cte"
            optionLabel="title"
            [filter]="true"
            filterBy="title"
            placeholder="CTE"
            class="w-full md:w-56"
            (ngModelChange)="applyFilter()">
            <ng-template let-cte #item>
              <div class="flex items-center gap-2">
                <div>{{ cte?.title }}</div>
              </div>
            </ng-template>
          </p-select>
        </div>
      } @else {
        <div
          *ngIf="filters.product && filters.startDate && filters.endDate"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">CTE</div>
              <div class="mr-3 font-bold">
                <small>{{ filters!.cte!.title }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="
                  filters.cte = null; filters.kde = null; applyFilter()
                "></span>
            </div>
          </div>
        </div>
      }
    }

    @if (allowedFilters.includes('KDE')) {
      @if (!filters.kde) {
        <div
          *ngIf="filters.cte && filters.product && filters.dateRange"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-select
            [options]="filters!.cte!.productKdes"
            [(ngModel)]="filters.kde"
            optionLabel="title"
            [filter]="true"
            filterBy="title"
            placeholder="KDE"
            class="w-full md:w-56"
            (ngModelChange)="applyFilter()">
            <ng-template let-kde #item>
              <div class="flex items-center gap-2">
                <div>{{ kde?.title }}</div>
              </div>
            </ng-template>
          </p-select>
        </div>
      } @else {
        <div
          *ngIf="filters.cte && filters.product && filters.dateRange"
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">KDE</div>
              <div class="mr-3 font-bold">
                <small>{{ filters!.kde!.title }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="
                  filters.kde = null; filters.kdeValue = null; applyFilter()
                "></span>
            </div>
          </div>
        </div>
      }
    }

    @if (allowedFilters.includes('KDE_VALUE')) {
      @if (!filters.kdeValue) {
        <div
          *ngIf="
            filters.cte && filters.product && filters.dateRange && filters.kde
          "
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <p-select
            [options]="productKdeValues$()?.values"
            [(ngModel)]="filters.kdeValue"
            optionLabel="value"
            optionValue="value"
            [filter]="true"
            filterBy="value"
            [placeholder]="productKdeValues$()?.kde?.title"
            class="w-full md:w-56"
            (ngModelChange)="applyFilter()">
            <ng-template let-value #item>
              <div class="flex items-center gap-2">
                <div>{{ value.value }}</div>
              </div>
            </ng-template>
          </p-select>
        </div>
      } @else {
        <div
          *ngIf="
            filters.cte && filters.product && filters.dateRange && filters.kde
          "
          class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-dark last:border-none w-100">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-xs">{{ (productKdeValues$()?.kde)!.title }}</div>
              <div class="mr-3 font-bold">
                <small>{{ filters!.kdeValue }}</small>
              </div>
            </div>
            <div class="mr-3">
              <span
                class="pi pi-times text-primary text-xs cursor-pointer"
                (click)="filters.kdeValue = null; applyFilter()"></span>
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>
