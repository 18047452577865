import { Component, Signal } from '@angular/core';
import { SubscriptionDetailsComponent } from '../../../components/shared-components/subscription-details/subscription-details.component';
import { StackBarChartComponent } from '../../../components/charts/stacked-bar-chart/stack-bar-chart.component';
import { IntroService } from '../../../services/intro.service';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { QrMetrics } from '../../../../store/dashboard-store/dashboard.model';
import { Store } from '@ngxs/store';
import { GetQrMetrics } from '../../../../store/dashboard-store/dashboard.actions';

@Component({
  selector: 'app-landing',
  imports: [SubscriptionDetailsComponent, StackBarChartComponent],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent {
  qrMetrics$: Signal<QrMetrics | null> = this.store.selectSignal(
    DashboardStateSelectors.getQrMetrics
  );
  constructor(
    private readonly introService: IntroService,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('regen_tour_completed') !== 'true')
      this.introService.startTour();
  }

  getQrMetrics(filters: any) {
    this.store.dispatch(
      new GetQrMetrics(filters?.timeFrame, filters?.productId)
    );
  }
}
