import {
  Component,
  Input,
  ViewEncapsulation,
  ViewChild,
  Signal,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  FileUploadHandlerEvent,
  FileUploadModule,
  FileUpload,
} from 'primeng/fileupload';
import { CommonModule } from '@angular/common';
import {
  ClearUploadedFilesByTypes,
  GeneratePresignedUrl,
  RemoveUploadedFile,
} from '../../../../store/general-store/general.actions';
import { Store } from '@ngxs/store';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-image-upload',
  standalone: true,
  imports: [CommonModule, FileUploadModule, ProgressSpinnerModule],
  providers: [MessageService],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ImageUploadComponent {
  @Input() singleMode = false;
  @Input() fileType = '';
  @ViewChild('fileUpload') fileUpload: FileUpload | undefined;
  @Output() fileChange = new EventEmitter<any>();
  @ViewChild('myElement') myElement!: ElementRef;

  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  isUpload$: Signal<boolean> = this.store.selectSignal(
    GeneralStateSelectors.isUploadInProgress
  );

  totalSize = 0;
  totalSizePercent = 0;
  uploadedFiles = new Set<string>();

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  onCustomUpload(event: FileUploadHandlerEvent): void {
    const files = event.files;
    const uploadfiles = this.uploadedFiles$()?.[this.fileType];
    if (this.singleMode && uploadfiles?.length === 1) return;
    files.forEach(file => {
      if (!this.uploadedFiles.has(file.name)) {
        this.uploadedFiles.add(file.name);
        this.fileChange.emit(event);
        this.store.dispatch(new GeneratePresignedUrl(file, this.fileType));
      }
    });
  }

  ngAfterViewInit() {
    this.setupClickListener();
  }

  setupClickListener() {
    setTimeout(() => {
      const buttonElement =
        this.el.nativeElement.querySelector('.p-message-close');
      if (buttonElement) {
        this.renderer.listen(
          buttonElement,
          'click',
          this.handleClick.bind(this)
        );
      } else {
        this.setupClickListener();
      }
    }, 100);
  }

  handleClick(event: MouseEvent) {
    this.fileUpload?.clear();
  }

  choose(_event: MouseEvent, callback: CallableFunction) {
    callback();
  }

  removeUploadedFileCallback(
    event: any,
    fileToRemove: UploadedFile,
    removeFileCallback: CallableFunction,
    idx: number
  ): void {
    removeFileCallback(event, idx);
    if (fileToRemove) {
      this.store.dispatch(
        new ClearUploadedFilesByTypes(this.fileType, fileToRemove)
      );
      this.store.dispatch(new RemoveUploadedFile(fileToRemove));
      this.uploadedFiles.delete(fileToRemove.name ?? '');
    }

    if (this.uploadedFiles$()[this.fileType].length === 0 && this.fileUpload) {
      this.fileUpload.clear();
    }
  }

  uploadEvent(callback: CallableFunction) {
    callback();
  }
}
