import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-no-subscription-banner',
  standalone: true,
  imports: [ButtonModule, RouterModule],
  templateUrl: './no-subscription-banner.component.html',
  styleUrl: './no-subscription-banner.component.scss',
})
export class NoSubscriptionBannerComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}
