import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Store } from '@ngxs/store';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { Product } from '../../../../store/products-store/products.model';
import { SelectModule } from 'primeng/select';
import { CommonModule } from '@angular/common';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { GraphListComponent } from '../../shared-components/graphs-list/graph-list.component';
import { Report } from '../../../../store/report-store/report.model';

@Component({
  selector: 'app-add-heat-map-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    SelectModule,
    CheckboxModule,
    InputNumberModule,
    CommonModule,
    RightFormHeaderComponent,
    GraphListComponent,
  ],
  templateUrl: './add-heat-map-form.component.html',
})
export class AddHeatMapFormComponent implements OnInit {
  @Input() data?: Product;
  @Input() id?: number;
  @Input() type?: string;
  @Input() report!: Report;
  @Output() formValid = new EventEmitter<boolean>();
  @Output() actionSuccess = new EventEmitter();
  @Output() graphSelected = new EventEmitter<number>();
  @Input() title!: string;

  heatMapForm!: FormGroup;

  processing$ = this.store.selectSignal(ProductStateSelectors.isProcessing);

  mapStyleOptions = ['Street', 'Satellite', 'Hybrid', 'Terrain'];
  zoomLevelOptions = [
    '5 - Regional View',
    '10 - City Level',
    '15 - Street Level',
  ];
  scaleOptions = ['Linear', 'Logarithmic', 'Exponential'];
  kdeTypeOptions = ['Organic Certification', 'Non-Organic Certification'];
  zoomBehaviorOptions = ['Auto zoom to active clusters', 'Manual Zoom'];
  layoutOptions = ['List for a cleaner UI', 'Grid Layout'];
  formatControlsOptions = [
    'Numeric for yield',
    'Date for certification validity',
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.heatMapForm = this.fb.group({
      chartName: ['', Validators.required],
      mapStyle: ['', Validators.required],
      zoomLevel: ['', Validators.required],
      centerCoordinates: ['', Validators.required],
      enableMapLayers: [true],

      locationKDE: ['', Validators.required],
      kde: ['', Validators.required],
      range: ['', Validators.required],
      scale: [''],
      kdeType: [''],

      clusteringRange: ['', Validators.required],
      zoomBehavior: ['', Validators.required],
      enableClustering: [true],

      fieldSelector: ['', Validators.required],
      layoutOptions: [''],
      formatControls: [''],
    });
  }

  action() {
    console.log(this.heatMapForm.value);
  }

  onGraphSelected(graphIndex: number) {
    this.graphSelected.emit(graphIndex);
    console.log('Selected Graph Index:', graphIndex);
  }
}
