import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
} from '@angular/core';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DropdownModule } from 'primeng/dropdown';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { User } from '../../../../store/users-store/user.model';
import { TextTransformPipe } from '../../../pipes/text-transform.pipe';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { Role } from '../../../../store/general-store/general.model';
import { MultiSelectModule } from 'primeng/multiselect';
import { UpdateUser } from '../../../../store/users-store/user.actions';
import { isSuperAdmin } from '../../../services/global.service';

@Component({
  selector: 'app-add-user-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    InputGroupModule,
    InputGroupAddonModule,
    RightFormFooterComponent,
    TextTransformPipe,
    MultiSelectModule,
  ],
  templateUrl: './add-user-form.component.html',
  styleUrl: './add-user-form.component.scss',
})
export class AddUserFormComponent implements OnChanges {
  @Input() type?: string;
  @Input() data?: User;
  @Input() id?: number;
  @Output() actionSuccess = new EventEmitter<boolean>();

  processing$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoading
  );
  roles$: Signal<Role[]> = this.store.selectSignal(
    GeneralStateSelectors.getRoles
  );
  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );

  form: FormGroup;
  userRoleIds: number[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      roleIds: [[], [Validators.required]],
    });
    this.form.get('email')?.disable();
    if (isSuperAdmin(this.user$())) {
      this.form.get('roleIds')?.disable();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.userRoleIds = this.data?.roles.map(r => r.id) ?? [];
      this.form.patchValue({
        firstName: this.data?.firstName || '',
        lastName: this.data?.lastName || '',
        email: this.data?.email || '',
        roleIds: this.userRoleIds,
      });
    }
  }

  action() {
    if (this.type === 'EDIT' && this.id && this.form.valid) {
      this.store
        .dispatch(
          new UpdateUser(this.id, {
            firstName: this.form.get('firstName')!.value,
            lastName: this.form.get('lastName')!.value,
            roleIds: this.form.get('roleIds')!.value,
          })
        )
        .subscribe(() => {
          this.actionSuccess.emit();
        });
    }
  }
}
