<div class="w-full p-8">
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-invite-vendor-user-form
      [type]="sidebar.type"
      [title]="sidebar.title"
      (actionSuccess)="
        this.invitationListComponent.getInvitationList();
        sidebar.visible = false
      ">
    </app-invite-vendor-user-form>
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <div class="flex justify-between items-center">
      <div>
        <h2>{{ pageTitle }}</h2>
        <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
      </div>
    </div>
    <div class="d-flex">
      <p-button
        appAccess="add-user"
        icon="pi pi-plus"
        label="Invite New User"
        id="invite-new-user"
        (onClick)="openInviteUserSidebar()" />
    </div>
  </div>

  <div class="mt-4">
    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Active</p-tab>
        <p-tab value="1">Invited</p-tab>
      </p-tablist>
      <p-tabpanels class="mt-5">
        <p-tabpanel value="0">
          <app-active-user-list></app-active-user-list>
        </p-tabpanel>
        <p-tabpanel value="1">
          <app-invitation-list></app-invitation-list>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  </div>
</div>
