import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NotificationService);
  const confirmationService = inject(ConfirmationService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const errorMessage = error.error.message || 'Unexpected error occurred';
      notificationService.openErrorToast(errorMessage);
      if (error.status === 402) {
        confirmationService.confirm({
          header: `NO SUBSCRIPTION`,
          message: `You are not subscribed to any plan. Please subscribe to get access to perform this action!`,
          icon: 'pi pi-exclamation-circle',
          acceptLabel: 'View Plans',
          acceptIcon: 'pi',
          acceptButtonStyleClass: 'p-button-primary',
          rejectVisible: false,
          accept: () => {
            router.navigateByUrl('/subscription/plans');
          },
        });
      }
      return throwError(() => error);
    })
  );
};
