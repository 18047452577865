import {
  AddCardParams,
  SubscribeParams,
  SwitchSubscriptionParams,
} from './subscription.model';

const CONTEXT = '[Subscription Store]';

export class GetPlans {
  static readonly type = `${CONTEXT} Get Plans`;
}

export class GetCards {
  static readonly type = `${CONTEXT} Get Cards`;
}

export class AddCard {
  static readonly type = `${CONTEXT} Add Card`;
  constructor(public payload: AddCardParams) {}
}

export class DeleteCard {
  static readonly type = `${CONTEXT} Delete Card`;
  constructor(public cardId: string) {}
}

export class SetCardAsDefault {
  static readonly type = `${CONTEXT} Set Card As Default`;
  constructor(public cardId: string) {}
}

export class Subscribe {
  static readonly type = `${CONTEXT} Subscribe`;
  constructor(public payload: SubscribeParams) {}
}

export class CancelSubscription {
  static readonly type = `${CONTEXT} Cancel Subscription`;
  constructor(public id: string) {}
}

export class SwitchSubscription {
  static readonly type = `${CONTEXT} Switch Subscription`;
  constructor(
    public id: number,
    public payload: SwitchSubscriptionParams
  ) {}
}
