<!-- <div class="bulk-upload-container grid grid-cols-3 gap-4 p-4">
  <div class="col-span-2">
    <div class="download-div pb-2">
      <h2 class="text-lg text-black font-bold mb-2">Download Template</h2>
      <div class="download-template flex items-center border rounded mb-4">
        <div class="flex-shrink-0">
          <img [src]="'assets/csv_file.png'" alt="csv" class="w-10 h-12" />
        </div>
        <div class="flex-grow ml-[2.5rem]">
          <h2 class="text-black font-bold mb-1">Bulk Upload Template</h2>
          <p class="text-sm text-gray-600">
            Download a predefined CSV template, fill in the required data
            fields, and upload it to streamline bulk data entry efficiently.
          </p>
        </div>
        <div class="flex-shrink-0 ml-4">
          <p-button
            id="download"
            icon="pi pi-download"
            label="Download"
            (onClick)="downloadTemplate()">
          </p-button>
        </div>
      </div>
    </div>

    <div class="upload-div">
      <h2 class="text-lg text-black font-bold mb-2">Upload Template</h2>
      <div
        class="upload-template border-dashed flex items-center justify-center border-2 rounded p-5 text-center cursor-pointer"
        (click)="triggerFileUpload()">
        <div class="mr-2">
          <img
            [src]="'assets/upload_file.png'"
            alt="csv"
            class="w-[24px] h-[20px]" />
        </div>
        <div class="ml-2">
          <h2 class="text-lg font-semi-bold text-black m-0">Upload Template</h2>
          <p class="text-sm text-gray-600 m-0">Support only (CSV)</p>
        </div>
        <input
          type="file"
          accept=".csv"
          class="hidden"
          id="file-upload"
          #fileInput
          (change)="handleFileUpload($event)" />
      </div>
      @if (selectedFileName) {
        <div class="file-preview mt-4">
          <p class="text-gray-700">Selected File: {{ selectedFileName }}</p>
        </div>
      }
      @if (isUploading) {
        <div class="loader mt-4">
          <p class="text-gray-700">Uploading file... Please wait.</p>
        </div>
      }
    </div>
  </div>

  <div class="upload-history border rounded p-4">
    <h2 class="text-lg font-bold text-black flex justify-between items-center">
      <span>Upload History</span>
      <p-button
        icon="pi pi-refresh"
        label="Reload"
        [outlined]="true"
        (click)="fetchUploadHistory()"
        [disabled]="isLoadingHistory"
        [loading]="isLoadingHistory">
      </p-button>
    </h2>

    @if (isLoadingHistory) {
      <div class="loader">
        <p class="text-center text-gray-600">Loading upload history...</p>
      </div>
    } @else if (uploadHistory.length > 0) {
      <div>
        <ul>
          <li *ngFor="let history of uploadHistory" class="mb-2">
            <div class="flex justify-between items-center">
              <div>
                <p class="date-style">
                  {{ history.date | date: 'mediumDate' }}
                </p>
                <p class="description-style">{{ history.description }}</p>
                <p class="name-style">By {{ history.name }}</p>
                <span
                  [ngClass]="
                    history.status === 'COMPLETED'
                      ? 'success-button'
                      : 'error-button'
                  "
                  class="font-bold">
                  {{ history.status }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    } @else {
      <div class="no-history">
        <p class="text-center text-gray-600">No upload history found.</p>
      </div>
    }
  </div>
</div> -->

<div class="grid grid-cols-3 gap-8 full-height-form">
  <div class="col-span-2">
    <div class="flex flex-col">
      <div class="sticky top-0 z-50 pt-5 bg-light">
        <app-right-form-header
          [processing]="processing$()"
          [showActionButton]="false"
          (callCancel)="actionSuccess.emit()"
          [title]="title"></app-right-form-header>
      </div>
      <div class="flex-1">
        <div class="mb-5">
          <p class="font-bold text-md">Download Template</p>
          <div
            class="bg-primaryLight px-10 py-5 flex items-center gap-5 border rounded">
            <div class="text-primary">
              <span class="pi pi-file-export !text-[50px]"></span>
            </div>
            <div>
              <p class="font-bold text-lg my-2">Bulk Upload Template</p>
              <p class="my-2">
                Download a predefined CSV template, fill in the required data
                fields, and upload it to streamline bulk data entry efficiently.
              </p>
            </div>
            <div>
              <p-button
                id="download"
                icon="pi pi-download"
                label="Download"
                (onClick)="downloadTemplate()">
              </p-button>
            </div>
          </div>
        </div>

        <div>
          <p class="font-bold">Upload Template</p>
          <app-document-upload
            [title]="'Upload Template'"
            [supportedFormats]="'text/csv'"
            [maxFileSizeMb]="5"
            [fileType]="'bulkUpload'"></app-document-upload>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="flex flex-col">
      <div class="sticky top-0 z-50 pt-5 bg-light">
        <div class="flex items-center justify-between">
          <h2>Upload History</h2>
          <p-button
            icon="pi pi-refresh"
            [outlined]="true"
            (click)="fetchUploadHistory()"
            [disabled]="isLoadingHistory"
            [loading]="isLoadingHistory" />
        </div>
      </div>
      <p-divider></p-divider>
    </div>

    <div class="flex-1">
      @if (isLoadingHistory) {
        <div class="loader">
          <p class="text-center text-gray-600">Loading upload history...</p>
        </div>
      } @else if (uploadHistory.length > 0) {
        <div>
          <div *ngFor="let history of uploadHistory" class="mb-5">
            <div class="flex justify-between items-center">
              <div>
                <p class="text-primary font-bold my-0 mb-1 text-sm">
                  {{ history.date | date: 'mediumDate' }}
                </p>
                <p class="font-bold my-0 text-md">{{ history.description }}</p>
                <p class="my-0">By {{ history.name }}</p>
                <div class="mt-2">
                  <span
                    [ngClass]="
                      history.status === 'COMPLETED'
                        ? 'bg-green-500 text-white'
                        : 'error-button'
                    "
                    class="font-bold p-2 rounded text-sm">
                    {{ history.status }}
                  </span>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
          </div>
        </div>
      } @else {
        <div class="no-history">
          <p class="text-center text-gray-600">No upload history found.</p>
        </div>
      }
    </div>
  </div>
</div>
