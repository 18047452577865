<div class="w-full p-8">
  <app-right-form-base
    [isVisible]="sidebar.visible"
    [widthClass]="sidebar.width"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    @switch (sidebar.formType) {
      @case ('qrOrder') {
        <app-create-qr-order-form
          [title]="sidebar.title"
          [data]="sidebar.data"
          [type]="sidebar.type"
          [id]="sidebar.id"
          (actionSuccess)="getQrOrderList(); sidebar.visible = false">
        </app-create-qr-order-form>
      }
      @case ('qrDownload') {
        <app-download-qr-order-form
          [data]="sidebar.data"
          [type]="sidebar.type"
          [id]="sidebar.id"
          (actionSuccess)="
            getQrOrderList(); sidebar.visible = false
          "></app-download-qr-order-form>
      }
      @case ('bulkUpload') {
        <app-bulk-upload-form
          [data]="sidebar.data"
          [type]="sidebar.type"
          [title]="sidebar.title"
          [id]="sidebar.id"
          (actionSuccess)="
            getQrOrderList(); sidebar.visible = false
          "></app-bulk-upload-form>
      }
    }
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex gap-3">
      <p-button
        id="create-qr-order-button"
        appAccess="add-qr-order"
        icon="pi pi-plus"
        label="Create QR Order"
        (onClick)="openQrOrderSidebar()" />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      export="QR_GENERATE_ORDER"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
