<div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      [actionLabel]="'Generate'"
      (callCancel)="actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
</div>
  
<app-graph-list></app-graph-list>

<form [formGroup]="form" class="full-height-form mt-5 mb-5">
    <div class="grid grid-cols-2 gap-x-4 gap-y-4">
      <div class="col-span-2">
        <p>Chart Title</p>
        <input
          pInputText
          class="w-full"
          placeholder="e.g. Stock Quantity by Product"
          formControlName="title"
          [class.input-danger]="
          form.get('title')?.invalid &&
          form.get('title')?.touched
          " />
        <app-error-message
          *ngIf="
          form.get('title')?.invalid &&
          form.get('title')?.touched
          "
          [control]="form.get('title')"
          label="Chart Title"></app-error-message>
      </div>

      <div class="col-span-2">
        <p>Chart Description</p>
        <input
          pInputText
          class="w-full"
          placeholder="e.g. This chart displays ..."
          formControlName="description"
          [class.input-danger]="
          form.get('description')?.invalid &&
          form.get('description')?.touched
          " />
        <app-error-message
          *ngIf="
          form.get('description')?.invalid &&
          form.get('description')?.touched
          "
          [control]="form.get('description')"
          label="Chart Description"></app-error-message>
      </div>

      <div class="col-span-2">
        <p class="font-bold text-md">Axis Configuration</p>
      </div>

      <div>
        <p>Category Axis (X-Axis)</p>
        <p-select
          formControlName="xAxis"
          [options]="xAxisOptions"
          optionLabel="title"
          placeholder="e.g., Region, Location"
          class="w-full"
          [class.input-danger]="
            form.get('xAxis')?.invalid &&
            form.get('xAxis')?.touched
          " />
        <app-error-message
          *ngIf="
          form.get('xAxis')?.invalid &&
          form.get('xAxis')?.touched
          "
          [control]="form.get('xAxis')"
          label="X-Axis"></app-error-message>
      </div>
  
      <div>
        <p>Value Axis (Y-Axis)</p>
        <p-select
          formControlName="yAxis"
          [options]="yAxisOptions"
          optionLabel="title"
          placeholder="e.g., Production Volume"
          class="w-full"
          [class.input-danger]="
            form.get('yAxis')?.invalid &&
            form.get('yAxis')?.touched
          " />
        <app-error-message
          *ngIf="
          form.get('yAxis')?.invalid &&
          form.get('yAxis')?.touched
          "
          [control]="form.get('yAxis')"
          label="Y-Axis"></app-error-message>
      </div>

      <div class="col-span-2">
        <p class="font-bold text-md">Layout Configuration</p>
      </div>

      <div>
        <p>Orientation</p>
        <p-select
          formControlName="orientation"
          [options]="orientation"
          class="w-full" />
      </div>

      <div>
        <p>Width</p>
        <p-select
          formControlName="width"
          [options]="width"
          class="w-full" />
      </div>

      <div>
        <p>Color</p>
        <input type="color" formControlName="color" class="w-full" />
      </div>
    </div>
</form>