import { Component } from '@angular/core';
import { AuthComponent } from '../../layouts/auth/auth.component';
import { ForgotComponent } from '../../components/form-components/forgot/forgot.component';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [AuthComponent, ForgotComponent],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {}
