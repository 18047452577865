<div class="sticky top-0 z-50 pt-5 bg-light">
  <app-right-form-header
    [processing]="processing$()"
    [disabled]="heatMapForm.invalid"
    [title]="title"
    [actionLabel]="'Generate'"
    (callCancel)="actionSuccess.emit(false)"
    (callAction)="action()"></app-right-form-header>
</div>

<app-graph-list></app-graph-list>

<form [formGroup]="heatMapForm" class="full-height-form mt-5 mb-5">
  <div class="grid grid-cols-2 gap-x-4 gap-y-4">
    <h4>Base Map Controls</h4>
    <div class="col-span-2">
      <p>Chart Name <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Cocoa Farm Certification Mapping"
        formControlName="chartName"
        [class.input-danger]="
          heatMapForm.get('chartName')?.invalid &&
          heatMapForm.get('chartName')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('chartName')?.invalid &&
          heatMapForm.get('chartName')?.touched
        "
        [control]="heatMapForm.get('chartName')"
        label="Chart Name"></app-error-message>
    </div>

    <div>
      <p>Select Map Style <span class="text-red-500">*</span></p>
      <p-select
        formControlName="mapStyle"
        [options]="mapStyleOptions"
        placeholder="e.g., Select 'Street' for a default road map view"
        class="w-full"
        [class.input-danger]="
          heatMapForm.get('mapStyle')?.invalid &&
          heatMapForm.get('mapStyle')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('mapStyle')?.invalid &&
          heatMapForm.get('mapStyle')?.touched
        "
        [control]="heatMapForm.get('mapStyle')"
        label="Map Style"></app-error-message>
    </div>

    <div>
      <p>Zoom Level <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., 10 for city-level, 5 for regional view"
        formControlName="zoomLevel"
        [class.input-danger]="
          heatMapForm.get('zoomLevel')?.invalid &&
          heatMapForm.get('zoomLevel')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('zoomLevel')?.invalid &&
          heatMapForm.get('zoomLevel')?.touched
        "
        [control]="heatMapForm.get('zoomLevel')"
        label="Zoom Level"></app-error-message>
    </div>

    <div>
      <p>Center Coordinates <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., San Francisco, USA (37.7749, -122.4194)"
        formControlName="centerCoordinates"
        [class.input-danger]="
          heatMapForm.get('centerCoordinates')?.invalid &&
          heatMapForm.get('centerCoordinates')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('centerCoordinates')?.invalid &&
          heatMapForm.get('centerCoordinates')?.touched
        "
        [control]="heatMapForm.get('centerCoordinates')"
        label="Center Coordinates"></app-error-message>
    </div>

    <div class="flex items-center gap-2">
      <p-checkbox
        formControlName="enableMapLayers"
        [binary]="true"
        inputId="enableMapLayers" />
      <label for="enableMapLayers" class="cursor-pointer"
        >Enable Map Layers</label
      >
    </div>

    <div class="col-span-2 font-bold">Marker Configuration</div>

    <div>
      <p>Location KDE <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Farm Location: 25.276987, 55.296249"
        formControlName="locationKDE"
        [class.input-danger]="
          heatMapForm.get('locationKDE')?.invalid &&
          heatMapForm.get('locationKDE')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('locationKDE')?.invalid &&
          heatMapForm.get('locationKDE')?.touched
        "
        [control]="heatMapForm.get('locationKDE')"
        label="Location KDE"></app-error-message>
    </div>

    <div>
      <p>KDE <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Corn Yield in tons"
        formControlName="kde"
        [class.input-danger]="
          heatMapForm.get('kde')?.invalid && heatMapForm.get('kde')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('kde')?.invalid && heatMapForm.get('kde')?.touched
        "
        [control]="heatMapForm.get('kde')"
        label="KDE"></app-error-message>
    </div>

    <div>
      <p>Range <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Smallest: 5px, Largest: 20px"
        formControlName="range"
        [class.input-danger]="
          heatMapForm.get('range')?.invalid && heatMapForm.get('range')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('range')?.invalid && heatMapForm.get('range')?.touched
        "
        [control]="heatMapForm.get('range')"
        label="Range"></app-error-message>
    </div>

    <div>
      <p>Scale <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Linear for balanced growth"
        formControlName="scale"
        [class.input-danger]="
          heatMapForm.get('scale')?.invalid && heatMapForm.get('scale')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('scale')?.invalid && heatMapForm.get('scale')?.touched
        "
        [control]="heatMapForm.get('scale')"
        label="Scale"></app-error-message>
    </div>

    <div>
      <p>KDE Type <span class="text-red-500">*</span></p>
      <input
        pInputText
        class="w-full"
        placeholder="e.g., Organic Certification"
        formControlName="kdeType"
        [class.input-danger]="
          heatMapForm.get('kdeType')?.invalid &&
          heatMapForm.get('kdeType')?.touched
        " />
      <app-error-message
        *ngIf="
          heatMapForm.get('kdeType')?.invalid &&
          heatMapForm.get('kdeType')?.touched
        "
        [control]="heatMapForm.get('kdeType')"
        label="KDE Type"></app-error-message>
    </div>

    <div class="col-span-2 font-bold">Clustering Settings</div>

    <div class="flex">
      <div>
        <p>Clustering Range <span class="text-red-500">*</span></p>
        <input
          pInputText
          class="w-full"
          placeholder="e.g., Default: 50px"
          formControlName="clusteringRange"
          [class.input-danger]="
            heatMapForm.get('clusteringRange')?.invalid &&
            heatMapForm.get('clusteringRange')?.touched
          " />
        <app-error-message
          *ngIf="
            heatMapForm.get('clusteringRange')?.invalid &&
            heatMapForm.get('clusteringRange')?.touched
          "
          [control]="heatMapForm.get('clusteringRange')"
          label="Clustering Range"></app-error-message>
      </div>

      <div>
        <p>Zoom Behavior <span class="text-red-500">*</span></p>
        <input
          pInputText
          class="w-full"
          placeholder="e.g., Auto zoom to active clusters"
          formControlName="zoomBehavior"
          [class.input-danger]="
            heatMapForm.get('zoomBehavior')?.invalid &&
            heatMapForm.get('zoomBehavior')?.touched
          " />
        <app-error-message
          *ngIf="
            heatMapForm.get('zoomBehavior')?.invalid &&
            heatMapForm.get('zoomBehavior')?.touched
          "
          [control]="heatMapForm.get('zoomBehavior')"
          label="Zoom Behavior"></app-error-message>
      </div>

      <div class="flex items-center gap-2 flex-grow">
        <p-checkbox
          formControlName="enableMapLayers"
          [binary]="true"
          inputId="enableMapLayers" />
        <label for="enableMapLayers" class="cursor-pointer"
          >Enable Map Layers</label
        >
      </div>
    </div>
  </div>
</form>
