import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  effect,
  Signal,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Store } from '@ngxs/store';
import {
  AddOrganizationCTE,
  UpdateOrganizationCTE,
} from '../../../../store/organizations-store/organizations.actions';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { CardModule } from 'primeng/card';
import { NgClass } from '@angular/common';
import {
  CTE,
  UploadedFile,
} from '../../../../store/general-store/general.model';
import { CommonModule } from '@angular/common';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { UpdateProductCTE } from '../../../../store/products-store/products.actions';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-add-cteform',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    InputTextareaModule,
    CardModule,
    NgClass,
    CommonModule,
    ProgressSpinnerModule,
    GoogleMapsModule,
    MapMarker,
    RightFormFooterComponent,
    InputSwitchModule,
  ],
  templateUrl: './add-cte-form.component.html',
  styleUrl: './add-cte-form.component.scss',
})
export class AddCTEFormComponent implements OnChanges {
  @Input() data?: CTE;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<boolean>();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;

  selectedIcon = '';
  uploadedIcon = '';
  isUploading = false;
  form: FormGroup;
  cteIcons$ = this.store.selectSignal(GeneralStateSelectors.getCTEIcons);
  processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  mapSelectedPosition: google.maps.LatLngLiteral | null = null;
  mapCenter: google.maps.LatLngLiteral | null = null;
  mapOptions = {
    zoomControl: true,
    animation: {
      animation: google.maps.Animation.BOUNCE,
    },
  };

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      latitude: [{ value: null, disabled: true }],
      longitude: [{ value: null, disabled: true }],
      isMapVisible: [false],
    });
    effect(() => {
      if (
        this.uploadedFiles$()[`cteIcon`] &&
        this.uploadedFiles$()[`cteIcon`][0].uploading
      ) {
        this.selectedIcon = '';
        this.uploadedIcon = '';
        this.isUploading = true;
      } else if (
        this.uploadedFiles$()[`cteIcon`] &&
        !this.uploadedFiles$()[`cteIcon`][0].uploading
      ) {
        this.selectedIcon = '';
        this.isUploading = false;
        this.uploadedIcon = this.uploadedFiles$()['cteIcon'][0].objectURL ?? '';
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      this.data &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.form.patchValue({
        title: this.data?.title || '',
        description: this.data?.description || '',
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        isMapVisible:
          this.data?.latitude && this.data?.longitude ? true : false,
      });
      if (this.data.isDefault) {
        this.form.get('title')?.disable();
        this.form.get('description')?.disable();
      } else {
        this.form.get('title')?.enable();
        this.form.get('description')?.enable();
      }
      this.mapSelectedPosition = {
        lat: this.data.latitude ?? 0,
        lng: this.data.longitude ?? 0,
      };
      this.mapCenter = {
        lat: this.data.latitude ?? 0,
        lng: this.data.longitude ?? 0,
      };
      if (this.cteIcons$().includes(this.data.icon)) {
        this.selectedIcon = this.data!.icon;
        this.uploadedIcon = '';
      } else {
        this.uploadedIcon = this.data!.icon;
        this.selectedIcon = '';
      }
    } else {
      this.setCurrentLocation();
      this.form.reset();
      this.selectedIcon = '';
      this.uploadedIcon = '';
    }
  }

  setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.mapSelectedPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.mapCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (this.form.get('isMapVisible')?.value) {
            this.form
              .get('latitude')
              ?.patchValue(this.mapSelectedPosition?.lat);
            this.form
              .get('longitude')
              ?.patchValue(this.mapSelectedPosition?.lng);
          }
        },
        error => {
          console.error('Error fetching location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.mapSelectedPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.form.controls['latitude'].patchValue(event.latLng.lat());
      this.form.controls['longitude'].patchValue(event.latLng.lng());
      this.form.updateValueAndValidity();
    }
  }

  action() {
    const payload = this.form.getRawValue();
    delete payload.isMapVisible;
    if (this.type === 'ADD') {
      this.store
        .dispatch(
          new AddOrganizationCTE({
            ...payload,
            type: 'STANDARD',
            icon:
              this.uploadedIcon === '' ? this.selectedIcon : this.uploadedIcon,
          })
        )
        .subscribe(() => {
          this.form.reset();
          this.uploadedIcon = '';
          if (this.fileInput) this.fileInput.nativeElement.value = '';
        });
    } else if (this.type === 'EDIT' && this.id && !this.data?.productId) {
      this.store
        .dispatch(
          new UpdateOrganizationCTE(this.id, {
            ...payload,
            type: 'STANDARD',
            icon:
              this.uploadedIcon === '' ? this.selectedIcon : this.uploadedIcon,
          })
        )
        .subscribe(() => {
          this.form.reset();
          this.uploadedIcon = '';
          if (this.fileInput) this.fileInput.nativeElement.value = '';
        });
    } else if (this.type === 'EDIT' && this.id && this.data?.productId) {
      this.store
        .dispatch(
          new UpdateProductCTE(this.id, this.data.productId, {
            latitude: this.form.get('latitude')?.value,
            longitude: this.form.get('longitude')?.value,
          })
        )
        .subscribe(() => {
          this.form.reset();
          this.uploadedIcon = '';
          if (this.fileInput) this.fileInput.nativeElement.value = '';
        });
    }
  }

  upload(event: any): void {
    this.store.dispatch(new ClearUploadedFiles());
    const files = event.target.files;
    const key = `cteIcon`;
    Array.from(files).forEach(async (file: any) => {
      this.store.dispatch(new GeneratePresignedUrl(file, key));
    });
  }

  toggleMapVisible(event: any) {
    this.form.get('isMapVisible')?.patchValue(event.checked);
    if (!event.checked) {
      this.form.get('latitude')?.patchValue(0);
      this.form.get('longitude')?.patchValue(0);
    } else {
      this.setCurrentLocation();
    }
  }
}
