import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
  standalone: true,
})
export class CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null || isNaN(value)) {
      return '$0.00'; // Handle invalid input
    }

    // Divide by 100 to convert to dollars
    const amount = value / 100;

    // Format with 2 decimal places
    const formattedAmount = amount.toFixed(2);

    // Check if amount is negative
    if (amount < 0) {
      return `- $${(amount * -1).toFixed(2)}`; // Parentheses to indicate negative amount
    }

    // Return positive formatted amount
    return `$${formattedAmount}`;
  }
}
