import { InjectionToken } from '@angular/core';

/**
 * Init action
 */
class InitState {
  static {
    this.type = '@@INIT';
  }
}
/**
 * Update action
 */
class UpdateState {
  static {
    this.type = '@@UPDATE_STATE';
  }
  constructor(addedStates) {
    this.addedStates = addedStates;
  }
}

/**
 * A multi-provider token used to resolve to custom NGXS plugins provided
 * at the root and feature levels through the `{provide}` scheme.
 *
 * @deprecated from v18.0.0, use `withNgxsPlugin` instead.
 */
const NGXS_PLUGINS = /* @__PURE__ */new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'NGXS_PLUGINS' : '');
function ɵisPluginClass(plugin) {
  // Determines whether the provided value is a class rather than a function.
  // If it’s a class, its handle method should be defined on its prototype,
  // as plugins can be either classes or functions.
  return !!plugin.prototype.handle;
}

/**
 * Returns the type from an action instance/class.
 * @ignore
 */
function getActionTypeFromInstance(action) {
  return action.constructor?.type || action.type;
}
/**
 * Matches a action
 * @ignore
 */
function actionMatcher(action1) {
  const type1 = getActionTypeFromInstance(action1);
  return function (action2) {
    return type1 === getActionTypeFromInstance(action2);
  };
}
/**
 * Set a deeply nested value. Example:
 *
 *   setValue({ foo: { bar: { eat: false } } },
 *      'foo.bar.eat', true) //=> { foo: { bar: { eat: true } } }
 *
 * While it traverses it also creates new objects from top down.
 *
 * @ignore
 */
const setValue = (obj, prop, val) => {
  obj = {
    ...obj
  };
  const split = prop.split('.');
  const lastIndex = split.length - 1;
  split.reduce((acc, part, index) => {
    if (index === lastIndex) {
      acc[part] = val;
    } else {
      acc[part] = Array.isArray(acc[part]) ? acc[part].slice() : {
        ...acc[part]
      };
    }
    return acc && acc[part];
  }, obj);
  return obj;
};
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * @ignore
 */
const getValue = (obj, prop) => prop.split('.').reduce((acc, part) => acc && acc[part], obj);

/**
 * Generated bundle index. Do not edit.
 */

export { InitState, NGXS_PLUGINS, UpdateState, actionMatcher, getActionTypeFromInstance, getValue, setValue, ɵisPluginClass };
