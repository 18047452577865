import { Component, OnInit, Signal, ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import {
  GetDemoRequestsList,
  UpdateDemoRequest,
} from '../../../../store/demo-requests-store/demo-requests.actions';
import { DemoRequest } from '../../../../store/demo-requests-store/demo-requests.model';
import { DemoRequestStateSelectors } from '../../../../store/demo-requests-store/demo-requests.selectors';
import {
  Header,
  Pagination,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ObjectToUrlParams } from '../../../services/global.service';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'app-organization-demo-requests',
  standalone: true,
  imports: [ButtonModule, DataTableComponent, BreadcrumbModule],
  templateUrl: './organization-demo-requests.component.html',
  styleUrl: './organization-demo-requests.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationDemoRequestsComponent implements OnInit {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type
  selectedFilters: Record<string, any> = {};

  data$: Signal<DemoRequest[]> = this.store.selectSignal(
    DemoRequestStateSelectors.getDemoRequests
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    DemoRequestStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    DemoRequestStateSelectors.isDemoStoreProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    DemoRequestStateSelectors.getHeaders
  );

  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.getDemoRequestsList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
  }

  ngOnInit(): void {}

  getDemoRequestsList() {
    this.store.dispatch(
      new GetDemoRequestsList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    this.confirmationService.confirm({
      header: `${action.event} DEMO REQUEST`,
      message: `Are you sure want to ${action.event.toLowerCase()} this demo request?`,
      icon: action.event === 'APPROVE' ? 'pi pi-check' : 'pi pi-times',
      acceptLabel: 'Confirm',
      rejectVisible: false,
      acceptIcon: 'pi',
      acceptButtonStyleClass:
        action.event === 'APPROVE' ? 'p-button-success' : 'p-button-danger',
      accept: () => {
        this.store
          .dispatch(
            new UpdateDemoRequest(
              {
                status: action.event === 'APPROVE' ? 'APPROVED' : 'REJECTED',
              },
              action.id
            )
          )
          .subscribe(() => {
            this.getDemoRequestsList();
          });
      },
    });
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getDemoRequestsList();
  }
}
