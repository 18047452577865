import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-bar-chat',
  standalone: true,
  imports: [],
  templateUrl: './bar.component.html',
  styleUrl: './bar.component.scss',
})
export class BarComponent implements AfterViewInit, OnDestroy {
  @Input() width = '100%';
  @Input() height = '200px';

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private series!: am5xy.ColumnSeries;
  private legend!: am5.Legend;
  private xAxis!: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private yAxis!: am5xy.ValueAxis<am5xy.AxisRenderer>;

  private data = [
    {
      country: 'CA',
      expired: 2.5,
      nearExpiration: 2.5,
      safe: 2.1,
    },
    {
      country: 'TX',
      expired: 2.6,
      nearExpiration: 2.7,
      safe: 2.2,
    },
    {
      country: 'NY',
      expired: 2.8,
      nearExpiration: 2.9,
      safe: 2.4,
    },
  ];

  ngAfterViewInit(): void {
    this.root = am5.Root.new('barChat');
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: this.root.verticalLayout,
      })
    );

    // // Add cursor
    // const cursor = this.chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    // cursor.lineY.set('visible', false);

    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'country',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Set data
    this.xAxis.data.setAll(this.data);
    // this.series.data.setAll(this.data);
    this.makeSeries('Expired', 'expired');
    this.makeSeries('Near Expiration', 'nearExpiration');
    this.makeSeries('Safe', 'safe');

    // Animate on load
    this.chart.appear(1000, 100);
  }
  makeSeries(name: string, fieldName: string) {
    const series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: name,
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        sequencedInterpolation: true,
        valueYField: fieldName,
        categoryXField: 'country',
      })
    );

    series.columns.template.setAll({
      tooltipText: '{name}, {categoryX}: {valueY}',
      tooltipY: am5.percent(10),
      // cornerRadiusTL: 5,
      // cornerRadiusTR: 5,
      // strokeOpacity: 0,
    });

    // series.columns.template.adapters.add('fill', (_fill, target) => {
    //   return this.chart
    //     .get('colors')!
    //     .getIndex(this.series.columns.indexOf(target));
    // });

    // series.columns.template.adapters.add('stroke', (_stroke, target) => {
    //   return this.chart
    //     .get('colors')!
    //     .getIndex(this.series.columns.indexOf(target));
    // });

    series.data.setAll(this.data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);

    series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        sprite: am5.Label.new(this.root, {
          text: '{valueY}',
          fill: this.root.interfaceColors.get('alternativeText'),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });

    this.legend.data.push(series);
  }

  ngOnDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
