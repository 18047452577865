<div class="w-full p-8">
  @if (sidebar.visible && (sidebar.type === 'ADD' || sidebar.type === 'EDIT')) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      (sidebarHidden)="sidebar.visible = false"
      [title]="sidebar.title"
      [widthClass]="sidebar.width">
      <app-initiate-recall-view
        [data]="sidebar.data"
        [title]="sidebar.title"
        [id]="sidebar.id"
        (actionSuccess)="
          sidebar.visible = false; getRecallRequestList()
        "></app-initiate-recall-view>
    </app-right-form-base>
  }

  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
  </div>

  <div class="mt-4">
    <app-data-table
      [allowedActions]="['VIEW']"
      [first]="first"
      export="RECALL"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isProcessing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
