import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-action-button',
  standalone: true,
  imports: [ProgressSpinnerModule, ButtonModule],
  templateUrl: './action-button.component.html',
})
export class ActionButtonComponent {
  @Input() disabled = false;
  @Input() actionInProgress = false;
  @Input() label = '';
  @Output() actionClicked = new EventEmitter<void>();
}
