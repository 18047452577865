<div class="h-screen w-screen bg-slider relative">
  @if (isLoading) {
    <div
      *ngIf="isLoading"
      class="absolute inset-0 flex items-center justify-center bg-dark z-50">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
  <p-carousel
    [value]="backgroundImages"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="true"
    [autoplayInterval]="10000"
    [showIndicators]="false"
    [showNavigators]="false"
    class="carousel-container">
    <ng-template let-image pTemplate="item">
      <div
        class="carousel-item"
        [style.backgroundImage]="'url(' + image.url + ')'">
        <img [src]="image.url" (load)="onImageLoad()" class="hidden" />
        <div class="flex flex-col items-start justify-between h-full p-10">
          <div class="self-start">
            <a href="/">
              <img
                [src]="'../../../assets/logos/logo-light.svg'"
                alt="Logo"
                class="w-[250px] mb-4" />
            </a>
            <div class="text-white text-[32px] lg:text-[32px]">
              Connecting People to <br />
              Products, Worldwide
            </div>
          </div>
          <div>
            <h1 class="text-[100px] text-white hidden sm:block font-normal">
              {{ image.title }}
            </h1>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>

  <div class="flex h-full items-center px-10 max-sm:px-0 content-container">
    <div class="w-full lg:w-5/12 ml-auto">
      <div class="m-5 p-5 box-border rounded bg-light">
        <div class="form-content overflow-hidden">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
