import { Injectable } from '@angular/core';

declare global {
  interface Window {
    google: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private googleMapsLoaded: boolean = false;

  loadGoogleMapsApi(): Promise<any> {
    if (this.googleMapsLoaded) {
      return Promise.resolve(window.google);
    }

    return new Promise((resolve, reject) => {
      if (window.google) {
        this.googleMapsLoaded = true;
        resolve(window.google);
      } else {
        const script = document.createElement('script');
        script.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCziAaniffzINQlfuNhkzCTHm_hWPvVZxw&libraries=places';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.googleMapsLoaded = true;
          resolve(window.google);
        };
        script.onerror = error => reject(error);
        document.body.appendChild(script);
      }
    });
  }
}
