import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import * as _ from 'lodash';
import { NotificationService } from '../../app/services/notification.service';
import { SubscriptionService } from './subscription.service';
import {
  AddCard,
  CancelSubscription,
  DeleteCard,
  GetCards,
  GetPlans,
  SetCardAsDefault,
  Subscribe,
  SwitchSubscription,
} from './subscription.actions';
import { SubscriptionStateModel } from './subscription.model';

@State<SubscriptionStateModel>({
  name: 'Subscription',
  defaults: {
    isLoading: false,
    plans: [],
    cards: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class SubscriptionState {
  constructor(
    private subscriptionService: SubscriptionService,
    private notificationService: NotificationService
  ) {}

  @Action(GetPlans)
  getPlans({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getPlans().pipe(
      tap(async res => {
        patchState({
          plans: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetCards)
  getCards({ patchState }: StateContext<SubscriptionStateModel>) {
    patchState({ isLoading: true });
    return this.subscriptionService.getCards().pipe(
      tap(async res => {
        patchState({
          cards: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(AddCard)
  addCard(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: AddCard
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.addCard(action.payload).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(SetCardAsDefault)
  setCardAsDefault(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: SetCardAsDefault
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.setCardAsDefault(action.cardId).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteCard)
  deleteCard(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: DeleteCard
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.deleteCard(action.cardId).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(Subscribe)
  subscribe(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: Subscribe
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.subscribe(action.payload).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast(
          'Your have subscribed to the plan successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(
          'User already has an active subscription. Please cancel the existing subscription before creating a new one.'
        );
        return throwError(() => error);
      })
    );
  }

  @Action(CancelSubscription)
  cancelSubscription(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: CancelSubscription
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService.cancelSubscription(action.id).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast(
          'Your subscription has been canceled successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(SwitchSubscription)
  switchSubscription(
    { patchState }: StateContext<SubscriptionStateModel>,
    action: SwitchSubscription
  ) {
    patchState({ isLoading: true });
    return this.subscriptionService
      .switchSubscription(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isLoading: false,
          });
          this.notificationService.openSuccessToast(
            'Your subscription has been switched to new plan successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isLoading: false });
          return throwError(() => error);
        })
      );
  }
}
