<div class="grid grid-cols-6 gap-3">
  <div class="col-span-2">
    <p-dropdown
      #dropdown
      [(ngModel)]="filters.productId"
      [options]="searchedProducts$()"
      optionLabel="productName"
      optionValue="id"
      optionDisabled="disabled"
      placeholder="Search Products"
      filterPlaceholder="Type here to search product"
      [emptyFilterMessage]="
        productStateLoading$() ? 'Loading...' : 'No matching products found'
      "
      emptyMessage="---"
      class="w-full"
      [virtualScroll]="true"
      [itemSize]="30"
      filter="true"
      filterBy="productName"
      (onFilter)="searchProducts($event.filter)"
      [style.width]="'300px'"
      (onChange)="loadData(dropdown)" />
  </div>

  <div class="col-span-2">
    <input
      [(ngModel)]="filters.qrCode"
      pInputText
      class="w-full"
      placeholder="QR Code" />
  </div>

  <div class="col-span-2">
    <p-dropdown
      placeholder="Time Frame"
      [options]="timeFrames"
      [(ngModel)]="filters.timeFrame"
      optionValue="value"
      optionLabel="key" />
  </div>

  <div class="col-span-1">
    <p-dropdown
      [(ngModel)]="filters.country"
      [options]="countries$()"
      optionLabel="name"
      placeholder="Country"
      class="w-full"
      (onChange)="getCities($event.value)"
      [virtualScroll]="true"
      [itemSize]="30"
      filter="true"
      filterBy="name" />
  </div>

  <div class="col-span-1">
    <p-dropdown
      [(ngModel)]="filters.city"
      [options]="cities$()"
      optionLabel="name"
      placeholder="City"
      class="w-full"
      filter="true"
      filterBy="name" />
  </div>

  <div>
    <p-dropdown
      placeholder="Device Type"
      [(ngModel)]="filters.deviceType"
      [options]="deviceTypes"
      optionValue="value"
      optionLabel="key" />
  </div>

  <div class="col-span-2">
    <p-calendar
      [(ngModel)]="filters.dateRange"
      class="w-100"
      [iconDisplay]="'input'"
      [showIcon]="true"
      placeholder="Date Range"
      selectionMode="range"
      [readonlyInput]="true" />
  </div>

  <div>
    <p-button (click)="applyFilter()">Apply Filter(s)</p-button>
  </div>
</div>
