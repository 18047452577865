import { CTE, KDE, PaginationParams } from '../general-store/general.model';
import {
  CountRecallItemsRequest,
  CreateRecallRequest,
  Recall,
  UpdateRecallCTEParams,
  UpdateRecallCTEsParams,
  UpdateRecallKDEsParams,
  UpdateRecallStatusRequest,
} from './recall-management.model';

const CONTEXT = '[RecallManagementStore]';

export class GetRecallList {
  static readonly type = `${CONTEXT} Get Recall List`;
  constructor(public payload: PaginationParams) {}
}

export class GetAllRecalls {
  static readonly type = `${CONTEXT} Get All Recalls`;
}

export class GetRecall {
  static readonly type = `${CONTEXT} Get Recall`;
  constructor(public id: number) {}
}

export class GetRecallUsers {
  static readonly type = `${CONTEXT} Get Recall Users`;
  constructor(public payload: PaginationParams) {}
}

export class GetRecallRequestList {
  static readonly type = `${CONTEXT} Get Recall Request List`;
  constructor(public payload: PaginationParams) {}
}

export class CreateRecall {
  static readonly type = `${CONTEXT} Create Recall`;
  constructor(public payload: CreateRecallRequest) {}
}
export class UpdateRecall {
  static readonly type = `${CONTEXT} Update Recall`;
  constructor(
    public id: number,
    public payload: CreateRecallRequest
  ) {}
}

export class UpdateRecallStatus {
  static readonly type = `${CONTEXT} Update Recall Status`;
  constructor(
    public id: number,
    public payload: UpdateRecallStatusRequest
  ) {}
}

export class DeleteRecall {
  static readonly type = `${CONTEXT} Delete Recall`;
  constructor(public id: number) {}
}

export class SetFilters {
  static readonly type = `${CONTEXT} Set Filters`;
  constructor(public filters: any) {}
}

export class AddRecallKDE {
  static readonly type = `${CONTEXT} Add Recall KDE`;
  constructor(public kde: KDE) {}
}

export class UpdateRecallKDE {
  static readonly type = `${CONTEXT} Update Recall KDE`;
  constructor(
    public id: number | undefined,
    public kde: KDE
  ) {}
}

export class UpdateRecallCTE {
  static readonly type = `${CONTEXT} Update Recall CTE`;
  constructor(
    public id: number,
    public payload: UpdateRecallCTEParams
  ) {}
}

export class UpdateRecallKDEs {
  static readonly type = `${CONTEXT} Update Recall KDEs`;
  constructor(public payload: UpdateRecallKDEsParams) {}
}

export class UpdateRecallCTEs {
  static readonly type = `${CONTEXT} Update Recall CTEs`;
  constructor(public payload: UpdateRecallCTEsParams) {}
}

export class DeleteRecallKDE {
  static readonly type = `${CONTEXT} Delete Recall KDE`;
  constructor(
    public cte: CTE,
    public id: number
  ) {}
}

export class ResetRecall {
  static readonly type = `${CONTEXT} Reset Recall`;
}

export class SetRecall {
  static readonly type = `${CONTEXT} Set Recall`;
  constructor(public recall: Recall) {}
}

export class CountRecallItems {
  static readonly type = `${CONTEXT} Count Recall Items`;
  constructor(public payload: CountRecallItemsRequest) {}
}
