import { Component, OnInit, Signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { User } from '../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../store/users-store/user.selectors';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CardModule, ButtonModule, TabViewModule, RouterModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  pageTitle = '';
  bucketUrl = environment.bucketUrl;
  activeTab = 0;
  tabs = [
    { title: 'Profile & Security', url: 'profile-security' },
    { title: 'Roles', url: 'roles' },
    { title: 'CTEs & KDEs', url: 'ctes-kdes' },
    { title: 'Payment Methods', url: 'payment-methods' },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(() => {
      if (
        this.user$()?.roles[0].assignedPermissions.findIndex(
          role => role.slug === 'list-role' && role.RolePermissions.allowed
        ) === -1
      ) {
        this.tabs = this.tabs.filter(tab => tab.url !== 'roles');
      }
      if (
        this.user$()?.roles[0].assignedPermissions.findIndex(
          role => role.slug === 'list-cte-kde' && role.RolePermissions.allowed
        ) === -1
      ) {
        this.tabs = this.tabs.filter(tab => tab.url !== 'ctes-kdes');
      }
      if (
        this.user$()?.roles[0].assignedPermissions.findIndex(
          role =>
            role.slug === 'view-subscription' && role.RolePermissions.allowed
        ) === -1
      ) {
        this.tabs = this.tabs.filter(tab => tab.url !== 'payment-methods');
      }
      const activeRoute =
        this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
      this.activeTab =
        this.tabs.findIndex(tab => tab.url === activeRoute) === -1
          ? 0
          : this.tabs.findIndex(tab => tab.url === activeRoute);
      this.router.navigate([`/settings/${this.tabs[this.activeTab].url}`]);
    });
  }

  onTabChange(event: TabViewChangeEvent) {
    const selectedTab = this.tabs[event.index];
    this.router.navigate([`/settings/${selectedTab.url}`]);
  }
}
