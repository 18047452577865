import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  CountriesResponse,
  PresignedUrlResponse,
  Country,
  CitiesResponse,
  City,
  Data,
  DataResponse,
  RolesResponse,
  Role,
  AddKDEParams,
  GenericApiResponse,
  PaginationParams,
  Activities,
  HeatMap,
} from '../../store/general-store/general.model';

interface UploadResponse {
  message: string;
  url: string;
}
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getCountries(): Observable<Country[]> {
    return this.http
      .get<CountriesResponse>(`${this.apiUrl}/api/data/countries`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching countries: ' + error.message)
          );
        })
      );
  }

  public getOrganizationTypes(): Observable<Data[]> {
    return this.http
      .get<DataResponse>(`${this.apiUrl}/api/data/organization-types`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getDesignations(): Observable<Data[]> {
    return this.http
      .get<DataResponse>(`${this.apiUrl}/api/data/designations`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getRoles(): Observable<Role[]> {
    return this.http
      .get<RolesResponse>(`${this.apiUrl}/api/roles-permissions/roles/defaults`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getOrganizationRoles(organizationId: number): Observable<Role[]> {
    return this.http
      .get<RolesResponse>(
        `${this.apiUrl}/api/roles-permissions/roles/organization/${organizationId}`
      )
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching data: ' + error.message)
          );
        })
      );
  }

  public getCities(countryId: number): Observable<City[]> {
    return this.http
      .get<CitiesResponse>(`${this.apiUrl}/api/data/cities/${countryId}`)
      .pipe(
        map(response => response.payload),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching cities: ' + error.message)
          );
        })
      );
  }

  //Todo: Add proper type here
  public getKDEInputTypes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/data/kde-input-types`).pipe(
      map(response => response.payload),
      catchError(error => {
        return throwError(
          () => new Error('Error fetching cities: ' + error.message)
        );
      })
    );
  }

  //Todo: Add proper type here
  public getIconsList(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/data/icons`).pipe(
      map(response => response.payload),
      catchError(error => {
        return throwError(
          () => new Error('Error fetching cities: ' + error.message)
        );
      })
    );
  }

  public getPresignedUrl(
    fileName: string,
    fileType: string
  ): Observable<string> {
    return this.http
      .get<PresignedUrlResponse>(
        `${this.apiUrl}/api/storage/presigned-upload-url`,
        {
          params: { fileName, fileType },
        }
      )
      .pipe(
        map(response => response.payload.url),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching presigned URL: ' + error.message)
          );
        })
      );
  }

  public uploadFileToS3(
    url: string,
    file: File
  ): Observable<HttpResponse<UploadResponse>> {
    return this.http.put<UploadResponse>(url, file, {
      headers: { 'Content-Type': file.type },
      observe: 'response',
    });
  }

  public addKDE(payload: AddKDEParams): Observable<GenericApiResponse> {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/organizations/kdes`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error creating organization kde: ' + error.message)
          );
        })
      );
  }
  public updateKDE(id: any, payload: any): Observable<GenericApiResponse> {
    return this.http
      .put<GenericApiResponse>(
        `${this.apiUrl}/api/organizations/kdes/${id}`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating organization kde: ' + error.message)
          );
        })
      );
  }
  public deleteKDE(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/organizations/kdes/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting organization kde: ' + error.message)
          );
        })
      );
  }
  public exportData(model: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/exports/${model}`, {}).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error exporting data: ' + error.message)
        );
      })
    );
  }
}
