import { Component, OnInit, Signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { User } from '../../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import {
  DeleteUser,
  GetUserList,
} from '../../../../store/users-store/user.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { AddOrganizationAdminFormComponent } from '../../../components/form-components/add-organization-admin-form/add-organization-admin-form.component';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { AddUserFormComponent } from '../../../components/form-components/add-user-form/add-user-form.component';
import { GetOrganizationRoles } from '../../../../store/general-store/general.actions';

@Component({
  selector: 'app-admin-user-list',
  standalone: true,
  imports: [
    AddOrganizationAdminFormComponent,
    ButtonModule,
    DataTableComponent,
    RightFormBaseComponent,
    AccessDirective,
    AddUserFormComponent,
  ],
  templateUrl: './admin-user-list.component.html',
  styleUrl: './admin-user-list.component.scss',
})
export class AdminUserListComponent implements OnInit {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };
  updateSidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };

  data$: Signal<User[]> = this.store.selectSignal(UserStateSelectors.getUsers);

  pagination$: Signal<Pagination> = this.store.selectSignal(
    UserStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoading
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    UserStateSelectors.getHeaders
  );

  constructor(
    private readonly primengConfig: PrimeNGConfig,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly confirmationService: ConfirmationService
  ) {
    this.getUserList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getUserList($event = true) {
    if ($event) {
      this.store.dispatch(
        new GetUserList({
          first: this.first,
          rows: this.rows,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
          search: this.search,
          filters: ObjectToUrlParams(this.selectedFilters),
          role: 'ORGANIZATION_ADMIN',
        })
      );
    }
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} USER`,
        message: `Are you sure want to delete this user?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteUser(action.id)).subscribe(() => {
            this.getUserList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      const organizationId = action.data.organizations[0].id;
      if (organizationId) {
        this.store
          .dispatch(new GetOrganizationRoles(organizationId))
          .subscribe(() => {
            this.updateSidebar = {
              visible: true,
              data: action.data,
              id: action.id,
              title: 'Update User',
              type: 'EDIT',
              width: 'w-25',
            };
          });
      }
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getUserList();
  }
}
