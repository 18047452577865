<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
  </div>
  <p-card class="p-[0px]">
    <div class="d-flex">
      <img
        class="profile-image mr-[20px]"
        [src]="user$()?.photo ?? bucketUrl + '/images/avatar.png'"
        alt="Profile" />
      <h2>{{ user$()?.firstName }} {{ user$()?.lastName }}</h2>
    </div>
  </p-card>

  <p-tabs value="profile-security">
    <p-tablist>
      @for (tab of filteredTabs; track tab) {
        <p-tab [value]="tab.url" [routerLink]="tab.url">
          <span>{{ tab.title }}</span>
        </p-tab>
      }
    </p-tablist>
  </p-tabs>
  <div class="pt-5">
    <router-outlet></router-outlet>
  </div>
</div>
