import { Component, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import { Card } from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { environment } from '../../../../environments/environment';
import { RouterModule } from '@angular/router';
import { CardsListComponent } from '../../../components/shared-components/cards-list/cards-list.component';

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [ButtonModule, RouterModule, CardsListComponent],
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.scss',
})
export class PaymentMethodsComponent {
  cards$: Signal<Card[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getCards
  );
  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  bucketUrl: string = environment.bucketUrl;

  constructor(private store: Store) {}
}
