<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form">
      <div class="flex">
        <div
          class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
          <input
            type="file"
            class="hidden"
            id="profileImage"
            accept="image/jpeg, image/png, image/svg+xml"
            (change)="upload($event)" />
          <label
            for="profileImage"
            class="flex flex-col items-center justify-center cursor-pointer text-center">
            <i class="pi pi-upload mb-[5px]"></i>
            <p class="text-primary font-bold m-0">Upload Photo</p>
          </label>
        </div>
        @if (!isUpload$() && !processing$()) {
          <img
            [src]="form.get('photo')?.value ?? bucketUrl + '/images/avatar.png'"
            [alt]="user$()?.firstName"
            class="w-[150px] h-[150px] ml-[20px] object-cover" />
        } @else {
          <div
            class="flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
            <p-progressSpinner />
          </div>
        }
      </div>

      <div class="grid grid-cols-2 gap-2">
        <div>
          <p>First Name</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('firstName')?.invalid && form.get('firstName')?.touched
            "
            placeholder="First Name"
            formControlName="firstName" />
          <app-error-message
            [control]="form.get('firstName')"
            label="First Name"></app-error-message>
        </div>

        <div>
          <p>Last Name</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('lastName')?.invalid && form.get('lastName')?.touched
            "
            placeholder="Last Name"
            formControlName="lastName" />
          <app-error-message
            [control]="form.get('lastName')"
            label="Last Name"></app-error-message>
        </div>
      </div>

      <div>
        <p>Email</p>
        <input
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('email')?.invalid && form.get('email')?.touched
          "
          placeholder="Email"
          formControlName="email" />
        <app-error-message
          [control]="form.get('email')"
          label="Email"></app-error-message>
      </div>
    </form>
  </div>
</div>
