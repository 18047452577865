<div>
  <div class="flex items-center justify-between mt-2 mb-2">
    <h3>CTE(s) Status</h3>
  </div>
  <div class="relative">
    <div
      class="bg-white rounded w-full h-[60vh] p-5 overflow-hidden relative flex items-center justify-center"
      [ngClass]="{
        'pointer-events-none': !overlay,
      }">
      <div
        [ngClass]="{
          'opacity-25': !overlay,
        }"
        id="trackMap"
        class="w-full h-full"></div>
    </div>

    @if (!overlay) {
      <div class="absolute inset-0 flex items-center justify-center">
        <div
          class="bg-primary text-white text-sm font-bold px-4 py-2 rounded shadow-lg">
          Select a Recall
        </div>
      </div>
    }
  </div>
</div>
