import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStore } from '@ngxs/store';
import { UserState } from '../store/users-store/user.state';
import { environment } from '../environments/environment';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { httpTokenInterceptor } from './interceptors/http.interceptor';
import { GeneralState } from '../store/general-store/general.state';
import { DemoRequestsState } from '../store/demo-requests-store/demo-requests.state';
import { OrganizationState } from '../store/organizations-store/organizations.state';
import { VendorState } from '../store/vendors-store/vendors.state';
import { InvitationState } from '../store/invitations-store/invitation.state';
import { RolesPermissionState } from '../store/roles-permission-store/roles-permission.state';
import { ProductState } from '../store/products-store/products.state';
import { QrState } from '../store/qr-store/qr.state';
import { DashboardState } from '../store/dashboard-store/dashboard.state';
import { SubscriptionState } from '../store/subscription-store/subscription.state';
import { httpErrorInterceptor } from './interceptors/error.interceptor';
import { ConfirmationService } from 'primeng/api';

const states = [
  GeneralState,
  UserState,
  DemoRequestsState,
  OrganizationState,
  VendorState,
  InvitationState,
  RolesPermissionState,
  ProductState,
  QrState,
  SubscriptionState,
  DashboardState,
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr(),
    provideStore(
      states,
      withNgxsReduxDevtoolsPlugin({ disabled: environment.production })
    ),
    provideHttpClient(
      withInterceptors([httpTokenInterceptor, httpErrorInterceptor])
    ),
    ConfirmationService,
  ],
};
