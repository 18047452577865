<div class="mt-5">
  <div class="bg-white p-1 rounded-lg shadow-md">
    <div class="grid grid-cols-5 gap-4">
      <div
        *ngFor="let graph of graphs; let i = index"
        (click)="selectGraph(i)"
        [class.bg-primaryLight]="selectedGraphIndex === i"
        [class.text-primary]="selectedGraphIndex === i"
        class="p-4 rounded-lg cursor-pointer transition-all border border-gray-300 flex flex-col items-center justify-center">
        <div class="py-5 d-flex flex-col justify-center">
          <i class="text-xl" [ngClass]="graph.icon"></i>
          <p>{{ graph.label.toUpperCase() }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
