import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
} from '@angular/core';
import { Report } from '../../../../store/report-store/report.model';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { Store } from '@ngxs/store';
import { ReportStateSelectors } from '../../../../store/report-store/report.selectors';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Product } from '../../../../store/products-store/products.model';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'primeng/select';
import { DatePickerModule } from 'primeng/datepicker';
import { TreeSelectModule } from 'primeng/treeselect';
import { CTE, KDE } from '../../../../store/general-store/general.model';
import { TreeNode } from 'primeng/api';
import {
  CreateCustomReport,
  UpdateReport,
} from '../../../../store/report-store/report.actions';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-generate-report-form',
  imports: [
    RightFormHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    CommonModule,
    SelectModule,
    DatePickerModule,
    TreeSelectModule,
  ],
  templateUrl: './generate-report-form.component.html',
})
export class GenerateReportFormComponent {
  @Input() data?: Report;
  @Input() id?: number;
  @Input() type?: string;
  @Input() title!: string;
  @Output() actionSuccess = new EventEmitter<boolean>();

  processing$ = this.store.selectSignal(ReportStateSelectors.isProcessing);

  report$: Signal<Report | null> = this.store.selectSignal(
    ReportStateSelectors.getReport
  );

  form: FormGroup;
  products: Product[];

  ctesKdes: TreeNode[] = [];
  selectedCtesKdes: any[] = [];

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.fb.group({
      reportName: ['', [Validators.required]],
      description: [''],
      filters: this.fb.group({
        product: ['', Validators.required],
        dateRange: ['', Validators.required],
        kdes: ['', Validators.required],
      }),
    });

    effect(() => {
      if (this.report$()) {
        this.setFormValues(this.report$());
      }
    });
    this.products = this.activatedRoute.snapshot.data['products'];
    this.form.get('filters.kdes')?.valueChanges.subscribe(value => {
      this.filterSelectedKdes(value);
    });
  }

  setCtesKdes(event: any) {
    this.ctesKdes = event.value.productCtes.map((cte: CTE) => {
      return {
        key: cte.id,
        label: cte.title,
        data: {
          id: cte.id,
        },
        type: 'CTE',
        children: cte.productKdes.map((kde: KDE) => {
          return {
            key: kde.id,
            label: kde.title,
            data: {
              id: kde.id,
              inputType: kde.inputType,
            },
            type: 'KDE',
          };
        }),
      };
    });
  }
  filterSelectedKdes(value: TreeNode<any>[]) {
    if (!value) return;
    this.form.get('filters.kdes')?.setValue(
      value.filter(item => item?.type === 'KDE'),
      { emitEvent: false }
    );
  }

  action() {
    if (!this.form.valid) return;
    const { reportName, description, filters } = this.form.value;
    const dateRange = {
      start:
        filters.dateRange[0] instanceof Date
          ? filters.dateRange[0].toISOString().split('T')[0]
          : String(filters.dateRange[0]).split('T')[0],

      end:
        filters.dateRange[1] instanceof Date
          ? filters.dateRange[1].toISOString().split('T')[0]
          : String(filters.dateRange[1]).split('T')[0],
    };

    const payload = {
      reportName,
      description,
      filters: {
        productId: filters.product.id,
        dateRange: {
          start: dateRange.start,
          end: dateRange.end,
        },
        kdes: filters.kdes.map(
          (kde: {
            data: { id: number, inputType: string };
            label: 'string';
          }) => ({
            id: kde.data.id,
            title: kde.label,
            inputType: kde.data.inputType,
          })
        ),
      },
    };

    const action$ =
      this.type === 'ADD'
        ? this.store.dispatch(new CreateCustomReport(payload))
        : this.store.dispatch(new UpdateReport(this.id!, payload));
    action$
      .pipe(
        catchError(error => {
          return of(error);
        })
      )
      .subscribe(res => {
        if (!res.error) {
          this.actionSuccess.emit();
        }
      });
  }

  private setFormValues(data?: Report | null): void {
    const filters =
      typeof data?.filters === 'string'
        ? JSON.parse(data.filters)
        : data?.filters;

    const selectedKdes =
      filters?.kdes?.map((kde: any) => ({
        key: kde.id,
        label: kde.title,
        data: { id: kde.id, inputType: kde.inputType },
        type: 'KDE',
      })) ?? [];

    const selectedProduct =
      this.products ? this.products.find(p => p.id === filters?.productId) : '';

    // Set CTEs and KDEs for the selected product
    if (selectedProduct) {
      this.setCtesKdes({ value: selectedProduct });
    }

    this.form.patchValue({
      reportName: data?.reportName ?? '',
      description: data?.description ?? '',
      filters: {
        product: selectedProduct,
        dateRange: [
          filters?.dateRange?.start ? new Date(filters.dateRange.start) : null,
          filters?.dateRange?.end ? new Date(filters.dateRange.end) : null,
        ],
        kdes: selectedKdes,
      },
    });

    this.filterSelectedKdes(selectedKdes);
  }
}
