import { Component, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { DashboardFiltersComponent } from '../../../components/shared-components/dashboard-filters/dashboard-filters.component';
import { ButtonModule } from 'primeng/button';
import {
  ProductLocationStats,
  TrackDashboardData,
} from '../../../../store/dashboard-store/dashboard.model';
import {
  GetProductLocationStats,
  GetTrackDashboardData,
} from '../../../../store/dashboard-store/dashboard.actions';
import { GanttChartComponent } from '../../../components/charts/gantt-chart/gantt-chart.component';
import { ClusterColumnsComponent } from '../../../components/charts/cluster-columns/cluster-columns.component';
import { TrackMapComponent } from '../../../components/charts/track-map/track-map.component';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
@Component({
  selector: 'app-track-products-dashboard',
  templateUrl: './track-products.component.html',
  styleUrls: ['./track-products.component.scss'],
  imports: [
    DashboardFiltersComponent,
    ButtonModule,
    GanttChartComponent,
    ClusterColumnsComponent,
    TrackMapComponent,
    BreadcrumbModule,
  ],
  standalone: true,
})
export class TrackDashboardComponent {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  selectedFilters: any = {};
  productId: any = 0;
  overlay: boolean = false;
  trackDashboardData$: Signal<TrackDashboardData[]> = this.store.selectSignal(
    DashboardStateSelectors.getTrackDashboardData
  );
  productLocationStats$: Signal<ProductLocationStats[]> =
    this.store.selectSignal(DashboardStateSelectors.getProductLocationStats);
  constructor(
    private readonly store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.getTrackDashboardData();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
  }

  getTrackDashboardData() {
    if (this.productId) {
      this.store.dispatch(
        new GetTrackDashboardData(this.selectedFilters, this.productId)
      );
    }
  }

  getProductLocationStats() {
    if (this.productId) {
      this.store.dispatch(
        new GetProductLocationStats(this.selectedFilters, this.productId)
      );
    }
  }

  setFilters(event: any) {
    if (event.productId) {
      this.overlay = true;
      this.productId = event.productId;
      delete event.productId;
      this.selectedFilters = event;
      this.getTrackDashboardData();
      this.getProductLocationStats();
    } else {
      this.overlay = false;
      this.productId = 0;
    }
  }
}
