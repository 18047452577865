<div class="w-full p-8">
  @if (templateSidebar.visible) {
    <app-right-form-base
      [isVisible]="templateSidebar.visible"
      (sidebarHidden)="templateSidebar.visible = false"
      [title]="templateSidebar.title"
      [widthClass]="templateSidebar.width">
      <app-product-design-template
        [title]="templateSidebar.title"
        [id]="templateSidebar.id"
        (close)="templateSidebar.visible = false"></app-product-design-template>
    </app-right-form-base>
  }

  @if (sidebar.visible && ['ADD', 'EDIT'].includes(sidebar.type)) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      [widthClass]="sidebar.width"
      (sidebarHidden)="sidebar.visible = false"
      [title]="sidebar.title">
      <app-product-qr-settings-form
        [data]="sidebar.data"
        [title]="sidebar.title"
        [type]="sidebar.type"
        [id]="sidebar.id"
        (actionSuccess)="getProductList(); sidebar.visible = false">
      </app-product-qr-settings-form>
    </app-right-form-base>
  }

  @if (
    productSidebar.visible && ['ADD', 'EDIT'].includes(productSidebar.type)
  ) {
    <app-right-form-base
      [isVisible]="productSidebar.visible"
      (sidebarHidden)="productSidebar.visible = false"
      [title]="productSidebar.title"
      [widthClass]="productSidebar.width">
      <app-product-add-edit
        [data]="productSidebar.data"
        [title]="productSidebar.title"
        [type]="productSidebar.type"
        [id]="productSidebar.id"
        (actionCreateProduct)="onProductCreated()"
        (actionSuccess)="productSidebar.visible = false"></app-product-add-edit>
    </app-right-form-base>
  }

  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
    <div class="d-flex">
      <p-button
        appAccess="add-product"
        icon="pi pi-plus"
        label="Add Product"
        id="add-product-button"
        (click)="openAddProductSidebar()" />
    </div>
  </div>
  <div class="mt-4">
    <app-data-table
      [first]="first"
      export="PRODUCT"
      [rows]="rows"
      [sortBy]="sortBy"
      [sortOrder]="sortOrder"
      [selectedFilters]="selectedFilters"
      [data$]="this.data$"
      [headers$]="this.headers$"
      [isProcessing$]="this.isListRefreshing$"
      [pagination$]="this.pagination$"
      (actionClickedEvent)="actionClicked($event)"
      (refreshEvent)="refresh($event)" />
  </div>
</div>
