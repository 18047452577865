<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
  </div>

  @if (!isLoading$()) {
    <div class="flex mt-10 gap-5 items-stretch justify-evenly">
      @for (plan of plans$(); track plan) {
        @if (!plan.hasPurchasedSubscription) {
          <div
            class="flex flex-col justify-between relative w-[50%] p-9 bg-slate-50 rounded-lg">
            <div>
              <p class="text-2xl font-bold m-0">
                {{ plan.productDetails.name }}
              </p>
              <p>
                <small>{{ plan.productDetails.description }}</small>
              </p>
              <p class="text-5xl font-bold">
                {{ plan.amount | currency
                }}<sub
                  ><small class="font-normal text-sm"
                    >/ {{ plan.interval_count > 1 ? plan.interval_count : '' }}
                    {{ plan.interval }}</small
                  ></sub
                >
              </p>

              @if (user$() && !user$()!.organizations[0]!.hasSetupFeePaid) {
                <div class="mb-5">
                  <small class="p-2 bg-primary-color text-white rounded"
                    >One Time Charge (OTC) {{ +plan.otc | currency }}</small
                  >
                </div>
              }
              <ul class="mb-9 list-none p-0">
                @for (
                  feature of plan.productDetails.marketing_features;
                  track feature
                ) {
                  <li>
                    <small class="flex"
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ feature.name }}</span></small
                    >
                  </li>
                }
              </ul>
              @if (plan.metadata['allow-addons'] === 'yes') {
                <p class="font-bold">Top-ups</p>
                <ul class="mb-9 list-none p-0">
                  <li>
                    <small class="flex"
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_0'] }}</span></small
                    >
                  </li>
                  <li>
                    <small
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_1'] }}</span></small
                    >
                  </li>
                  <li>
                    <small
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_2'] }}</span></small
                    >
                  </li>
                </ul>
              }
            </div>
            <div>
              @if (
                user$()?.subscriptionInfo?.subscriptionStatus !== 'canceled'
              ) {
                <button
                  pButton
                  [label]="getLabel(plan)"
                  (click)="switchSubscription(plan)"
                  class="p-button-primary rounded-md w-[100%]"></button>
              }
            </div>
          </div>
        } @else {
          <div
            class="flex flex-col justify-between shadow-primary-color-200 relative bottom-5 w-[50%] p-9 bg-primary-color rounded-lg text-white">
            <div>
              <p class="text-2xl font-bold m-0">
                {{ plan.productDetails.name }}
              </p>
              <p>
                <small>{{ plan.productDetails.description }}</small>
              </p>
              <p class="text-5xl font-bold">
                {{ plan.amount | currency
                }}<sub
                  ><small class="font-normal text-sm"
                    >/ {{ plan.interval_count > 1 ? plan.interval_count : '' }}
                    {{ plan.interval }}</small
                  ></sub
                >
              </p>

              @if (user$() && !user$()!.organizations[0]!.hasSetupFeePaid) {
                <div>
                  <small class="p-2 bg-white text-primary-color rounded"
                    >One Time Charge (OTC) {{ +plan.otc | currency }}</small
                  >
                </div>
              }

              <ul class="mb-9 list-none p-0">
                @for (
                  feature of plan.productDetails.marketing_features;
                  track feature
                ) {
                  <li>
                    <small class="flex"
                      ><span
                        class="pi pi-check mr-3 text-white font-bold"></span>
                      <span>{{ feature.name }}</span></small
                    >
                  </li>
                }
              </ul>
              @if (plan.metadata['allow-addons'] === 'yes') {
                <p class="font-bold">Top-ups</p>
                <ul class="mb-9 list-none p-0">
                  <li>
                    <small class="flex"
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_0'] }}</span></small
                    >
                  </li>
                  <li>
                    <small
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_1'] }}</span></small
                    >
                  </li>
                  <li>
                    <small
                      ><span
                        class="pi pi-check mr-3 text-primary-color font-bold"></span>
                      <span>{{ plan.metadata['addOn_2'] }}</span></small
                    >
                  </li>
                </ul>
              }
            </div>
            <div>
              <div class="text-center">
                <p>
                  <small class="p-2 text-primary-color bg-white rounded">{{
                    plan.subscriptionStatus.toUpperCase()
                  }}</small>
                </p>
                <p>
                  <small class="italic"
                    >Subscription valid till
                    {{
                      user$()?.subscription?.currentPeriodEnd
                        | formatDate: 'MMM dd, yyyy'
                    }}</small
                  >
                </p>
              </div>
              @if (
                user$()?.subscriptionInfo?.subscriptionStatus !== 'canceled' &&
                user$()?.subscriptionInfo?.canCancelPlan
              ) {
                <button
                  pButton
                  [disabled]="!user$()?.subscriptionInfo?.canCancelPlan"
                  [label]="getLabel(plan)"
                  (click)="cancelSubscription()"
                  class="p-button-outlined bg-white text-primary-color rounded-md w-[100%]"></button>
              }
            </div>
          </div>
        }
      }
    </div>
  } @else {
    <div class="flex items-center justify-center w-[100%] h-[50vh]">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
</div>
