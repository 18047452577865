import { Injectable } from '@angular/core';
import Shepherd from 'shepherd.js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IntroService {
  private tour: Shepherd.Tour;

  constructor(private router: Router) {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        classes: 'shepherd-theme-arrows',
        scrollTo: true,
      },
    });
  }

  startTour(): void {
    // Step 1: Navigate to the Product List page
    this.router.navigate(['/products/list']).then(() => {
      // Step 2: Guide to Add Product button
      this.tour.addStep({
        id: 'add-product-intro',
        text: "Welcome! Let's start by adding a new product.",
        buttons: [
          {
            text: 'Next',
            action: this.tour.next,
          },
        ],
      });

      this.tour.addStep({
        id: 'add-product-step',
        text: 'Click here to add a new product.',
        attachTo: {
          element: '#add-product-button',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Finish',
            action: this.tour.complete,
          },
        ],
      });

      // Start the tour
      this.tour.start();
    });
  }

  exitTour(): void {
    this.tour.complete();
  }
}
