<p class="text-4xl font-semibold">Reset Password</p>
<form [formGroup]="form" autocomplete="off">
  <p class="text-base font-normal !mt-10 mb-1">Password</p>
  <p-iconField iconPosition="right">
    <p-inputIcon
      (click)="passwordVisibility = !passwordVisibility"
      [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
    <input
      [type]="passwordVisibility ? 'text' : 'password'"
      formControlName="password"
      placeholder="************"
      pInputText
      class="w-full"
      [class.input-danger]="
        form.get('password')?.invalid && form.get('password')?.touched
      " />
  </p-iconField>
  <app-error-message
    [control]="form.get('password')"
    label="Password"></app-error-message>

  <p class="text-base font-normal mb-1">Confirm Password</p>
  <p-iconField iconPosition="right">
    <p-inputIcon
      (click)="confirmPasswordVisibility = !confirmPasswordVisibility"
      [styleClass]="
        confirmPasswordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
      " />
    <input
      [type]="confirmPasswordVisibility ? 'text' : 'password'"
      formControlName="confirmPassword"
      placeholder="************"
      pInputText
      class="w-full"
      [class.input-danger]="
        form.get('confirmPassword')?.invalid &&
        form.get('confirmPassword')?.touched
      " />
  </p-iconField>
  <app-error-message
    [control]="form.get('confirmPassword')"
    label="Confirm Password"></app-error-message>
  <div class="text-xs font-normal">
    @for (validation of passwordValidations; track $index) {
      <p class="password-text flex items-center !mb-1">
        <span
          class="password-icon mr-2"
          [class.text-teal-700]="validation.valid"
          [class.text-red-600]="!validation.valid">
          @if (!validation.valid) {
            <i class="pi pi-times-circle"></i>
          } @else {
            <i class="pi pi-verified"></i>
          }
        </span>
        <span>
          {{ validation.message }}
        </span>
      </p>
    }
    <p class="password-text flex items-center !mb-1">
      <span
        class="password-icon mr-2"
        [class.text-teal-700]="
          !form.get('confirmPassword')?.hasError('mismatch')
        "
        [class.text-red-600]="
          form.get('confirmPassword')?.hasError('mismatch')
        ">
        <i
          class="pi"
          [ngClass]="{
            'pi-times-circle': form
              .get('confirmPassword')
              ?.hasError('mismatch'),
            'pi-verified': !form.get('confirmPassword')?.hasError('mismatch'),
          }"></i>
      </span>
      <span *ngIf="form.get('confirmPassword')?.hasError('mismatch')"
        >Passwords do not match</span
      >
      <span *ngIf="!form.get('confirmPassword')?.hasError('mismatch')"
        >Passwords are matching</span
      >
    </p>
  </div>
</form>
<app-action-button
  [disabled]="!this.form.valid"
  [actionInProgress]="processing$()"
  (actionClicked)="resetPassword()"
  label="Reset Password"></app-action-button>
<p class="text-base font-normal text-center pt-4">
  Remember your password?
  <a class="text-blue-600 underline" href="/">Sign In</a>
</p>
