import { Component, OnInit, Signal } from '@angular/core';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { ButtonModule } from 'primeng/button';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { QrOrder } from '../../../../store/qr-store/qr.model';
import { QrStateSelectors } from '../../../../store/qr-store/qr.selectors';
import {
  GetQrOrder,
  GetQrOrderList,
} from '../../../../store/qr-store/qr.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { CreateQrOrderFormComponent } from '../../../components/form-components/create-qr-order-form/create-qr-order-form.component';
import { DownloadQrOrderFormComponent } from '../../../components/form-components/download-qr-order-form/download-qr-order-form.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { User } from '../../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';

@Component({
  selector: 'app-qr-order-list',
  standalone: true,
  imports: [
    ButtonModule,
    RightFormBaseComponent,
    DataTableComponent,
    CreateQrOrderFormComponent,
    DownloadQrOrderFormComponent,
    AccessDirective,
  ],
  templateUrl: './qr-order-list.component.html',
  styleUrl: './qr-order-list.component.scss',
})
export class QrOrderListComponent implements OnInit {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  search = '';
  sortOrder = -1;
  //Todo: Add proper type
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    formType: '',
    width: 'w-50',
  };

  data$: Signal<QrOrder[]> = this.store.selectSignal(
    QrStateSelectors.getQrOrders
  );

  user$: Signal<User | undefined> = this.store.selectSignal(
    UserStateSelectors.getUser
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    QrStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    QrStateSelectors.isProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    QrStateSelectors.getHeaders
  );

  constructor(
    private readonly primengConfig: PrimeNGConfig,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.getQrOrderList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getQrOrderList() {
    this.store.dispatch(
      new GetQrOrderList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getQrOrderList();
  }

  openQrOrderSidebar() {
    this.sidebar = {
      visible: true,
      title: 'Create QR Order',
      type: 'ADD',
      formType: 'qrOrder',
      width: 'w-75',
    };
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      console.log('Delete Functionality');
    } else if (action.event === 'EDIT') {
      console.log('Edit Functionality');
    } else if (action.event === 'DOWNLOAD') {
      this.store.dispatch(new GetQrOrder(action.id));
      this.sidebar = {
        data: action.data,
        visible: true,
        title: 'Download QR',
        type: 'ADD',
        formType: 'qrDownload',
        width: 'w-25',
      };
    }
  }
}
