import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { GraphListComponent } from '../../shared-components/graphs-list/graph-list.component';
import { CreateVisualizationPayload, Report, Visualization } from '../../../../store/report-store/report.model';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ReportStateSelectors } from '../../../../store/report-store/report.selectors';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { CommonModule } from '@angular/common';
import { KDE } from '../../../../store/general-store/general.model';
import { SelectModule } from 'primeng/select';
import { CreateVisualization } from '../../../../store/report-store/report.actions';

@Component({
  selector: 'app-add-bar-chart-form',
  imports: [
    RightFormHeaderComponent,
    GraphListComponent,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    CommonModule,
    SelectModule
  ],
  templateUrl: './add-bar-chart-form.component.html',
})
export class AddBarChartFormComponent implements OnInit {
  @Input() data?: Visualization;
  @Input() id?: number;
  @Input() type?: string;
  @Input() report!: Report;
  @Output() formValid = new EventEmitter<boolean>();
  @Output() actionSuccess = new EventEmitter();
  @Output() graphSelected = new EventEmitter<number>();
  @Input() title!: string;

  form!: FormGroup;
  xAxisOptions: KDE[] = [];
  yAxisOptions: KDE[] = [];
  orientation = ['Vertical', 'Horizontal'];
  width = ['10px', '20px', '50px'];

  processing$ = this.store.selectSignal(ReportStateSelectors.isProcessing);

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      visualizationType: ['BAR_CHART', Validators.required],
      xAxis: ['', Validators.required],
      yAxis: ['', Validators.required],
      orientation: ['Horizontal', Validators.required],
      color: ['#000000', Validators.required],
      width: ['10px', Validators.required]
    });

    this.xAxisOptions = this.report.filters.kdes.filter(kde => kde.inputType === 'TEXT');
    this.yAxisOptions = this.report.filters.kdes.filter(kde => kde.inputType === 'NUMBER');
  }

  action() {
    const payload: CreateVisualizationPayload = {
      title: this.form.get('title')?.value,
      description: this.form.get('description')?.value,
      visualizationType: this.form.get('visualizationType')?.value,
      config: {
        title: this.form.get('title')?.value,
        xAxisLabel: this.form.get('xAxis')?.value.title,
        yAxisLabel: this.form.get('yAxis')?.value.title,
        barOrientation: this.form.get('orientation')?.value.toLowerCase(),
        colors: [
          this.form.get('color')?.value,
        ],
      },
      dataConfig: {
        kdes: this.report.filters.kdes,
        categoryKey: this.form.get('xAxis')?.value.id.toString(),
        valueField: this.form.get('yAxis')?.value.id.toString()
      }
    }
    if(this.report.id) {
      this.store.dispatch(
        new CreateVisualization(this.report.id, payload)
      ).subscribe(() => {
        this.actionSuccess.emit(true);
      })
    }
  }

  onGraphSelected(graphIndex: number) {
    this.graphSelected.emit(graphIndex);
  }
}
