import { PaginationParams } from '../general-store/general.model';
import { CreateQrOrderParams, DownloadQrOrderParams } from './qr.model';
const CONTEXT = '[QrStore]';

export class GetQrOrderList {
  static readonly type = `${CONTEXT} Get QR Order List`;
  constructor(public payload: PaginationParams) {}
}
export class GetQrOrder {
  static readonly type = `${CONTEXT} Get QR Order`;
  constructor(public id: number) {}
}
export class CreateQrOrder {
  static readonly type = `${CONTEXT} Create QR Order`;
  constructor(public payload: CreateQrOrderParams) {}
}
export class DownloadQrOrder {
  static readonly type = `${CONTEXT} Download QR Order`;
  constructor(public payload: DownloadQrOrderParams) {}
}
