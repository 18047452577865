import { Component, HostListener, Signal } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { UserLogin } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    IconFieldModule,
    InputIconModule,
    CommonModule,
    ButtonModule,
    RouterModule,
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  form: FormGroup;
  passwordVisible: boolean = false;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  isLoggedIn$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoggedIn
  );
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.login();
    }
  }
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  login() {
    if (this.form.invalid) return;
    this.store.dispatch(
      new UserLogin({
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
      })
    );
  }
}
