import { Component, Signal, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { User } from '../../../../../store/users-store/user.model';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../../store/general-store/general.model';
import { UserStateSelectors } from '../../../../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';
import {
  DeleteUser,
  GetUserList,
} from '../../../../../store/users-store/user.actions';
import { ObjectToUrlParams } from '../../../../services/global.service';
import { DataTableComponent } from '../../../../components/shared-components/data-table/data-table.component';
import { RightFormBaseComponent } from '../../../../components/shared-components/right-form-base/right-form-base.component';
import { AddUserFormComponent } from '../../../../components/form-components/add-user-form/add-user-form.component';

@Component({
  selector: 'app-active-user-list',
  standalone: true,
  imports: [
    ButtonModule,
    DataTableComponent,
    RightFormBaseComponent,
    AddUserFormComponent,
  ],
  templateUrl: './active-user-list.component.html',
  styleUrl: './active-user-list.component.scss',
})
export class ActiveUserListComponent implements OnInit {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-25',
  };

  data$: Signal<User[]> = this.store.selectSignal(UserStateSelectors.getUsers);

  pagination$: Signal<Pagination> = this.store.selectSignal(
    UserStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoading
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    UserStateSelectors.getHeaders
  );

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private confirmationService: ConfirmationService
  ) {
    this.getUserList();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getUserList() {
    this.store.dispatch(
      new GetUserList({
        first: 0,
        rows: 5,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
        role: '',
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} USER`,
        message: `Are you sure want to delete this user?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteUser(action.id)).subscribe(() => {
            this.getUserList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.sidebar = {
        visible: true,
        data: action.data,
        id: action.id,
        title: 'Update User',
        type: 'EDIT',
        width: 'w-25',
      };
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getUserList();
  }
}
