import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import {
  ChartDatum,
  ZoomCords,
} from '../../../../store/general-store/general.model';

@Component({
  selector: 'app-heat-map',
  standalone: true,
  imports: [],
  templateUrl: './heat-map.component.html',
  styleUrl: './heat-map.component.scss',
})
export class HeatMapComponent implements OnDestroy, OnChanges, OnInit {
  @Input({ required: true }) chartData: ChartDatum[] = [];
  @Input({ required: true }) zoomCords?: ZoomCords;

  private chart!: am5map.MapChart;
  private root!: am5.Root;
  private bubbleSeries!: am5map.MapPointSeries;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
      this.bubbleSeries.data.setAll(
        this.chartData.map(data => {
          return { ...data, circleTemplate: { fill: data.color } };
        })
      );
    }
    if (changes['zoomCords'] && !changes['zoomCords'].firstChange) {
      this.chart.zoomToGeoPoint(
        {
          longitude: this.zoomCords?.lon ?? 0,
          latitude: this.zoomCords?.lat ?? 0,
        },
        5
      );
    }
  }

  ngOnInit(): void {
    this.initChart();
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initChart(): void {
    this.root = am5.Root.new('heatMap');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create map chart
    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        panX: 'translateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
        // homeZoomLevel: 1,
      })
    );

    // Create background series
    const backgroundSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {})
    );
    backgroundSeries.mapPolygons.template.setAll({
      fill: this.root.interfaceColors.get('alternativeBackground'),
      fillOpacity: 0,
      strokeOpacity: 0,
    });
    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });

    // Create main polygon series for countries
    const polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ'],
      })
    );
    polygonSeries.mapPolygons.template.setAll({
      fill: this.root.interfaceColors.get('alternativeBackground'),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: this.root.interfaceColors.get('background'),
    });

    // Create bubble series
    this.bubbleSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: 'value',
        calculateAggregates: true,
        latitudeField: 'latitude',
        longitudeField: 'longitude',
      })
    );

    const circleTemplate = am5.Template.new<am5.Circle>({
      tooltipText: '{type}: {value}',
    });

    this.bubbleSeries.bullets.push(() =>
      am5.Bullet.new(this.root, {
        sprite: am5.Circle.new(
          this.root,
          {
            radius: 10,
            templateField: 'circleTemplate',
          },
          circleTemplate
        ),
      })
    );

    // Configure heat rules for bubbles
    this.bubbleSeries.set('heatRules', [
      {
        target: circleTemplate,
        min: 3,
        max: 30,
        key: 'radius',
        dataField: 'value',
      },
    ]);
  }
}
