import { PaginationParams } from '../general-store/general.model';
import { CreateReportPayload, CreateVisualizationPayload } from './report.model';

const CONTEXT = '[ReportStore]';

export class GetReportList {
  static readonly type = `${CONTEXT} Get Report List`;
  constructor(public payload: PaginationParams) {}
}

export class GetReport {
  static readonly type = `${CONTEXT} Get Report`;
  constructor(public id: number) {}
}

export class GetViewReportData {
  static readonly type = `${CONTEXT} Get View Report Data`;
  constructor(
    public id: number,
    public payload: PaginationParams
  ) {}
}

export class CreateCustomReport {
  static readonly type = `${CONTEXT} Create Custom Report`;
  constructor(public payload: CreateReportPayload) {}
}

export class CreateVisualization {
  static readonly type = `${CONTEXT} Create Visualization`;
  constructor(public id: number, public payload: CreateVisualizationPayload) {}
}

export class UpdateReport {
  static readonly type = `${CONTEXT} Update Report`;
  constructor(
    public id: number,
    public payload: CreateReportPayload
  ) {}
}

export class DeleteReport {
  static readonly type = `${CONTEXT} Delete Report`;
  constructor(public id: number) {}
}

export class GetVisualizationData {
  static readonly type = `${CONTEXT} Get Visualization Data`;
  constructor(public reportId: number, public visualizationId: number) {}
}
