import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Store } from '@ngxs/store';
import { SelectModule } from 'primeng/select';
import { CommonModule } from '@angular/common';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { GraphListComponent } from '../../shared-components/graphs-list/graph-list.component';
import { CreateVisualizationPayload, Report, Visualization } from '../../../../store/report-store/report.model';
import { KDE } from '../../../../store/general-store/general.model';
import { CreateVisualization } from '../../../../store/report-store/report.actions';
import { ReportStateSelectors } from '../../../../store/report-store/report.selectors';

@Component({
  selector: 'app-add-line-chart-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    SelectModule,
    CheckboxModule,
    InputNumberModule,
    CommonModule,
    RightFormHeaderComponent,
    GraphListComponent,
  ],
  templateUrl: './add-line-chart-form.component.html',
})
export class AddLineChartFormComponent implements OnInit {
  @Input() data?: Visualization;
  @Input() id?: number;
  @Input() type?: string;
  @Input() report!: Report;
  @Output() formValid = new EventEmitter<boolean>();
  @Output() actionSuccess = new EventEmitter();
  @Output() graphSelected = new EventEmitter<number>();
  @Input() title!: string;

  lineChartForm!: FormGroup;

  processing$ = this.store.selectSignal(ReportStateSelectors.isProcessing);

  xAxisOptions: KDE[] = [];
  yAxisOptions: KDE[] = [];
  lineStyleOptions = ['Solid', 'Dashed', 'Dotted'];
  showLegend = [true, false];

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.lineChartForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      visualizationType: ['LINE_CHART', Validators.required],
      xAxis: ['', Validators.required],
      yAxis: ['', Validators.required],
      lineStyle: ['', Validators.required],
      lineColor: ['#000000', Validators.required],
      showLegend: [true, Validators.required]
    });

    this.xAxisOptions = this.report.filters.kdes.filter(kde => kde.inputType === 'DATE');
    this.yAxisOptions = this.report.filters.kdes.filter(kde => kde.inputType === 'NUMBER');
  }

  action() {
    const payload: CreateVisualizationPayload = {
      title: this.lineChartForm.get('title')?.value,
      description: this.lineChartForm.get('description')?.value,
      visualizationType: this.lineChartForm.get('visualizationType')?.value,
      config: {
        title: this.lineChartForm.get('title')?.value,
        xAxisLabel: this.lineChartForm.get('xAxis')?.value.title,
        yAxisLabel: this.lineChartForm.get('yAxis')?.value.title,
        showLegend: this.lineChartForm.get('showLegend')?.value,
        colors: [
          this.lineChartForm.get('lineColor')?.value,
        ],
        lineStyle: this.lineChartForm.get('lineStyle')?.value.toLowerCase(),
      },
      dataConfig: {
        kdes: this.report.filters.kdes,
        timeField: this.lineChartForm.get('xAxis')?.value.id.toString(),
        valueField: this.lineChartForm.get('yAxis')?.value.id.toString()
      }
    }
    if(this.report.id) {
      this.store.dispatch(
        new CreateVisualization(this.report.id, payload)
      ).subscribe(() => {
        this.actionSuccess.emit(true);
      })
    }
  }

  onGraphSelected(graphIndex: number) {
    this.graphSelected.emit(graphIndex);
  }
}
