<div class="w-full px-8 mt-8">
  <div class="flex items-center justify-between bg-orange-100 p-5 rounded-lg">
    <div>
      <p class="font-bold m-0 mb-2">Subscribe to Continue!</p>
      <p class="m-0">
        Actions on this platform are available to subscribed users only. Please
        upgrade your account to proceed.
      </p>
    </div>
    <div>
      <p-button [routerLink]="'/subscription/plans'">View Plans</p-button>
    </div>
  </div>
</div>
