<p-confirmDialog></p-confirmDialog>
@if (currUser$() && notAnAuthRoute()) {
  <div class="flex h-screen overflow-hidden bg-dark">
    <!-- Sidebar -->
    <div class="flex-none">
      <app-sidebar></app-sidebar>
    </div>

    <!-- Main Content Area -->
    <div
      class="flex-auto flex flex-col bg-light overflow-hidden main-layout w-100">
      @if (currUser$()?.currentPlan === null && showSubscriptionBanner) {
        <app-no-subscription-banner
          class="block px-8 mt-8"
          title="Subscribe to Continue!"
          description="Actions on this platform are available to subscribed users only. Please
        upgrade your account to proceed."></app-no-subscription-banner>
      }
      <ng-scrollbar class="flex-auto" appearance="compact">
        <router-outlet></router-outlet>
      </ng-scrollbar>
    </div>

    @if (showActivityFeed) {
      <!-- Activity Feed -->
      <div
        class="flex-auto flex flex-col bg-white overflow-hidden transition-all duration-200 ease-in-out"
        [ngClass]="isActivityFeedCollapsed ? 'w-10' : 'w-[500px]'">
        <div
          class="absolute top-[10%] translate-x-[-50%] right-100 cursor-pointer transition-transform duration-200 hover:scale-110 z-[99999]"
          (click)="isActivityFeedCollapsed = !isActivityFeedCollapsed">
          <span
            class="flex items-center text-white justify-center cursor-pointer rounded-full bg-primary h-[24px] w-[24px] transition-transform duration-200 hover:scale-110"
            [ngClass]="
              isActivityFeedCollapsed ? 'pi pi-angle-left' : 'pi pi-angle-right'
            "></span>
        </div>
        @if (!isActivityFeedCollapsed) {
          <ng-scrollbar class="flex-auto" appearance="compact">
            <app-activity-feed></app-activity-feed>
          </ng-scrollbar>
        }
      </div>
    }
  </div>
} @else {
  <ng-scrollbar>
    <router-outlet></router-outlet>
  </ng-scrollbar>
}
