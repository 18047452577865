<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h1 class="font-normal">Reset <strong>Password</strong></h1>
    <p class="text-gray">
      Securely reset your password and regain access to your account
    </p>
  </div>
  <div class="flex-1 px-4">
    <form [formGroup]="form" autocomplete="off">
      <div>
        <p>Password</p>
        <p-iconField iconPosition="right">
          <p-inputicon class="pi pi-lock text-gray-500" />
          <input
            [type]="passwordVisibility ? 'text' : 'password'"
            formControlName="password"
            placeholder="Enter your new password"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('password')?.invalid && form.get('password')?.touched
            " />
          <i
            class="pi cursor-pointer absolute right-2 top-2 text-gray-500"
            [ngClass]="passwordVisibility ? 'pi-eye' : 'pi-eye-slash'"
            (click)="passwordVisibility = !passwordVisibility">
          </i>
        </p-iconField>
        <app-error-message
          *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          [control]="form.get('password')"
          label="Password"></app-error-message>
      </div>

      <div>
        <p>Confirm Password</p>
        <p-iconField iconPosition="right">
          <p-inputicon class="pi pi-lock text-gray-500" />
          <input
            [type]="confirmPasswordVisibility ? 'text' : 'password'"
            formControlName="confirmPassword"
            placeholder="Re-enter your new password"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('confirmPassword')?.invalid &&
              form.get('confirmPassword')?.touched
            " />
          <i
            class="pi cursor-pointer absolute right-2 top-2 text-gray-500"
            [ngClass]="confirmPasswordVisibility ? 'pi-eye' : 'pi-eye-slash'"
            (click)="confirmPasswordVisibility = !confirmPasswordVisibility">
          </i>
        </p-iconField>
        <app-error-message
          *ngIf="
            form.get('confirmPassword')?.invalid &&
            form.get('confirmPassword')?.touched
          "
          [control]="form.get('confirmPassword')"
          label="Confirm Password"></app-error-message>
      </div>

      <div class="text-xs font-normal">
        @for (validation of passwordValidations; track $index) {
          <p class="password-text flex items-center !mb-1">
            <span
              class="password-icon mr-2"
              [class.text-teal-700]="validation.valid"
              [class.text-red-600]="!validation.valid">
              @if (!validation.valid) {
                <i class="pi pi-times-circle"></i>
              } @else {
                <i class="pi pi-verified"></i>
              }
            </span>
            <span>
              {{ validation.message }}
            </span>
          </p>
        }
        <p class="password-text flex items-center !mb-1">
          <span
            class="password-icon mr-2"
            [class.text-teal-700]="
              !form.get('confirmPassword')?.hasError('mismatch')
            "
            [class.text-red-600]="
              form.get('confirmPassword')?.hasError('mismatch')
            ">
            <i
              class="pi"
              [ngClass]="{
                'pi-times-circle': form
                  .get('confirmPassword')
                  ?.hasError('mismatch'),
                'pi-verified': !form
                  .get('confirmPassword')
                  ?.hasError('mismatch'),
              }"></i>
          </span>
          <span *ngIf="form.get('confirmPassword')?.hasError('mismatch')"
            >Passwords do not match</span
          >
          <span *ngIf="!form.get('confirmPassword')?.hasError('mismatch')"
            >Passwords are matching</span
          >
        </p>
      </div>

      <div>
        <p-button
          label="Reset Password"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="w-100"
          (click)="resetPassword()"
          [disabled]="this.form.invalid"
          [loading]="processing$()" />
      </div>
      <p class="text-center text-gray">
        Remember your password?
        <a class="text-dark cursor-pointer" href="/">SIGN IN</a>
      </p>
    </form>
  </div>
</div>
