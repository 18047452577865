import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { User } from '../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../store/users-store/user.selectors';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CardModule, ButtonModule, TabViewModule, RouterModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  pageTitle = '';
  bucketUrl = environment.bucketUrl;
  user?: User;
  activeTab = 0;
  tabs = [
    // { title: 'Profile & Security', url: 'profile-security' },
    { title: 'Roles', url: 'roles' },
    { title: 'CTEs & KDEs', url: 'ctes-kdes' },
    { title: 'Payment Methods', url: 'payment-methods' },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.user = this.store.selectSnapshot(UserStateSelectors.getUser);
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(() => {
      const activeRoute =
        this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
      this.activeTab = this.tabs.findIndex(tab => tab.url === activeRoute);
    });
  }

  onTabChange(event: TabViewChangeEvent) {
    const selectedTab = this.tabs[event.index];
    this.router.navigate([`/settings/${selectedTab.url}`]);
  }
}
