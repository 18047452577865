import { Component, Signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { Store } from '@ngxs/store';
import { GetRecallRequestList } from '../../../../store/recall-management-store/recall-management.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { Recall } from '../../../../store/recall-management-store/recall-management.model';
import { RecallManagementStateSelectors } from '../../../../store/recall-management-store/recall-management.selectors';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { InitiateRecallViewComponent } from '../../../components/form-components/initiate-recall-view/initiate-recall-view.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-recall-request-list',
  imports: [
    DataTableComponent,
    RightFormBaseComponent,
    InitiateRecallViewComponent,
    BreadcrumbModule,
  ],
  templateUrl: './recall-request-list.component.html',
  styleUrl: './recall-request-list.component.scss',
})
export class RecallRequestListComponent {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-80',
  };
  selectedFilters: Record<string, any> = {};

  data$: Signal<Recall[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecallRequests
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    RecallManagementStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    RecallManagementStateSelectors.isProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getHeaders
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
    this.getRecallRequestList();
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getRecallRequestList();
  }

  getRecallRequestList() {
    this.store.dispatch(
      new GetRecallRequestList({
        first: 0,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'VIEW') {
      this.sidebar = {
        visible: true,
        title: `Recall Request [${action.data.recallName}]`,
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-80',
      };
    }
  }
}
