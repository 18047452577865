import { PaginationParams } from '../general-store/general.model';

const CONTEXT = '[Dashboard Store]';

export class GetActivityLogs {
  static readonly type = `${CONTEXT} Get Activity Logs`;
  constructor(public payload: PaginationParams) {}
}
export class GetChartsData {
  static readonly type = `${CONTEXT} Get Dashboard Chart Data `;
  constructor(public productId: number) {}
}
export class GetCteStatistics {
  static readonly type = `${CONTEXT} Get CTE Statistics`;
  constructor(public productId: number) {}
}
export class GetProductExpiration {
  static readonly type = `${CONTEXT} Get CTE Expiration analysis data`;
  constructor(public productId: number) {}
}
export class GetQrMetrics {
  static readonly type = `${CONTEXT} Get QR Metrics`;
  constructor(
    public timeFrame: string,
    public productId: number | null
  ) {}
}
export class GetLocationAnalytics {
  static readonly type = `${CONTEXT} Get Location Analytics`;
  constructor(public filters: any) {}
}
export class GetDeviceAnalytics {
  static readonly type = `${CONTEXT} Get Device Analytics`;
  constructor(public filters: any) {}
}
export class GetProductAnalytics {
  static readonly type = `${CONTEXT} Get Product Analytics`;
  constructor(public filters: any) {}
}
export class GetDailyProductStats {
  static readonly type = `${CONTEXT} Get Daily Product Stats`;
  constructor(public filters: any) {}
}
