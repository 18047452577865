<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"
      [actionLabel]="getButtonTitle()"
      [processing]="isProcessing$()"
      [disabled]="getDisabledValue()"
      [title]="title"></app-right-form-header>
  </div>
  <div class="flex-1">
    <div class="flex min-h-[90vh] custom-gradient rounded">
      <div class="w-[20%]">
        <div>
          <h3 class="mb-5">Steps to Recall</h3>
          <ul class="list-none p-0">
            <li
              *ngFor="let step of steps; let i = index"
              (click)="navigateToStep(i)"
              class="cursor-pointer mb-[30px] hover:text-primary transition"
              [class.text-primary]="selectedStepIndex === i">
              <div class="font-bold flex gap-4 items-center">
                <div>
                  <span [class]="step.icon"></span>
                </div>
                <div>
                  <p class="m-0">{{ step.label }}</p>
                  <small>Step {{ i + 1 }}</small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Steps Sidebar -->

      <p-divider layout="vertical"></p-divider>
      <!-- Content Area -->
      <div class="w-[80%]">
        <div *ngIf="selectedStepIndex === 0">
          <h3 class="mb-5">Filter to find the product</h3>
          <app-product-filters
            [filters]="filters$()"
            (selectedFilters)="setFilters($event)"></app-product-filters>
          <div class="block mt-5">
            <p-button
              [disabled]="filters$().kdeValues.length === 0"
              [outlined]="true"
              icon="pi pi-arrow-right"
              iconPos="right"
              label="Fetch Items"
              (click)="countProductItems()" />
          </div>
          @if (countRecallItems$()) {
            <div class="mt-5">
              <div class="p-5 rounded border-dashed border-2 bg-white">
                <div class="font-bold text-md mb-5">
                  {{ countRecallItems$() }} item(s) fetched
                </div>
              </div>
            </div>
          }
        </div>

        <div *ngIf="selectedStepIndex === 1">
          <h3 class="mb-5">Provide recall details with reason & attachments</h3>
          <form [formGroup]="form">
            <div class="grid grid-cols-2 gap-x-4 gap-y-0">
              <div class="col-span-2">
                <p>Recall Name</p>
                <input
                  pInputText
                  class="w-full"
                  [class.input-danger]="
                    form.get('recallName')?.invalid &&
                    form.get('recallName')?.touched
                  "
                  placeholder="e.g. Recall-12-Jan-2025"
                  formControlName="recallName" />
                <app-error-message
                  [control]="form.get('recallName')"
                  label="Recall Name"></app-error-message>
              </div>
              <div>
                <p>Select Authorized User(s)</p>
                <p-multiSelect
                  [options]="users$()"
                  formControlName="authorizedUsers"
                  optionLabel="firstName"
                  optionValue="id"
                  placeholder="Select Users"
                  class="!w-full"
                  [class.input-danger]="
                    form.get('authorizedUsers')?.invalid &&
                    form.get('authorizedUsers')?.touched
                  ">
                  <ng-template let-user pTemplate="item">
                    <span>{{ user.firstName }} {{ user.lastName }}</span>
                  </ng-template></p-multiSelect
                >
                <app-error-message
                  [control]="form.get('authorizedUsers')"
                  label="Authorized Users"></app-error-message>
              </div>
              <div>
                <p>Select Severity Level</p>
                <p-select
                  placeholder="Choose Severity Level"
                  [options]="severityLevels"
                  formControlName="severityLevel" />
                <app-error-message
                  [control]="form.get('severityLevel')"
                  label="Severity Level"></app-error-message>
              </div>
              <div>
                <p>Reason of Recall</p>
                <textarea
                  pInputTextarea
                  rows="5"
                  class="w-full"
                  [class.input-danger]="
                    form.get('recallReason')?.invalid &&
                    form.get('recallReason')?.touched
                  "
                  placeholder="Reason of Recall"
                  formControlName="recallReason"></textarea>
                <app-error-message
                  [control]="form.get('recallReason')"
                  label="Reason of Recall"></app-error-message>
              </div>
              <div>
                <p>Description for Email & SMS</p>
                <textarea
                  pInputTextarea
                  rows="5"
                  class="w-full"
                  [class.input-danger]="
                    form.get('descriptionForEmailAndSms')?.invalid &&
                    form.get('descriptionForEmailAndSms')?.touched
                  "
                  placeholder="Description for Email & SMS"
                  formControlName="descriptionForEmailAndSms"></textarea>
                <app-error-message
                  [control]="form.get('descriptionForEmailAndSms')"
                  label="Description"></app-error-message>
              </div>
              <div>
                <p>Product Scan Message</p>
                <textarea
                  pInputTextarea
                  rows="5"
                  class="w-full"
                  [class.input-danger]="
                    form.get('productScanMessage')?.invalid &&
                    form.get('productScanMessage')?.touched
                  "
                  placeholder="Product Scan Message"
                  formControlName="productScanMessage"></textarea>
                <app-error-message
                  [control]="form.get('productScanMessage')"
                  label="Product Scan Message"></app-error-message>
              </div>
              <div>
                <p>Activity Feed</p>
                <textarea
                  pInputTextarea
                  rows="5"
                  class="w-full"
                  [class.input-danger]="
                    form.get('activityFeed')?.invalid &&
                    form.get('activityFeed')?.touched
                  "
                  placeholder="Activity Feed"
                  formControlName="activityFeed"></textarea>
                <app-error-message
                  [control]="form.get('activityFeed')"
                  label="Activity Feed"></app-error-message>
              </div>
              <div class="col-span-2">
                <p>Supporting Documents</p>
                <app-document-upload
                  [fileType]="'recallDocument'"
                  (onUpload)="onUpload($event)"></app-document-upload>
              </div>
            </div>
          </form>
        </div>

        <div *ngIf="selectedStepIndex === 2">
          <h3 class="mb-5">Recall CTEs & KDEs</h3>
          @if (recall$()) {
            <app-ctes-kdes
              [cteType]="'RECALL'"
              [isProductCtes]="true"
              [product]="null"
              [recallCtes]="recall$()?.recallProductCtes ?? []"
              [showNavigationButtons]="false"
              [showHeading]="false"></app-ctes-kdes>
          }
        </div>

        <div *ngIf="selectedStepIndex === 3">
          <div>
            <div class="flex justify-between items-center mb-4">
              <h3 class="mb-5">Recall Users</h3>
              <a
                class="no-underline text-primary"
                href="/settings/roles"
                target="_blank"
                ><p-button label="Manage Roles" [outlined]="true"
              /></a>
            </div>
          </div>
          <div class="mt-4">
            @if (this.recallUsers$() && this.recallUsers$().length > 0) {
              <app-data-table
                [showTableToolbar]="false"
                [allowSorting]="false"
                [allowFiltering]="false"
                [first]="first"
                [rows]="rows"
                [sortBy]="sortBy"
                [sortOrder]="sortOrder"
                [data$]="this.recallUsers$"
                [headers$]="this.headers$"
                [showActions]="false"
                [isProcessing$]="this.isProcessing$"
                [pagination$]="this.pagination$" />
            }
          </div>
        </div>

        <div *ngIf="selectedStepIndex === 4">
          <h3 class="mb-5">Recall Summary</h3>
          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Recall Name</div>
            </div>
            <div class="col-span-2">
              <div>{{ recall$()?.recallName }}</div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Severity Level</div>
            </div>
            <div class="col-span-2">
              <div>{{ recall$()?.severityLevel }}</div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Authorized Users</div>
            </div>
            <div class="col-span-2">
              <div>{{ getAuthorizedUsers(recall$()?.authorizedUsers) }}</div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Reason of Recall</div>
            </div>
            <div class="col-span-2">
              <div>
                {{ recall$()?.recallReason }}
              </div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Description for Email & SMS</div>
            </div>
            <div class="col-span-2">
              <div>
                {{ recall$()?.descriptionForEmailAndSms }}
              </div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Product Scan Message</div>
            </div>
            <div class="col-span-2">
              <div>
                {{ recall$()?.productScanMessage }}
              </div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Attachments</div>
            </div>
            <div class="col-span-2">
              <div>
                @for (
                  url of getAttachments(recall$()?.attachments);
                  track url
                ) {
                  <div>{{ url }}</div>
                }
              </div>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="grid grid-cols-3 gap-3 border-0">
            <div>
              <div class="font-bold">Recall Items</div>
            </div>
            <div class="col-span-2">
              <div>{{ countRecallItems$() }} Items</div>
            </div>
          </div>

          <p-divider></p-divider>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  (onHide)="onOtpDialogClose()"
  [modal]="false"
  [(visible)]="otpDialogVisible"
  class="w-[25%]">
  <div class="text-center p-5">
    <div class="mb-4">
      <span class="pi pi-exclamation-circle text-primary !text-xl"></span>
    </div>
    <h3>Confirm your recall with Two-Factor Authentication</h3>
    <div class="mb-4">
      Please enter the 4-digit code sent to your registered email address
    </div>
    <div class="mb-4 text-center">
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length: 4,
          inputClass: 'otp-box',
          allowNumbersOnly: true,
        }"></ng-otp-input>
    </div>
    <div class="flex w-full justify-between items-center mb-4">
      <div>
        <countdown
          #cd
          [config]="{ leftTime: timeLeft, format: 'm:ss' }"
          (event)="countdownEvent($event)" />
      </div>
      <div>
        <p-button
          [text]="true"
          label="Resend OTP"
          (click)="resendOtp()"
          [disabled]="resendDisabled" />
      </div>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <p-button
          (click)="otpDialogVisible = false"
          label="Cancel"
          icon="pi pi-times"
          [outlined]="true" />
        <p-button
          [disabled]="!otp || otp.length < 4 || authProcessing$()"
          (click)="submitRecall()"
          label="Continue"
          icon="pi pi-check" />
      </div>
    </div>
  </div>
</p-dialog>
