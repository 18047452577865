<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h1 class="font-normal">Welcome <strong>back!</strong></h1>
    <p class="text-gray">Enter credentials to access you reGenesis account.</p>
  </div>
  <div class="flex-1 px-4">
    <form [formGroup]="form">
      <div>
        <p>Email</p>
        <p-iconfield>
          <p-inputicon class="pi pi-envelope text-gray-500" />
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('email')?.invalid && form.get('email')?.touched
            "
            placeholder="Enter your email address"
            formControlName="email" />
        </p-iconfield>
        <app-error-message
          *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
          [control]="form.get('email')"
          label="Email"></app-error-message>
      </div>

      <div>
        <p>Password</p>
        <p-iconfield>
          <p-inputicon class="pi pi-lock text-gray-500" />
          <input
            pInputText
            class="w-full pr-10"
            [type]="passwordVisible ? 'text' : 'password'"
            [class.input-danger]="
              form.get('password')?.invalid && form.get('password')?.touched
            "
            formControlName="password"
            placeholder="Enter Your Password" />
          <i
            class="pi cursor-pointer absolute right-2 top-2 text-gray-500"
            [ngClass]="passwordVisible ? 'pi-eye' : 'pi-eye-slash'"
            (click)="togglePasswordVisibility()">
          </i>
        </p-iconfield>
        <app-error-message
          *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          [control]="form.get('password')"
          label="Password"></app-error-message>
      </div>

      <div>
        <p>
          <a [routerLink]="'/forgot-password'" class="text-dark font-bold"
            >FORGOT YOUR PASSWORD?</a
          >
        </p>
      </div>

      <div>
        <p-button
          label="Sign In to Your Account"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="w-100"
          (click)="login()"
          [disabled]="this.form.invalid"
          [loading]="processing$()" />
      </div>

      <div class="mt-8">
        <p class="text-center text-gray">
          New to reGenesis?
          <a class="text-dark font-bold cursor-pointer" [routerLink]="'/demo'"
            >REQUEST A DEMO</a
          >
        </p>
      </div>
    </form>
  </div>
</div>
