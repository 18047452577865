import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [],
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent implements OnInit {
  @Input() control: AbstractControl | null = null;
  @Input() label = '';
  lowercaseLabel = '';
  capitalizeLabel = '';
  ngOnInit(): void {
    this.lowercaseLabel = this.label.toLocaleLowerCase().trim();
    this.capitalizeLabel = (
      this.label.charAt(0).toUpperCase() + this.label.slice(1)
    ).trim();
  }
}
