<div class="w-full">
  <div class="flex items-center justify-between bg-orange-100 p-5 rounded">
    <div>
      <p class="font-bold m-0 mb-2">{{ title }}</p>
      <p class="m-0">
        {{ description }}
      </p>
    </div>
    <div>
      <p-button
        [routerLink]="'/subscription/plans'"
        label="View Plans"
        icon="pi pi-eye" />
    </div>
  </div>
</div>
