<p-sidebar
  #sidebarRef
  [ngClass]="'right-form ' + widthClass"
  [(visible)]="isVisible"
  position="right"
  dismissible="false"
  showCloseIcon="false"
  (onHide)="sidebarHidden.emit(); clearUploadedFiles()">
  <ng-template pTemplate="header">
    <div
      class="p-4 custom-gradient rounded-xl w-full flex justify-between items-center">
      <p class="font-bold text-xl text-gray-900 m-0">{{ title }}</p>
      <div>
        <i
          class="pi pi-times-circle text-blue-600 text-xl cursor-pointer"
          (click)="closeCallback($event)"></i>
      </div>
    </div>
  </ng-template>
  <ng-content></ng-content>
</p-sidebar>
