<div class="flex flex-col">
  <div class="sticky top-0 z-50 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      [actionLabel]="'Generate'"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form" class="full-height-form">
      <div class="font-bold text-md">Report Settings</div>
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2">
          <p>Name</p>
          <input
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('reportName')?.invalid && form.get('reportName')?.touched
            "
            placeholder="e.g. Supplier Performance Report"
            formControlName="reportName" />
          <app-error-message
            *ngIf="
              form.get('reportName')?.invalid && form.get('reportName')?.touched
            "
            [control]="form.get('reportName')"
            label="Report Name"></app-error-message>
        </div>

        <div class="col-span-2">
          <p>Description</p>
          <textarea
            type="text"
            formControlName="description"
            placeholder="e.g. I generated this report for ..."
            pInputText
            rows="4"
            class="w-full"
            [class.input-danger]="
              form.get('description')?.invalid &&
              form.get('description')?.touched
            "></textarea>
          <app-error-message
            *ngIf="
              form.get('description')?.invalid &&
              form.get('description')?.touched
            "
            [control]="form.get('description')"
            label="Description"></app-error-message>
        </div>
      </div>

      <div class="font-bold text-md">Filters for Report</div>

      <div class="grid grid-cols-2 gap-4" formGroupName="filters">
        <div>
          <p>Product</p>
          <p-select
            formControlName="product"
            [options]="products"
            optionLabel="productName"
            placeholder="e.g. Milk Pack 250g"
            (onChange)="setCtesKdes($event)"
            class="w-full"
            [class.input-danger]="
              form.get('product')?.invalid && form.get('product')?.touched
            " />
          <app-error-message
            [control]="form.get('product')"
            label="Product"></app-error-message>
        </div>

        <div>
          <p>Date Range</p>
          <p-datePicker
            formControlName="dateRange"
            [showIcon]="false"
            placeholder="e.g. MM/DD/YYYY - MM/DD/YYYY"
            selectionMode="range"
            [readonlyInput]="true" />
        </div>

        <div class="col-span-2">
          <p>KDE(s)</p>
          <p-treeselect
            [virtualScrollItemSize]="5"
            [options]="ctesKdes"
            class="w-full"
            [selectionMode]="'checkbox'"
            optionLabel="title"
            [showClear]="true"
            formControlName="kdes"
            placeholder="Click to add KDEs"
            display="chip">
          </p-treeselect>
        </div>
      </div>
    </form>
  </div>
</div>
