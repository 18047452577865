import { Component, OnInit, Signal } from '@angular/core';
import { Organization } from '../../../../store/organizations-store/organizations.model';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { Store } from '@ngxs/store';
import {
  DeleteOrganization,
  GetOrganizationList,
} from '../../../../store/organizations-store/organizations.actions';
import { ButtonModule } from 'primeng/button';
import { AddOrganizationFormComponent } from '../../../components/form-components/add-organization-form/add-organization-form.component';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { ActivatedRoute } from '@angular/router';
import { ObjectToUrlParams } from '../../../services/global.service';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';

@Component({
  selector: 'app-organization-list',
  standalone: true,
  imports: [
    ButtonModule,
    AddOrganizationFormComponent,
    RightFormBaseComponent,
    DataTableComponent,
  ],
  templateUrl: './organization-list.component.html',
  styleUrl: './organization-list.component.scss',
})
export class OrganizationListComponent implements OnInit {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  search = '';
  sortOrder = -1;
  //Todo: Add proper type
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-50',
  };

  data$: Signal<Organization[]> = this.store.selectSignal(
    OrganizationStateSelectors.getOrganizations
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    OrganizationStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    OrganizationStateSelectors.getHeaders
  );

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {
    this.getOrganizationList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getOrganizationList($event = true) {
    if ($event) {
      this.store.dispatch(
        new GetOrganizationList({
          first: this.first,
          rows: this.rows,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
          search: this.search,
          filters: ObjectToUrlParams(this.selectedFilters),
        })
      );
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getOrganizationList();
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} ORGANIZATION`,
        message: `Are you sure want to delete this organization?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store
            .dispatch(new DeleteOrganization(action.id))
            .subscribe(() => {
              this.getOrganizationList();
            });
        },
      });
    } else if (action.event === 'EDIT') {
      this.sidebar = {
        visible: true,
        title: 'Edit Organization',
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-50',
      };
    }
  }
}
