import { PaginationParams } from '../general-store/general.model';
import {
  AddRoleProductParams,
  UpdateRoleParams,
  UpdateRolePermissionParams,
  UpdateRoleProductParams,
} from './roles-permission.model';

const CONTEXT = '[RolesPermissionStore]';

export class GetRoleList {
  static readonly type = `${CONTEXT} Get Role List`;
  constructor(public payload: PaginationParams) {}
}

export class GetRole {
  static readonly type = `${CONTEXT} Get Role`;
  constructor(public id: number) {}
}

export class CreateRole {
  static readonly type = `${CONTEXT} Create Role`;
}

export class UpdateRole {
  static readonly type = `${CONTEXT} Update Role`;
  constructor(
    public id: number,
    public payload: UpdateRoleParams
  ) {}
}

export class DeleteRole {
  static readonly type = `${CONTEXT} Delete Role`;
  constructor(public id: number) {}
}

export class UpdateRolePermission {
  static readonly type = `${CONTEXT} Update Role Permission`;
  constructor(
    public id: number,
    public payload: UpdateRolePermissionParams
  ) {}
}

export class UpdateRolePermissions {
  static readonly type = `${CONTEXT} Update Role Permissions`;
  constructor(
    public roleId: number,
    public payload: UpdateRolePermissionParams
  ) {}
}

export class UpdateRoleCte {
  static readonly type = `${CONTEXT} Update Role CTE`;
  constructor(
    public id: number,
    public payload: UpdateRolePermissionParams
  ) {}
}

export class UpdateRoleProduct {
  static readonly type = `${CONTEXT} Update Role Product`;
  constructor(
    public id: number,
    public payload: UpdateRoleProductParams
  ) {}
}

export class DeleteRoleProduct {
  static readonly type = `${CONTEXT} Delete Role Product`;
  constructor(public id: number) {}
}

export class AddRoleProduct {
  static readonly type = `${CONTEXT} Add Role Product`;
  constructor(public payload: AddRoleProductParams) {}
}
