import { MergedPermission, Roles } from '../../store/users-store/user.model';

export function ObjectToUrlParams(obj: Record<string, string[]>) {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key].join(',').replace(/ /g, '%20');
      params.append(key, value);
    }
  }

  return params.toString();
}

export function UrlParamsToObject(urlString: string): object {
  const params = new URLSearchParams(urlString);
  const result: Record<string, string[]> = {};

  params.forEach((value, key) => {
    result[key] = decodeURIComponent(value).split(',');
  });

  return result;
}
export const mergePermissions = (roles: Roles[]): MergedPermission[] => {
  const permissionMap: Record<string, boolean[]> = {};

  roles.forEach(role => {
    role.assignedPermissions.forEach(permission => {
      const { slug, RolePermissions } = permission;
      const allowed = RolePermissions.allowed;

      if (!permissionMap[slug]) {
        permissionMap[slug] = [];
      }
      permissionMap[slug].push(allowed);
    });
  });

  return Object.keys(permissionMap).map(slug => {
    const allowed = permissionMap[slug].some(isAllowed => isAllowed === true);
    return { slug, allowed };
  });
};

export function isS3Url(str: string): boolean {
  const pattern = /^https:\/\/[^\s/$.?#].[^\s]*$/i;
  return pattern.test(str);
}
