<form [formGroup]="form" class="pt-6">
  <div class="grid grid-cols-1 gap-2">
    <div>
      <p class="text-base font-normal">Current Password</p>
      <p-iconField iconPosition="right">
        <p-inputIcon
          (click)="passwordVisibility = !passwordVisibility"
          [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
        <input
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="currentPassword"
          placeholder="************"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('currentPassword')?.invalid &&
            form.get('currentPassword')?.touched
          " />
      </p-iconField>
      <app-error-message
        [control]="form.get('currentPassword')"
        label="Current Password"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal">New Password</p>
      <p-iconField iconPosition="right">
        <p-inputIcon
          (click)="passwordVisibility = !passwordVisibility"
          [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
        <input
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="newPassword"
          placeholder="************"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('newPassword')?.invalid && form.get('newPassword')?.touched
          " />
      </p-iconField>
      <app-error-message
        [control]="form.get('newPassword')"
        label="New Password"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Re-type Password</p>
      <p-iconField iconPosition="right">
        <p-inputIcon
          (click)="passwordVisibility = !passwordVisibility"
          [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
        <input
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="retypePassword"
          placeholder="************"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('retypePassword')?.invalid &&
            form.get('retypePassword')?.touched
          " />
      </p-iconField>
      <app-error-message
        [control]="form.get('retypePassword')"
        label="Re-type Password"></app-error-message>
    </div>
  </div>

  <div class="text-xs font-normal">
    @for (validation of passwordValidations; track $index) {
      <p class="password-text flex items-center !mb-1">
        <span
          class="password-icon mr-2"
          [class.text-teal-700]="validation.valid"
          [class.text-red-600]="!validation.valid">
          @if (!validation.valid) {
            <i class="pi pi-times-circle"></i>
          } @else {
            <i class="pi pi-verified"></i>
          }
        </span>
        <span>
          {{ validation.message }}
        </span>
      </p>
    }
    <p class="password-text flex items-center !mb-1">
      <span
        class="password-icon mr-2"
        [class.text-teal-700]="
          !form.get('retypePassword')?.hasError('mismatch')
        "
        [class.text-red-600]="form.get('retypePassword')?.hasError('mismatch')">
        <i
          class="pi"
          [ngClass]="{
            'pi-times-circle': form.get('retypePassword')?.hasError('mismatch'),
            'pi-verified': !form.get('retypePassword')?.hasError('mismatch'),
          }"></i>
      </span>
      <span *ngIf="form.get('retypePassword')?.hasError('mismatch')"
        >Passwords do not match</span
      >
      <span *ngIf="!form.get('retypePassword')?.hasError('mismatch')"
        >Passwords are matching</span
      >
    </p>
  </div>
</form>

<app-right-form-footer
  [type]="type"
  [formValid]="this.form.valid && !processing$()"
  (callAction)="action()"
  [processing]="processing$()"
  (callCancel)="actionSuccess.emit(false)">
</app-right-form-footer>
