<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h1 class="font-normal">OTP <strong>Verification</strong></h1>
    <p class="text-gray">
      We send an OTP to your email
      <span class="font-bold">{{ user?.email }}</span
      >You can check your notifications.
    </p>
  </div>
  <div class="flex-1 px-4">
    <form>
      <div>
        <ng-otp-input
          (onInputChange)="onOtpChange($event)"
          [config]="{
            length: 4,
            inputClass: 'otp-box',
            placeholder: '-',
          }"></ng-otp-input>
      </div>
      <div class="flex items-center justify-start">
        <div class="w-[50px]">
          <countdown
            #cd
            [config]="{ leftTime: timeLeft, format: 'm:ss' }"
            (event)="countdownEvent($event)" />
        </div>
        <div>
          <p-button
            class="dark !mb-0"
            label="RESEND OTP"
            [text]="true"
            (click)="resendOtp()"
            [disabled]="resendDisabled" />
        </div>
      </div>

      <div>
        <p-button
          label="Verify code"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="w-100"
          (click)="sendOtp()"
          [disabled]="code.length < 4"
          [loading]="processing$()" />
      </div>
      <div class="mt-8">
        <p class="text-center text-gray">
          New to reGenesis?
          <a class="text-black cursor-pointer" [routerLink]="'/demo'"
            >REQUEST A DEMO</a
          >
        </p>
      </div>
    </form>
  </div>
</div>
