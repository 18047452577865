import { PaginationParams } from '../general-store/general.model';
import { UpdateDemoRequestParams } from './demo-requests.model';

const CONTEXT = '[Demp Requests Store]';
export class GetDemoRequestsList {
  static readonly type = `${CONTEXT} Get Demo Requests List`;
  constructor(public payload: PaginationParams) {}
}
export class UpdateDemoRequest {
  static readonly type = `${CONTEXT} Update Demo Request`;
  constructor(
    public payload: UpdateDemoRequestParams,
    public id: number
  ) {}
}
