import { Component, Signal, OnInit, OnDestroy, effect } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ObjectToUrlParams } from '../../../services/global.service';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  ClearProductData,
  DeleteProduct,
  GetProduct,
  GetProductList,
  ResetHeaders,
} from '../../../../store/products-store/products.actions';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { ProductQrSettingsFormComponent } from '../../../components/form-components/product-qr-settings-form/product-qr-settings-form.component';
import { ProductScanDetailsComponent } from '../../../components/shared-components/product-scan-details/product-scan-details.component';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';
import { ProductAddEditComponent } from '../product-add-edit/product-add-edit.component';
import { ProductDesignTemplateComponent } from '../../../components/shared-components/product-design-template/product-design-template.component';
import { IntroService } from '../../../services/intro.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    DataTableComponent,
    RightFormBaseComponent,
    ProductQrSettingsFormComponent,
    ProductScanDetailsComponent,
    ProductAddEditComponent,
    AccessDirective,
    ProductDesignTemplateComponent,
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
})
export class ProductListComponent implements OnInit, OnDestroy {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  selectedFilters: Record<string, any> = {};
  sidebar: SidebarData = {
    visible: false,
    title: 'Product Scan Details',
    type: '',
    width: 'w-75',
  };
  templateSidebar: SidebarData = {
    visible: false,
    title: 'Product Design Template',
    type: '',
    width: 'w-80',
  };
  productSidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-75',
  };
  selectedProduct: Product | null = null;

  showSidemenu = this.store.select(GeneralStateSelectors.shouldHideSideMenu);

  data$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getProducts
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    ProductStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    ProductStateSelectors.getHeaders
  );

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private introService: IntroService
  ) {
    this.getProductList();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.showSidemenu.pipe(takeUntilDestroyed()).subscribe(res => {
      if (res) {
        this.sidebar.visible = false;
      }
    });
    effect(() => {
      if (this.data$().length > 0) {
        this.selectedProduct = this.data$()[0];
      }
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.introService.startTour();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetHeaders());
    this.store.dispatch(new ClearUploadedFiles());
  }

  getProductList() {
    this.store.dispatch(
      new GetProductList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  onProductCreated() {
    this.productSidebar.visible = true;
    this.getProductList();
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} PRODUCT`,
        message: `Are you sure want to delete this product?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteProduct(action.id)).subscribe(() => {
            this.getProductList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.store.dispatch(new GetProduct(action.id));
      this.productSidebar = {
        visible: true,
        title: 'Edit Product',
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-75',
      };
    } else if (action.event === 'QR') {
      this.sidebar = {
        visible: true,
        title: 'QR Settings',
        id: action.id,
        data: action.data,
        type: 'EDIT',
        width: 'w-75',
      };
    } else if (action.event === 'SETTINGS') {
      this.store.dispatch(new ClearProductData()).subscribe(() => {
        this.templateSidebar.visible = true;
        this.templateSidebar.data = action.data;
        this.templateSidebar.id = action.id;
      });
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getProductList();
  }
}
