import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { User } from '../../../../store/users-store/user.model';
import {
  ResendOtp,
  ValidateOtp,
} from '../../../../store/users-store/user.actions';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [NgOtpInputModule, CountdownComponent, ButtonModule],
  templateUrl: './otp.component.html',
})
export class OtpComponent {
  @ViewChild('cd', { static: false }) countdown: CountdownComponent | undefined;
  timeLeft = 300;
  resendDisabled = true;
  buttonDisabled = true;
  code = '';
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  user?: User;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.user = this.store.selectSnapshot(UserStateSelectors.getUser);
    if (!this.user) {
      this.notificationService.openErrorToast(
        'You need to login to validate otp'
      );
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  onOtpChange(value: string) {
    if (value === this.code) return;
    this.code = value;
    if (this.code.length < 4) {
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
    }
    if (this.code.length === 4 && !this.processing$()) {
      this.store.dispatch(
        new ValidateOtp(this.user?.id ?? 0, parseInt(this.code))
      );
    }
  }

  resendOtp() {
    this.resendDisabled = true;
    this.store.dispatch(new ResendOtp(this.user?.id ?? 1));
    this.countdown?.restart();
  }

  countdownEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.resendDisabled = false;
    }
  }
}
