import { Component } from '@angular/core';

@Component({
  selector: 'app-no-access',
  standalone: true,
  imports: [],
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.scss',
})
export class NoAccessComponent {}
