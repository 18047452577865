<div class="sidebar-form-button">
  <div
    class="p-4 bg-gray-100 h-16 rounded-xl w-full flex justify-end items-center">
    <p-button
      class="cancel-button !rounded-lg flex !text-lg"
      (click)="callCancel.emit()">
      Cancel
    </p-button>
    @if (showPrimaryActionButton) {
      <app-action-button
        class="ml-3"
        [disabled]="!formValid"
        [actionInProgress]="processing"
        (actionClicked)="callAction.emit()"
        [label]="
          label ? label : type === 'EDIT' ? 'Update' : 'Submit'
        "></app-action-button>
    }
  </div>
</div>
