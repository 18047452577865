import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  DemoRequestsResponsse,
  UpdateDemoRequestParams,
} from './demo-requests.model';
import { PaginationParams } from '../general-store/general.model';

@Injectable({
  providedIn: 'root',
})
export class DemoRequestsService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getDemoRequests(
    paginationParams: PaginationParams
  ): Observable<DemoRequestsResponsse> {
    return this.http
      .get<DemoRequestsResponsse>(
        `${this.apiUrl}/api/organizations/demo-requests`,
        {
          params: {
            page: paginationParams.first / paginationParams.rows + 1,
            limit: paginationParams.rows,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            search: paginationParams.search,
            filters: paginationParams.filters,
          },
        }
      )
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching demo requests: ' + error.message)
          );
        })
      );
  }

  public updateDemoRequest(
    payload: UpdateDemoRequestParams,
    id: number
  ): Observable<DemoRequestsResponsse> {
    return this.http
      .put<DemoRequestsResponsse>(
        `${this.apiUrl}/api/organizations/demo-requests/${id}`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating demo request: ' + error.message)
          );
        })
      );
  }
}
