import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'primeng/select';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { GenerateReportFormComponent } from '../../../components/form-components/generate-report-form/generate-report-form.component';
import { DashboardFiltersComponent } from '../../../components/shared-components/dashboard-filters/dashboard-filters.component';
import { ReportStateSelectors } from '../../../../store/report-store/report.selectors';
import { Header, Pagination, SidebarData, TableActionEvent, TableRefreshEvent } from '../../../../store/general-store/general.model';
import { GetViewReportData, GetVisualizationData } from '../../../../store/report-store/report.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { Graph, Report, Visualization, VisualizationData } from '../../../../store/report-store/report.model';
import { map } from 'rxjs';
import { GraphListComponent } from '../../../components/shared-components/graphs-list/graph-list.component';
import { ReportLineChartComponent } from '../../../components/charts/report-line-chart/report-line-chart.component';
import { AddLineChartFormComponent } from '../../../components/form-components/add-line-chart-form/add-line-chart-form.component';
import { AddHeatMapFormComponent } from '../../../components/form-components/add-heat-map-form /add-heat-map-form.component';
import { AddBarChartFormComponent } from '../../../components/form-components/add-bar-chart-form/add-bar-chart-form.component';
import { ReportBarChartComponent } from '../../../components/charts/report-bar-chart/report-bar-chart.component';

@Component({
  selector: 'app-supplier-performance-report',
  imports: [
    CommonModule,
    SelectModule,
    RouterModule,
    BreadcrumbModule,
    DataTableComponent,
    ButtonModule,
    RightFormBaseComponent,
    GenerateReportFormComponent,
    DashboardFiltersComponent,
    GraphListComponent,
    ReportLineChartComponent,
    AddLineChartFormComponent,
    AddHeatMapFormComponent,
    AddBarChartFormComponent,
    ReportBarChartComponent
  ],
  templateUrl: './view.component.html',
})
export class ViewComponent implements OnInit {
  @Input() data?: Report;
  @Input() type?: string;
  @Input() title!: string;
  @Output() actionSuccess = new EventEmitter<boolean>();

  id!: number;
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  selectedGraph: Graph | null = null;
  selectedFilters: Record<string, any> = {};

  sidebar: SidebarData = {
    visible: false,
    title: 'Generate Custom Report',
    type: '',
    width: 'w-80',
  };
  chartSidebar: SidebarData = {
    visible: true,
    title: 'Chart Configuration',
    type: '',
    width: 'w-80',
  };

  data$: Signal<Report[]> = this.store.selectSignal(
    ReportStateSelectors.getViewReportData
  );

  visualizationData$: Signal<VisualizationData | null> = this.store.selectSignal(
    ReportStateSelectors.getVisualizationData
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    ReportStateSelectors.pagination
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    ReportStateSelectors.isProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    ReportStateSelectors.reportHeaders
  );

  report: Report;
  visualizations: Visualization[] = [];

  constructor(
    private store: Store,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });

    this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
      this.getViewReportData();
    });

    this.report = this.activatedRoute.snapshot.data['report'];
    this.pageTitle = this.report.reportName;

    effect(() => {
      if(this.report) {
        this.report.visualizations.forEach((v) => {
          if(this.report.id) {
            this.getVisualizationData(this.report.id, v.id);
          }
        })
      }
    })
  }

  ngOnInit() {}

  getVisualizationData(reportId: number, visualizationId: number) {
    this.store.dispatch(
      new GetVisualizationData(reportId, visualizationId)
    )
  }

  getViewReportData() {
    this.store.dispatch(
      new GetViewReportData(this.id, {
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  action() {}

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
    }
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getViewReportData();
  }

  setFilters(event: any) {}

  onGraphSelected(graph: Graph) {
    this.selectedGraph = graph;
    this.visualizations = this.report.visualizations.filter(v => v.visualizationType === this.selectedGraph?.type);
    this.visualizations.forEach((v) => {
      if(this.report.id) {
        this.getVisualizationData(this.report.id, v.id);
      }
    })
  }

  showChartSidebar() {
    this.chartSidebar.visible = true;
  }
}
